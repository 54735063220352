"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file debit_notes.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.DebitNotesService = void 0;
const debit_notes_scailo_pb_js_1 = require("./debit_notes.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
const families_scailo_pb_js_1 = require("./families.scailo_pb.js");
/**
 *
 * Describes the common methods applicable on each debit note
 *
 * @generated from service Scailo.DebitNotesService
 */
exports.DebitNotesService = {
    typeName: "Scailo.DebitNotesService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.DebitNotesService.Create
         */
        create: {
            name: "Create",
            I: debit_notes_scailo_pb_js_1.DebitNotesServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.DebitNotesService.Draft
         */
        draft: {
            name: "Draft",
            I: debit_notes_scailo_pb_js_1.DebitNotesServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.DebitNotesService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: debit_notes_scailo_pb_js_1.DebitNotesServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.DebitNotesService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.DebitNotesService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.DebitNotesService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.DebitNotesService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.DebitNotesService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: debit_notes_scailo_pb_js_1.DebitNotesServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.DebitNotesService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.DebitNotesService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.DebitNotesService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.DebitNotesService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.DebitNotesService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reopen
         *
         * @generated from rpc Scailo.DebitNotesService.Reopen
         */
        reopen: {
            name: "Reopen",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * @generated from rpc Scailo.DebitNotesService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send Email
         *
         * @generated from rpc Scailo.DebitNotesService.SendEmail
         */
        sendEmail: {
            name: "SendEmail",
            I: base_scailo_pb_js_1.IdentifierWithEmailAttributes,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Autofill the debit note
         *
         * @generated from rpc Scailo.DebitNotesService.Autofill
         */
        autofill: {
            name: "Autofill",
            I: debit_notes_scailo_pb_js_1.DebitNotesServiceAutofillRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an item to a debit note
         *
         * @generated from rpc Scailo.DebitNotesService.AddDebitNoteItem
         */
        addDebitNoteItem: {
            name: "AddDebitNoteItem",
            I: debit_notes_scailo_pb_js_1.DebitNotesServiceItemCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify an item in a debit note
         *
         * @generated from rpc Scailo.DebitNotesService.ModifyDebitNoteItem
         */
        modifyDebitNoteItem: {
            name: "ModifyDebitNoteItem",
            I: debit_notes_scailo_pb_js_1.DebitNotesServiceItemUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve an item in a debit note
         *
         * @generated from rpc Scailo.DebitNotesService.ApproveDebitNoteItem
         */
        approveDebitNoteItem: {
            name: "ApproveDebitNoteItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an item in a debit note
         *
         * @generated from rpc Scailo.DebitNotesService.DeleteDebitNoteItem
         */
        deleteDebitNoteItem: {
            name: "DeleteDebitNoteItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder items in a debit note
         *
         * @generated from rpc Scailo.DebitNotesService.ReorderDebitNoteItems
         */
        reorderDebitNoteItems: {
            name: "ReorderDebitNoteItems",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Debit Note Item by ID
         *
         * @generated from rpc Scailo.DebitNotesService.ViewDebitNoteItemByID
         */
        viewDebitNoteItemByID: {
            name: "ViewDebitNoteItemByID",
            I: base_scailo_pb_js_1.Identifier,
            O: debit_notes_scailo_pb_js_1.DebitNoteItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved debit note items for given debit note ID
         *
         * @generated from rpc Scailo.DebitNotesService.ViewApprovedDebitNoteItems
         */
        viewApprovedDebitNoteItems: {
            name: "ViewApprovedDebitNoteItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: debit_notes_scailo_pb_js_1.DebitNoteItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved debit note items for given debit note ID
         *
         * @generated from rpc Scailo.DebitNotesService.ViewUnapprovedDebitNoteItems
         */
        viewUnapprovedDebitNoteItems: {
            name: "ViewUnapprovedDebitNoteItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: debit_notes_scailo_pb_js_1.DebitNoteItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the debit note item
         *
         * @generated from rpc Scailo.DebitNotesService.ViewDebitNoteItemHistory
         */
        viewDebitNoteItemHistory: {
            name: "ViewDebitNoteItemHistory",
            I: debit_notes_scailo_pb_js_1.DebitNoteItemHistoryRequest,
            O: debit_notes_scailo_pb_js_1.DebitNoteItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved debit note items for given debit note ID with pagination
         *
         * @generated from rpc Scailo.DebitNotesService.ViewPaginatedApprovedDebitNoteItems
         */
        viewPaginatedApprovedDebitNoteItems: {
            name: "ViewPaginatedApprovedDebitNoteItems",
            I: debit_notes_scailo_pb_js_1.DebitNoteItemsSearchRequest,
            O: debit_notes_scailo_pb_js_1.DebitNotesServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved debit note items for given debit note ID with pagination
         *
         * @generated from rpc Scailo.DebitNotesService.ViewPaginatedUnapprovedDebitNoteItems
         */
        viewPaginatedUnapprovedDebitNoteItems: {
            name: "ViewPaginatedUnapprovedDebitNoteItems",
            I: debit_notes_scailo_pb_js_1.DebitNoteItemsSearchRequest,
            O: debit_notes_scailo_pb_js_1.DebitNotesServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through debit note items with pagination
         *
         * @generated from rpc Scailo.DebitNotesService.SearchItemsWithPagination
         */
        searchItemsWithPagination: {
            name: "SearchItemsWithPagination",
            I: debit_notes_scailo_pb_js_1.DebitNoteItemsSearchRequest,
            O: debit_notes_scailo_pb_js_1.DebitNotesServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * CSV operations
         * Download the CSV file that could be used to upload items from the filled CSV file. The same file can also be used as a template when there are no existing records
         *
         * @generated from rpc Scailo.DebitNotesService.DownloadItemsAsCSV
         */
        downloadItemsAsCSV: {
            name: "DownloadItemsAsCSV",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Upload items using a CSV file. This is an idempotent operation. All the existing items are deleted before adding the items from the file.
         *
         * @generated from rpc Scailo.DebitNotesService.UploadDebitNoteItems
         */
        uploadDebitNoteItems: {
            name: "UploadDebitNoteItems",
            I: base_scailo_pb_js_1.IdentifierUUIDWithFile,
            O: base_scailo_pb_js_1.IdentifiersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add a reference
         *
         * @generated from rpc Scailo.DebitNotesService.AddDebitNoteReference
         */
        addDebitNoteReference: {
            name: "AddDebitNoteReference",
            I: debit_notes_scailo_pb_js_1.DebitNotesServiceReferenceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve a reference
         *
         * @generated from rpc Scailo.DebitNotesService.ApproveDebitNoteReference
         */
        approveDebitNoteReference: {
            name: "ApproveDebitNoteReference",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete a reference
         *
         * @generated from rpc Scailo.DebitNotesService.DeleteDebitNoteReference
         */
        deleteDebitNoteReference: {
            name: "DeleteDebitNoteReference",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View a reference for the given ID
         *
         * @generated from rpc Scailo.DebitNotesService.ViewDebitNoteReferenceByID
         */
        viewDebitNoteReferenceByID: {
            name: "ViewDebitNoteReferenceByID",
            I: base_scailo_pb_js_1.Identifier,
            O: debit_notes_scailo_pb_js_1.DebitNoteReference,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all references for given debit note ID
         *
         * @generated from rpc Scailo.DebitNotesService.ViewDebitNoteReferences
         */
        viewDebitNoteReferences: {
            name: "ViewDebitNoteReferences",
            I: base_scailo_pb_js_1.Identifier,
            O: debit_notes_scailo_pb_js_1.DebitNoteReferencesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.DebitNotesService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: debit_notes_scailo_pb_js_1.DebitNote,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by UUID
         *
         * @generated from rpc Scailo.DebitNotesService.ViewByUUID
         */
        viewByUUID: {
            name: "ViewByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: debit_notes_scailo_pb_js_1.DebitNote,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View only essential components by ID (without logs)
         *
         * @generated from rpc Scailo.DebitNotesService.ViewEssentialByID
         */
        viewEssentialByID: {
            name: "ViewEssentialByID",
            I: base_scailo_pb_js_1.Identifier,
            O: debit_notes_scailo_pb_js_1.DebitNote,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View only essential components (without logs) that matches the given UUID
         *
         * @generated from rpc Scailo.DebitNotesService.ViewEssentialByUUID
         */
        viewEssentialByUUID: {
            name: "ViewEssentialByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: debit_notes_scailo_pb_js_1.DebitNote,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all records with the given IDs
         *
         * @generated from rpc Scailo.DebitNotesService.ViewFromIDs
         */
        viewFromIDs: {
            name: "ViewFromIDs",
            I: base_scailo_pb_js_1.IdentifiersList,
            O: debit_notes_scailo_pb_js_1.DebitNotesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the ancillary parameters (UUIDs of the internal references) by UUID
         *
         * @generated from rpc Scailo.DebitNotesService.ViewAncillaryParametersByUUID
         */
        viewAncillaryParametersByUUID: {
            name: "ViewAncillaryParametersByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: debit_notes_scailo_pb_js_1.DebitNoteAncillaryParameters,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.DebitNotesService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: debit_notes_scailo_pb_js_1.DebitNotesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.DebitNotesService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: debit_notes_scailo_pb_js_1.DebitNotesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.DebitNotesService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: debit_notes_scailo_pb_js_1.DebitNotesServicePaginationReq,
            O: debit_notes_scailo_pb_js_1.DebitNotesServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective families for the given debit note
         *
         * @generated from rpc Scailo.DebitNotesService.ViewProspectiveFamilies
         */
        viewProspectiveFamilies: {
            name: "ViewProspectiveFamilies",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Filter prospective families for the record represented by the given UUID identifier
         *
         * @generated from rpc Scailo.DebitNotesService.FilterProspectiveFamilies
         */
        filterProspectiveFamilies: {
            name: "FilterProspectiveFamilies",
            I: families_scailo_pb_js_1.FilterFamiliesReqForIdentifier,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective debit note item info for the given family ID and debit note ID
         *
         * @generated from rpc Scailo.DebitNotesService.ViewProspectiveDebitNoteItem
         */
        viewProspectiveDebitNoteItem: {
            name: "ViewProspectiveDebitNoteItem",
            I: debit_notes_scailo_pb_js_1.DebitNoteItemProspectiveInfoRequest,
            O: debit_notes_scailo_pb_js_1.DebitNotesServiceItemCreateRequest,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Other view operations
         * Download debit note with the given IdentifierUUID (can be used to allow public downloads)
         *
         * @generated from rpc Scailo.DebitNotesService.DownloadByUUID
         */
        downloadByUUID: {
            name: "DownloadByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View already added quantities
         *
         * @generated from rpc Scailo.DebitNotesService.ViewAddedFamilyQuantityForSource
         */
        viewAddedFamilyQuantityForSource: {
            name: "ViewAddedFamilyQuantityForSource",
            I: debit_notes_scailo_pb_js_1.DebitNotesServiceAlreadyAddedQuantityForSourceRequest,
            O: base_scailo_pb_js_1.DualQuantitiesResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.DebitNotesService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: debit_notes_scailo_pb_js_1.DebitNotesServiceSearchAllReq,
            O: debit_notes_scailo_pb_js_1.DebitNotesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.DebitNotesService.Filter
         */
        filter: {
            name: "Filter",
            I: debit_notes_scailo_pb_js_1.DebitNotesServiceFilterReq,
            O: debit_notes_scailo_pb_js_1.DebitNotesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.DebitNotesService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given count criteria
         *
         * @generated from rpc Scailo.DebitNotesService.Count
         */
        count: {
            name: "Count",
            I: debit_notes_scailo_pb_js_1.DebitNotesServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * CSV operations
         * Download the CSV file that consists of the list of records according to the given filter request. The same file could also be used as a template for uploading records
         *
         * @generated from rpc Scailo.DebitNotesService.DownloadAsCSV
         */
        downloadAsCSV: {
            name: "DownloadAsCSV",
            I: debit_notes_scailo_pb_js_1.DebitNotesServiceFilterReq,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
