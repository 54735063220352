"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file quotations_requests.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuotationsRequestsServiceSearchAllReq = exports.QuotationsRequestsServiceCountReq = exports.QuotationsRequestsServiceFilterReq = exports.QuotationsRequestsServicePaginationResponse = exports.QuotationsRequestsServicePaginationReq = exports.QuotationsRequestsServicePaginatedItemsResponse = exports.QuotationRequestItemsSearchRequest = exports.QuotationRequestItemHistoryRequest = exports.QuotationsRequestsItemsList = exports.QuotationsRequestsList = exports.QuotationRequestItem = exports.QuotationsRequestsServiceItemUpdateRequest = exports.QuotationsRequestsServiceItemCreateRequest = exports.QuotationRequest = exports.QuotationRequestAncillaryParameters = exports.QuotationsRequestsServiceAutofillRequest = exports.QuotationsRequestsServiceUpdateRequest = exports.QuotationsRequestsServiceCreateRequest = exports.QUOTATION_REQUEST_SORT_KEY = exports.QUOTATION_REQUEST_ITEM_STATUS = exports.QUOTATION_REQUEST_ITEM_SORT_KEY = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const forms_fields_data_scailo_pb_js_1 = require("./forms_fields_data.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.QUOTATION_REQUEST_ITEM_SORT_KEY
 */
var QUOTATION_REQUEST_ITEM_SORT_KEY;
(function (QUOTATION_REQUEST_ITEM_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: QUOTATION_REQUEST_ITEM_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    QUOTATION_REQUEST_ITEM_SORT_KEY[QUOTATION_REQUEST_ITEM_SORT_KEY["QUOTATION_REQUEST_ITEM_SORT_KEY_ID_UNSPECIFIED"] = 0] = "QUOTATION_REQUEST_ITEM_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: QUOTATION_REQUEST_ITEM_SORT_KEY_CREATED_AT = 1;
     */
    QUOTATION_REQUEST_ITEM_SORT_KEY[QUOTATION_REQUEST_ITEM_SORT_KEY["QUOTATION_REQUEST_ITEM_SORT_KEY_CREATED_AT"] = 1] = "QUOTATION_REQUEST_ITEM_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: QUOTATION_REQUEST_ITEM_SORT_KEY_MODIFIED_AT = 2;
     */
    QUOTATION_REQUEST_ITEM_SORT_KEY[QUOTATION_REQUEST_ITEM_SORT_KEY["QUOTATION_REQUEST_ITEM_SORT_KEY_MODIFIED_AT"] = 2] = "QUOTATION_REQUEST_ITEM_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: QUOTATION_REQUEST_ITEM_SORT_KEY_APPROVED_ON = 3;
     */
    QUOTATION_REQUEST_ITEM_SORT_KEY[QUOTATION_REQUEST_ITEM_SORT_KEY["QUOTATION_REQUEST_ITEM_SORT_KEY_APPROVED_ON"] = 3] = "QUOTATION_REQUEST_ITEM_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: QUOTATION_REQUEST_ITEM_SORT_KEY_APPROVED_BY = 4;
     */
    QUOTATION_REQUEST_ITEM_SORT_KEY[QUOTATION_REQUEST_ITEM_SORT_KEY["QUOTATION_REQUEST_ITEM_SORT_KEY_APPROVED_BY"] = 4] = "QUOTATION_REQUEST_ITEM_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: QUOTATION_REQUEST_ITEM_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    QUOTATION_REQUEST_ITEM_SORT_KEY[QUOTATION_REQUEST_ITEM_SORT_KEY["QUOTATION_REQUEST_ITEM_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "QUOTATION_REQUEST_ITEM_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the name
     *
     * @generated from enum value: QUOTATION_REQUEST_ITEM_SORT_KEY_NAME = 10;
     */
    QUOTATION_REQUEST_ITEM_SORT_KEY[QUOTATION_REQUEST_ITEM_SORT_KEY["QUOTATION_REQUEST_ITEM_SORT_KEY_NAME"] = 10] = "QUOTATION_REQUEST_ITEM_SORT_KEY_NAME";
    /**
     * Fetch ordered results by the baseline price
     *
     * @generated from enum value: QUOTATION_REQUEST_ITEM_SORT_KEY_BASELINE_PRICE = 11;
     */
    QUOTATION_REQUEST_ITEM_SORT_KEY[QUOTATION_REQUEST_ITEM_SORT_KEY["QUOTATION_REQUEST_ITEM_SORT_KEY_BASELINE_PRICE"] = 11] = "QUOTATION_REQUEST_ITEM_SORT_KEY_BASELINE_PRICE";
    /**
     * Fetch ordered results by the quantity
     *
     * @generated from enum value: QUOTATION_REQUEST_ITEM_SORT_KEY_QUANTITY = 12;
     */
    QUOTATION_REQUEST_ITEM_SORT_KEY[QUOTATION_REQUEST_ITEM_SORT_KEY["QUOTATION_REQUEST_ITEM_SORT_KEY_QUANTITY"] = 12] = "QUOTATION_REQUEST_ITEM_SORT_KEY_QUANTITY";
    /**
     * Fetch ordered results by the delivery date
     *
     * @generated from enum value: QUOTATION_REQUEST_ITEM_SORT_KEY_DELIVERY_DATE = 13;
     */
    QUOTATION_REQUEST_ITEM_SORT_KEY[QUOTATION_REQUEST_ITEM_SORT_KEY["QUOTATION_REQUEST_ITEM_SORT_KEY_DELIVERY_DATE"] = 13] = "QUOTATION_REQUEST_ITEM_SORT_KEY_DELIVERY_DATE";
})(QUOTATION_REQUEST_ITEM_SORT_KEY || (exports.QUOTATION_REQUEST_ITEM_SORT_KEY = QUOTATION_REQUEST_ITEM_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(QUOTATION_REQUEST_ITEM_SORT_KEY)
protobuf_1.proto3.util.setEnumType(QUOTATION_REQUEST_ITEM_SORT_KEY, "Scailo.QUOTATION_REQUEST_ITEM_SORT_KEY", [
    { no: 0, name: "QUOTATION_REQUEST_ITEM_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "QUOTATION_REQUEST_ITEM_SORT_KEY_CREATED_AT" },
    { no: 2, name: "QUOTATION_REQUEST_ITEM_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "QUOTATION_REQUEST_ITEM_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "QUOTATION_REQUEST_ITEM_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "QUOTATION_REQUEST_ITEM_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 10, name: "QUOTATION_REQUEST_ITEM_SORT_KEY_NAME" },
    { no: 11, name: "QUOTATION_REQUEST_ITEM_SORT_KEY_BASELINE_PRICE" },
    { no: 12, name: "QUOTATION_REQUEST_ITEM_SORT_KEY_QUANTITY" },
    { no: 13, name: "QUOTATION_REQUEST_ITEM_SORT_KEY_DELIVERY_DATE" },
]);
/**
 *
 * Describes the applicable statuses of quotation request items
 *
 * @generated from enum Scailo.QUOTATION_REQUEST_ITEM_STATUS
 */
var QUOTATION_REQUEST_ITEM_STATUS;
(function (QUOTATION_REQUEST_ITEM_STATUS) {
    /**
     * Denotes that status be disregarded. This is used only within search APIs
     *
     * @generated from enum value: QUOTATION_REQUEST_ITEM_STATUS_ANY_UNSPECIFIED = 0;
     */
    QUOTATION_REQUEST_ITEM_STATUS[QUOTATION_REQUEST_ITEM_STATUS["QUOTATION_REQUEST_ITEM_STATUS_ANY_UNSPECIFIED"] = 0] = "QUOTATION_REQUEST_ITEM_STATUS_ANY_UNSPECIFIED";
    /**
     * Denotes that the quotation request items must have been approved
     *
     * @generated from enum value: QUOTATION_REQUEST_ITEM_STATUS_APPROVED = 1;
     */
    QUOTATION_REQUEST_ITEM_STATUS[QUOTATION_REQUEST_ITEM_STATUS["QUOTATION_REQUEST_ITEM_STATUS_APPROVED"] = 1] = "QUOTATION_REQUEST_ITEM_STATUS_APPROVED";
    /**
     * Denotes that the quotation request items must be waiting for approval
     *
     * @generated from enum value: QUOTATION_REQUEST_ITEM_STATUS_UNAPPROVED = 2;
     */
    QUOTATION_REQUEST_ITEM_STATUS[QUOTATION_REQUEST_ITEM_STATUS["QUOTATION_REQUEST_ITEM_STATUS_UNAPPROVED"] = 2] = "QUOTATION_REQUEST_ITEM_STATUS_UNAPPROVED";
})(QUOTATION_REQUEST_ITEM_STATUS || (exports.QUOTATION_REQUEST_ITEM_STATUS = QUOTATION_REQUEST_ITEM_STATUS = {}));
// Retrieve enum metadata with: proto3.getEnumType(QUOTATION_REQUEST_ITEM_STATUS)
protobuf_1.proto3.util.setEnumType(QUOTATION_REQUEST_ITEM_STATUS, "Scailo.QUOTATION_REQUEST_ITEM_STATUS", [
    { no: 0, name: "QUOTATION_REQUEST_ITEM_STATUS_ANY_UNSPECIFIED" },
    { no: 1, name: "QUOTATION_REQUEST_ITEM_STATUS_APPROVED" },
    { no: 2, name: "QUOTATION_REQUEST_ITEM_STATUS_UNAPPROVED" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.QUOTATION_REQUEST_SORT_KEY
 */
var QUOTATION_REQUEST_SORT_KEY;
(function (QUOTATION_REQUEST_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: QUOTATION_REQUEST_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    QUOTATION_REQUEST_SORT_KEY[QUOTATION_REQUEST_SORT_KEY["QUOTATION_REQUEST_SORT_KEY_ID_UNSPECIFIED"] = 0] = "QUOTATION_REQUEST_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: QUOTATION_REQUEST_SORT_KEY_CREATED_AT = 1;
     */
    QUOTATION_REQUEST_SORT_KEY[QUOTATION_REQUEST_SORT_KEY["QUOTATION_REQUEST_SORT_KEY_CREATED_AT"] = 1] = "QUOTATION_REQUEST_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: QUOTATION_REQUEST_SORT_KEY_MODIFIED_AT = 2;
     */
    QUOTATION_REQUEST_SORT_KEY[QUOTATION_REQUEST_SORT_KEY["QUOTATION_REQUEST_SORT_KEY_MODIFIED_AT"] = 2] = "QUOTATION_REQUEST_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: QUOTATION_REQUEST_SORT_KEY_APPROVED_ON = 3;
     */
    QUOTATION_REQUEST_SORT_KEY[QUOTATION_REQUEST_SORT_KEY["QUOTATION_REQUEST_SORT_KEY_APPROVED_ON"] = 3] = "QUOTATION_REQUEST_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: QUOTATION_REQUEST_SORT_KEY_APPROVED_BY = 4;
     */
    QUOTATION_REQUEST_SORT_KEY[QUOTATION_REQUEST_SORT_KEY["QUOTATION_REQUEST_SORT_KEY_APPROVED_BY"] = 4] = "QUOTATION_REQUEST_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: QUOTATION_REQUEST_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    QUOTATION_REQUEST_SORT_KEY[QUOTATION_REQUEST_SORT_KEY["QUOTATION_REQUEST_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "QUOTATION_REQUEST_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the approver's completed on timestamp
     *
     * @generated from enum value: QUOTATION_REQUEST_SORT_KEY_COMPLETED_ON = 6;
     */
    QUOTATION_REQUEST_SORT_KEY[QUOTATION_REQUEST_SORT_KEY["QUOTATION_REQUEST_SORT_KEY_COMPLETED_ON"] = 6] = "QUOTATION_REQUEST_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch ordered results by the reference ID
     *
     * @generated from enum value: QUOTATION_REQUEST_SORT_KEY_REFERENCE_ID = 10;
     */
    QUOTATION_REQUEST_SORT_KEY[QUOTATION_REQUEST_SORT_KEY["QUOTATION_REQUEST_SORT_KEY_REFERENCE_ID"] = 10] = "QUOTATION_REQUEST_SORT_KEY_REFERENCE_ID";
    /**
     * Fetch ordered results by the final ref number
     *
     * @generated from enum value: QUOTATION_REQUEST_SORT_KEY_FINAL_REF_NUMBER = 11;
     */
    QUOTATION_REQUEST_SORT_KEY[QUOTATION_REQUEST_SORT_KEY["QUOTATION_REQUEST_SORT_KEY_FINAL_REF_NUMBER"] = 11] = "QUOTATION_REQUEST_SORT_KEY_FINAL_REF_NUMBER";
    /**
     * Fetch ordered results by the priority
     *
     * @generated from enum value: QUOTATION_REQUEST_SORT_KEY_PRIORITY = 14;
     */
    QUOTATION_REQUEST_SORT_KEY[QUOTATION_REQUEST_SORT_KEY["QUOTATION_REQUEST_SORT_KEY_PRIORITY"] = 14] = "QUOTATION_REQUEST_SORT_KEY_PRIORITY";
})(QUOTATION_REQUEST_SORT_KEY || (exports.QUOTATION_REQUEST_SORT_KEY = QUOTATION_REQUEST_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(QUOTATION_REQUEST_SORT_KEY)
protobuf_1.proto3.util.setEnumType(QUOTATION_REQUEST_SORT_KEY, "Scailo.QUOTATION_REQUEST_SORT_KEY", [
    { no: 0, name: "QUOTATION_REQUEST_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "QUOTATION_REQUEST_SORT_KEY_CREATED_AT" },
    { no: 2, name: "QUOTATION_REQUEST_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "QUOTATION_REQUEST_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "QUOTATION_REQUEST_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "QUOTATION_REQUEST_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 6, name: "QUOTATION_REQUEST_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "QUOTATION_REQUEST_SORT_KEY_REFERENCE_ID" },
    { no: 11, name: "QUOTATION_REQUEST_SORT_KEY_FINAL_REF_NUMBER" },
    { no: 14, name: "QUOTATION_REQUEST_SORT_KEY_PRIORITY" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.QuotationsRequestsServiceCreateRequest
 */
class QuotationsRequestsServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the quotation request
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The associated purchase enquiry
     *
     * @generated from field: uint64 purchase_enquiry_id = 12;
     */
    purchaseEnquiryId = protobuf_1.protoInt64.zero;
    /**
     * The date until which quotations responses can be sent
     *
     * @generated from field: string last_date = 13;
     */
    lastDate = "";
    /**
     * The associated currency
     *
     * @generated from field: uint64 currency_id = 14;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The number of days until which the quotation request is valid
     *
     * @generated from field: uint64 renewal_period = 15;
     */
    renewalPeriod = protobuf_1.protoInt64.zero;
    /**
     * The priority of the quotation request. Possible values are "low", "medium", "high".
     *
     * @generated from field: string priority = 16;
     */
    priority = "";
    /**
     * The description of the quotation request
     *
     * @generated from field: string description = 17;
     */
    description = "";
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QuotationsRequestsServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "purchase_enquiry_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "last_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "renewal_period", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "priority", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 17, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new QuotationsRequestsServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QuotationsRequestsServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QuotationsRequestsServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QuotationsRequestsServiceCreateRequest, a, b);
    }
}
exports.QuotationsRequestsServiceCreateRequest = QuotationsRequestsServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.QuotationsRequestsServiceUpdateRequest
 */
class QuotationsRequestsServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the quotation request
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The date until which quotations responses can be sent
     *
     * @generated from field: string last_date = 13;
     */
    lastDate = "";
    /**
     * The associated currency
     *
     * @generated from field: uint64 currency_id = 14;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The number of days until which the quotation request is valid
     *
     * @generated from field: uint64 renewal_period = 15;
     */
    renewalPeriod = protobuf_1.protoInt64.zero;
    /**
     * The priority of the quotation request. Possible values are "low", "medium", "high".
     *
     * @generated from field: string priority = 16;
     */
    priority = "";
    /**
     * The description of the quotation request
     *
     * @generated from field: string description = 17;
     */
    description = "";
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QuotationsRequestsServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "last_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "renewal_period", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "priority", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 17, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new QuotationsRequestsServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QuotationsRequestsServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QuotationsRequestsServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QuotationsRequestsServiceUpdateRequest, a, b);
    }
}
exports.QuotationsRequestsServiceUpdateRequest = QuotationsRequestsServiceUpdateRequest;
/**
 *
 * Describes the parameters necessary to perform an autofill request
 *
 * @generated from message Scailo.QuotationsRequestsServiceAutofillRequest
 */
class QuotationsRequestsServiceAutofillRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The UUID of the record that needs to be updated
     *
     * @generated from field: string uuid = 2;
     */
    uuid = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QuotationsRequestsServiceAutofillRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new QuotationsRequestsServiceAutofillRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QuotationsRequestsServiceAutofillRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QuotationsRequestsServiceAutofillRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QuotationsRequestsServiceAutofillRequest, a, b);
    }
}
exports.QuotationsRequestsServiceAutofillRequest = QuotationsRequestsServiceAutofillRequest;
/**
 *
 * Stores the UUID references of the record
 *
 * @generated from message Scailo.QuotationRequestAncillaryParameters
 */
class QuotationRequestAncillaryParameters extends protobuf_1.Message {
    /**
     * The UUID of the purchase_enquiry_id (the UUID of the associated purchase_enquiry_id)
     *
     * @generated from field: string purchase_enquiry_uuid = 212;
     */
    purchaseEnquiryUuid = "";
    /**
     * The UUID of the currency (the UUID of the associated currency_id)
     *
     * @generated from field: string currency_uuid = 214;
     */
    currencyUuid = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QuotationRequestAncillaryParameters";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 212, name: "purchase_enquiry_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 214, name: "currency_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new QuotationRequestAncillaryParameters().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QuotationRequestAncillaryParameters().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QuotationRequestAncillaryParameters().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QuotationRequestAncillaryParameters, a, b);
    }
}
exports.QuotationRequestAncillaryParameters = QuotationRequestAncillaryParameters;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.QuotationRequest
 */
class QuotationRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this quotation request
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this quotation request
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this quotation request
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this quotation request was marked as completed
     *
     * @generated from field: uint64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the quotation request
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 11;
     */
    finalRefNumber = "";
    /**
     * The associated purchase enquiry
     *
     * @generated from field: uint64 purchase_enquiry_id = 12;
     */
    purchaseEnquiryId = protobuf_1.protoInt64.zero;
    /**
     * The date until which quotations responses can be sent
     *
     * @generated from field: string last_date = 13;
     */
    lastDate = "";
    /**
     * The associated currency
     *
     * @generated from field: uint64 currency_id = 14;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The number of days until which the quotation request is valid
     *
     * @generated from field: uint64 renewal_period = 15;
     */
    renewalPeriod = protobuf_1.protoInt64.zero;
    /**
     * The priority of the quotation request. Possible values are "low", "medium", "high".
     *
     * @generated from field: string priority = 16;
     */
    priority = "";
    /**
     * The description of the quotation request
     *
     * @generated from field: string description = 17;
     */
    description = "";
    /**
     * The list of associated quotation request items
     *
     * @generated from field: repeated Scailo.QuotationRequestItem list = 20;
     */
    list = [];
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatum form_data = 30;
     */
    formData = [];
    /**
     * Stores the total value of the quotation request (as a double, which requires no adjustments)
     *
     * @generated from field: double total_value = 50;
     */
    totalValue = 0;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QuotationRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "purchase_enquiry_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "last_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "renewal_period", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "priority", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 17, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "list", kind: "message", T: QuotationRequestItem, repeated: true },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatum, repeated: true },
        { no: 50, name: "total_value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    ]);
    static fromBinary(bytes, options) {
        return new QuotationRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QuotationRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QuotationRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QuotationRequest, a, b);
    }
}
exports.QuotationRequest = QuotationRequest;
/**
 *
 * Describes the parameters required to add an item to a quotation request
 *
 * @generated from message Scailo.QuotationsRequestsServiceItemCreateRequest
 */
class QuotationsRequestsServiceItemCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quotation request ID
     *
     * @generated from field: uint64 quotation_request_id = 10;
     */
    quotationRequestId = protobuf_1.protoInt64.zero;
    /**
     * Stores the name of the item
     *
     * @generated from field: string name = 11;
     */
    name = "";
    /**
     * Stores the HSN/SAC code of the item
     *
     * @generated from field: string hsn_sac_code = 12;
     */
    hsnSacCode = "";
    /**
     * The min price (in cents)
     *
     * @generated from field: uint64 baseline_price = 13;
     */
    baselinePrice = protobuf_1.protoInt64.zero;
    /**
     * The quantity required (in cents)
     *
     * @generated from field: uint64 quantity = 14;
     */
    quantity = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated unit of material
     *
     * @generated from field: uint64 uom_id = 15;
     */
    uomId = protobuf_1.protoInt64.zero;
    /**
     * The delivery date
     *
     * @generated from field: string delivery_date = 16;
     */
    deliveryDate = "";
    /**
     * The specifications of the item
     *
     * @generated from field: string specifications = 17;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QuotationsRequestsServiceItemCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "quotation_request_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "hsn_sac_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "baseline_price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "delivery_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 17, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new QuotationsRequestsServiceItemCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QuotationsRequestsServiceItemCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QuotationsRequestsServiceItemCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QuotationsRequestsServiceItemCreateRequest, a, b);
    }
}
exports.QuotationsRequestsServiceItemCreateRequest = QuotationsRequestsServiceItemCreateRequest;
/**
 *
 * Describes the parameters required to update an item in a quotation request
 *
 * @generated from message Scailo.QuotationsRequestsServiceItemUpdateRequest
 */
class QuotationsRequestsServiceItemUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * Stores the name of the item
     *
     * @generated from field: string name = 11;
     */
    name = "";
    /**
     * Stores the HSN/SAC code of the item
     *
     * @generated from field: string hsn_sac_code = 12;
     */
    hsnSacCode = "";
    /**
     * The min price (in cents)
     *
     * @generated from field: uint64 baseline_price = 13;
     */
    baselinePrice = protobuf_1.protoInt64.zero;
    /**
     * The quantity required (in cents)
     *
     * @generated from field: uint64 quantity = 14;
     */
    quantity = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated unit of material
     *
     * @generated from field: uint64 uom_id = 15;
     */
    uomId = protobuf_1.protoInt64.zero;
    /**
     * The delivery date
     *
     * @generated from field: string delivery_date = 16;
     */
    deliveryDate = "";
    /**
     * The specifications of the item
     *
     * @generated from field: string specifications = 17;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QuotationsRequestsServiceItemUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "hsn_sac_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "baseline_price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "delivery_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 17, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new QuotationsRequestsServiceItemUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QuotationsRequestsServiceItemUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QuotationsRequestsServiceItemUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QuotationsRequestsServiceItemUpdateRequest, a, b);
    }
}
exports.QuotationsRequestsServiceItemUpdateRequest = QuotationsRequestsServiceItemUpdateRequest;
/**
 *
 * Describes the parameters that constitute an item associated to a quotation request
 *
 * @generated from message Scailo.QuotationRequestItem
 */
class QuotationRequestItem extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this quotation request
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quotation request ID
     *
     * @generated from field: uint64 quotation_request_id = 10;
     */
    quotationRequestId = protobuf_1.protoInt64.zero;
    /**
     * Stores the name of the item
     *
     * @generated from field: string name = 11;
     */
    name = "";
    /**
     * Stores the HSN/SAC code of the item
     *
     * @generated from field: string hsn_sac_code = 12;
     */
    hsnSacCode = "";
    /**
     * The min price (in cents)
     *
     * @generated from field: uint64 baseline_price = 13;
     */
    baselinePrice = protobuf_1.protoInt64.zero;
    /**
     * The quantity required (in cents)
     *
     * @generated from field: uint64 quantity = 14;
     */
    quantity = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated unit of material
     *
     * @generated from field: uint64 uom_id = 15;
     */
    uomId = protobuf_1.protoInt64.zero;
    /**
     * The delivery date
     *
     * @generated from field: string delivery_date = 16;
     */
    deliveryDate = "";
    /**
     * The specifications of the item
     *
     * @generated from field: string specifications = 17;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QuotationRequestItem";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "quotation_request_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "hsn_sac_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "baseline_price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "delivery_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 17, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new QuotationRequestItem().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QuotationRequestItem().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QuotationRequestItem().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QuotationRequestItem, a, b);
    }
}
exports.QuotationRequestItem = QuotationRequestItem;
/**
 *
 * Describes the message consisting of the list of quotations requests
 *
 * @generated from message Scailo.QuotationsRequestsList
 */
class QuotationsRequestsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.QuotationRequest list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QuotationsRequestsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: QuotationRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new QuotationsRequestsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QuotationsRequestsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QuotationsRequestsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QuotationsRequestsList, a, b);
    }
}
exports.QuotationsRequestsList = QuotationsRequestsList;
/**
 *
 * Describes the message consisting of the list of quotation request items
 *
 * @generated from message Scailo.QuotationsRequestsItemsList
 */
class QuotationsRequestsItemsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.QuotationRequestItem list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QuotationsRequestsItemsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: QuotationRequestItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new QuotationsRequestsItemsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QuotationsRequestsItemsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QuotationsRequestsItemsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QuotationsRequestsItemsList, a, b);
    }
}
exports.QuotationsRequestsItemsList = QuotationsRequestsItemsList;
/**
 *
 * Describes the parameters that are required to retrieve the history of the record
 *
 * @generated from message Scailo.QuotationRequestItemHistoryRequest
 */
class QuotationRequestItemHistoryRequest extends protobuf_1.Message {
    /**
     * Stores the quotation request ID
     *
     * @generated from field: uint64 quotation_request_id = 10;
     */
    quotationRequestId = protobuf_1.protoInt64.zero;
    /**
     * Stores the name of the item
     *
     * @generated from field: string name = 11;
     */
    name = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QuotationRequestItemHistoryRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "quotation_request_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new QuotationRequestItemHistoryRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QuotationRequestItemHistoryRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QuotationRequestItemHistoryRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QuotationRequestItemHistoryRequest, a, b);
    }
}
exports.QuotationRequestItemHistoryRequest = QuotationRequestItemHistoryRequest;
/**
 *
 * Describes the request payload to retrieve approved or unapproved items.
 *
 * @generated from message Scailo.QuotationRequestItemsSearchRequest
 */
class QuotationRequestItemsSearchRequest extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.QUOTATION_REQUEST_ITEM_SORT_KEY sort_key = 5;
     */
    sortKey = QUOTATION_REQUEST_ITEM_SORT_KEY.QUOTATION_REQUEST_ITEM_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * The status of the items
     *
     * @generated from field: Scailo.QUOTATION_REQUEST_ITEM_STATUS status = 7;
     */
    status = QUOTATION_REQUEST_ITEM_STATUS.QUOTATION_REQUEST_ITEM_STATUS_ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 10;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 11;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 12;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 13;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quotation request ID
     *
     * @generated from field: uint64 quotation_request_id = 20;
     */
    quotationRequestId = protobuf_1.protoInt64.zero;
    /**
     * Stores an optional unit of material
     *
     * @generated from field: uint64 uom_id = 21;
     */
    uomId = protobuf_1.protoInt64.zero;
    /**
     * The exact delivery date of the item in the purchase indent
     *
     * @generated from field: string delivery_date_exact = 28;
     */
    deliveryDateExact = "";
    /**
     * The start delivery date of the item in the purchase indent
     *
     * @generated from field: string delivery_date_start = 29;
     */
    deliveryDateStart = "";
    /**
     * The end delivery date of the item in the purchase indent
     *
     * @generated from field: string delivery_date_end = 30;
     */
    deliveryDateEnd = "";
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 40;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QuotationRequestItemsSearchRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(QUOTATION_REQUEST_ITEM_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 7, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(QUOTATION_REQUEST_ITEM_STATUS) },
        { no: 10, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "quotation_request_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 28, name: "delivery_date_exact", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 29, name: "delivery_date_start", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "delivery_date_end", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 40, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new QuotationRequestItemsSearchRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QuotationRequestItemsSearchRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QuotationRequestItemsSearchRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QuotationRequestItemsSearchRequest, a, b);
    }
}
exports.QuotationRequestItemsSearchRequest = QuotationRequestItemsSearchRequest;
/**
 *
 * Describes the response to a pagination items request
 *
 * @generated from message Scailo.QuotationsRequestsServicePaginatedItemsResponse
 */
class QuotationsRequestsServicePaginatedItemsResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.QuotationRequestItem payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QuotationsRequestsServicePaginatedItemsResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: QuotationRequestItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new QuotationsRequestsServicePaginatedItemsResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QuotationsRequestsServicePaginatedItemsResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QuotationsRequestsServicePaginatedItemsResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QuotationsRequestsServicePaginatedItemsResponse, a, b);
    }
}
exports.QuotationsRequestsServicePaginatedItemsResponse = QuotationsRequestsServicePaginatedItemsResponse;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.QuotationsRequestsServicePaginationReq
 */
class QuotationsRequestsServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.QUOTATION_REQUEST_SORT_KEY sort_key = 5;
     */
    sortKey = QUOTATION_REQUEST_SORT_KEY.QUOTATION_REQUEST_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this quotation request
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QuotationsRequestsServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(QUOTATION_REQUEST_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new QuotationsRequestsServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QuotationsRequestsServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QuotationsRequestsServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QuotationsRequestsServicePaginationReq, a, b);
    }
}
exports.QuotationsRequestsServicePaginationReq = QuotationsRequestsServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.QuotationsRequestsServicePaginationResponse
 */
class QuotationsRequestsServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.QuotationRequest payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QuotationsRequestsServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: QuotationRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new QuotationsRequestsServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QuotationsRequestsServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QuotationsRequestsServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QuotationsRequestsServicePaginationResponse, a, b);
    }
}
exports.QuotationsRequestsServicePaginationResponse = QuotationsRequestsServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.QuotationsRequestsServiceFilterReq
 */
class QuotationsRequestsServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.QUOTATION_REQUEST_SORT_KEY sort_key = 5;
     */
    sortKey = QUOTATION_REQUEST_SORT_KEY.QUOTATION_REQUEST_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this quotation request
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the quotation request
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The associated purchase enquiry
     *
     * @generated from field: uint64 purchase_enquiry_id = 22;
     */
    purchaseEnquiryId = protobuf_1.protoInt64.zero;
    /**
     * The associated currency
     *
     * @generated from field: uint64 currency_id = 24;
     */
    currencyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QuotationsRequestsServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(QUOTATION_REQUEST_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "purchase_enquiry_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new QuotationsRequestsServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QuotationsRequestsServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QuotationsRequestsServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QuotationsRequestsServiceFilterReq, a, b);
    }
}
exports.QuotationsRequestsServiceFilterReq = QuotationsRequestsServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.QuotationsRequestsServiceCountReq
 */
class QuotationsRequestsServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this quotation request
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the quotation request
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The associated purchase enquiry
     *
     * @generated from field: uint64 purchase_enquiry_id = 22;
     */
    purchaseEnquiryId = protobuf_1.protoInt64.zero;
    /**
     * The associated currency
     *
     * @generated from field: uint64 currency_id = 24;
     */
    currencyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QuotationsRequestsServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "purchase_enquiry_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new QuotationsRequestsServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QuotationsRequestsServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QuotationsRequestsServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QuotationsRequestsServiceCountReq, a, b);
    }
}
exports.QuotationsRequestsServiceCountReq = QuotationsRequestsServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.QuotationsRequestsServiceSearchAllReq
 */
class QuotationsRequestsServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.QUOTATION_REQUEST_SORT_KEY sort_key = 5;
     */
    sortKey = QUOTATION_REQUEST_SORT_KEY.QUOTATION_REQUEST_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QuotationsRequestsServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(QUOTATION_REQUEST_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new QuotationsRequestsServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QuotationsRequestsServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QuotationsRequestsServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QuotationsRequestsServiceSearchAllReq, a, b);
    }
}
exports.QuotationsRequestsServiceSearchAllReq = QuotationsRequestsServiceSearchAllReq;
