"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file teams.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.TeamsService = void 0;
const teams_scailo_pb_js_1 = require("./teams.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
/**
 *
 * Describes the common methods applicable on each team
 *
 * @generated from service Scailo.TeamsService
 */
exports.TeamsService = {
    typeName: "Scailo.TeamsService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.TeamsService.Create
         */
        create: {
            name: "Create",
            I: teams_scailo_pb_js_1.TeamsServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.TeamsService.Draft
         */
        draft: {
            name: "Draft",
            I: teams_scailo_pb_js_1.TeamsServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.TeamsService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: teams_scailo_pb_js_1.TeamsServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.TeamsService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.TeamsService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.TeamsService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.TeamsService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.TeamsService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: teams_scailo_pb_js_1.TeamsServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.TeamsService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.TeamsService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.TeamsService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.TeamsService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.TeamsService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reopen
         *
         * @generated from rpc Scailo.TeamsService.Reopen
         */
        reopen: {
            name: "Reopen",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * Send Email
         * rpc SendEmail (IdentifierWithEmailAttributes) returns (IdentifierResponse);
         *
         * @generated from rpc Scailo.TeamsService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Clone team from an existing team (denoted by the identifier)
         *
         * @generated from rpc Scailo.TeamsService.Clone
         */
        clone: {
            name: "Clone",
            I: base_scailo_pb_js_1.CloneRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add a member to a team
         *
         * @generated from rpc Scailo.TeamsService.AddTeamMember
         */
        addTeamMember: {
            name: "AddTeamMember",
            I: teams_scailo_pb_js_1.TeamsServiceMemberCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify a member in a team
         *
         * @generated from rpc Scailo.TeamsService.ModifyTeamMember
         */
        modifyTeamMember: {
            name: "ModifyTeamMember",
            I: teams_scailo_pb_js_1.TeamsServiceMemberUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve a member in a team
         *
         * @generated from rpc Scailo.TeamsService.ApproveTeamMember
         */
        approveTeamMember: {
            name: "ApproveTeamMember",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete a member in a team
         *
         * @generated from rpc Scailo.TeamsService.DeleteTeamMember
         */
        deleteTeamMember: {
            name: "DeleteTeamMember",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder members in a team
         *
         * @generated from rpc Scailo.TeamsService.ReorderTeamMembers
         */
        reorderTeamMembers: {
            name: "ReorderTeamMembers",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Team Member by ID
         *
         * @generated from rpc Scailo.TeamsService.ViewTeamMemberByID
         */
        viewTeamMemberByID: {
            name: "ViewTeamMemberByID",
            I: base_scailo_pb_js_1.Identifier,
            O: teams_scailo_pb_js_1.TeamMember,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved team members for given team ID
         *
         * @generated from rpc Scailo.TeamsService.ViewApprovedTeamMembers
         */
        viewApprovedTeamMembers: {
            name: "ViewApprovedTeamMembers",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: teams_scailo_pb_js_1.TeamsMembersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved team members for given team ID
         *
         * @generated from rpc Scailo.TeamsService.ViewUnapprovedTeamMembers
         */
        viewUnapprovedTeamMembers: {
            name: "ViewUnapprovedTeamMembers",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: teams_scailo_pb_js_1.TeamsMembersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the team member
         *
         * @generated from rpc Scailo.TeamsService.ViewTeamMemberHistory
         */
        viewTeamMemberHistory: {
            name: "ViewTeamMemberHistory",
            I: teams_scailo_pb_js_1.TeamMemberHistoryRequest,
            O: teams_scailo_pb_js_1.TeamsMembersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved team members for given team ID with pagination
         *
         * @generated from rpc Scailo.TeamsService.ViewPaginatedApprovedTeamMembers
         */
        viewPaginatedApprovedTeamMembers: {
            name: "ViewPaginatedApprovedTeamMembers",
            I: teams_scailo_pb_js_1.TeamMembersSearchRequest,
            O: teams_scailo_pb_js_1.TeamsServicePaginatedMembersResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved team members for given team ID with pagination
         *
         * @generated from rpc Scailo.TeamsService.ViewPaginatedUnapprovedTeamMembers
         */
        viewPaginatedUnapprovedTeamMembers: {
            name: "ViewPaginatedUnapprovedTeamMembers",
            I: teams_scailo_pb_js_1.TeamMembersSearchRequest,
            O: teams_scailo_pb_js_1.TeamsServicePaginatedMembersResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through team members with pagination
         *
         * @generated from rpc Scailo.TeamsService.SearchMembersWithPagination
         */
        searchMembersWithPagination: {
            name: "SearchMembersWithPagination",
            I: teams_scailo_pb_js_1.TeamMembersSearchRequest,
            O: teams_scailo_pb_js_1.TeamsServicePaginatedMembersResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all the teams that the member is part of (and not the team lead)
         *
         * @generated from rpc Scailo.TeamsService.ViewTeamsForMember
         */
        viewTeamsForMember: {
            name: "ViewTeamsForMember",
            I: base_scailo_pb_js_1.Identifier,
            O: teams_scailo_pb_js_1.TeamsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.TeamsService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: teams_scailo_pb_js_1.Team,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by UUID
         *
         * @generated from rpc Scailo.TeamsService.ViewByUUID
         */
        viewByUUID: {
            name: "ViewByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: teams_scailo_pb_js_1.Team,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View only essential components by ID (without logs)
         *
         * @generated from rpc Scailo.TeamsService.ViewEssentialByID
         */
        viewEssentialByID: {
            name: "ViewEssentialByID",
            I: base_scailo_pb_js_1.Identifier,
            O: teams_scailo_pb_js_1.Team,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View only essential components (without logs) that matches the given UUID
         *
         * @generated from rpc Scailo.TeamsService.ViewEssentialByUUID
         */
        viewEssentialByUUID: {
            name: "ViewEssentialByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: teams_scailo_pb_js_1.Team,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all records with the given IDs
         *
         * @generated from rpc Scailo.TeamsService.ViewFromIDs
         */
        viewFromIDs: {
            name: "ViewFromIDs",
            I: base_scailo_pb_js_1.IdentifiersList,
            O: teams_scailo_pb_js_1.TeamsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.TeamsService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: teams_scailo_pb_js_1.TeamsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.TeamsService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: teams_scailo_pb_js_1.TeamsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.TeamsService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: teams_scailo_pb_js_1.TeamsServicePaginationReq,
            O: teams_scailo_pb_js_1.TeamsServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.TeamsService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: teams_scailo_pb_js_1.TeamsServiceSearchAllReq,
            O: teams_scailo_pb_js_1.TeamsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.TeamsService.Filter
         */
        filter: {
            name: "Filter",
            I: teams_scailo_pb_js_1.TeamsServiceFilterReq,
            O: teams_scailo_pb_js_1.TeamsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.TeamsService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.TeamsService.Count
         */
        count: {
            name: "Count",
            I: teams_scailo_pb_js_1.TeamsServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * CSV operations
         * Download the CSV file that consists of the list of records according to the given filter request. The same file could also be used as a template for uploading records
         *
         * @generated from rpc Scailo.TeamsService.DownloadAsCSV
         */
        downloadAsCSV: {
            name: "DownloadAsCSV",
            I: teams_scailo_pb_js_1.TeamsServiceFilterReq,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Import records using a CSV file (duplicate codes will be skipped)
         *
         * @generated from rpc Scailo.TeamsService.ImportFromCSV
         */
        importFromCSV: {
            name: "ImportFromCSV",
            I: base_scailo_pb_js_1.StandardFile,
            O: base_scailo_pb_js_1.IdentifierUUIDsList,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
