"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file asset_indents.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssetIndentsService = void 0;
const asset_indents_scailo_pb_js_1 = require("./asset_indents.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
const families_scailo_pb_js_1 = require("./families.scailo_pb.js");
/**
 *
 * Describes the common methods applicable on each asset indent
 *
 * @generated from service Scailo.AssetIndentsService
 */
exports.AssetIndentsService = {
    typeName: "Scailo.AssetIndentsService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.AssetIndentsService.Create
         */
        create: {
            name: "Create",
            I: asset_indents_scailo_pb_js_1.AssetIndentsServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.AssetIndentsService.Draft
         */
        draft: {
            name: "Draft",
            I: asset_indents_scailo_pb_js_1.AssetIndentsServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.AssetIndentsService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: asset_indents_scailo_pb_js_1.AssetIndentsServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.AssetIndentsService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.AssetIndentsService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.AssetIndentsService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.AssetIndentsService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.AssetIndentsService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: asset_indents_scailo_pb_js_1.AssetIndentsServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.AssetIndentsService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.AssetIndentsService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.AssetIndentsService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.AssetIndentsService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.AssetIndentsService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reopen
         *
         * @generated from rpc Scailo.AssetIndentsService.Reopen
         */
        reopen: {
            name: "Reopen",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * @generated from rpc Scailo.AssetIndentsService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send Email
         *
         * @generated from rpc Scailo.AssetIndentsService.SendEmail
         */
        sendEmail: {
            name: "SendEmail",
            I: base_scailo_pb_js_1.IdentifierWithEmailAttributes,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Checks if the Asset Indent can be marked as completed
         *
         * @generated from rpc Scailo.AssetIndentsService.IsCompletable
         */
        isCompletable: {
            name: "IsCompletable",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.BooleanResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an item to a asset indent
         *
         * @generated from rpc Scailo.AssetIndentsService.AddAssetIndentItem
         */
        addAssetIndentItem: {
            name: "AddAssetIndentItem",
            I: asset_indents_scailo_pb_js_1.AssetIndentsServiceItemCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify an item in a asset indent
         *
         * @generated from rpc Scailo.AssetIndentsService.ModifyAssetIndentItem
         */
        modifyAssetIndentItem: {
            name: "ModifyAssetIndentItem",
            I: asset_indents_scailo_pb_js_1.AssetIndentsServiceItemUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve an item in a asset indent
         *
         * @generated from rpc Scailo.AssetIndentsService.ApproveAssetIndentItem
         */
        approveAssetIndentItem: {
            name: "ApproveAssetIndentItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an item in a asset indent
         *
         * @generated from rpc Scailo.AssetIndentsService.DeleteAssetIndentItem
         */
        deleteAssetIndentItem: {
            name: "DeleteAssetIndentItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder items in a asset indent
         *
         * @generated from rpc Scailo.AssetIndentsService.ReorderAssetIndentItems
         */
        reorderAssetIndentItems: {
            name: "ReorderAssetIndentItems",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Asset Indent Item by ID
         *
         * @generated from rpc Scailo.AssetIndentsService.ViewAssetIndentItemByID
         */
        viewAssetIndentItemByID: {
            name: "ViewAssetIndentItemByID",
            I: base_scailo_pb_js_1.Identifier,
            O: asset_indents_scailo_pb_js_1.AssetIndentItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved asset indent items for given asset indent ID
         *
         * @generated from rpc Scailo.AssetIndentsService.ViewApprovedAssetIndentItems
         */
        viewApprovedAssetIndentItems: {
            name: "ViewApprovedAssetIndentItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: asset_indents_scailo_pb_js_1.AssetIndentsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved asset indent items for given asset indent ID
         *
         * @generated from rpc Scailo.AssetIndentsService.ViewUnapprovedAssetIndentItems
         */
        viewUnapprovedAssetIndentItems: {
            name: "ViewUnapprovedAssetIndentItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: asset_indents_scailo_pb_js_1.AssetIndentsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the asset indent item
         *
         * @generated from rpc Scailo.AssetIndentsService.ViewAssetIndentItemHistory
         */
        viewAssetIndentItemHistory: {
            name: "ViewAssetIndentItemHistory",
            I: asset_indents_scailo_pb_js_1.AssetIndentItemHistoryRequest,
            O: asset_indents_scailo_pb_js_1.AssetIndentsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved asset indent items for given asset indent ID with pagination
         *
         * @generated from rpc Scailo.AssetIndentsService.ViewPaginatedApprovedAssetIndentItems
         */
        viewPaginatedApprovedAssetIndentItems: {
            name: "ViewPaginatedApprovedAssetIndentItems",
            I: asset_indents_scailo_pb_js_1.AssetIndentItemsSearchRequest,
            O: asset_indents_scailo_pb_js_1.AssetIndentsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved asset indent items for given asset indent ID with pagination
         *
         * @generated from rpc Scailo.AssetIndentsService.ViewPaginatedUnapprovedAssetIndentItems
         */
        viewPaginatedUnapprovedAssetIndentItems: {
            name: "ViewPaginatedUnapprovedAssetIndentItems",
            I: asset_indents_scailo_pb_js_1.AssetIndentItemsSearchRequest,
            O: asset_indents_scailo_pb_js_1.AssetIndentsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through asset indent items with pagination
         *
         * @generated from rpc Scailo.AssetIndentsService.SearchItemsWithPagination
         */
        searchItemsWithPagination: {
            name: "SearchItemsWithPagination",
            I: asset_indents_scailo_pb_js_1.AssetIndentItemsSearchRequest,
            O: asset_indents_scailo_pb_js_1.AssetIndentsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * CSV operations
         * Download the CSV file that could be used to upload items from the filled CSV file. The same file can also be used as a template when there are no existing records
         *
         * @generated from rpc Scailo.AssetIndentsService.DownloadItemsAsCSV
         */
        downloadItemsAsCSV: {
            name: "DownloadItemsAsCSV",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Upload items using a CSV file. This is an idempotent operation. All the existing items are deleted before adding the items from the file.
         *
         * @generated from rpc Scailo.AssetIndentsService.UploadAssetIndentItems
         */
        uploadAssetIndentItems: {
            name: "UploadAssetIndentItems",
            I: base_scailo_pb_js_1.IdentifierUUIDWithFile,
            O: base_scailo_pb_js_1.IdentifiersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.AssetIndentsService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: asset_indents_scailo_pb_js_1.AssetIndent,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by UUID
         *
         * @generated from rpc Scailo.AssetIndentsService.ViewByUUID
         */
        viewByUUID: {
            name: "ViewByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: asset_indents_scailo_pb_js_1.AssetIndent,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View only essential components by ID (without logs)
         *
         * @generated from rpc Scailo.AssetIndentsService.ViewEssentialByID
         */
        viewEssentialByID: {
            name: "ViewEssentialByID",
            I: base_scailo_pb_js_1.Identifier,
            O: asset_indents_scailo_pb_js_1.AssetIndent,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View only essential components (without logs) that matches the given UUID
         *
         * @generated from rpc Scailo.AssetIndentsService.ViewEssentialByUUID
         */
        viewEssentialByUUID: {
            name: "ViewEssentialByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: asset_indents_scailo_pb_js_1.AssetIndent,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all records with the given IDs
         *
         * @generated from rpc Scailo.AssetIndentsService.ViewFromIDs
         */
        viewFromIDs: {
            name: "ViewFromIDs",
            I: base_scailo_pb_js_1.IdentifiersList,
            O: asset_indents_scailo_pb_js_1.AssetIndentsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.AssetIndentsService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: asset_indents_scailo_pb_js_1.AssetIndentsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.AssetIndentsService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: asset_indents_scailo_pb_js_1.AssetIndentsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.AssetIndentsService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: asset_indents_scailo_pb_js_1.AssetIndentsServicePaginationReq,
            O: asset_indents_scailo_pb_js_1.AssetIndentsServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective families for the given asset indent
         *
         * @generated from rpc Scailo.AssetIndentsService.ViewProspectiveFamilies
         */
        viewProspectiveFamilies: {
            name: "ViewProspectiveFamilies",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Filter prospective families for the record represented by the given UUID identifier
         *
         * View prospective asset indent item info for the given family ID and asset indent ID
         * rpc ViewProspectiveAssetIndentItem(AssetIndentItemProspectiveInfoRequest) returns (AssetIndentsServiceItemCreateRequest);
         *
         * @generated from rpc Scailo.AssetIndentsService.FilterProspectiveFamilies
         */
        filterProspectiveFamilies: {
            name: "FilterProspectiveFamilies",
            I: families_scailo_pb_js_1.FilterFamiliesReqForIdentifier,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download asset indent with the given IdentifierUUID (can be used to allow public downloads)
         *
         * @generated from rpc Scailo.AssetIndentsService.DownloadByUUID
         */
        downloadByUUID: {
            name: "DownloadByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the added quantity for the family with the given request payload
         *
         * @generated from rpc Scailo.AssetIndentsService.ViewAddedQuantityForFamily
         */
        viewAddedQuantityForFamily: {
            name: "ViewAddedQuantityForFamily",
            I: asset_indents_scailo_pb_js_1.AssetIndentItemProspectiveInfoRequest,
            O: base_scailo_pb_js_1.QuantityResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.AssetIndentsService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: asset_indents_scailo_pb_js_1.AssetIndentsServiceSearchAllReq,
            O: asset_indents_scailo_pb_js_1.AssetIndentsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.AssetIndentsService.Filter
         */
        filter: {
            name: "Filter",
            I: asset_indents_scailo_pb_js_1.AssetIndentsServiceFilterReq,
            O: asset_indents_scailo_pb_js_1.AssetIndentsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.AssetIndentsService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given count criteria
         *
         * @generated from rpc Scailo.AssetIndentsService.Count
         */
        count: {
            name: "Count",
            I: asset_indents_scailo_pb_js_1.AssetIndentsServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * CSV operations
         * Download the CSV file that consists of the list of records according to the given filter request. The same file could also be used as a template for uploading records
         *
         * @generated from rpc Scailo.AssetIndentsService.DownloadAsCSV
         */
        downloadAsCSV: {
            name: "DownloadAsCSV",
            I: asset_indents_scailo_pb_js_1.AssetIndentsServiceFilterReq,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
