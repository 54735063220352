"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file qc_groups.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.QCGroupsService = void 0;
const qc_groups_scailo_pb_js_1 = require("./qc_groups.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
/**
 *
 * Describes the common methods applicable on each qc group
 *
 * @generated from service Scailo.QCGroupsService
 */
exports.QCGroupsService = {
    typeName: "Scailo.QCGroupsService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.QCGroupsService.Create
         */
        create: {
            name: "Create",
            I: qc_groups_scailo_pb_js_1.QCGroupsServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.QCGroupsService.Draft
         */
        draft: {
            name: "Draft",
            I: qc_groups_scailo_pb_js_1.QCGroupsServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.QCGroupsService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: qc_groups_scailo_pb_js_1.QCGroupsServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.QCGroupsService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.QCGroupsService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.QCGroupsService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.QCGroupsService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.QCGroupsService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: qc_groups_scailo_pb_js_1.QCGroupsServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.QCGroupsService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.QCGroupsService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.QCGroupsService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.QCGroupsService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.QCGroupsService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reopen
         *
         * @generated from rpc Scailo.QCGroupsService.Reopen
         */
        reopen: {
            name: "Reopen",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * Send Email
         * rpc SendEmail (IdentifierWithEmailAttributes) returns (IdentifierResponse);
         *
         * @generated from rpc Scailo.QCGroupsService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Clone qc group from an existing qc group (denoted by the identifier)
         *
         * @generated from rpc Scailo.QCGroupsService.Clone
         */
        clone: {
            name: "Clone",
            I: base_scailo_pb_js_1.CloneRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add a param to a qc group
         *
         * @generated from rpc Scailo.QCGroupsService.AddQCGroupItem
         */
        addQCGroupItem: {
            name: "AddQCGroupItem",
            I: qc_groups_scailo_pb_js_1.QCGroupsServiceItemCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify a param in a qc group
         *
         * @generated from rpc Scailo.QCGroupsService.ModifyQCGroupItem
         */
        modifyQCGroupItem: {
            name: "ModifyQCGroupItem",
            I: qc_groups_scailo_pb_js_1.QCGroupsServiceItemUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve a param in a qc group
         *
         * @generated from rpc Scailo.QCGroupsService.ApproveQCGroupItem
         */
        approveQCGroupItem: {
            name: "ApproveQCGroupItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete a param in a qc group
         *
         * @generated from rpc Scailo.QCGroupsService.DeleteQCGroupItem
         */
        deleteQCGroupItem: {
            name: "DeleteQCGroupItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder params in a qc group
         *
         * @generated from rpc Scailo.QCGroupsService.ReorderQCGroupItems
         */
        reorderQCGroupItems: {
            name: "ReorderQCGroupItems",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View QC Group Item by ID
         *
         * @generated from rpc Scailo.QCGroupsService.ViewQCGroupItemByID
         */
        viewQCGroupItemByID: {
            name: "ViewQCGroupItemByID",
            I: base_scailo_pb_js_1.Identifier,
            O: qc_groups_scailo_pb_js_1.QCGroupItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved qc group params for given qc group ID
         *
         * @generated from rpc Scailo.QCGroupsService.ViewApprovedQCGroupItems
         */
        viewApprovedQCGroupItems: {
            name: "ViewApprovedQCGroupItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: qc_groups_scailo_pb_js_1.QCGroupsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved qc group params for given qc group ID
         *
         * @generated from rpc Scailo.QCGroupsService.ViewUnapprovedQCGroupItems
         */
        viewUnapprovedQCGroupItems: {
            name: "ViewUnapprovedQCGroupItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: qc_groups_scailo_pb_js_1.QCGroupsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the qc group item
         *
         * @generated from rpc Scailo.QCGroupsService.ViewQCGroupItemHistory
         */
        viewQCGroupItemHistory: {
            name: "ViewQCGroupItemHistory",
            I: qc_groups_scailo_pb_js_1.QCGroupItemHistoryRequest,
            O: qc_groups_scailo_pb_js_1.QCGroupsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved qc group items for given qc group ID with pagination
         *
         * @generated from rpc Scailo.QCGroupsService.ViewPaginatedApprovedQCGroupItems
         */
        viewPaginatedApprovedQCGroupItems: {
            name: "ViewPaginatedApprovedQCGroupItems",
            I: qc_groups_scailo_pb_js_1.QCGroupItemsSearchRequest,
            O: qc_groups_scailo_pb_js_1.QCGroupsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved qc group items for given qc group ID with pagination
         *
         * @generated from rpc Scailo.QCGroupsService.ViewPaginatedUnapprovedQCGroupItems
         */
        viewPaginatedUnapprovedQCGroupItems: {
            name: "ViewPaginatedUnapprovedQCGroupItems",
            I: qc_groups_scailo_pb_js_1.QCGroupItemsSearchRequest,
            O: qc_groups_scailo_pb_js_1.QCGroupsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through qc group items with pagination
         *
         * @generated from rpc Scailo.QCGroupsService.SearchItemsWithPagination
         */
        searchItemsWithPagination: {
            name: "SearchItemsWithPagination",
            I: qc_groups_scailo_pb_js_1.QCGroupItemsSearchRequest,
            O: qc_groups_scailo_pb_js_1.QCGroupsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * CSV operations
         * Download the CSV file that could be used to upload items from the filled CSV file. The same file can also be used as a template when there are no existing records
         *
         * @generated from rpc Scailo.QCGroupsService.DownloadItemsAsCSV
         */
        downloadItemsAsCSV: {
            name: "DownloadItemsAsCSV",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Upload items using a CSV file. This is an idempotent operation. All the existing items are deleted before adding the items from the file.
         *
         * @generated from rpc Scailo.QCGroupsService.UploadQCGroupItems
         */
        uploadQCGroupItems: {
            name: "UploadQCGroupItems",
            I: base_scailo_pb_js_1.IdentifierUUIDWithFile,
            O: base_scailo_pb_js_1.IdentifiersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.QCGroupsService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: qc_groups_scailo_pb_js_1.QCGroup,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by UUID
         *
         * @generated from rpc Scailo.QCGroupsService.ViewByUUID
         */
        viewByUUID: {
            name: "ViewByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: qc_groups_scailo_pb_js_1.QCGroup,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View only essential components by ID (without logs)
         *
         * @generated from rpc Scailo.QCGroupsService.ViewEssentialByID
         */
        viewEssentialByID: {
            name: "ViewEssentialByID",
            I: base_scailo_pb_js_1.Identifier,
            O: qc_groups_scailo_pb_js_1.QCGroup,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View only essential components (without logs) that matches the given UUID
         *
         * @generated from rpc Scailo.QCGroupsService.ViewEssentialByUUID
         */
        viewEssentialByUUID: {
            name: "ViewEssentialByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: qc_groups_scailo_pb_js_1.QCGroup,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all records with the given IDs
         *
         * @generated from rpc Scailo.QCGroupsService.ViewFromIDs
         */
        viewFromIDs: {
            name: "ViewFromIDs",
            I: base_scailo_pb_js_1.IdentifiersList,
            O: qc_groups_scailo_pb_js_1.QCGroupsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.QCGroupsService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: qc_groups_scailo_pb_js_1.QCGroupsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.QCGroupsService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: qc_groups_scailo_pb_js_1.QCGroupsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.QCGroupsService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: qc_groups_scailo_pb_js_1.QCGroupsServicePaginationReq,
            O: qc_groups_scailo_pb_js_1.QCGroupsServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.QCGroupsService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: qc_groups_scailo_pb_js_1.QCGroupsServiceSearchAllReq,
            O: qc_groups_scailo_pb_js_1.QCGroupsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.QCGroupsService.Filter
         */
        filter: {
            name: "Filter",
            I: qc_groups_scailo_pb_js_1.QCGroupsServiceFilterReq,
            O: qc_groups_scailo_pb_js_1.QCGroupsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.QCGroupsService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.QCGroupsService.Count
         */
        count: {
            name: "Count",
            I: qc_groups_scailo_pb_js_1.QCGroupsServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * CSV operations
         * Download the CSV file that consists of the list of records according to the given filter request. The same file could also be used as a template for uploading records
         *
         * @generated from rpc Scailo.QCGroupsService.DownloadAsCSV
         */
        downloadAsCSV: {
            name: "DownloadAsCSV",
            I: qc_groups_scailo_pb_js_1.QCGroupsServiceFilterReq,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Import records using a CSV file (duplicate codes will be skipped)
         *
         * @generated from rpc Scailo.QCGroupsService.ImportFromCSV
         */
        importFromCSV: {
            name: "ImportFromCSV",
            I: base_scailo_pb_js_1.StandardFile,
            O: base_scailo_pb_js_1.IdentifierUUIDsList,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
