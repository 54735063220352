"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file general_streams.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.GeneralStreamsService = void 0;
const general_streams_scailo_pb_js_1 = require("./general_streams.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
/**
 *
 * Describes the common methods applicable on each general stream
 *
 * @generated from service Scailo.GeneralStreamsService
 */
exports.GeneralStreamsService = {
    typeName: "Scailo.GeneralStreamsService",
    methods: {
        /**
         * Create
         *
         * @generated from rpc Scailo.GeneralStreamsService.Create
         */
        create: {
            name: "Create",
            I: general_streams_scailo_pb_js_1.GeneralStreamsServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update
         *
         * @generated from rpc Scailo.GeneralStreamsService.Update
         */
        update: {
            name: "Update",
            I: general_streams_scailo_pb_js_1.GeneralStreamsServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Cancel
         *
         * @generated from rpc Scailo.GeneralStreamsService.Cancel
         */
        cancel: {
            name: "Cancel",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.GeneralStreamsService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reopen
         *
         * @generated from rpc Scailo.GeneralStreamsService.Reopen
         */
        reopen: {
            name: "Reopen",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.GeneralStreamsService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * Send Email
         * rpc SendEmail(IdentifierWithEmailAttributes) returns (IdentifierUUID);
         *
         * @generated from rpc Scailo.GeneralStreamsService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add a message to an general stream
         *
         * @generated from rpc Scailo.GeneralStreamsService.AddMessage
         */
        addMessage: {
            name: "AddMessage",
            I: general_streams_scailo_pb_js_1.GeneralStreamsServiceMessageCreateRequest,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Saves a message for viewing it later
         *
         * @generated from rpc Scailo.GeneralStreamsService.SaveMessageForLater
         */
        saveMessageForLater: {
            name: "SaveMessageForLater",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete a message in an general stream
         *
         * @generated from rpc Scailo.GeneralStreamsService.DeleteMessage
         */
        deleteMessage: {
            name: "DeleteMessage",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View General Stream Message by UUID
         *
         * @generated from rpc Scailo.GeneralStreamsService.ViewMessageByUUID
         */
        viewMessageByUUID: {
            name: "ViewMessageByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: general_streams_scailo_pb_js_1.GeneralStreamMessage,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all messages for given general stream UUID
         *
         * @generated from rpc Scailo.GeneralStreamsService.ViewMessages
         */
        viewMessages: {
            name: "ViewMessages",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: general_streams_scailo_pb_js_1.GeneralStreamMessagesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View general stream messages for given general stream ID with pagination
         *
         * @generated from rpc Scailo.GeneralStreamsService.ViewPaginatedMessages
         */
        viewPaginatedMessages: {
            name: "ViewPaginatedMessages",
            I: general_streams_scailo_pb_js_1.GeneralStreamMessagesSearchRequest,
            O: general_streams_scailo_pb_js_1.GeneralStreamsServicePaginatedMessagesResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through general stream messages with pagination
         *
         * @generated from rpc Scailo.GeneralStreamsService.SearchMessagesWithPagination
         */
        searchMessagesWithPagination: {
            name: "SearchMessagesWithPagination",
            I: general_streams_scailo_pb_js_1.GeneralStreamMessagesSearchRequest,
            O: general_streams_scailo_pb_js_1.GeneralStreamsServicePaginatedMessagesResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all receipts for the given message UUID
         *
         * @generated from rpc Scailo.GeneralStreamsService.ViewMessageReceipts
         */
        viewMessageReceipts: {
            name: "ViewMessageReceipts",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: general_streams_scailo_pb_js_1.GeneralStreamMessageReceiptsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an internal subscriber
         *
         * @generated from rpc Scailo.GeneralStreamsService.AddInternalSubscriber
         */
        addInternalSubscriber: {
            name: "AddInternalSubscriber",
            I: general_streams_scailo_pb_js_1.GeneralStreamsServiceInternalSubscriberCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an internal subscriber
         *
         * @generated from rpc Scailo.GeneralStreamsService.DeleteInternalSubscriber
         */
        deleteInternalSubscriber: {
            name: "DeleteInternalSubscriber",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View an internal subscriber for the given ID
         *
         * @generated from rpc Scailo.GeneralStreamsService.ViewInternalSubscriberByID
         */
        viewInternalSubscriberByID: {
            name: "ViewInternalSubscriberByID",
            I: base_scailo_pb_js_1.Identifier,
            O: general_streams_scailo_pb_js_1.GeneralStreamInternalSubscriber,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all internal subscribers for given general stream UUID
         *
         * @generated from rpc Scailo.GeneralStreamsService.ViewInternalSubscribers
         */
        viewInternalSubscribers: {
            name: "ViewInternalSubscribers",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: general_streams_scailo_pb_js_1.GeneralStreamInternalSubscribersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Import users as internal subscribers from team and returns the ID of the general stream
         *
         * @generated from rpc Scailo.GeneralStreamsService.ImportInternalSubscribersFromTeam
         */
        importInternalSubscribersFromTeam: {
            name: "ImportInternalSubscribersFromTeam",
            I: general_streams_scailo_pb_js_1.GeneralStreamsServiceImportInternalSubscribersRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Import users as internal subscribers from department and returns the ID of the general stream
         *
         * @generated from rpc Scailo.GeneralStreamsService.ImportInternalSubscribersFromDepartment
         */
        importInternalSubscribersFromDepartment: {
            name: "ImportInternalSubscribersFromDepartment",
            I: general_streams_scailo_pb_js_1.GeneralStreamsServiceImportInternalSubscribersRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.GeneralStreamsService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: general_streams_scailo_pb_js_1.GeneralStream,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by UUID
         *
         * @generated from rpc Scailo.GeneralStreamsService.ViewByUUID
         */
        viewByUUID: {
            name: "ViewByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: general_streams_scailo_pb_js_1.GeneralStream,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View only essential components by ID (without logs)
         *
         * @generated from rpc Scailo.GeneralStreamsService.ViewEssentialByID
         */
        viewEssentialByID: {
            name: "ViewEssentialByID",
            I: base_scailo_pb_js_1.Identifier,
            O: general_streams_scailo_pb_js_1.GeneralStream,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View only essential components (without logs) that matches the given UUID
         *
         * @generated from rpc Scailo.GeneralStreamsService.ViewEssentialByUUID
         */
        viewEssentialByUUID: {
            name: "ViewEssentialByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: general_streams_scailo_pb_js_1.GeneralStream,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all records with the given IDs
         *
         * @generated from rpc Scailo.GeneralStreamsService.ViewFromIDs
         */
        viewFromIDs: {
            name: "ViewFromIDs",
            I: base_scailo_pb_js_1.IdentifiersList,
            O: general_streams_scailo_pb_js_1.GeneralStreamsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.GeneralStreamsService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: general_streams_scailo_pb_js_1.GeneralStreamsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.GeneralStreamsService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: general_streams_scailo_pb_js_1.GeneralStreamsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.GeneralStreamsService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: general_streams_scailo_pb_js_1.GeneralStreamsServicePaginationReq,
            O: general_streams_scailo_pb_js_1.GeneralStreamsServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.GeneralStreamsService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: general_streams_scailo_pb_js_1.GeneralStreamsServiceSearchAllReq,
            O: general_streams_scailo_pb_js_1.GeneralStreamsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.GeneralStreamsService.Filter
         */
        filter: {
            name: "Filter",
            I: general_streams_scailo_pb_js_1.GeneralStreamsServiceFilterReq,
            O: general_streams_scailo_pb_js_1.GeneralStreamsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.GeneralStreamsService.Count
         */
        count: {
            name: "Count",
            I: general_streams_scailo_pb_js_1.GeneralStreamsServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
