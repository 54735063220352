"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file purchases_enquiries.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.PurchasesEnquiriesService = void 0;
const purchases_enquiries_scailo_pb_js_1 = require("./purchases_enquiries.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
/**
 *
 * Describes the common methods applicable on each purchase enquiry
 *
 * @generated from service Scailo.PurchasesEnquiriesService
 */
exports.PurchasesEnquiriesService = {
    typeName: "Scailo.PurchasesEnquiriesService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.PurchasesEnquiriesService.Create
         */
        create: {
            name: "Create",
            I: purchases_enquiries_scailo_pb_js_1.PurchasesEnquiriesServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.PurchasesEnquiriesService.Draft
         */
        draft: {
            name: "Draft",
            I: purchases_enquiries_scailo_pb_js_1.PurchasesEnquiriesServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.PurchasesEnquiriesService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: purchases_enquiries_scailo_pb_js_1.PurchasesEnquiriesServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.PurchasesEnquiriesService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.PurchasesEnquiriesService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.PurchasesEnquiriesService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.PurchasesEnquiriesService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.PurchasesEnquiriesService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: purchases_enquiries_scailo_pb_js_1.PurchasesEnquiriesServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.PurchasesEnquiriesService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.PurchasesEnquiriesService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.PurchasesEnquiriesService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.PurchasesEnquiriesService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.PurchasesEnquiriesService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reopen
         *
         * @generated from rpc Scailo.PurchasesEnquiriesService.Reopen
         */
        reopen: {
            name: "Reopen",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * @generated from rpc Scailo.PurchasesEnquiriesService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an item to a purchase enquiry
         *
         * @generated from rpc Scailo.PurchasesEnquiriesService.AddPurchaseEnquiryItem
         */
        addPurchaseEnquiryItem: {
            name: "AddPurchaseEnquiryItem",
            I: purchases_enquiries_scailo_pb_js_1.PurchasesEnquiriesServiceItemCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify an item in a purchase enquiry
         *
         * @generated from rpc Scailo.PurchasesEnquiriesService.ModifyPurchaseEnquiryItem
         */
        modifyPurchaseEnquiryItem: {
            name: "ModifyPurchaseEnquiryItem",
            I: purchases_enquiries_scailo_pb_js_1.PurchasesEnquiriesServiceItemUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve an item in a purchase enquiry
         *
         * @generated from rpc Scailo.PurchasesEnquiriesService.ApprovePurchaseEnquiryItem
         */
        approvePurchaseEnquiryItem: {
            name: "ApprovePurchaseEnquiryItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an item in a purchase enquiry
         *
         * @generated from rpc Scailo.PurchasesEnquiriesService.DeletePurchaseEnquiryItem
         */
        deletePurchaseEnquiryItem: {
            name: "DeletePurchaseEnquiryItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder items in a purchase enquiry
         *
         * @generated from rpc Scailo.PurchasesEnquiriesService.ReorderPurchaseEnquiryItems
         */
        reorderPurchaseEnquiryItems: {
            name: "ReorderPurchaseEnquiryItems",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View purchase enquiry Item by ID
         *
         * @generated from rpc Scailo.PurchasesEnquiriesService.ViewPurchaseEnquiryItemByID
         */
        viewPurchaseEnquiryItemByID: {
            name: "ViewPurchaseEnquiryItemByID",
            I: base_scailo_pb_js_1.Identifier,
            O: purchases_enquiries_scailo_pb_js_1.PurchaseEnquiryItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved purchase enquiry items for given purchase enquiry ID
         *
         * @generated from rpc Scailo.PurchasesEnquiriesService.ViewApprovedPurchaseEnquiryItems
         */
        viewApprovedPurchaseEnquiryItems: {
            name: "ViewApprovedPurchaseEnquiryItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: purchases_enquiries_scailo_pb_js_1.PurchasesEnquiriesItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved purchase enquiry items for given purchase enquiry ID
         *
         * @generated from rpc Scailo.PurchasesEnquiriesService.ViewUnapprovedPurchaseEnquiryItems
         */
        viewUnapprovedPurchaseEnquiryItems: {
            name: "ViewUnapprovedPurchaseEnquiryItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: purchases_enquiries_scailo_pb_js_1.PurchasesEnquiriesItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the purchase enquiry item
         *
         * @generated from rpc Scailo.PurchasesEnquiriesService.ViewPurchaseEnquiryItemHistory
         */
        viewPurchaseEnquiryItemHistory: {
            name: "ViewPurchaseEnquiryItemHistory",
            I: purchases_enquiries_scailo_pb_js_1.PurchaseEnquiryItemHistoryRequest,
            O: purchases_enquiries_scailo_pb_js_1.PurchasesEnquiriesItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved purchase enquiry items for given purchase enquiry ID with pagination
         *
         * @generated from rpc Scailo.PurchasesEnquiriesService.ViewPaginatedApprovedPurchaseEnquiryItems
         */
        viewPaginatedApprovedPurchaseEnquiryItems: {
            name: "ViewPaginatedApprovedPurchaseEnquiryItems",
            I: purchases_enquiries_scailo_pb_js_1.PurchaseEnquiryItemsSearchRequest,
            O: purchases_enquiries_scailo_pb_js_1.PurchaseEnquiriesServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved purchase enquiry items for given purchase enquiry ID with pagination
         *
         * @generated from rpc Scailo.PurchasesEnquiriesService.ViewPaginatedUnapprovedPurchaseEnquiryItems
         */
        viewPaginatedUnapprovedPurchaseEnquiryItems: {
            name: "ViewPaginatedUnapprovedPurchaseEnquiryItems",
            I: purchases_enquiries_scailo_pb_js_1.PurchaseEnquiryItemsSearchRequest,
            O: purchases_enquiries_scailo_pb_js_1.PurchaseEnquiriesServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through purchase enquiry items with pagination
         *
         * @generated from rpc Scailo.PurchasesEnquiriesService.SearchItemsWithPagination
         */
        searchItemsWithPagination: {
            name: "SearchItemsWithPagination",
            I: purchases_enquiries_scailo_pb_js_1.PurchaseEnquiryItemsSearchRequest,
            O: purchases_enquiries_scailo_pb_js_1.PurchaseEnquiriesServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * CSV operations
         * Download the CSV file that could be used to upload items from the filled CSV file. The same file can also be used as a template when there are no existing records
         *
         * @generated from rpc Scailo.PurchasesEnquiriesService.DownloadItemsAsCSV
         */
        downloadItemsAsCSV: {
            name: "DownloadItemsAsCSV",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Upload items using a CSV file. This is an idempotent operation. All the existing items are deleted before adding the items from the file.
         *
         * @generated from rpc Scailo.PurchasesEnquiriesService.UploadPurchaseEnquiryItems
         */
        uploadPurchaseEnquiryItems: {
            name: "UploadPurchaseEnquiryItems",
            I: base_scailo_pb_js_1.IdentifierUUIDWithFile,
            O: base_scailo_pb_js_1.IdentifiersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.PurchasesEnquiriesService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: purchases_enquiries_scailo_pb_js_1.PurchaseEnquiry,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by UUID
         *
         * @generated from rpc Scailo.PurchasesEnquiriesService.ViewByUUID
         */
        viewByUUID: {
            name: "ViewByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: purchases_enquiries_scailo_pb_js_1.PurchaseEnquiry,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View only essential components by ID (without logs)
         *
         * @generated from rpc Scailo.PurchasesEnquiriesService.ViewEssentialByID
         */
        viewEssentialByID: {
            name: "ViewEssentialByID",
            I: base_scailo_pb_js_1.Identifier,
            O: purchases_enquiries_scailo_pb_js_1.PurchaseEnquiry,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View only essential components (without logs) that matches the given UUID
         *
         * @generated from rpc Scailo.PurchasesEnquiriesService.ViewEssentialByUUID
         */
        viewEssentialByUUID: {
            name: "ViewEssentialByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: purchases_enquiries_scailo_pb_js_1.PurchaseEnquiry,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all records with the given IDs
         *
         * @generated from rpc Scailo.PurchasesEnquiriesService.ViewFromIDs
         */
        viewFromIDs: {
            name: "ViewFromIDs",
            I: base_scailo_pb_js_1.IdentifiersList,
            O: purchases_enquiries_scailo_pb_js_1.PurchasesEnquiriesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.PurchasesEnquiriesService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: purchases_enquiries_scailo_pb_js_1.PurchasesEnquiriesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.PurchasesEnquiriesService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: purchases_enquiries_scailo_pb_js_1.PurchasesEnquiriesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.PurchasesEnquiriesService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: purchases_enquiries_scailo_pb_js_1.PurchasesEnquiriesServicePaginationReq,
            O: purchases_enquiries_scailo_pb_js_1.PurchasesEnquiriesServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download purchase enquiry with the given IdentifierUUID (can be used to allow public downloads)
         *
         * @generated from rpc Scailo.PurchasesEnquiriesService.DownloadByUUID
         */
        downloadByUUID: {
            name: "DownloadByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.PurchasesEnquiriesService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: purchases_enquiries_scailo_pb_js_1.PurchasesEnquiriesServiceSearchAllReq,
            O: purchases_enquiries_scailo_pb_js_1.PurchasesEnquiriesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.PurchasesEnquiriesService.Filter
         */
        filter: {
            name: "Filter",
            I: purchases_enquiries_scailo_pb_js_1.PurchasesEnquiriesServiceFilterReq,
            O: purchases_enquiries_scailo_pb_js_1.PurchasesEnquiriesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.PurchasesEnquiriesService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.PurchasesEnquiriesService.Count
         */
        count: {
            name: "Count",
            I: purchases_enquiries_scailo_pb_js_1.PurchasesEnquiriesServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * CSV operations
         * Download the CSV file that consists of the list of records according to the given filter request. The same file could also be used as a template for uploading records
         *
         * @generated from rpc Scailo.PurchasesEnquiriesService.DownloadAsCSV
         */
        downloadAsCSV: {
            name: "DownloadAsCSV",
            I: purchases_enquiries_scailo_pb_js_1.PurchasesEnquiriesServiceFilterReq,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
