"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file stock_issuances.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.StockIssuancesService = void 0;
const stock_issuances_scailo_pb_js_1 = require("./stock_issuances.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
const families_scailo_pb_js_1 = require("./families.scailo_pb.js");
/**
 *
 * Describes the common methods applicable on each stock issuance
 *
 * @generated from service Scailo.StockIssuancesService
 */
exports.StockIssuancesService = {
    typeName: "Scailo.StockIssuancesService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.StockIssuancesService.Create
         */
        create: {
            name: "Create",
            I: stock_issuances_scailo_pb_js_1.StockIssuancesServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.StockIssuancesService.Draft
         */
        draft: {
            name: "Draft",
            I: stock_issuances_scailo_pb_js_1.StockIssuancesServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.StockIssuancesService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: stock_issuances_scailo_pb_js_1.StockIssuancesServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.StockIssuancesService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.StockIssuancesService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.StockIssuancesService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.StockIssuancesService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.StockIssuancesService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: stock_issuances_scailo_pb_js_1.StockIssuancesServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.StockIssuancesService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.StockIssuancesService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.StockIssuancesService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.StockIssuancesService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.StockIssuancesService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reopen
         *
         * @generated from rpc Scailo.StockIssuancesService.Reopen
         */
        reopen: {
            name: "Reopen",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * @generated from rpc Scailo.StockIssuancesService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send Email
         *
         * @generated from rpc Scailo.StockIssuancesService.SendEmail
         */
        sendEmail: {
            name: "SendEmail",
            I: base_scailo_pb_js_1.IdentifierWithEmailAttributes,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Autofill the stock issuance
         *
         * @generated from rpc Scailo.StockIssuancesService.Autofill
         */
        autofill: {
            name: "Autofill",
            I: stock_issuances_scailo_pb_js_1.StockIssuancesServiceAutofillRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Checks if the Stock Issuance can be marked as completed
         *
         * @generated from rpc Scailo.StockIssuancesService.IsCompletable
         */
        isCompletable: {
            name: "IsCompletable",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.BooleanResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an item to a stock issuance
         *
         * @generated from rpc Scailo.StockIssuancesService.AddStockIssuanceItem
         */
        addStockIssuanceItem: {
            name: "AddStockIssuanceItem",
            I: stock_issuances_scailo_pb_js_1.StockIssuancesServiceItemCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify an item in a stock issuance
         *
         * @generated from rpc Scailo.StockIssuancesService.ModifyStockIssuanceItem
         */
        modifyStockIssuanceItem: {
            name: "ModifyStockIssuanceItem",
            I: stock_issuances_scailo_pb_js_1.StockIssuancesServiceItemUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve an item in a stock issuance
         *
         * @generated from rpc Scailo.StockIssuancesService.ApproveStockIssuanceItem
         */
        approveStockIssuanceItem: {
            name: "ApproveStockIssuanceItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an item in a stock issuance
         *
         * @generated from rpc Scailo.StockIssuancesService.DeleteStockIssuanceItem
         */
        deleteStockIssuanceItem: {
            name: "DeleteStockIssuanceItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder items in a stock issuance
         *
         * @generated from rpc Scailo.StockIssuancesService.ReorderStockIssuanceItems
         */
        reorderStockIssuanceItems: {
            name: "ReorderStockIssuanceItems",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Stock Issuance Item by ID
         *
         * @generated from rpc Scailo.StockIssuancesService.ViewStockIssuanceItemByID
         */
        viewStockIssuanceItemByID: {
            name: "ViewStockIssuanceItemByID",
            I: base_scailo_pb_js_1.Identifier,
            O: stock_issuances_scailo_pb_js_1.StockIssuanceItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Stock Issuance Item by Inventory Hash
         *
         * @generated from rpc Scailo.StockIssuancesService.ViewStockIssuanceItemByInventoryHash
         */
        viewStockIssuanceItemByInventoryHash: {
            name: "ViewStockIssuanceItemByInventoryHash",
            I: base_scailo_pb_js_1.SimpleSearchReq,
            O: stock_issuances_scailo_pb_js_1.StockIssuanceItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved stock issuance items for given stock issuance ID
         *
         * @generated from rpc Scailo.StockIssuancesService.ViewApprovedStockIssuanceItems
         */
        viewApprovedStockIssuanceItems: {
            name: "ViewApprovedStockIssuanceItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: stock_issuances_scailo_pb_js_1.StockIssuancesItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved stock issuance items for given stock issuance ID
         *
         * @generated from rpc Scailo.StockIssuancesService.ViewUnapprovedStockIssuanceItems
         */
        viewUnapprovedStockIssuanceItems: {
            name: "ViewUnapprovedStockIssuanceItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: stock_issuances_scailo_pb_js_1.StockIssuancesItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the stock issuance item
         *
         * @generated from rpc Scailo.StockIssuancesService.ViewStockIssuanceItemHistory
         */
        viewStockIssuanceItemHistory: {
            name: "ViewStockIssuanceItemHistory",
            I: stock_issuances_scailo_pb_js_1.StockIssuanceItemHistoryRequest,
            O: stock_issuances_scailo_pb_js_1.StockIssuancesItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved stock issuance items for given stock issuance ID with pagination
         *
         * @generated from rpc Scailo.StockIssuancesService.ViewPaginatedApprovedStockIssuanceItems
         */
        viewPaginatedApprovedStockIssuanceItems: {
            name: "ViewPaginatedApprovedStockIssuanceItems",
            I: stock_issuances_scailo_pb_js_1.StockIssuanceItemsSearchRequest,
            O: stock_issuances_scailo_pb_js_1.StockIssuancesServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved stock issuance items for given stock issuance ID with pagination
         *
         * @generated from rpc Scailo.StockIssuancesService.ViewPaginatedUnapprovedStockIssuanceItems
         */
        viewPaginatedUnapprovedStockIssuanceItems: {
            name: "ViewPaginatedUnapprovedStockIssuanceItems",
            I: stock_issuances_scailo_pb_js_1.StockIssuanceItemsSearchRequest,
            O: stock_issuances_scailo_pb_js_1.StockIssuancesServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through stock issuance items with pagination
         *
         * @generated from rpc Scailo.StockIssuancesService.SearchItemsWithPagination
         */
        searchItemsWithPagination: {
            name: "SearchItemsWithPagination",
            I: stock_issuances_scailo_pb_js_1.StockIssuanceItemsSearchRequest,
            O: stock_issuances_scailo_pb_js_1.StockIssuancesServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.StockIssuancesService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: stock_issuances_scailo_pb_js_1.StockIssuance,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by UUID
         *
         * @generated from rpc Scailo.StockIssuancesService.ViewByUUID
         */
        viewByUUID: {
            name: "ViewByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: stock_issuances_scailo_pb_js_1.StockIssuance,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View only essential components by ID (without logs)
         *
         * @generated from rpc Scailo.StockIssuancesService.ViewEssentialByID
         */
        viewEssentialByID: {
            name: "ViewEssentialByID",
            I: base_scailo_pb_js_1.Identifier,
            O: stock_issuances_scailo_pb_js_1.StockIssuance,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View only essential components (without logs) that matches the given UUID
         *
         * @generated from rpc Scailo.StockIssuancesService.ViewEssentialByUUID
         */
        viewEssentialByUUID: {
            name: "ViewEssentialByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: stock_issuances_scailo_pb_js_1.StockIssuance,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all records with the given IDs
         *
         * @generated from rpc Scailo.StockIssuancesService.ViewFromIDs
         */
        viewFromIDs: {
            name: "ViewFromIDs",
            I: base_scailo_pb_js_1.IdentifiersList,
            O: stock_issuances_scailo_pb_js_1.StockIssuancesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.StockIssuancesService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: stock_issuances_scailo_pb_js_1.StockIssuancesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.StockIssuancesService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: stock_issuances_scailo_pb_js_1.StockIssuancesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.StockIssuancesService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: stock_issuances_scailo_pb_js_1.StockIssuancesServicePaginationReq,
            O: stock_issuances_scailo_pb_js_1.StockIssuancesServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective families for the given stock issuance
         *
         * @generated from rpc Scailo.StockIssuancesService.ViewProspectiveFamilies
         */
        viewProspectiveFamilies: {
            name: "ViewProspectiveFamilies",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Filter prospective families for the record represented by the given UUID identifier
         *
         * @generated from rpc Scailo.StockIssuancesService.FilterProspectiveFamilies
         */
        filterProspectiveFamilies: {
            name: "FilterProspectiveFamilies",
            I: families_scailo_pb_js_1.FilterFamiliesReqForIdentifier,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective stock issuance item info for the given family ID and stock issuance ID
         *
         * @generated from rpc Scailo.StockIssuancesService.ViewProspectiveStockIssuanceItem
         */
        viewProspectiveStockIssuanceItem: {
            name: "ViewProspectiveStockIssuanceItem",
            I: stock_issuances_scailo_pb_js_1.StockIssuanceItemProspectiveInfoRequest,
            O: stock_issuances_scailo_pb_js_1.StockIssuancesServiceItemCreateRequest,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View already added quantities
         *
         * @generated from rpc Scailo.StockIssuancesService.ViewAddedFamilyQuantityForSource
         */
        viewAddedFamilyQuantityForSource: {
            name: "ViewAddedFamilyQuantityForSource",
            I: stock_issuances_scailo_pb_js_1.StockIssuancesServiceAlreadyAddedQuantityForSourceRequest,
            O: base_scailo_pb_js_1.QuantityResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download the label for the stock issuance with the given IdentifierUUID
         *
         * @generated from rpc Scailo.StockIssuancesService.DownloadLabelByUUID
         */
        downloadLabelByUUID: {
            name: "DownloadLabelByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download stock issuance with the given IdentifierUUID
         *
         * @generated from rpc Scailo.StockIssuancesService.DownloadByUUID
         */
        downloadByUUID: {
            name: "DownloadByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.StockIssuancesService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: stock_issuances_scailo_pb_js_1.StockIssuancesServiceSearchAllReq,
            O: stock_issuances_scailo_pb_js_1.StockIssuancesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.StockIssuancesService.Filter
         */
        filter: {
            name: "Filter",
            I: stock_issuances_scailo_pb_js_1.StockIssuancesServiceFilterReq,
            O: stock_issuances_scailo_pb_js_1.StockIssuancesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.StockIssuancesService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.StockIssuancesService.Count
         */
        count: {
            name: "Count",
            I: stock_issuances_scailo_pb_js_1.StockIssuancesServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * CSV operations
         * Download the CSV file that consists of the list of records according to the given filter request. The same file could also be used as a template for uploading records
         *
         * @generated from rpc Scailo.StockIssuancesService.DownloadAsCSV
         */
        downloadAsCSV: {
            name: "DownloadAsCSV",
            I: stock_issuances_scailo_pb_js_1.StockIssuancesServiceFilterReq,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
