import { context } from "./../../router";
import { QCSample, QCSamplesService, QCSampleAncillaryParameters, UnitOfMaterial, getClientForQCSamplesService, getClientForUnitsOfMaterialsService, getClientForFamiliesService, FAMILY_TYPE, getClientForComponentsService, ComponentsService, EquipmentsService, FeedstocksService, InfrastructuresService, MerchandisesService, ProductsService, getClientForEquipmentsService, getClientForFeedstocksService, getClientForInfrastructuresService, getClientForMerchandisesService, getClientForProductsService, getClientForQCParamsService, QCParam, QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE } from "@kernelminds/scailo-sdk";
import { emptyDiv, renderFilterPrimarySubSection, renderImage, renderInput, renderPageTitleSection, renderSpan } from "../../ui";
import { bgColor, convertBigIntTimestampToDate, convertCentsToMoney, decodeQCSampleLifecycle, downloadData, randomId, toTitleCase } from "../../utilities";
import { PromiseClient, Transport } from "@connectrpc/connect";
import { getTransport } from "../../clients";

const downloadPDFButtonClass = "__download-pdf-btn";

type inventoryAccessClientType = PromiseClient<typeof ComponentsService> | PromiseClient<typeof EquipmentsService> |
    PromiseClient<typeof FeedstocksService> | PromiseClient<typeof InfrastructuresService> |
    PromiseClient<typeof MerchandisesService> | PromiseClient<typeof ProductsService>

export async function handleIndividualQcSample(ctx: context) {
    let content = <HTMLDivElement>document.getElementById("central-content");
    while (content.firstChild) {
        content.removeChild(content.firstChild);
    }
    const transport = getTransport();
    const accessClient = getClientForQCSamplesService(transport);
    const [qasample, ancillaryParams] = await Promise.all([
        accessClient.viewByUUID({ uuid: ctx.params.uuid }),
        accessClient.viewAncillaryParametersByUUID({ uuid: ctx.params.uuid })
    ]);

    let inventoryAccessClient: inventoryAccessClientType;

    if (qasample.familyType == FAMILY_TYPE.FAMILY_TYPE_COMPONENT) {
        inventoryAccessClient = getClientForComponentsService(transport);
    } else if (qasample.familyType == FAMILY_TYPE.FAMILY_TYPE_EQUIPMENT) {
        inventoryAccessClient = getClientForEquipmentsService(transport);
    } else if (qasample.familyType == FAMILY_TYPE.FAMILY_TYPE_FEEDSTOCK) {
        inventoryAccessClient = getClientForFeedstocksService(transport);
    } else if (qasample.familyType == FAMILY_TYPE.FAMILY_TYPE_INFRASTRUCTURE) {
        inventoryAccessClient = getClientForInfrastructuresService(transport);
    } else if (qasample.familyType == FAMILY_TYPE.FAMILY_TYPE_MERCHANDISE) {
        inventoryAccessClient = getClientForMerchandisesService(transport);
    } else if (qasample.familyType == FAMILY_TYPE.FAMILY_TYPE_PRODUCT) {
        inventoryAccessClient = getClientForProductsService(transport);
    } else {
        // Default value, just to bypass compiler errors
        inventoryAccessClient = getClientForFeedstocksService(transport);
    }

    const inventoryItem = await inventoryAccessClient.viewByUUID({ uuid: ancillaryParams.inventoryItemUuid })

    document.title = qasample.approvalMetadata?.approvedOn! > 0 ? qasample.name : qasample.name;

    let container = document.createElement("div");
    container.className = "overflow-x-auto";
    content.appendChild(container);

    const readonly = true;
    let { formGrid, buttonContainer } = await getForm(qasample, ancillaryParams, readonly, accessClient, inventoryItem.hash, transport);

    container.appendChild(formGrid);

    // Setup PDF downloads
    let pdfDownloadButtons = container.getElementsByClassName(downloadPDFButtonClass);
    for (let i = 0; i < pdfDownloadButtons.length; i++) {
        let btn = <HTMLButtonElement>pdfDownloadButtons[i];
        btn.addEventListener("click", async evt => {
            evt.preventDefault();

            const originalButtonHTML = btn.innerHTML;
            btn.disabled = true;
            btn.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
            let file = await inventoryAccessClient.downloadQCReportByUUID({ uuid: inventoryItem.metadata?.uuid });

            btn.disabled = false;
            btn.innerHTML = originalButtonHTML;

            downloadData(file.content, "pdf", file.name.replace(".pdf", ""));
        });
    }

    buttonContainer = <HTMLDivElement>document.getElementById(buttonContainer.id);
}

async function renderReferencesSection(qasample: QCSample, ancillaryParams: QCSampleAncillaryParameters, readonly: boolean, accessClient: PromiseClient<typeof QCSamplesService>, inventoryItemHash: string, transport: Transport): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "References", titleMdColSpan: 3 });

    let familiesAccessClient = getClientForFamiliesService(transport);
    const [family] = await Promise.all([
        familiesAccessClient.viewEssentialByUUID({ uuid: ancillaryParams.familyUuid }),
    ]);

    // Sample name
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "QA Sample Name", inputType: "text", dataMapper: "name", dataType: "string", value: qasample.name, mdColSpan: 12, helpText: "Name of the QA Sample.", dataRegex: ".+" }));

    // Family name
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Material Name", inputType: "text", dataMapper: "familyId", dataType: "string", value: `${family.name}`, mdColSpan: 12, helpText: "Name of the Material.", dataRegex: ".+" }));

    // Family code
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Material Code", inputType: "text", dataMapper: "", dataType: "string", value: `${family.code}`, mdColSpan: 12, helpText: "Code of the Material.", dataRegex: ".+" }));

    // Inventory code
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Item Code", inputType: "text", dataMapper: "", dataType: "string", value: `${inventoryItemHash.substring(0, 7)}`, mdColSpan: 6, helpText: "Identifier of the individual inventory item.", dataRegex: ".+" }));

    // Display status as well
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Status", inputType: "text", dataMapper: "status", dataType: "string", value: toTitleCase(decodeQCSampleLifecycle(qasample.status).split("-").join(" ")), mdColSpan: 3, helpText: "Status of the record.", dataRegex: ".+" }));

    contentGrid.appendChild(emptyDiv());

    // Display the QR code
    contentGrid.appendChild(renderImage({ id: randomId(), label: "QR Code", link: `/inventory/qr?hash=${inventoryItemHash}`, mdColSpan: 3, helpText: "QR Code of the Material." }));

    return grid;
}

async function renderDatesSection(qasample: QCSample, ancillaryParams: QCSampleAncillaryParameters, readonly: boolean, accessClient: PromiseClient<typeof QCSamplesService>): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "Important Dates", titleMdColSpan: 3 });

    // Dates (creation and approval)
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Created On", inputType: "text", dataMapper: "createdAt", dataType: "string", value: convertBigIntTimestampToDate(qasample.metadata?.createdAt!), mdColSpan: 3, helpText: "The creation date of this record.", dataRegex: ".*" }));
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Approved On", inputType: "text", dataMapper: "approvedOn", dataType: "string", value: convertBigIntTimestampToDate(qasample.approvalMetadata?.approvedOn!), mdColSpan: 3, helpText: "The approval date of this record.", dataRegex: ".*" }));

    return grid;
}

async function renderParametersSection(qasample: QCSample, readonly: boolean, accessClient: PromiseClient<typeof QCSamplesService>, transport: Transport): Promise<HTMLDivElement> {
    let container = document.createElement("div");

    let containerTitle = document.createElement("div");
    containerTitle.className = "rounded-t mb-0 px-0 border-0";
    containerTitle.appendChild(renderPageTitleSection({ title: `Tested Parameters` }));

    container.appendChild(containerTitle);

    const parametersList = await accessClient.viewParameters({ uuid: qasample.metadata?.uuid });

    let qcParamsAccessCleint = getClientForQCParamsService(transport);
    let uomAccessClient = getClientForUnitsOfMaterialsService(transport);

    const filteredParameters = parametersList.list.filter(p => {
        if (!p.isInternal) {
            // Only public parameters should be rendered
            return p;
        }
    });

    const qcParamsList = (await qcParamsAccessCleint.viewFromIDs({ list: Array.from(new Set(filteredParameters.map(p => p.qcParamId))) })).list;
    let qcParamsMap = new Map<bigint, QCParam>();

    qcParamsList.forEach(qcParam => {
        qcParamsMap.set(qcParam.metadata!.id, qcParam);
    });

    const uomsList = (await uomAccessClient.viewFromIDs({ list: Array.from(new Set(filteredParameters.map(p => p.uomId))) })).list;
    let uomsMap = new Map<bigint, UnitOfMaterial>();

    uomsList.forEach(uom => {
        uomsMap.set(uom.metadata!.id, uom);
    });

    filteredParameters.forEach((item, index) => {
        let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: `${index + 1}.`, titleMdColSpan: 1 });

        let qcParam = qcParamsMap.get(item.qcParamId) || new QCParam();
        let unitOfMaterial = uomsMap.get(item.uomId) || new UnitOfMaterial();

        // Observed Value
        let acceptableValue = (
            item.acceptableValueType == QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE.QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE_NUMBER_ABSOLUTE ||
            item.acceptableValueType == QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE.QC_GROUP_ITEM_ACCEPTABLE_VALUE_TYPE_NUMBER_PERCENTAGE
        ) ? "number" : "string";

        let observedValue = acceptableValue == "number" ? convertCentsToMoney(item.numberObservedValue) : item.textObservedValue;

        if (qcParam.code.length > 0) {
            contentGrid.appendChild(renderSpan({ id: randomId(), label: "Parameter Code", value: `${qcParam.code}`, mdColSpan: 3, helpText: `The code of the parameter.` }));
        }
        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Parameter Name", value: `${qcParam.name}`, mdColSpan: 9, helpText: `The name of the parameter.` }));
        contentGrid.appendChild(emptyDiv());

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "UoM", value: `(${unitOfMaterial.symbol}) ${unitOfMaterial?.name}`, mdColSpan: 3, helpText: `The unit of material.` }));

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Observed Value", value: observedValue, mdColSpan: 6, helpText: `The observed value in the selected unit of material.` }));

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Checked At", value: item.checkedAt > 0 ? new Date(parseInt(String(item.checkedAt)) * 1000).toDateString() : "-", mdColSpan: 3, helpText: `The date when this parameter was checked.` }));

        container.appendChild(grid);

        let hr = document.createElement("hr");
        hr.classList.add("m-5");
        container.appendChild(hr);
    });

    return container;
}

async function getForm(qasample: QCSample, ancillaryParams: QCSampleAncillaryParameters, readonly: boolean, accessClient: PromiseClient<typeof QCSamplesService>, inventoryItemHash: string, transport: Transport) {
    let formGrid = document.createElement("div");
    formGrid.className = "grid grid-cols-1 gap-6 mb-6";
    const formId = randomId();

    const [
        referencesSection,
        datesSection,
        parametersSection,
    ] = await Promise.all([
        renderReferencesSection(qasample, ancillaryParams, readonly, accessClient, inventoryItemHash, transport),
        renderDatesSection(qasample, ancillaryParams, readonly, accessClient),
        renderParametersSection(qasample, readonly, accessClient, transport)
    ]);

    let buttonContainer = document.createElement("div");
    buttonContainer.id = randomId();
    buttonContainer.classList.add("col-span-12", "flex", "justify-start", "md:justify-center", "overflow-x-auto");

    // Download PDF button
    let downloadPDFButton = document.createElement("button");
    downloadPDFButton.id = randomId();
    downloadPDFButton.className = `btn btn-success btn-outline btn-sm ${downloadPDFButtonClass} mr-4`;
    downloadPDFButton.innerText = "Download PDF";
    downloadPDFButton.setAttribute("data-uuid", qasample.metadata?.uuid!);
    downloadPDFButton.setAttribute("data-name", qasample.approvalMetadata?.approvedOn! > 0 ? qasample.name : qasample.name);
    buttonContainer.appendChild(downloadPDFButton);

    formGrid.innerHTML = `
        <div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words ${bgColor} w-full shadow-lg rounded">
            <div class="rounded-t mb-0 px-0 border-0">
                ${renderPageTitleSection({ title: `QA Sample: ` + document.title }).outerHTML}
                ${buttonContainer.outerHTML}
            </div>
            <form id="${formId}">
                <hr class="m-5">
                ${referencesSection.outerHTML}
                <hr class="m-5">
                ${datesSection.outerHTML}
                <hr class="m-5">
            </form>
        </div>
        <div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words ${bgColor} w-full shadow-lg rounded">
            ${parametersSection.outerHTML}
        </div>
    `;

    return { formGrid, buttonContainer }
}
