"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file feedstocks.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.FeedstocksService = void 0;
const feedstocks_scailo_pb_js_1 = require("./feedstocks.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
/**
 *
 * Describes the common methods applicable on each feedstock
 *
 * @generated from service Scailo.FeedstocksService
 */
exports.FeedstocksService = {
    typeName: "Scailo.FeedstocksService",
    methods: {
        /**
         * Create (and Send for QC)
         *
         * @generated from rpc Scailo.FeedstocksService.Create
         */
        create: {
            name: "Create",
            I: feedstocks_scailo_pb_js_1.FeedstocksServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send to Store
         *
         * @generated from rpc Scailo.FeedstocksService.SendToStore
         */
        sendToStore: {
            name: "SendToStore",
            I: feedstocks_scailo_pb_js_1.FeedstocksServiceSendToStoreRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update
         *
         * @generated from rpc Scailo.FeedstocksService.Update
         */
        update: {
            name: "Update",
            I: feedstocks_scailo_pb_js_1.FeedstocksServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for Rework
         *
         * @generated from rpc Scailo.FeedstocksService.SendForRework
         */
        sendForRework: {
            name: "SendForRework",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for QC
         *
         * @generated from rpc Scailo.FeedstocksService.SendForQC
         */
        sendForQC: {
            name: "SendForQC",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Split into unit quantities
         *
         * @generated from rpc Scailo.FeedstocksService.SplitLot
         */
        splitLot: {
            name: "SplitLot",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create a partition from the parent lot (and returns the identifier of the new partition)
         *
         * @generated from rpc Scailo.FeedstocksService.Partition
         */
        partition: {
            name: "Partition",
            I: base_scailo_pb_js_1.InventoryPartitionRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Consume
         *
         * @generated from rpc Scailo.FeedstocksService.Consume
         */
        consume: {
            name: "Consume",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reject
         *
         * @generated from rpc Scailo.FeedstocksService.Reject
         */
        reject: {
            name: "Reject",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Scrap
         *
         * @generated from rpc Scailo.FeedstocksService.Scrap
         */
        scrap: {
            name: "Scrap",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Return
         *
         * @generated from rpc Scailo.FeedstocksService.ReturnMaterial
         */
        returnMaterial: {
            name: "ReturnMaterial",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.FeedstocksService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * @generated from rpc Scailo.FeedstocksService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.FeedstocksService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: feedstocks_scailo_pb_js_1.Feedstock,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by UUID
         *
         * @generated from rpc Scailo.FeedstocksService.ViewByUUID
         */
        viewByUUID: {
            name: "ViewByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: feedstocks_scailo_pb_js_1.Feedstock,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View only essential components by ID (without logs)
         *
         * @generated from rpc Scailo.FeedstocksService.ViewEssentialByID
         */
        viewEssentialByID: {
            name: "ViewEssentialByID",
            I: base_scailo_pb_js_1.Identifier,
            O: feedstocks_scailo_pb_js_1.Feedstock,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View only essential components (without logs) that matches the given UUID
         *
         * @generated from rpc Scailo.FeedstocksService.ViewEssentialByUUID
         */
        viewEssentialByUUID: {
            name: "ViewEssentialByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: feedstocks_scailo_pb_js_1.Feedstock,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all records with the given IDs
         *
         * @generated from rpc Scailo.FeedstocksService.ViewFromIDs
         */
        viewFromIDs: {
            name: "ViewFromIDs",
            I: base_scailo_pb_js_1.IdentifiersList,
            O: feedstocks_scailo_pb_js_1.FeedstocksList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.FeedstocksService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: feedstocks_scailo_pb_js_1.FeedstocksList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.FeedstocksService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: feedstocks_scailo_pb_js_1.FeedstocksServicePaginationReq,
            O: feedstocks_scailo_pb_js_1.FeedstocksServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download QC Report with the given Identifier
         *
         * @generated from rpc Scailo.FeedstocksService.DownloadQCReportByID
         */
        downloadQCReportByID: {
            name: "DownloadQCReportByID",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download QC Report with the given IdentifierUUID (can be used to allow public downloads)
         *
         * @generated from rpc Scailo.FeedstocksService.DownloadQCReportByUUID
         */
        downloadQCReportByUUID: {
            name: "DownloadQCReportByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download Label for the given Identifier
         *
         * @generated from rpc Scailo.FeedstocksService.DownloadLabelByID
         */
        downloadLabelByID: {
            name: "DownloadLabelByID",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download Label for the given IdentifierUUID (can be used to allow public downloads)
         *
         * @generated from rpc Scailo.FeedstocksService.DownloadLabelByUUID
         */
        downloadLabelByUUID: {
            name: "DownloadLabelByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all inventory interactions for feedstock with the given IdentifierUUID
         *
         * @generated from rpc Scailo.FeedstocksService.ViewInventoryInteractions
         */
        viewInventoryInteractions: {
            name: "ViewInventoryInteractions",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.InventoryInteractionsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.FeedstocksService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: feedstocks_scailo_pb_js_1.FeedstocksServiceSearchAllReq,
            O: feedstocks_scailo_pb_js_1.FeedstocksList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.FeedstocksService.Filter
         */
        filter: {
            name: "Filter",
            I: feedstocks_scailo_pb_js_1.FeedstocksServiceFilterReq,
            O: feedstocks_scailo_pb_js_1.FeedstocksList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.FeedstocksService.Count
         */
        count: {
            name: "Count",
            I: feedstocks_scailo_pb_js_1.FeedstocksServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
