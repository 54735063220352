import { vaultMenu } from "../../menus";
import { Router, context } from "./../../router";
import { _addFileFunctionality, bgColor, convertBigIntTimestampToDateTime, convertBytesToHumanReadbleFileSize, convertQueryStringToObject, createObjectFromForm, destroyAlert, downloadButtonClass, downloadData, handlePageForInvalidPermissions, interceptPage, internationalizeMoney, randomId, renderTableWithCSVHeadersAndRows, round, showFailureAlert, showSuccessAlert, toTitleCase, uuidNil } from "./../../utilities";
import { getTransport } from "../../clients";
import { getClientForVaultService, VAULT_REF_FOR, VaultFile, VaultFolder, VaultPermission, VaultSearchReq, VaultService } from "@kernelminds/scailo-sdk";
import { PromiseClient } from "@connectrpc/connect";
import { protoInt64 } from "@bufbuild/protobuf";
import { redirectTo, renderSelect } from "../../ui";
import { _renderPageFilters } from "./searchcomponents";

const vaultItemClass = "_vault-item";
const vaultClass = "_vault-class";
export const fileClass = "_vault-file";
export const folderClass = "_vault-folder";

export const vaultItemHighlightClass = "highlight-item";

const dataName = "data-name";
const dataMimeType = "data-mime-type";
const dataCreatedAt = "data-created-at";
const dataModifiedAt = "data-modified-at";

export const folderBaseURL = "/ui/vault/";

export const moreOptionsClassName = "__more-options";

// BitWeightExecute stores thebit weight of the execute permission
export const BitWeightExecute = 32
// BitWeightDelete stores the bit weight of the delete permission
export const BitWeightDelete = 16
// BitWeightAdd stores the bit weight of the add permission
export const BitWeightAdd = 8
// BitWeightDownload stores the bit weight of the download permission
export const BitWeightDownload = 4
// BitWeightMeta stores the bit weight of the meta permission
export const BitWeightMeta = 2
// BitWeightView stores the bit weight of the view permission
export const BitWeightView = 1

/**All the routes of this module */
export function Routes(r: Router) {
    r.add(vaultMenu.href, async (ctx) => {
        if (await interceptPage(ctx, vaultMenu)) {
            handleVaultPage(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    r.add(vaultMenu.href + "/:uuid", async (ctx) => {
        if (await interceptPage(ctx, vaultMenu)) {
            handleVaultPage(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
}

type sortByOptions = "name_ascending" | "name_descending" | "added_date_ascending" | "added_date_descending" | "modified_date_ascending" | "modified_date_descending";

async function handleVaultPage(ctx: context) {
    let content = <HTMLDivElement>document.getElementById("central-content");
    while (content.firstChild) {
        content.removeChild(content.firstChild);
    }

    const GETQuery = convertQueryStringToObject(ctx.querystring);

    let folderUUID: string = ctx.params.uuid == null || ctx.params.uuid == undefined ? uuidNil : ctx.params.uuid;
    let sortBy: sortByOptions = GETQuery["s"] == null || GETQuery["s"] == undefined ? "name_ascending" : GETQuery["s"];

    // Highlight parameters
    let hltuid: string = (GETQuery["hltuid"] == null || GETQuery["hltuid"] == undefined) ? "" : GETQuery["hltuid"];

    const transport = getTransport();
    const client = getClientForVaultService(transport);

    let container = document.createElement("div");
    container.className = `overflow-x-auto p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words ${bgColor} w-full shadow-lg h-screen rounded`;
    content.appendChild(container);


    let [folder, folderNodes, fileNodes, folderPath, urlToRedirectTo] = await returnVaultNodesAndFolderPath(folderUUID, sortBy, true, hltuid, client);
    if (urlToRedirectTo.length > 0) {
        redirectTo(urlToRedirectTo, true);
        return
    }

    if (sortBy == "name_ascending") {
        folderNodes = folderNodes.sort(sortByNameAsc);
        fileNodes = fileNodes.sort(sortByNameAsc);
    } else if (sortBy == "added_date_ascending") {
        folderNodes = folderNodes.sort(sortByDateAddedAsc);
        fileNodes = fileNodes.sort(sortByDateAddedAsc);
    } else if (sortBy == "modified_date_ascending") {
        folderNodes = folderNodes.sort(sortByDateModifiedAsc);
        fileNodes = fileNodes.sort(sortByDateModifiedAsc);
    } else if (sortBy == "name_descending") {
        folderNodes = folderNodes.sort(sortByNameDesc);
        fileNodes = fileNodes.sort(sortByNameDesc);
    } else if (sortBy == "added_date_descending") {
        folderNodes = folderNodes.sort(sortByDateAddedDesc);
        fileNodes = fileNodes.sort(sortByDateAddedDesc);
    } else if (sortBy == "modified_date_descending") {
        folderNodes = folderNodes.sort(sortByDateModifiedDesc);
        fileNodes = fileNodes.sort(sortByDateModifiedDesc);
    }

    document.title = folder.name == "/" ? "Home" : folder.name;

    let titleDiv = document.createElement("div");
    titleDiv.className = "grid grid-cols-12";
    let title = document.createElement("h4");
    title.className = "col-span-12 md:col-span-10 my-auto text-xl";
    titleDiv.appendChild(title);

    let searchButton = document.createElement("button");
    searchButton.className = "btn btn-sucess btn-outline btn-sm mr-3 tooltip";
    searchButton.setAttribute("data-tip", "Search for files or folders");
    searchButton.innerHTML = `<i class="bx bx-search"></i>`;
    title.appendChild(searchButton);

    container.appendChild(titleDiv);

    searchButton.addEventListener("click", async (evt) => {
        evt.preventDefault();
        let { html, formId, resetButtonId, getButtonId, tableDivId } = _renderPageFilters("Records");

        let dialog = document.createElement("dialog");
        dialog.className = "modal";
        dialog.id = randomId();

        dialog.innerHTML = `
            <div class="max-w-full modal-box dark:text-white text-gray-700">
                <form method="dialog">
                <button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                </form>
                ${html}
            </div>
        `;
        dialog.innerHTML = html;

        document.body.appendChild(dialog);

        let getButton = <HTMLButtonElement>document.getElementById(getButtonId)!;
        let resetButton = <HTMLButtonElement>document.getElementById(resetButtonId)!;
        let resultsTableDiv = <HTMLDivElement>document.getElementById(tableDivId)!;

        resetButton.addEventListener("click", async (evt) => {
            evt.preventDefault();
            dialog.close();
            searchButton.dispatchEvent(new Event("click"));
        });

        getButton.addEventListener("click", async (evt) => {
            evt.preventDefault();

            while (resultsTableDiv.firstChild) {
                resultsTableDiv.removeChild(resultsTableDiv.firstChild);
            }

            getButton.disabled = true;
            getButton.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;

            let searchResultsList = await client.search(new VaultSearchReq(createObjectFromForm(formId)));

            getButton.disabled = false;
            getButton.innerText = `Get Records`;

            const searchRecords = searchResultsList.list;

            if (!searchRecords.length) {
                showFailureAlert("No Records Found");
                return;
            }

            let tableHeaders = ["S. No.", "Type", "Name", "Path", "Link"];
            let tableRows = searchRecords.map((r, i) => {
                let link = document.createElement("a");
                link.className = "btn btn-outline btn-info";
                link.target = "_blank";
                link.innerText = "View";
                if (r.type == VAULT_REF_FOR.VAULT_REF_FOR_FILE) {
                    link.href = `${folderBaseURL}${r.parentFolderUuid}?hltuid=${r.elementUuid}&s=${sortBy}`;
                } else if (r.type == VAULT_REF_FOR.VAULT_REF_FOR_FOLDER) {
                    link.href = `${folderBaseURL}${r.elementUuid}?&s=${sortBy}`;
                }
                return [
                    `${i + 1}.`,
                    r.type == VAULT_REF_FOR.VAULT_REF_FOR_FILE ? "File" : "Folder",
                    r.name,
                    r.path,
                    link.outerHTML,
                ]
            });

            let table = renderTableWithCSVHeadersAndRows({ title: "Search Results", headers_array: tableHeaders, rows_array: tableRows, responsive: true });
            resultsTableDiv.appendChild(table.table);
        });

        dialog.showModal();
        dialog.addEventListener("close", () => {
            document.body.removeChild(dialog);
        });
    });

    await _displayAddButtons(container, folder, client);

    // Create a dropdown that handles sort by
    let sortByElementId = randomId();
    let sortByOptions = ["name_ascending", "name_descending", "added_date_ascending", "added_date_descending", "modified_date_ascending", "modified_date_descending"].map(o => {
        let opt = document.createElement("option");
        opt.innerText = toTitleCase(o.split("_").join(" "));
        opt.value = o;
        if (o == sortBy) {
            opt.selected = true
        }
        return opt
    });
    let sortByElement = renderSelect({ id: sortByElementId, readonly: false, label: "Sort By", dataMapper: "", dataType: "string", value: "", mdColSpan: 2, helpText: "Select the Sort Order.", options: sortByOptions, dataRegex: ".*" })
    titleDiv.appendChild(sortByElement);

    let sortBySelect = <HTMLSelectElement>document.getElementById(sortByElementId);

    sortBySelect.addEventListener("change", async (evt) => {
        evt.preventDefault();
        let sortBy = sortBySelect.value;
        GETQuery["s"] = sortBy;
        redirectTo(location.pathname + "?" + (new URLSearchParams(<any>GETQuery)).toString(), true);
        return
    });

    folderPath.forEach(p => {
        title.appendChild(p);
        if (p.nodeName.toLowerCase() == "a") {
            let divider = document.createElement("span");
            divider.className = "m-2";
            divider.innerText = ">";
            title.appendChild(divider);
        }
    });

    _displayFolders(container, folderNodes, client);
    _displayFiles(container, fileNodes, client);
}

async function _displayAddButtons(container: HTMLDivElement, folder: VaultFolder, client: PromiseClient<typeof VaultService>) {
    let perm = await client.viewFolderPermission({ uuid: folder.metadata?.uuid! });
    if (!_checkForPermissionMatch(perm.permissionCode, BitWeightAdd)) {
        return;
    }

    // Add a div for additional buttons
    let buttonsDiv = document.createElement("div");
    container.appendChild(buttonsDiv);
    buttonsDiv.className = `col-span-12 pl-4 mt-4`;

    let addFolderButton = document.createElement("button");
    addFolderButton.id = randomId();
    addFolderButton.type = "button";
    addFolderButton.className = `btn btn-sm btn-success text-white mr-4 ml-2 float-right`;
    addFolderButton.innerHTML = `<i class='bx bx-folder-plus'></i> Create Folder`;
    buttonsDiv.appendChild(addFolderButton);

    addFolderButton.addEventListener("click", async (evt) => {
        evt.preventDefault();
        handleFolderName(folder.metadata?.uuid!, "", "add", client);
    });

    let addFileButton = document.createElement("button");
    addFileButton.id = randomId();
    addFileButton.type = "button";
    addFileButton.className = `btn btn-sm btn-success text-white mr-1 ml-4 float-right`;
    addFileButton.innerHTML = `<i class='bx bx-file-plus'></i> Upload File`;
    buttonsDiv.appendChild(addFileButton);

    addFileButton.addEventListener("click", async (evt) => {
        // Show the file picker
        evt.preventDefault();
        let up = document.createElement("input");
        up.multiple = true;
        up.type = "file";
        up.style.display = "none";
        buttonsDiv.appendChild(up);
        up.addEventListener("change", evt => {
            let files = <File[]>(<any>evt.target).files;
            for (let i = 0; i < files.length; i++) {
                _addFileFunctionality(folder.metadata?.uuid!, files[i], false, client);
            }
        });
        up.click();
    });
}

function handleFolderName(parentFolderUuid: string, folderUuid: string, scope: "add" | "rename", client: PromiseClient<typeof VaultService>) {
    let dialog = document.createElement("dialog");
    dialog.className = "modal";
    dialog.id = randomId();

    const inputId = randomId();
    const submitBtnId = randomId();

    dialog.innerHTML = `
        <div class="w-full md:w-1/2 lg:w-1/3 modal-box bg-white">
            <p class="text-center text-xl">Enter the name of the folder</p>
            <form method="dialog">
                <button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                <section class="mt-4">
                    <input type="text" id="${inputId}" class="input input-bordered w-full bg-gray-200" placeholder="Folder Name" />
                </section>
                <section class="mt-4">
                    <button type="submit" id="${submitBtnId}" class="btn btn-sm btn-success text-white float-right">${scope == "add" ? "Create" : "Rename"}</button>
                </section>
            </form>
        </div>
        `;
    document.body.appendChild(dialog);
    dialog.showModal();
    dialog.addEventListener("close", () => {
        document.body.removeChild(dialog);
    });
    document.getElementById(inputId)?.focus();

    document.getElementById(submitBtnId)?.addEventListener("click", async (evt) => {
        evt.preventDefault();
        let folderName = (<HTMLInputElement>document.getElementById(inputId)).value;
        if (folderName) {
            const alreadyExists = await client.doesFolderExist({ name: folderName.trim(), folderUuid: parentFolderUuid });
            if (alreadyExists.value) {
                showFailureAlert("Folder already exists");
                return;
            }
            if (scope == "add") {
                await client.addFolder({ name: folderName.trim(), parentFolderUuid });
            } else if (scope == "rename") {
                await client.renameFolder({ uuid: folderUuid, name: folderName.trim() });
            }

            dialog.close();
        } else {
            showFailureAlert("Please enter a name for the folder");
        }
    });
}

function _displayFolders(container: HTMLDivElement, folderNodes: HTMLElement[], client: PromiseClient<typeof VaultService>) {
    if (folderNodes.length == 0) {
        return;
    }
    const foldersGrid = document.createElement("div");
    foldersGrid.classList.add("grid", "grid-cols-12", "gap-4", "mt-4");
    let foldersTitle = document.createElement("h4");
    foldersTitle.className = "text-center";
    foldersTitle.innerText = "Folders";
    container.appendChild(foldersTitle);
    container.appendChild(foldersGrid);


    folderNodes.forEach(n => {
        foldersGrid.appendChild(n);
    });

    // Setup double click on folders
    let folders = foldersGrid.getElementsByClassName(folderClass);
    for (let i = 0; i < folders.length; i++) {
        folders[i].addEventListener("dblclick", async evt => {
            evt.preventDefault();
            let uuid = folders[i].getAttribute("data-uuid");
            let searchParams = new URLSearchParams(location.search);
            if (searchParams.get("hltuid") != null) {
                searchParams.delete("hltuid");
            }
            redirectTo(folderBaseURL + uuid + "?" + (new URLSearchParams(searchParams)).toString(), true);
        });
    }

    let moreFolderOptions = foldersGrid.getElementsByClassName(moreOptionsClassName);
    for (let i = 0; i < moreFolderOptions.length; i++) {
        moreFolderOptions[i].addEventListener("click", async evt => {
            evt.preventDefault();
            let ul = moreFolderOptions[i].parentElement?.getElementsByTagName("ul")![0]!;
            while (ul.firstChild) {
                ul.removeChild(ul.firstChild);
            }
            let folderUuid = moreFolderOptions[i].getAttribute("data-uuid")!
            let parentFolderUuid = moreFolderOptions[i].getAttribute("data-parent-folder-uuid")!;

            // Add link to open folder in new tab
            let openInNewTab = document.createElement("li");
            let searchParams = new URLSearchParams(location.search);
            if (searchParams.get("hltuid") != null) {
                searchParams.delete("hltuid");
            }

            openInNewTab.innerHTML = `<a href="${folderBaseURL + folderUuid + "?" + (new URLSearchParams(searchParams)).toString()}" target="_blank"><i class='bx bx-window-open'></i> Open in new tab</a>`;
            ul.appendChild(openInNewTab);

            // Get permissions for this folder
            let permission = await client.viewFolderPermission({ uuid: folderUuid });

            if (_checkForPermissionMatch(permission.permissionCode, BitWeightDownload)) {
                let downloadOption = document.createElement("li");
                let id = randomId();
                downloadOption.innerHTML = `<a id="${id}"><i class='bx bx-download'></i> Download</a>`;
                ul.appendChild(downloadOption);
                document.getElementById(id)?.addEventListener("click", async (evt) => {
                    evt.preventDefault();
                    let nID = showSuccessAlert(`Folder is being zipped...`, { timeoutInMs: -1 });
                    // Zip folder returns a websocket notification as well, so the UI will auto refresh
                    let zipResp = await client.zipFolder({ uuid: folderUuid });
                    checkIfFolderHasBeenZipped(zipResp.uuid, nID, 0, client);
                });
            }
            if (_checkForPermissionMatch(permission.permissionCode, BitWeightMeta)) {
                let renameOption = document.createElement("li");
                let id = randomId();
                renameOption.innerHTML = `<a id="${id}"><i class='bx bx-edit-alt'></i> Rename</a>`;
                ul.appendChild(renameOption);
                document.getElementById(id)?.addEventListener("click", async (evt) => {
                    evt.preventDefault();
                    handleFolderName(parentFolderUuid, folderUuid, "rename", client);
                });
            }
            if (_checkForPermissionMatch(permission.permissionCode, BitWeightDelete)) {
                let deleteOption = document.createElement("li");
                let id = randomId();
                deleteOption.innerHTML = `<a id="${id}"><i class='bx bx-trash'></i> Delete</a>`;
                ul.appendChild(deleteOption);
                document.getElementById(id)?.addEventListener("click", async (evt) => {
                    evt.preventDefault();
                    await client.deleteFolder({ uuid: folderUuid });
                });
            }
        });
    }
}

const downloadZipCheckTimeout = 2000;
/**Checks if the folder with the downloadUUID has been zipped yet */
async function checkIfFolderHasBeenZipped(downloadUUID: string, notificationID: string, iteration: number, client: PromiseClient<typeof VaultService>) {
    let status = await client.viewFolderDownloadStatus({ uuid: downloadUUID });
    if (status.error.length > 0) {
        destroyAlert(notificationID);
        showFailureAlert("Oops! Something has gone wrong with the download process. Please try again");
    } else if (!status.isZipped) {
        // Keep checking if the file has been zipped
        setTimeout(function () {
            checkIfFolderHasBeenZipped(downloadUUID, notificationID, iteration + 1, client);
        }, downloadZipCheckTimeout);
    } else {
        // Once the folder has been zipped, download the file
        destroyAlert(notificationID);
        showSuccessAlert("Starting download");
        __downloadFile("/vault/downloads/folder?uuid=" + downloadUUID);
    }
}

function handleFileName(parentFolderUuid: string, fileUuid: string, scope: | "rename", client: PromiseClient<typeof VaultService>) {
    let dialog = document.createElement("dialog");
    dialog.className = "modal";
    dialog.id = randomId();

    const inputId = randomId();
    const submitBtnId = randomId();

    dialog.innerHTML = `
        <div class="w-full md:w-1/2 lg:w-1/3 modal-box bg-white">
            <p class="text-center text-xl">Enter the name of the file</p>
            <form method="dialog">
                <button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                <section class="mt-4">
                    <input type="text" id="${inputId}" class="input input-bordered w-full bg-gray-200" placeholder="File Name" />
                </section>
                <section class="mt-4">
                    <button type="submit" id="${submitBtnId}" class="btn btn-sm btn-success text-white float-right">${scope == "rename" ? "Rename" : "Rename"}</button>
                </section>
            </form>
        </div>
        `;
    document.body.appendChild(dialog);
    dialog.showModal();
    dialog.addEventListener("close", () => {
        document.body.removeChild(dialog);
    });
    document.getElementById(inputId)?.focus();

    document.getElementById(submitBtnId)?.addEventListener("click", async (evt) => {
        evt.preventDefault();
        let fileName = (<HTMLInputElement>document.getElementById(inputId)).value;
        if (fileName) {
            const alreadyExists = await client.doesFileExist({ name: fileName.trim(), folderUuid: parentFolderUuid });
            if (alreadyExists.value) {
                showFailureAlert("File already exists");
                return;
            }
            if (scope == "rename") {
                await client.renameFile({ uuid: fileUuid, name: fileName.trim() });
            }

            dialog.close();
        } else {
            showFailureAlert("Please enter a name for the file");
        }
    });
}

async function displayFileVersions(fileUuid: string, canDownload: boolean, client: PromiseClient<typeof VaultService>) {
    const [file, versionsResp] = await Promise.all([
        client.viewFileByUUID({ uuid: fileUuid }),
        client.viewFileVersions({ uuid: fileUuid })
    ]);
    const versions = versionsResp.list;
    if (versions.length == 0) {
        showFailureAlert("No versions found");
        return;
    }

    let tableHeaders = ["S. No.", "Name", "Size", "Added By", "Added On"];
    if (canDownload) {
        tableHeaders.push("Download");
    }
    let tableRows = versions.map((v, index) => {
        let row = [
            `${index + 1}.`,
            v.name,
            convertBytesToHumanReadbleFileSize(parseFloat(String(v.size))),
            v.metadata?.addedBy!,
            convertBigIntTimestampToDateTime(v.metadata?.createdAt!),
        ];
        if (canDownload) {
            row.push(`<a target="_blank" href="/vault/downloads/version?uuid=${v.metadata?.uuid}"><i class='bx bx-download'></i></a>`);
        }
        return row
    });

    let table = renderTableWithCSVHeadersAndRows({ title: `Versions for ${file.name}`, headers_array: tableHeaders, rows_array: tableRows, responsive: true });

    // Render the table inside a modal
    let dialog = document.createElement("dialog");
    dialog.className = "modal";
    dialog.id = randomId();
    dialog.innerHTML = `
    <div class="max-w-full modal-box bg-gray-50 text-gray-700">
        <form method="dialog">
        <button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
        </form>
        ${table.table.outerHTML}
    </div>          
    `;

    document.body.appendChild(dialog);
    dialog.showModal()
    dialog.addEventListener("close", () => {
        document.body.removeChild(dialog);
    });
}

function _displayFiles(container: HTMLDivElement, fileNodes: HTMLElement[], client: PromiseClient<typeof VaultService>) {
    if (fileNodes.length == 0) {
        return;
    }
    const filesGrid = document.createElement("div");
    filesGrid.classList.add("grid", "grid-cols-12", "gap-4", "mt-4");
    let filesTitle = document.createElement("h4");
    filesTitle.className = "mt-4 text-center";
    filesTitle.innerText = "Files";
    container.appendChild(filesTitle);
    container.appendChild(filesGrid);

    fileNodes.forEach(n => {
        filesGrid.appendChild(n);
    });

    // Setup double click on files
    let files = filesGrid.getElementsByClassName(fileClass);
    for (let i = 0; i < files.length; i++) {
        let f = files[i];
        f.addEventListener("dblclick", async evt => {
            evt.preventDefault();
            let fileName = f.getAttribute(dataName) ?? "";
            let fileUUID = f.getAttribute("data-uuid") ?? "";
            let mimeType = f.getAttribute(dataMimeType);
            if (fileName.endsWith(".pdf")) {
                window.open(location.protocol + "//" + location.host + `/viewer.html?file=${encodeURIComponent("/vault/downloads/file?uuid=" + fileUUID)}`);
                return
            } else if (mimeType == "application/gix") {
                handleGixAppExecute(fileName, fileUUID, client);
            } else {
                checkDownloadVaultFile(fileUUID, client);
            }
        });
    }

    let moreFileOptions = filesGrid.getElementsByClassName(moreOptionsClassName);
    for (let i = 0; i < moreFileOptions.length; i++) {
        moreFileOptions[i].addEventListener("click", async evt => {
            evt.preventDefault();
            let ul = moreFileOptions[i].parentElement?.getElementsByTagName("ul")![0]!;
            while (ul.firstChild) {
                ul.removeChild(ul.firstChild);
            }
            let fileUuid = moreFileOptions[i].getAttribute("data-uuid")!
            let parentFolderUuid = moreFileOptions[i].getAttribute("data-parent-folder-uuid")!;
            // Get permissions for this file
            let permission = await client.viewFilePermission({ uuid: fileUuid });

            if (_checkForPermissionMatch(permission.permissionCode, BitWeightDownload)) {
                let downloadOption = document.createElement("li");
                let id = randomId();
                downloadOption.innerHTML = `<a id="${id}"><i class='bx bx-download'></i> Download</a>`;
                ul.appendChild(downloadOption);
                document.getElementById(id)?.addEventListener("click", async (evt) => {
                    evt.preventDefault();
                    checkDownloadVaultFile(fileUuid, client);
                });
            }
            if (_checkForPermissionMatch(permission.permissionCode, BitWeightMeta)) {
                let renameOption = document.createElement("li");
                let id = randomId();
                renameOption.innerHTML = `<a id="${id}"><i class='bx bx-edit-alt'></i> Rename</a>`;
                ul.appendChild(renameOption);
                document.getElementById(id)?.addEventListener("click", async (evt) => {
                    evt.preventDefault();
                    handleFileName(parentFolderUuid, fileUuid, "rename", client);
                });
            }
            if (_checkForPermissionMatch(permission.permissionCode, BitWeightMeta)) {
                let renameOption = document.createElement("li");
                let id = randomId();
                renameOption.innerHTML = `<a id="${id}"><i class='bx bx-history'></i> Versions</a>`;
                ul.appendChild(renameOption);
                document.getElementById(id)?.addEventListener("click", async (evt) => {
                    evt.preventDefault();
                    displayFileVersions(fileUuid, _checkForPermissionMatch(permission.permissionCode, BitWeightDownload), client);
                });
            }

            if (_checkForPermissionMatch(permission.permissionCode, BitWeightDelete)) {
                let deleteOption = document.createElement("li");
                let id = randomId();
                deleteOption.innerHTML = `<a id="${id}"><i class='bx bx-trash'></i> Delete</a>`;
                ul.appendChild(deleteOption);
                document.getElementById(id)?.addEventListener("click", async (evt) => {
                    evt.preventDefault();
                    await client.deleteFile({ uuid: fileUuid });
                });
            }
        });
    }
}

/**Returns all the HTML nodes that could be rendered on the UI */
export async function returnVaultNodesAndFolderPath(folderUUID: string, sortBy: string, hlt: boolean, hltuid: string, client: PromiseClient<typeof VaultService>): Promise<[VaultFolder, HTMLDivElement[], HTMLDivElement[], HTMLElement[], string]> {

    let [resources, folder] = await Promise.all([
        client.viewAccessibleResourcesInFolder({ uuid: folderUUID }),
        client.viewFolderByUUID({ uuid: folderUUID }),
    ])

    let vaultFolders = resources.folders;
    let vaultFiles = resources.files;

    let folderPath = <HTMLElement[]>[];

    let title = "Vault - ";
    if (folderUUID == uuidNil) {
        title += "Root"
    }

    if (folderUUID != uuidNil) {
        folderPath.push(_displayPathDetailForVaultFolder(<VaultFolder><any>{ metadata: { id: protoInt64.zero, uuid: uuidNil }, name: "Home" }, sortBy));
        if (!folder.metadata?.isActive) {
            let urlToRedirectTo = await getVaultRedirectionURL(folder.parentFolderUuid, sortBy);
            return [folder, [], [], folderPath, urlToRedirectTo];
        }

        title += folder.name;

        if (vaultFolders.length == 0 && vaultFiles.length == 0) {
            // Most likely the case where the this folder's permission was granted due to a subfolder/subfile access, but it no longer exists because
            // there are no more folders or files that can still be accessed

            // Fetch the folder permissions here -> and display the folder only if its permission_code > 0
            let perm = await getFolderPermission(folderUUID, client);
            if (perm.permissionCode == protoInt64.zero) {
                let urlToRedirectTo = await getVaultRedirectionURL(folder.parentFolderUuid, sortBy);
                return [folder, [], [], folderPath, urlToRedirectTo];
            }
        }

        folder.parentFolders.forEach(f => {
            folderPath.push(_displayPathDetailForVaultFolder(f, sortBy));
        });
        // Append the name of the current folder
        folderPath.push(_displayCurrentFolderDetails(folder.name));

    } else {
        folderPath.push(_displayCurrentFolderDetails("Home"));
    }

    // Create the HTML nodes here
    let folderNodes = vaultFolders.map(f => {
        return _renderVaultFolder(f, hlt, hltuid);
    });

    let fileNodes = vaultFiles.map(f => {
        return _renderVaultFile(f, hlt, hltuid);
    });

    document.title = title;

    return [folder, folderNodes, fileNodes, folderPath, ""];
}

/**Internal function that renders a vault item */
function __renderVaultItem(baseEl: HTMLElement, id: bigint, uuid: string, parentFolderUuid: string, name: string, dataType: "folder" | "file", createdAt: bigint, modifiedAt: bigint, persist: boolean, mimeType: string): HTMLElement {
    // let span = document.createElement("span");
    baseEl.setAttribute("data-tip", name);
    baseEl.draggable = true;
    baseEl.className = `${dataType == "folder" ? folderClass : fileClass} ${vaultClass} flex items-center tooltip cursor-pointer`;
    baseEl.setAttribute("data-id", String(id));
    baseEl.setAttribute("data-uuid", uuid);
    baseEl.setAttribute("data-parent-folder-uuid", parentFolderUuid);
    baseEl.setAttribute(dataName, name);
    baseEl.setAttribute(dataType, dataType);
    baseEl.setAttribute(dataMimeType, mimeType);
    baseEl.setAttribute(dataCreatedAt, String(createdAt));
    baseEl.setAttribute(dataModifiedAt, String(modifiedAt));

    let moreOptions = document.createElement("div")
    moreOptions.className = "dropdown dropdown-bottom";
    moreOptions.innerHTML = `
        <div class="${moreOptionsClassName}" data-uuid="${uuid}" data-parent-folder-uuid="${parentFolderUuid}" tabindex="0" role="button">
            <i class="bx bx-dots-vertical-rounded text-xl"></i>
        </div>
        <ul tabindex="0" class="dropdown-content menu bg-gray-50 rounded-box z-[1] w-52 p-2 shadow">
        </ul>
    `;

    let icon: HTMLElement | null = null;

    if (dataType == "file") {
        icon = document.createElement("img");
        icon.setAttribute("src", `/vault/files/logo?uuid=${uuid}&disableCache=${new Date().getTime()}`);
        icon.classList.add("m-auto");
        icon.style.width = "10rem";
    } else if (dataType == "folder") {
        icon = document.createElement("i");
        icon.className = `bx bx-folder-open mr-1 text-2xl ml-auto`;
    }

    let nameEl = document.createElement("div");
    nameEl.classList.add("ml-1");
    nameEl.classList.add("truncate");
    nameEl.innerText = name;

    if (dataType == "folder") {
        baseEl.appendChild(moreOptions);
        baseEl.appendChild(nameEl);
        baseEl.appendChild(icon!);
    } else if (dataType == "file") {
        let t = document.createElement("div");
        t.classList.add("m-auto");
        t.classList.add("truncate");
        t.classList.add("grid", "grid-cols-12");

        moreOptions.classList.add("col-span-1");
        t.appendChild(moreOptions);

        nameEl.classList.add("col-span-11");
        t.appendChild(nameEl);

        icon?.classList.add("col-span-12");
        t.appendChild(icon!);

        baseEl.appendChild(t);
    }

    return baseEl
}

/**Renders the vault folder component */
function _renderVaultFolder(folder: VaultFolder, hlt: boolean, hltuid: string): HTMLDivElement {
    let a = <HTMLAnchorElement>__renderVaultItem(document.createElement("a"), folder.metadata?.id!, folder.metadata?.uuid!, folder.parentFolderUuid, folder.name, "folder", folder.metadata?.createdAt!, folder.metadata?.modifiedAt!, false, "");
    let div = document.createElement("div");
    div.className = `col-span-6 md:col-span-3 lg:col-span-2 ${vaultItemClass} bg-gray-200 text-gray-900 pt-3 pb-3 pl-1 pr-1 rounded-lg`;
    if (hlt && folder.metadata?.uuid == hltuid) {
        div.classList.remove("bg-gray-200");
        div.classList.add("bg-gray-400");
    }
    div.appendChild(a);

    return div
}

/**Returns the vault file component */
function _renderVaultFile(file: VaultFile, hlt: boolean, hltuid: string): HTMLDivElement {
    let el = __renderVaultItem(document.createElement("a"), file.metadata?.id!, file.metadata?.uuid!, file.folderUuid, file.name, "file", file.metadata?.createdAt!, file.metadata?.modifiedAt!, file.isPersistent, file.type);
    let div = document.createElement("div");
    div.className = `col-span-6 md:col-span-3 lg:col-span-2 ${vaultItemClass} bg-gray-200 text-gray-900 pt-3 pb-3 pl-1 pr-1 rounded-lg`;
    if (hlt && file.metadata?.uuid == hltuid) {
        div.classList.remove("bg-gray-200");
        div.classList.add("bg-gray-400");
    }
    div.appendChild(el);
    return div
}

function _displayPathDetailForVaultFolder(folder: VaultFolder, sortBy: string): HTMLAnchorElement {
    let el = document.createElement("a");
    el.classList.add("badge", "badge-primary", "p-4");
    // el.setAttribute("data-folder-id", String(folder.metadata?.id));
    el.setAttribute("data-folder-uuid", folder.metadata?.uuid!);
    if (folder.metadata?.id == protoInt64.zero) {
        el.href = folderBaseURL + "?s=" + sortBy;
    } else {
        el.href = generateVaultFolderURL(folder.metadata?.uuid!, sortBy);
    }
    el.innerText = folder.name;
    return el
}

export function generateVaultFolderURL(uuid: string, sortBy: string) {
    return folderBaseURL + uuid + "?s=" + sortBy;
}

/**Returns the URL that the user needs to be redirected to */
async function getVaultRedirectionURL(folderUuid: string, sortBy: string) {
    return generateVaultFolderURL(folderUuid, sortBy);
}

export function _displayCurrentFolderDetails(name: string): HTMLSpanElement {
    let el = document.createElement("span");
    el.setAttribute("data-folder-id", "0");
    el.setAttribute("data-folder-uuid", uuidNil);
    el.innerText = name;
    return el
}

/**Downloads the file after checking for file permissions */
async function checkDownloadVaultFile(fileUUID: string, client: PromiseClient<typeof VaultService>) {
    let cumulative = await Promise.all([
        client.viewFileByUUID({ uuid: fileUUID }),
        client.viewFilePermission({ uuid: fileUUID }),
    ]);
    let file = <VaultFile>cumulative[0];
    let permission = <VaultPermission>cumulative[1];
    if (_checkForPermissionMatch(permission.permissionCode, BitWeightDownload)) {
        // User has permission to download the file
        __downloadFile("/vault/downloads/file?uuid=" + file.metadata?.uuid);
    } else {
        // No permission to download the file -> display an appropriate notification
        showFailureAlert("You do not have the permission to download this file");
    }
}

/**Checks if the presentPermCode is present in the toMatchPermCode */
export function _checkForPermissionMatch(presentPermCode: bigint, toMatchPermCode: number): boolean {
    if ((parseInt(String(presentPermCode)) & toMatchPermCode) == toMatchPermCode) {
        return true
    }
    return false
}

/**Internal function that downloads the file */
function __downloadFile(url: string) {
    let a = document.createElement("a");
    a.href = location.origin + url;
    a.style.display = "none";
    a.download = "download";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

/**Handles the execution of a gix app */
async function handleGixAppExecute(filename: string, fileUUID: string, client: PromiseClient<typeof VaultService>) {
    let nID = showSuccessAlert("Setting up GiX app");
    let perm = await client.viewFilePermission({ uuid: fileUUID });
    if (!_checkForPermissionMatch(perm.permissionCode, BitWeightExecute)) {
        showFailureAlert(`Sorry, you do not have the permission to run ${filename}`);
        setTimeout(() => {
            destroyAlert(nID);
        }, 3000);
        return
    }
    // User has permission
    let newNotificationId = showSuccessAlert("Loading the app...");

    // Process
    // - Make a call to the server to setup the app
    let runData = await client.setupGiX({ uuid: fileUUID });

    destroyAlert(newNotificationId);
    window.open(location.protocol + "//" + location.host + runData.appEndpoint);
    // notifications.confirm(`${filename} has been setup. Are you sure you want to execute it?`, "success",
    //     async function () {
    //         window.open(location.protocol + "//" + location.host + runData.appEndpoint);
    //     }, async function () { });
}

/**Generic function that is used to sort HTMLSpan elements in the ascending order */
function _compareAsc(a: HTMLDivElement, b: HTMLDivElement, attrName: string) {
    let el1 = a.getElementsByClassName(vaultClass)[0].getAttribute(attrName);
    let el2 = b.getElementsByClassName(vaultClass)[0].getAttribute(attrName);
    if (el1! < el2!) {
        return -1
    }
    if (el1! > el2!) {
        return 1
    }
    return 0
}

/**Sorts the HTMLDivElements' internal vault items by their data-name in the ascending order */
function sortByNameAsc(a: HTMLDivElement, b: HTMLDivElement): -1 | 0 | 1 {
    return _compareAsc(a, b, dataName)
}

/**Sorts the HTMLDivElements' internal vault items by their data-timestamp in the ascending order */
function sortByDateAddedAsc(a: HTMLDivElement, b: HTMLDivElement): -1 | 0 | 1 {
    return _compareAsc(a, b, dataCreatedAt)
}

/**Sorts the HTMLDivElements' internal vault items by their data-timestamp in the ascending order */
function sortByDateModifiedAsc(a: HTMLDivElement, b: HTMLDivElement): -1 | 0 | 1 {
    return _compareAsc(a, b, dataModifiedAt)
}

/**Generic function that is used to sort HTMLSpan elements in the descending order */
function _compareDesc(a: HTMLDivElement, b: HTMLDivElement, attrName: string) {
    let el1 = a.getElementsByClassName(vaultClass)[0].getAttribute(attrName);
    let el2 = b.getElementsByClassName(vaultClass)[0].getAttribute(attrName);
    if (el1! < el2!) {
        return 1
    }
    if (el1! > el2!) {
        return -1
    }
    return 0
}

/**Sorts the HTMLDivElements' internal vault items by their data-name in the descending order */
function sortByNameDesc(a: HTMLDivElement, b: HTMLDivElement): -1 | 0 | 1 {
    return _compareDesc(a, b, dataName)
}

/**Sorts the HTMLDivElements' internal vault items by their data-timestamp in the descending order */
function sortByDateAddedDesc(a: HTMLDivElement, b: HTMLDivElement): -1 | 0 | 1 {
    return _compareDesc(a, b, dataCreatedAt)
}

/**Sorts the HTMLDivElements' internal vault items by their data-timestamp in the descending order */
function sortByDateModifiedDesc(a: HTMLDivElement, b: HTMLDivElement): -1 | 0 | 1 {
    return _compareDesc(a, b, dataModifiedAt)
}

/**Gets the access permission of the folder with folderUUID */
export async function getFolderPermission(folderUUID: string, client: PromiseClient<typeof VaultService>): Promise<VaultPermission> {
    return client.viewFolderPermission({ uuid: folderUUID });
}