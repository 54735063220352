"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file production_plans.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductionPlansService = void 0;
const production_plans_scailo_pb_js_1 = require("./production_plans.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
const families_scailo_pb_js_1 = require("./families.scailo_pb.js");
/**
 *
 * Describes the common methods applicable on each production plan
 *
 * @generated from service Scailo.ProductionPlansService
 */
exports.ProductionPlansService = {
    typeName: "Scailo.ProductionPlansService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.ProductionPlansService.Create
         */
        create: {
            name: "Create",
            I: production_plans_scailo_pb_js_1.ProductionPlansServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.ProductionPlansService.Draft
         */
        draft: {
            name: "Draft",
            I: production_plans_scailo_pb_js_1.ProductionPlansServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.ProductionPlansService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: production_plans_scailo_pb_js_1.ProductionPlansServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.ProductionPlansService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.ProductionPlansService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.ProductionPlansService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.ProductionPlansService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.ProductionPlansService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: production_plans_scailo_pb_js_1.ProductionPlansServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.ProductionPlansService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.ProductionPlansService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.ProductionPlansService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.ProductionPlansService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.ProductionPlansService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reopen
         *
         * @generated from rpc Scailo.ProductionPlansService.Reopen
         */
        reopen: {
            name: "Reopen",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * @generated from rpc Scailo.ProductionPlansService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send Email
         *
         * @generated from rpc Scailo.ProductionPlansService.SendEmail
         */
        sendEmail: {
            name: "SendEmail",
            I: base_scailo_pb_js_1.IdentifierWithEmailAttributes,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Autofill the production plan
         *
         * @generated from rpc Scailo.ProductionPlansService.Autofill
         */
        autofill: {
            name: "Autofill",
            I: production_plans_scailo_pb_js_1.ProductionPlansServiceAutofillRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Checks if the Production Plan can be marked as completed
         *
         * @generated from rpc Scailo.ProductionPlansService.IsCompletable
         */
        isCompletable: {
            name: "IsCompletable",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.BooleanResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an item to a production plan
         *
         * @generated from rpc Scailo.ProductionPlansService.AddProductionPlanItem
         */
        addProductionPlanItem: {
            name: "AddProductionPlanItem",
            I: production_plans_scailo_pb_js_1.ProductionPlansServiceItemCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify an item in a production plan
         *
         * @generated from rpc Scailo.ProductionPlansService.ModifyProductionPlanItem
         */
        modifyProductionPlanItem: {
            name: "ModifyProductionPlanItem",
            I: production_plans_scailo_pb_js_1.ProductionPlansServiceItemUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve an item in a production plan
         *
         * @generated from rpc Scailo.ProductionPlansService.ApproveProductionPlanItem
         */
        approveProductionPlanItem: {
            name: "ApproveProductionPlanItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an item in a production plan
         *
         * @generated from rpc Scailo.ProductionPlansService.DeleteProductionPlanItem
         */
        deleteProductionPlanItem: {
            name: "DeleteProductionPlanItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder items in a production plan
         *
         * @generated from rpc Scailo.ProductionPlansService.ReorderProductionPlanItems
         */
        reorderProductionPlanItems: {
            name: "ReorderProductionPlanItems",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Production Plan Item by ID
         *
         * @generated from rpc Scailo.ProductionPlansService.ViewProductionPlanItemByID
         */
        viewProductionPlanItemByID: {
            name: "ViewProductionPlanItemByID",
            I: base_scailo_pb_js_1.Identifier,
            O: production_plans_scailo_pb_js_1.ProductionPlanItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved production plan items for given production plan ID
         *
         * @generated from rpc Scailo.ProductionPlansService.ViewApprovedProductionPlanItems
         */
        viewApprovedProductionPlanItems: {
            name: "ViewApprovedProductionPlanItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: production_plans_scailo_pb_js_1.ProductionPlansItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved production plan items for given production plan ID
         *
         * @generated from rpc Scailo.ProductionPlansService.ViewUnapprovedProductionPlanItems
         */
        viewUnapprovedProductionPlanItems: {
            name: "ViewUnapprovedProductionPlanItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: production_plans_scailo_pb_js_1.ProductionPlansItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the production plan item
         *
         * @generated from rpc Scailo.ProductionPlansService.ViewProductionPlanItemHistory
         */
        viewProductionPlanItemHistory: {
            name: "ViewProductionPlanItemHistory",
            I: production_plans_scailo_pb_js_1.ProductionPlanItemHistoryRequest,
            O: production_plans_scailo_pb_js_1.ProductionPlansItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved production plan items for given production plan ID with pagination
         *
         * @generated from rpc Scailo.ProductionPlansService.ViewPaginatedApprovedProductionPlanItems
         */
        viewPaginatedApprovedProductionPlanItems: {
            name: "ViewPaginatedApprovedProductionPlanItems",
            I: production_plans_scailo_pb_js_1.ProductionPlanItemsSearchRequest,
            O: production_plans_scailo_pb_js_1.ProductionPlansServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved production plan items for given production plan ID with pagination
         *
         * @generated from rpc Scailo.ProductionPlansService.ViewPaginatedUnapprovedProductionPlanItems
         */
        viewPaginatedUnapprovedProductionPlanItems: {
            name: "ViewPaginatedUnapprovedProductionPlanItems",
            I: production_plans_scailo_pb_js_1.ProductionPlanItemsSearchRequest,
            O: production_plans_scailo_pb_js_1.ProductionPlansServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through production plan items with pagination
         *
         * @generated from rpc Scailo.ProductionPlansService.SearchItemsWithPagination
         */
        searchItemsWithPagination: {
            name: "SearchItemsWithPagination",
            I: production_plans_scailo_pb_js_1.ProductionPlanItemsSearchRequest,
            O: production_plans_scailo_pb_js_1.ProductionPlansServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * CSV operations
         * Download the CSV file that could be used to upload items from the filled CSV file. The same file can also be used as a template when there are no existing records
         *
         * @generated from rpc Scailo.ProductionPlansService.DownloadItemsAsCSV
         */
        downloadItemsAsCSV: {
            name: "DownloadItemsAsCSV",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Upload items using a CSV file. This is an idempotent operation. All the existing items are deleted before adding the items from the file.
         *
         * @generated from rpc Scailo.ProductionPlansService.UploadProductionPlanItems
         */
        uploadProductionPlanItems: {
            name: "UploadProductionPlanItems",
            I: base_scailo_pb_js_1.IdentifierUUIDWithFile,
            O: base_scailo_pb_js_1.IdentifiersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.ProductionPlansService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: production_plans_scailo_pb_js_1.ProductionPlan,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by UUID
         *
         * @generated from rpc Scailo.ProductionPlansService.ViewByUUID
         */
        viewByUUID: {
            name: "ViewByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: production_plans_scailo_pb_js_1.ProductionPlan,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View only essential components by ID (without logs)
         *
         * @generated from rpc Scailo.ProductionPlansService.ViewEssentialByID
         */
        viewEssentialByID: {
            name: "ViewEssentialByID",
            I: base_scailo_pb_js_1.Identifier,
            O: production_plans_scailo_pb_js_1.ProductionPlan,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View only essential components (without logs) that matches the given UUID
         *
         * @generated from rpc Scailo.ProductionPlansService.ViewEssentialByUUID
         */
        viewEssentialByUUID: {
            name: "ViewEssentialByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: production_plans_scailo_pb_js_1.ProductionPlan,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all records with the given IDs
         *
         * @generated from rpc Scailo.ProductionPlansService.ViewFromIDs
         */
        viewFromIDs: {
            name: "ViewFromIDs",
            I: base_scailo_pb_js_1.IdentifiersList,
            O: production_plans_scailo_pb_js_1.ProductionPlansList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the ancillary parameters (UUIDs of the internal references) by UUID
         *
         * @generated from rpc Scailo.ProductionPlansService.ViewAncillaryParametersByUUID
         */
        viewAncillaryParametersByUUID: {
            name: "ViewAncillaryParametersByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: production_plans_scailo_pb_js_1.ProductionPlanAncillaryParameters,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.ProductionPlansService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: production_plans_scailo_pb_js_1.ProductionPlansList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.ProductionPlansService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: production_plans_scailo_pb_js_1.ProductionPlansList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.ProductionPlansService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: production_plans_scailo_pb_js_1.ProductionPlansServicePaginationReq,
            O: production_plans_scailo_pb_js_1.ProductionPlansServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective families for the given production plan
         *
         * @generated from rpc Scailo.ProductionPlansService.ViewProspectiveFamilies
         */
        viewProspectiveFamilies: {
            name: "ViewProspectiveFamilies",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Filter prospective families for the record represented by the given UUID identifier
         *
         * @generated from rpc Scailo.ProductionPlansService.FilterProspectiveFamilies
         */
        filterProspectiveFamilies: {
            name: "FilterProspectiveFamilies",
            I: families_scailo_pb_js_1.FilterFamiliesReqForIdentifier,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective production plan item info for the given family ID and production plan ID
         *
         * @generated from rpc Scailo.ProductionPlansService.ViewProspectiveProductionPlanItem
         */
        viewProspectiveProductionPlanItem: {
            name: "ViewProspectiveProductionPlanItem",
            I: production_plans_scailo_pb_js_1.ProductionPlanItemProspectiveInfoRequest,
            O: production_plans_scailo_pb_js_1.ProductionPlansServiceItemCreateRequest,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the net quantity to be produced for the given family in the given production plan
         *
         * @generated from rpc Scailo.ProductionPlansService.ViewRemainingProductionQuantiy
         */
        viewRemainingProductionQuantiy: {
            name: "ViewRemainingProductionQuantiy",
            I: production_plans_scailo_pb_js_1.ProductionPlanItemProspectiveInfoRequest,
            O: base_scailo_pb_js_1.QuantityResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View already added quantities
         *
         * @generated from rpc Scailo.ProductionPlansService.ViewAddedFamilyQuantityForSource
         */
        viewAddedFamilyQuantityForSource: {
            name: "ViewAddedFamilyQuantityForSource",
            I: production_plans_scailo_pb_js_1.ProductionPlansServiceAlreadyAddedQuantityForSourceRequest,
            O: base_scailo_pb_js_1.QuantityResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download production plan with the given IdentifierUUID (can be used to allow public downloads)
         *
         * @generated from rpc Scailo.ProductionPlansService.DownloadByUUID
         */
        downloadByUUID: {
            name: "DownloadByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.ProductionPlansService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: production_plans_scailo_pb_js_1.ProductionPlansServiceSearchAllReq,
            O: production_plans_scailo_pb_js_1.ProductionPlansList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.ProductionPlansService.Filter
         */
        filter: {
            name: "Filter",
            I: production_plans_scailo_pb_js_1.ProductionPlansServiceFilterReq,
            O: production_plans_scailo_pb_js_1.ProductionPlansList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.ProductionPlansService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.ProductionPlansService.Count
         */
        count: {
            name: "Count",
            I: production_plans_scailo_pb_js_1.ProductionPlansServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * CSV operations
         * Download the CSV file that consists of the list of records according to the given filter request. The same file could also be used as a template for uploading records
         *
         * @generated from rpc Scailo.ProductionPlansService.DownloadAsCSV
         */
        downloadAsCSV: {
            name: "DownloadAsCSV",
            I: production_plans_scailo_pb_js_1.ProductionPlansServiceFilterReq,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
