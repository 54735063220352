"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file replaceable_indents.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReplaceableIndentsService = void 0;
const replaceable_indents_scailo_pb_js_1 = require("./replaceable_indents.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
const families_scailo_pb_js_1 = require("./families.scailo_pb.js");
/**
 *
 * Describes the common methods applicable on each replaceable indent
 *
 * @generated from service Scailo.ReplaceableIndentsService
 */
exports.ReplaceableIndentsService = {
    typeName: "Scailo.ReplaceableIndentsService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.Create
         */
        create: {
            name: "Create",
            I: replaceable_indents_scailo_pb_js_1.ReplaceableIndentsServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.Draft
         */
        draft: {
            name: "Draft",
            I: replaceable_indents_scailo_pb_js_1.ReplaceableIndentsServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: replaceable_indents_scailo_pb_js_1.ReplaceableIndentsServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: replaceable_indents_scailo_pb_js_1.ReplaceableIndentsServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reopen
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.Reopen
         */
        reopen: {
            name: "Reopen",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send Email
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.SendEmail
         */
        sendEmail: {
            name: "SendEmail",
            I: base_scailo_pb_js_1.IdentifierWithEmailAttributes,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Autofill the replaceable indent
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.Autofill
         */
        autofill: {
            name: "Autofill",
            I: replaceable_indents_scailo_pb_js_1.ReplaceableIndentsServiceAutofillRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Checks if the Replaceable Indent can be marked as completed
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.IsCompletable
         */
        isCompletable: {
            name: "IsCompletable",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.BooleanResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an item to a replaceable indent
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.AddReplaceableIndentItem
         */
        addReplaceableIndentItem: {
            name: "AddReplaceableIndentItem",
            I: replaceable_indents_scailo_pb_js_1.ReplaceableIndentsServiceItemCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify an item in a replaceable indent
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.ModifyReplaceableIndentItem
         */
        modifyReplaceableIndentItem: {
            name: "ModifyReplaceableIndentItem",
            I: replaceable_indents_scailo_pb_js_1.ReplaceableIndentsServiceItemUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve an item in a replaceable indent
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.ApproveReplaceableIndentItem
         */
        approveReplaceableIndentItem: {
            name: "ApproveReplaceableIndentItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an item in a replaceable indent
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.DeleteReplaceableIndentItem
         */
        deleteReplaceableIndentItem: {
            name: "DeleteReplaceableIndentItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder items in a replaceable indent
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.ReorderReplaceableIndentItems
         */
        reorderReplaceableIndentItems: {
            name: "ReorderReplaceableIndentItems",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Replaceable Indent Item by ID
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.ViewReplaceableIndentItemByID
         */
        viewReplaceableIndentItemByID: {
            name: "ViewReplaceableIndentItemByID",
            I: base_scailo_pb_js_1.Identifier,
            O: replaceable_indents_scailo_pb_js_1.ReplaceableIndentItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved replaceable indent items for given replaceable indent ID
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.ViewApprovedReplaceableIndentItems
         */
        viewApprovedReplaceableIndentItems: {
            name: "ViewApprovedReplaceableIndentItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: replaceable_indents_scailo_pb_js_1.ReplaceableIndentsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved replaceable indent items for given replaceable indent ID
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.ViewUnapprovedReplaceableIndentItems
         */
        viewUnapprovedReplaceableIndentItems: {
            name: "ViewUnapprovedReplaceableIndentItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: replaceable_indents_scailo_pb_js_1.ReplaceableIndentsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the replaceable indent item
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.ViewReplaceableIndentItemHistory
         */
        viewReplaceableIndentItemHistory: {
            name: "ViewReplaceableIndentItemHistory",
            I: replaceable_indents_scailo_pb_js_1.ReplaceableIndentItemHistoryRequest,
            O: replaceable_indents_scailo_pb_js_1.ReplaceableIndentsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved replaceable indent items for given replaceable indent ID with pagination
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.ViewPaginatedApprovedReplaceableIndentItems
         */
        viewPaginatedApprovedReplaceableIndentItems: {
            name: "ViewPaginatedApprovedReplaceableIndentItems",
            I: replaceable_indents_scailo_pb_js_1.ReplaceableIndentItemsSearchRequest,
            O: replaceable_indents_scailo_pb_js_1.ReplaceableIndentsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved replaceable indent items for given replaceable indent ID with pagination
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.ViewPaginatedUnapprovedReplaceableIndentItems
         */
        viewPaginatedUnapprovedReplaceableIndentItems: {
            name: "ViewPaginatedUnapprovedReplaceableIndentItems",
            I: replaceable_indents_scailo_pb_js_1.ReplaceableIndentItemsSearchRequest,
            O: replaceable_indents_scailo_pb_js_1.ReplaceableIndentsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through replaceable indent items with pagination
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.SearchItemsWithPagination
         */
        searchItemsWithPagination: {
            name: "SearchItemsWithPagination",
            I: replaceable_indents_scailo_pb_js_1.ReplaceableIndentItemsSearchRequest,
            O: replaceable_indents_scailo_pb_js_1.ReplaceableIndentsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * CSV operations
         * Download the CSV file that could be used to upload items from the filled CSV file. The same file can also be used as a template when there are no existing records
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.DownloadItemsAsCSV
         */
        downloadItemsAsCSV: {
            name: "DownloadItemsAsCSV",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Upload items using a CSV file. This is an idempotent operation. All the existing items are deleted before adding the items from the file.
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.UploadReplaceableIndentItems
         */
        uploadReplaceableIndentItems: {
            name: "UploadReplaceableIndentItems",
            I: base_scailo_pb_js_1.IdentifierUUIDWithFile,
            O: base_scailo_pb_js_1.IdentifiersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: replaceable_indents_scailo_pb_js_1.ReplaceableIndent,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by UUID
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.ViewByUUID
         */
        viewByUUID: {
            name: "ViewByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: replaceable_indents_scailo_pb_js_1.ReplaceableIndent,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View only essential components by ID (without logs)
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.ViewEssentialByID
         */
        viewEssentialByID: {
            name: "ViewEssentialByID",
            I: base_scailo_pb_js_1.Identifier,
            O: replaceable_indents_scailo_pb_js_1.ReplaceableIndent,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View only essential components (without logs) that matches the given UUID
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.ViewEssentialByUUID
         */
        viewEssentialByUUID: {
            name: "ViewEssentialByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: replaceable_indents_scailo_pb_js_1.ReplaceableIndent,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all records with the given IDs
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.ViewFromIDs
         */
        viewFromIDs: {
            name: "ViewFromIDs",
            I: base_scailo_pb_js_1.IdentifiersList,
            O: replaceable_indents_scailo_pb_js_1.ReplaceableIndentsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: replaceable_indents_scailo_pb_js_1.ReplaceableIndentsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: replaceable_indents_scailo_pb_js_1.ReplaceableIndentsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: replaceable_indents_scailo_pb_js_1.ReplaceableIndentsServicePaginationReq,
            O: replaceable_indents_scailo_pb_js_1.ReplaceableIndentsServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective families for the given replaceable indent
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.ViewProspectiveFamilies
         */
        viewProspectiveFamilies: {
            name: "ViewProspectiveFamilies",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Filter prospective families for the record represented by the given UUID identifier
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.FilterProspectiveFamilies
         */
        filterProspectiveFamilies: {
            name: "FilterProspectiveFamilies",
            I: families_scailo_pb_js_1.FilterFamiliesReqForIdentifier,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective replaceable indent item info for the given family ID and replaceable indent ID
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.ViewProspectiveReplaceableIndentItem
         */
        viewProspectiveReplaceableIndentItem: {
            name: "ViewProspectiveReplaceableIndentItem",
            I: replaceable_indents_scailo_pb_js_1.ReplaceableIndentItemProspectiveInfoRequest,
            O: replaceable_indents_scailo_pb_js_1.ReplaceableIndentsServiceItemCreateRequest,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download replaceable indent with the given IdentifierUUID (can be used to allow public downloads)
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.DownloadByUUID
         */
        downloadByUUID: {
            name: "DownloadByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the added quantity for the family with the given request payload
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.ViewAddedQuantityForFamily
         */
        viewAddedQuantityForFamily: {
            name: "ViewAddedQuantityForFamily",
            I: replaceable_indents_scailo_pb_js_1.ReplaceableIndentItemProspectiveInfoRequest,
            O: base_scailo_pb_js_1.QuantityResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: replaceable_indents_scailo_pb_js_1.ReplaceableIndentsServiceSearchAllReq,
            O: replaceable_indents_scailo_pb_js_1.ReplaceableIndentsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.Filter
         */
        filter: {
            name: "Filter",
            I: replaceable_indents_scailo_pb_js_1.ReplaceableIndentsServiceFilterReq,
            O: replaceable_indents_scailo_pb_js_1.ReplaceableIndentsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.Count
         */
        count: {
            name: "Count",
            I: replaceable_indents_scailo_pb_js_1.ReplaceableIndentsServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * CSV operations
         * Download the CSV file that consists of the list of records according to the given filter request. The same file could also be used as a template for uploading records
         *
         * @generated from rpc Scailo.ReplaceableIndentsService.DownloadAsCSV
         */
        downloadAsCSV: {
            name: "DownloadAsCSV",
            I: replaceable_indents_scailo_pb_js_1.ReplaceableIndentsServiceFilterReq,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
