import { context } from "./../../router";
import { ProductionPlan, ProductionPlansService, ProductionPlanAncillaryParameters, Currency, Family, UnitOfMaterial, TaxGroup, WorkOrdersService, WorkOrderAncillaryParameters, getClientForProductionPlansService, getClientForUnitsOfMaterialsService, getClientForWorkOrdersService, WorkOrder } from "@kernelminds/scailo-sdk";
import { renderFilterPrimarySubSection, renderInput, renderPageTitleSection, renderSpan, returnFormFromFields } from "../../ui";
import { bgColor, convertBigIntTimestampToDate, convertCentsToMoney, decodeSLC, downloadData, internationalizeMoney, randomId, returnAssociateBadge, toTitleCase } from "../../utilities";
import { PromiseClient, Transport } from "@connectrpc/connect";
import { familiesListFromIDs } from "../../fetches";
import { getTransport } from "../../clients";

const downloadPDFButtonClass = "__download-pdf-btn";

export async function handleIndividualProductionPlan(ctx: context) {
    let content = <HTMLDivElement>document.getElementById("central-content");
    while (content.firstChild) {
        content.removeChild(content.firstChild);
    }
    const transport = getTransport();
    const accessClient = getClientForProductionPlansService(transport);
    const [productionplan, ancillaryParams] = await Promise.all([
        accessClient.viewByUUID({ uuid: ctx.params.uuid }),
        accessClient.viewAncillaryParametersByUUID({ uuid: ctx.params.uuid }),
    ]);

    document.title = productionplan.approvalMetadata?.approvedOn! > 0 ? productionplan.finalRefNumber : productionplan.referenceId;

    let container = document.createElement("div");
    container.className = "overflow-x-auto";
    content.appendChild(container);

    const readonly = true;
    const { formGrid } = await getForm(productionplan, ancillaryParams, readonly, accessClient, transport);

    container.appendChild(formGrid);

    // Setup PDF downloads
    let pdfDownloadButtons = container.getElementsByClassName(downloadPDFButtonClass);
    for (let i = 0; i < pdfDownloadButtons.length; i++) {
        let btn = <HTMLButtonElement>pdfDownloadButtons[i];
        btn.addEventListener("click", async evt => {
            evt.preventDefault();

            const originalButtonHTML = btn.innerHTML;
            btn.disabled = true;
            btn.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
            let file = await accessClient.downloadByUUID({ uuid: btn.getAttribute("data-uuid") || "" });

            btn.disabled = false;
            btn.innerHTML = originalButtonHTML;

            downloadData(file.content, "pdf", file.name.replace(".pdf", ""));
        });
    }
}

async function renderReferencesSection(productionplan: ProductionPlan, ancillaryParams: ProductionPlanAncillaryParameters, workOrder: WorkOrder, readonly: boolean, accessClient: PromiseClient<typeof ProductionPlansService>): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "References", titleMdColSpan: 3 });

    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Initial Reference", inputType: "text", dataMapper: "referenceId", dataType: "string", value: productionplan.referenceId, mdColSpan: 6, helpText: "Initial Reference of the record.", dataRegex: ".+" }));
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Final Reference", inputType: "text", dataMapper: "finalRefNumber", dataType: "string", value: productionplan.finalRefNumber, mdColSpan: 6, helpText: "Final Reference of the record that is automatically generated.", dataRegex: ".+" }));
    // Display status as well
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Status", inputType: "text", dataMapper: "status", dataType: "string", value: toTitleCase(decodeSLC(productionplan.status)), mdColSpan: 3, helpText: "Status of the record.", dataRegex: ".+" }));

    return grid;
}

async function renderDatesSection(productionplan: ProductionPlan, ancillaryParams: ProductionPlanAncillaryParameters, workOrder: WorkOrder, readonly: boolean, accessClient: PromiseClient<typeof ProductionPlansService>): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "Important Dates", titleMdColSpan: 3 });

    // Dates (creation and approval)
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Production Plan Created On", inputType: "text", dataMapper: "createdAt", dataType: "string", value: convertBigIntTimestampToDate(productionplan.metadata?.createdAt!), mdColSpan: 3, helpText: "The creation date of this Production Plan.", dataRegex: ".*" }));
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Production Plan Approved On", inputType: "text", dataMapper: "approvedOn", dataType: "string", value: convertBigIntTimestampToDate(productionplan.approvalMetadata?.approvedOn!), mdColSpan: 3, helpText: "The approval date of this Production Plan.", dataRegex: ".*" }));

    return grid;
}

async function renderDynamicFormsSection(productionplan: ProductionPlan, readonly: boolean, accessClient: PromiseClient<typeof ProductionPlansService>): Promise<HTMLDivElement> {
    let container = document.createElement("div");

    if (productionplan.formData.length == 0) {
        return container;
    }

    let containerTitle = document.createElement("div");
    containerTitle.className = "rounded-t mb-0 px-0 border-0";
    containerTitle.appendChild(renderPageTitleSection({ title: `Other Details` }));
    container.appendChild(containerTitle);

    let formFields = await returnFormFromFields(productionplan.formData, "productionplans", true, readonly);

    container.appendChild(formFields);

    return container;
}

async function renderFamiliesSection(productionplan: ProductionPlan, readonly: boolean, accessClient: PromiseClient<typeof ProductionPlansService>, transport: Transport): Promise<HTMLDivElement> {
    let container = document.createElement("div");

    let containerTitle = document.createElement("div");
    containerTitle.className = "rounded-t mb-0 px-0 border-0";
    containerTitle.appendChild(renderPageTitleSection({ title: `Line Items` }));

    container.appendChild(containerTitle);

    let familiesMap: Map<bigint, Family> = new Map();
    (await familiesListFromIDs(Array.from(new Set(productionplan.list.map(p => p.familyId))))).forEach(family => {
        familiesMap.set(family.metadata?.id!, family);
    });
    
    const uomClient = getClientForUnitsOfMaterialsService(transport);
    let unitsList = <bigint[]>[];
    familiesMap.forEach(family => {
        unitsList.push(family.uomId);
    });

    const uomsList = (await uomClient.viewFromIDs({ list: Array.from(new Set(unitsList)) })).list;
    let uomsMap: Map<bigint, UnitOfMaterial> = new Map();

    uomsList.forEach(uom => {
        uomsMap.set(uom.metadata!.id, uom);
    });

    productionplan.list.forEach((item, index) => {
        let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: `${index + 1}.`, titleMdColSpan: 1 });

        let fam = familiesMap.get(item.familyId) || new Family();

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Material Code", value: `${fam.code}`, mdColSpan: 3, helpText: `The code of the material` }));
        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Material Name", value: `${fam.name}`, mdColSpan: 9, helpText: `The name of the material` }));

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Quantity & UoM", value: `${internationalizeMoney(parseFloat(convertCentsToMoney(item.quantity)))} (${uomsMap.get(fam.uomId)?.symbol}) ${uomsMap.get(fam.uomId)?.name}`, mdColSpan: 3, helpText: `The quantity of the material in internal unit of material` }));

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "HSN/SAC Code", value: `${fam.hsnSacCode}`, mdColSpan: 3, helpText: `The HSN/SAC code of the material` }));

        container.appendChild(grid);

        let hr = document.createElement("hr");
        hr.classList.add("m-5");
        container.appendChild(hr);
    });

    return container;
}

async function getForm(productionplan: ProductionPlan, ancillaryParams: ProductionPlanAncillaryParameters, readonly: boolean, accessClient: PromiseClient<typeof ProductionPlansService>, transport: Transport) {
    let formGrid = document.createElement("div");
    formGrid.className = "grid grid-cols-1 gap-6 mb-6";
    const formId = randomId();

    const workOrdersClient = getClientForWorkOrdersService(transport);
    const [workOrder, workOrderAncillaryParams] = await Promise.all([
        ancillaryParams.refUuid.length > 0 ? workOrdersClient.viewByUUID({ uuid: ancillaryParams.refUuid }) : new WorkOrder(),
        workOrdersClient.viewAncillaryParametersByUUID({ uuid: ancillaryParams.refUuid }),
    ]);

    const [
        referencesSection,
        datesSection,
        dynamicFormsSection,
        familiesSection,
    ] = await Promise.all([
        renderReferencesSection(productionplan, ancillaryParams, workOrder, readonly, accessClient),
        renderDatesSection(productionplan, ancillaryParams, workOrder, readonly, accessClient),
        renderDynamicFormsSection(productionplan, readonly, accessClient),
        renderFamiliesSection(productionplan, readonly, accessClient, transport)
    ]);

    let buttonContainer = document.createElement("div");
    buttonContainer.classList.add("col-span-12", "flex", "justify-start", "md:justify-center", "overflow-x-auto");

    // Download PDF button
    let downloadPDFButton = document.createElement("button");
    downloadPDFButton.id = randomId();
    downloadPDFButton.className = `btn btn-success btn-outline btn-sm ${downloadPDFButtonClass} mr-4`;
    downloadPDFButton.innerText = "Download PDF";
    downloadPDFButton.setAttribute("data-uuid", productionplan.metadata?.uuid!);
    downloadPDFButton.setAttribute("data-name", productionplan.approvalMetadata?.approvedOn! > 0 ? productionplan.finalRefNumber : productionplan.referenceId);
    buttonContainer.appendChild(downloadPDFButton);

    formGrid.innerHTML = `
        <div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words ${bgColor} w-full shadow-lg rounded">
            <div class="rounded-t mb-0 px-0 border-0">
                ${renderPageTitleSection({ title: `Production Plan: ` + document.title }).outerHTML}
                ${buttonContainer.outerHTML}
            </div>
            <form id="${formId}">
                <hr class="m-5">
                ${referencesSection.outerHTML}
                <hr class="m-5">
                ${datesSection.outerHTML}
                <hr class="m-5">
            </form>
        </div>
        ${dynamicFormsSection.childElementCount > 0 ? 
            `<div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words ${bgColor} w-full shadow-lg rounded">
                ${dynamicFormsSection.outerHTML}
            </div>` 
        : ''}
        <div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words ${bgColor} w-full shadow-lg rounded">
            ${familiesSection.outerHTML}
        </div>
    `;

    return { formGrid }
}
