"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file purchases_orders.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.PurchaseOrderInventoryMatchList = exports.PurchaseOrderInventoryMatch = exports.PurchaseOrderBillingStatistics = exports.PurchaseOrderInventoryStatistics = exports.PurchaseOrderContactsList = exports.PurchaseOrderContact = exports.PurchasesOrdersServiceContactCreateRequest = exports.PurchasesOrdersServicePaginatedItemsResponse = exports.PurchaseOrderItemsSearchRequest = exports.PurchaseOrderReferencesList = exports.PurchaseOrderReference = exports.PurchasesOrdersServiceReferenceCreateRequest = exports.PurchasesOrdersServiceSearchAllReq = exports.PurchasesOrdersServiceCountReq = exports.PurchasesOrdersServiceFilterReq = exports.PurchasesOrdersServicePaginationResponse = exports.PurchasesOrdersServicePaginationReq = exports.PurchaseOrderItemProspectiveInfoRequest = exports.PurchaseOrderItemHistoryRequest = exports.PurchaseOrderItemsList = exports.PurchasesOrdersList = exports.PurchaseOrderItem = exports.PurchasesOrdersServiceItemUpdateRequest = exports.PurchasesOrdersServiceItemCreateRequest = exports.PurchaseOrder = exports.PurchaseOrderAncillaryParameters = exports.PurchasesOrdersServiceAutofillRequest = exports.PurchasesOrdersServiceUpdateRequest = exports.PurchasesOrdersServiceCreateRequest = exports.PURCHASE_ORDER_ITEM_STATUS = exports.PURCHASE_ORDER_ITEM_SORT_KEY = exports.PURCHASE_ORDER_REFERENCE_REF_FROM = exports.PURCHASE_ORDER_REFERENCE_CONTEXT = exports.PURCHASE_ORDER_SORT_KEY = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const forms_fields_data_scailo_pb_js_1 = require("./forms_fields_data.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.PURCHASE_ORDER_SORT_KEY
 */
var PURCHASE_ORDER_SORT_KEY;
(function (PURCHASE_ORDER_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: PURCHASE_ORDER_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    PURCHASE_ORDER_SORT_KEY[PURCHASE_ORDER_SORT_KEY["PURCHASE_ORDER_SORT_KEY_ID_UNSPECIFIED"] = 0] = "PURCHASE_ORDER_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: PURCHASE_ORDER_SORT_KEY_CREATED_AT = 1;
     */
    PURCHASE_ORDER_SORT_KEY[PURCHASE_ORDER_SORT_KEY["PURCHASE_ORDER_SORT_KEY_CREATED_AT"] = 1] = "PURCHASE_ORDER_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: PURCHASE_ORDER_SORT_KEY_MODIFIED_AT = 2;
     */
    PURCHASE_ORDER_SORT_KEY[PURCHASE_ORDER_SORT_KEY["PURCHASE_ORDER_SORT_KEY_MODIFIED_AT"] = 2] = "PURCHASE_ORDER_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: PURCHASE_ORDER_SORT_KEY_APPROVED_ON = 3;
     */
    PURCHASE_ORDER_SORT_KEY[PURCHASE_ORDER_SORT_KEY["PURCHASE_ORDER_SORT_KEY_APPROVED_ON"] = 3] = "PURCHASE_ORDER_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: PURCHASE_ORDER_SORT_KEY_APPROVED_BY = 4;
     */
    PURCHASE_ORDER_SORT_KEY[PURCHASE_ORDER_SORT_KEY["PURCHASE_ORDER_SORT_KEY_APPROVED_BY"] = 4] = "PURCHASE_ORDER_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: PURCHASE_ORDER_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    PURCHASE_ORDER_SORT_KEY[PURCHASE_ORDER_SORT_KEY["PURCHASE_ORDER_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "PURCHASE_ORDER_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the approver's completed on timestamp
     *
     * @generated from enum value: PURCHASE_ORDER_SORT_KEY_COMPLETED_ON = 6;
     */
    PURCHASE_ORDER_SORT_KEY[PURCHASE_ORDER_SORT_KEY["PURCHASE_ORDER_SORT_KEY_COMPLETED_ON"] = 6] = "PURCHASE_ORDER_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch ordered results by the reference ID
     *
     * @generated from enum value: PURCHASE_ORDER_SORT_KEY_REFERENCE_ID = 10;
     */
    PURCHASE_ORDER_SORT_KEY[PURCHASE_ORDER_SORT_KEY["PURCHASE_ORDER_SORT_KEY_REFERENCE_ID"] = 10] = "PURCHASE_ORDER_SORT_KEY_REFERENCE_ID";
    /**
     * Fetch ordered results by the final ref number
     *
     * @generated from enum value: PURCHASE_ORDER_SORT_KEY_FINAL_REF_NUMBER = 11;
     */
    PURCHASE_ORDER_SORT_KEY[PURCHASE_ORDER_SORT_KEY["PURCHASE_ORDER_SORT_KEY_FINAL_REF_NUMBER"] = 11] = "PURCHASE_ORDER_SORT_KEY_FINAL_REF_NUMBER";
    /**
     * Fetch ordered results by the consignee location ID
     *
     * @generated from enum value: PURCHASE_ORDER_SORT_KEY_CONSIGNEE_LOCATION_ID = 12;
     */
    PURCHASE_ORDER_SORT_KEY[PURCHASE_ORDER_SORT_KEY["PURCHASE_ORDER_SORT_KEY_CONSIGNEE_LOCATION_ID"] = 12] = "PURCHASE_ORDER_SORT_KEY_CONSIGNEE_LOCATION_ID";
    /**
     * Fetch ordered results by the buyer location ID
     *
     * @generated from enum value: PURCHASE_ORDER_SORT_KEY_BUYER_LOCATION_ID = 13;
     */
    PURCHASE_ORDER_SORT_KEY[PURCHASE_ORDER_SORT_KEY["PURCHASE_ORDER_SORT_KEY_BUYER_LOCATION_ID"] = 13] = "PURCHASE_ORDER_SORT_KEY_BUYER_LOCATION_ID";
    /**
     * Fetch ordered results by the vendor ID
     *
     * @generated from enum value: PURCHASE_ORDER_SORT_KEY_VENDOR_ID = 14;
     */
    PURCHASE_ORDER_SORT_KEY[PURCHASE_ORDER_SORT_KEY["PURCHASE_ORDER_SORT_KEY_VENDOR_ID"] = 14] = "PURCHASE_ORDER_SORT_KEY_VENDOR_ID";
    /**
     * Fetch ordered results by the currency ID
     *
     * @generated from enum value: PURCHASE_ORDER_SORT_KEY_CURRENCY_ID = 15;
     */
    PURCHASE_ORDER_SORT_KEY[PURCHASE_ORDER_SORT_KEY["PURCHASE_ORDER_SORT_KEY_CURRENCY_ID"] = 15] = "PURCHASE_ORDER_SORT_KEY_CURRENCY_ID";
    /**
     * Fetch ordered results by the project ID
     *
     * @generated from enum value: PURCHASE_ORDER_SORT_KEY_PROJECT_ID = 16;
     */
    PURCHASE_ORDER_SORT_KEY[PURCHASE_ORDER_SORT_KEY["PURCHASE_ORDER_SORT_KEY_PROJECT_ID"] = 16] = "PURCHASE_ORDER_SORT_KEY_PROJECT_ID";
    /**
     * Fetch ordered results by the advance payment
     *
     * @generated from enum value: PURCHASE_ORDER_SORT_KEY_PAYMENT_ADVANCE = 17;
     */
    PURCHASE_ORDER_SORT_KEY[PURCHASE_ORDER_SORT_KEY["PURCHASE_ORDER_SORT_KEY_PAYMENT_ADVANCE"] = 17] = "PURCHASE_ORDER_SORT_KEY_PAYMENT_ADVANCE";
    /**
     * Fetch ordered results by the amendment count
     *
     * @generated from enum value: PURCHASE_ORDER_SORT_KEY_AMENDMENT_COUNT = 18;
     */
    PURCHASE_ORDER_SORT_KEY[PURCHASE_ORDER_SORT_KEY["PURCHASE_ORDER_SORT_KEY_AMENDMENT_COUNT"] = 18] = "PURCHASE_ORDER_SORT_KEY_AMENDMENT_COUNT";
    /**
     * Fetch ordered results by the total value
     *
     * @generated from enum value: PURCHASE_ORDER_SORT_KEY_TOTAL_VALUE = 30;
     */
    PURCHASE_ORDER_SORT_KEY[PURCHASE_ORDER_SORT_KEY["PURCHASE_ORDER_SORT_KEY_TOTAL_VALUE"] = 30] = "PURCHASE_ORDER_SORT_KEY_TOTAL_VALUE";
})(PURCHASE_ORDER_SORT_KEY || (exports.PURCHASE_ORDER_SORT_KEY = PURCHASE_ORDER_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(PURCHASE_ORDER_SORT_KEY)
protobuf_1.proto3.util.setEnumType(PURCHASE_ORDER_SORT_KEY, "Scailo.PURCHASE_ORDER_SORT_KEY", [
    { no: 0, name: "PURCHASE_ORDER_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "PURCHASE_ORDER_SORT_KEY_CREATED_AT" },
    { no: 2, name: "PURCHASE_ORDER_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "PURCHASE_ORDER_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "PURCHASE_ORDER_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "PURCHASE_ORDER_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 6, name: "PURCHASE_ORDER_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "PURCHASE_ORDER_SORT_KEY_REFERENCE_ID" },
    { no: 11, name: "PURCHASE_ORDER_SORT_KEY_FINAL_REF_NUMBER" },
    { no: 12, name: "PURCHASE_ORDER_SORT_KEY_CONSIGNEE_LOCATION_ID" },
    { no: 13, name: "PURCHASE_ORDER_SORT_KEY_BUYER_LOCATION_ID" },
    { no: 14, name: "PURCHASE_ORDER_SORT_KEY_VENDOR_ID" },
    { no: 15, name: "PURCHASE_ORDER_SORT_KEY_CURRENCY_ID" },
    { no: 16, name: "PURCHASE_ORDER_SORT_KEY_PROJECT_ID" },
    { no: 17, name: "PURCHASE_ORDER_SORT_KEY_PAYMENT_ADVANCE" },
    { no: 18, name: "PURCHASE_ORDER_SORT_KEY_AMENDMENT_COUNT" },
    { no: 30, name: "PURCHASE_ORDER_SORT_KEY_TOTAL_VALUE" },
]);
/**
 *
 * Describes the purchase order reference context
 *
 * @generated from enum Scailo.PURCHASE_ORDER_REFERENCE_CONTEXT
 */
var PURCHASE_ORDER_REFERENCE_CONTEXT;
(function (PURCHASE_ORDER_REFERENCE_CONTEXT) {
    /**
     * Denotes that context be disregarded. This is used only within search APIs
     *
     * @generated from enum value: PURCHASE_ORDER_REFERENCE_CONTEXT_ANY_UNSPECIFIED = 0;
     */
    PURCHASE_ORDER_REFERENCE_CONTEXT[PURCHASE_ORDER_REFERENCE_CONTEXT["PURCHASE_ORDER_REFERENCE_CONTEXT_ANY_UNSPECIFIED"] = 0] = "PURCHASE_ORDER_REFERENCE_CONTEXT_ANY_UNSPECIFIED";
    /**
     * Denotes buying context
     *
     * @generated from enum value: PURCHASE_ORDER_REFERENCE_CONTEXT_BUYING = 1;
     */
    PURCHASE_ORDER_REFERENCE_CONTEXT[PURCHASE_ORDER_REFERENCE_CONTEXT["PURCHASE_ORDER_REFERENCE_CONTEXT_BUYING"] = 1] = "PURCHASE_ORDER_REFERENCE_CONTEXT_BUYING";
    /**
     * Denotes billing context
     *
     * @generated from enum value: PURCHASE_ORDER_REFERENCE_CONTEXT_BILLING = 2;
     */
    PURCHASE_ORDER_REFERENCE_CONTEXT[PURCHASE_ORDER_REFERENCE_CONTEXT["PURCHASE_ORDER_REFERENCE_CONTEXT_BILLING"] = 2] = "PURCHASE_ORDER_REFERENCE_CONTEXT_BILLING";
})(PURCHASE_ORDER_REFERENCE_CONTEXT || (exports.PURCHASE_ORDER_REFERENCE_CONTEXT = PURCHASE_ORDER_REFERENCE_CONTEXT = {}));
// Retrieve enum metadata with: proto3.getEnumType(PURCHASE_ORDER_REFERENCE_CONTEXT)
protobuf_1.proto3.util.setEnumType(PURCHASE_ORDER_REFERENCE_CONTEXT, "Scailo.PURCHASE_ORDER_REFERENCE_CONTEXT", [
    { no: 0, name: "PURCHASE_ORDER_REFERENCE_CONTEXT_ANY_UNSPECIFIED" },
    { no: 1, name: "PURCHASE_ORDER_REFERENCE_CONTEXT_BUYING" },
    { no: 2, name: "PURCHASE_ORDER_REFERENCE_CONTEXT_BILLING" },
]);
/**
 *
 * Describes the possible options for ref_from field for a purchase order reference
 *
 * @generated from enum Scailo.PURCHASE_ORDER_REFERENCE_REF_FROM
 */
var PURCHASE_ORDER_REFERENCE_REF_FROM;
(function (PURCHASE_ORDER_REFERENCE_REF_FROM) {
    /**
     * Denotes that the ref from be disregarded. This is used only within search APIs
     *
     * @generated from enum value: PURCHASE_ORDER_REFERENCE_REF_FROM_ANY_UNSPECIFIED = 0;
     */
    PURCHASE_ORDER_REFERENCE_REF_FROM[PURCHASE_ORDER_REFERENCE_REF_FROM["PURCHASE_ORDER_REFERENCE_REF_FROM_ANY_UNSPECIFIED"] = 0] = "PURCHASE_ORDER_REFERENCE_REF_FROM_ANY_UNSPECIFIED";
    /**
     * Denotes purchase indent
     *
     * @generated from enum value: PURCHASE_ORDER_REFERENCE_REF_FROM_PURCHASE_INDENT = 1;
     */
    PURCHASE_ORDER_REFERENCE_REF_FROM[PURCHASE_ORDER_REFERENCE_REF_FROM["PURCHASE_ORDER_REFERENCE_REF_FROM_PURCHASE_INDENT"] = 1] = "PURCHASE_ORDER_REFERENCE_REF_FROM_PURCHASE_INDENT";
    /**
     * Denotes outward job
     *
     * @generated from enum value: PURCHASE_ORDER_REFERENCE_REF_FROM_OUTWARD_JOB = 2;
     */
    PURCHASE_ORDER_REFERENCE_REF_FROM[PURCHASE_ORDER_REFERENCE_REF_FROM["PURCHASE_ORDER_REFERENCE_REF_FROM_OUTWARD_JOB"] = 2] = "PURCHASE_ORDER_REFERENCE_REF_FROM_OUTWARD_JOB";
    /**
     * Denotes quotation response
     *
     * @generated from enum value: PURCHASE_ORDER_REFERENCE_REF_FROM_QUOTATION_RESPONSE = 3;
     */
    PURCHASE_ORDER_REFERENCE_REF_FROM[PURCHASE_ORDER_REFERENCE_REF_FROM["PURCHASE_ORDER_REFERENCE_REF_FROM_QUOTATION_RESPONSE"] = 3] = "PURCHASE_ORDER_REFERENCE_REF_FROM_QUOTATION_RESPONSE";
    /**
     * Denotes sales order
     *
     * @generated from enum value: PURCHASE_ORDER_REFERENCE_REF_FROM_SALES_ORDER = 4;
     */
    PURCHASE_ORDER_REFERENCE_REF_FROM[PURCHASE_ORDER_REFERENCE_REF_FROM["PURCHASE_ORDER_REFERENCE_REF_FROM_SALES_ORDER"] = 4] = "PURCHASE_ORDER_REFERENCE_REF_FROM_SALES_ORDER";
    /**
     * Denotes work order
     *
     * @generated from enum value: PURCHASE_ORDER_REFERENCE_REF_FROM_WORK_ORDER = 5;
     */
    PURCHASE_ORDER_REFERENCE_REF_FROM[PURCHASE_ORDER_REFERENCE_REF_FROM["PURCHASE_ORDER_REFERENCE_REF_FROM_WORK_ORDER"] = 5] = "PURCHASE_ORDER_REFERENCE_REF_FROM_WORK_ORDER";
    /**
     * Denotes purchase order
     *
     * @generated from enum value: PURCHASE_ORDER_REFERENCE_REF_FROM_PURCHASE_ORDER = 6;
     */
    PURCHASE_ORDER_REFERENCE_REF_FROM[PURCHASE_ORDER_REFERENCE_REF_FROM["PURCHASE_ORDER_REFERENCE_REF_FROM_PURCHASE_ORDER"] = 6] = "PURCHASE_ORDER_REFERENCE_REF_FROM_PURCHASE_ORDER";
    /**
     * Denotes sales invoice
     *
     * @generated from enum value: PURCHASE_ORDER_REFERENCE_REF_FROM_SALES_INVOICE = 7;
     */
    PURCHASE_ORDER_REFERENCE_REF_FROM[PURCHASE_ORDER_REFERENCE_REF_FROM["PURCHASE_ORDER_REFERENCE_REF_FROM_SALES_INVOICE"] = 7] = "PURCHASE_ORDER_REFERENCE_REF_FROM_SALES_INVOICE";
})(PURCHASE_ORDER_REFERENCE_REF_FROM || (exports.PURCHASE_ORDER_REFERENCE_REF_FROM = PURCHASE_ORDER_REFERENCE_REF_FROM = {}));
// Retrieve enum metadata with: proto3.getEnumType(PURCHASE_ORDER_REFERENCE_REF_FROM)
protobuf_1.proto3.util.setEnumType(PURCHASE_ORDER_REFERENCE_REF_FROM, "Scailo.PURCHASE_ORDER_REFERENCE_REF_FROM", [
    { no: 0, name: "PURCHASE_ORDER_REFERENCE_REF_FROM_ANY_UNSPECIFIED" },
    { no: 1, name: "PURCHASE_ORDER_REFERENCE_REF_FROM_PURCHASE_INDENT" },
    { no: 2, name: "PURCHASE_ORDER_REFERENCE_REF_FROM_OUTWARD_JOB" },
    { no: 3, name: "PURCHASE_ORDER_REFERENCE_REF_FROM_QUOTATION_RESPONSE" },
    { no: 4, name: "PURCHASE_ORDER_REFERENCE_REF_FROM_SALES_ORDER" },
    { no: 5, name: "PURCHASE_ORDER_REFERENCE_REF_FROM_WORK_ORDER" },
    { no: 6, name: "PURCHASE_ORDER_REFERENCE_REF_FROM_PURCHASE_ORDER" },
    { no: 7, name: "PURCHASE_ORDER_REFERENCE_REF_FROM_SALES_INVOICE" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.PURCHASE_ORDER_ITEM_SORT_KEY
 */
var PURCHASE_ORDER_ITEM_SORT_KEY;
(function (PURCHASE_ORDER_ITEM_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: PURCHASE_ORDER_ITEM_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    PURCHASE_ORDER_ITEM_SORT_KEY[PURCHASE_ORDER_ITEM_SORT_KEY["PURCHASE_ORDER_ITEM_SORT_KEY_ID_UNSPECIFIED"] = 0] = "PURCHASE_ORDER_ITEM_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: PURCHASE_ORDER_ITEM_SORT_KEY_CREATED_AT = 1;
     */
    PURCHASE_ORDER_ITEM_SORT_KEY[PURCHASE_ORDER_ITEM_SORT_KEY["PURCHASE_ORDER_ITEM_SORT_KEY_CREATED_AT"] = 1] = "PURCHASE_ORDER_ITEM_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: PURCHASE_ORDER_ITEM_SORT_KEY_MODIFIED_AT = 2;
     */
    PURCHASE_ORDER_ITEM_SORT_KEY[PURCHASE_ORDER_ITEM_SORT_KEY["PURCHASE_ORDER_ITEM_SORT_KEY_MODIFIED_AT"] = 2] = "PURCHASE_ORDER_ITEM_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: PURCHASE_ORDER_ITEM_SORT_KEY_APPROVED_ON = 3;
     */
    PURCHASE_ORDER_ITEM_SORT_KEY[PURCHASE_ORDER_ITEM_SORT_KEY["PURCHASE_ORDER_ITEM_SORT_KEY_APPROVED_ON"] = 3] = "PURCHASE_ORDER_ITEM_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: PURCHASE_ORDER_ITEM_SORT_KEY_APPROVED_BY = 4;
     */
    PURCHASE_ORDER_ITEM_SORT_KEY[PURCHASE_ORDER_ITEM_SORT_KEY["PURCHASE_ORDER_ITEM_SORT_KEY_APPROVED_BY"] = 4] = "PURCHASE_ORDER_ITEM_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: PURCHASE_ORDER_ITEM_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    PURCHASE_ORDER_ITEM_SORT_KEY[PURCHASE_ORDER_ITEM_SORT_KEY["PURCHASE_ORDER_ITEM_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "PURCHASE_ORDER_ITEM_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the family ID
     *
     * @generated from enum value: PURCHASE_ORDER_ITEM_SORT_KEY_FAMILY_ID = 10;
     */
    PURCHASE_ORDER_ITEM_SORT_KEY[PURCHASE_ORDER_ITEM_SORT_KEY["PURCHASE_ORDER_ITEM_SORT_KEY_FAMILY_ID"] = 10] = "PURCHASE_ORDER_ITEM_SORT_KEY_FAMILY_ID";
    /**
     * Fetch ordered results by the internal quantity
     *
     * @generated from enum value: PURCHASE_ORDER_ITEM_SORT_KEY_INTERNAL_QUANTITY = 11;
     */
    PURCHASE_ORDER_ITEM_SORT_KEY[PURCHASE_ORDER_ITEM_SORT_KEY["PURCHASE_ORDER_ITEM_SORT_KEY_INTERNAL_QUANTITY"] = 11] = "PURCHASE_ORDER_ITEM_SORT_KEY_INTERNAL_QUANTITY";
    /**
     * Fetch ordered results by the vendor unit of material ID
     *
     * @generated from enum value: PURCHASE_ORDER_ITEM_SORT_KEY_VENDOR_UOM_ID = 12;
     */
    PURCHASE_ORDER_ITEM_SORT_KEY[PURCHASE_ORDER_ITEM_SORT_KEY["PURCHASE_ORDER_ITEM_SORT_KEY_VENDOR_UOM_ID"] = 12] = "PURCHASE_ORDER_ITEM_SORT_KEY_VENDOR_UOM_ID";
    /**
     * Fetch ordered results by the vendor quantity
     *
     * @generated from enum value: PURCHASE_ORDER_ITEM_SORT_KEY_VENDOR_QUANTITY = 13;
     */
    PURCHASE_ORDER_ITEM_SORT_KEY[PURCHASE_ORDER_ITEM_SORT_KEY["PURCHASE_ORDER_ITEM_SORT_KEY_VENDOR_QUANTITY"] = 13] = "PURCHASE_ORDER_ITEM_SORT_KEY_VENDOR_QUANTITY";
    /**
     * Fetch ordered results by the vendor unit price
     *
     * @generated from enum value: PURCHASE_ORDER_ITEM_SORT_KEY_VENDOR_UNIT_PRICE = 14;
     */
    PURCHASE_ORDER_ITEM_SORT_KEY[PURCHASE_ORDER_ITEM_SORT_KEY["PURCHASE_ORDER_ITEM_SORT_KEY_VENDOR_UNIT_PRICE"] = 14] = "PURCHASE_ORDER_ITEM_SORT_KEY_VENDOR_UNIT_PRICE";
    /**
     * Fetch ordered results by the tax group ID
     *
     * @generated from enum value: PURCHASE_ORDER_ITEM_SORT_KEY_TAX_GROUP_ID = 15;
     */
    PURCHASE_ORDER_ITEM_SORT_KEY[PURCHASE_ORDER_ITEM_SORT_KEY["PURCHASE_ORDER_ITEM_SORT_KEY_TAX_GROUP_ID"] = 15] = "PURCHASE_ORDER_ITEM_SORT_KEY_TAX_GROUP_ID";
    /**
     * Fetch ordered results by the discount
     *
     * @generated from enum value: PURCHASE_ORDER_ITEM_SORT_KEY_DISCOUNT = 16;
     */
    PURCHASE_ORDER_ITEM_SORT_KEY[PURCHASE_ORDER_ITEM_SORT_KEY["PURCHASE_ORDER_ITEM_SORT_KEY_DISCOUNT"] = 16] = "PURCHASE_ORDER_ITEM_SORT_KEY_DISCOUNT";
    /**
     * Fetch ordered results by the delivery date
     *
     * @generated from enum value: PURCHASE_ORDER_ITEM_SORT_KEY_DELIVERY_DATE = 17;
     */
    PURCHASE_ORDER_ITEM_SORT_KEY[PURCHASE_ORDER_ITEM_SORT_KEY["PURCHASE_ORDER_ITEM_SORT_KEY_DELIVERY_DATE"] = 17] = "PURCHASE_ORDER_ITEM_SORT_KEY_DELIVERY_DATE";
})(PURCHASE_ORDER_ITEM_SORT_KEY || (exports.PURCHASE_ORDER_ITEM_SORT_KEY = PURCHASE_ORDER_ITEM_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(PURCHASE_ORDER_ITEM_SORT_KEY)
protobuf_1.proto3.util.setEnumType(PURCHASE_ORDER_ITEM_SORT_KEY, "Scailo.PURCHASE_ORDER_ITEM_SORT_KEY", [
    { no: 0, name: "PURCHASE_ORDER_ITEM_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "PURCHASE_ORDER_ITEM_SORT_KEY_CREATED_AT" },
    { no: 2, name: "PURCHASE_ORDER_ITEM_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "PURCHASE_ORDER_ITEM_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "PURCHASE_ORDER_ITEM_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "PURCHASE_ORDER_ITEM_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 10, name: "PURCHASE_ORDER_ITEM_SORT_KEY_FAMILY_ID" },
    { no: 11, name: "PURCHASE_ORDER_ITEM_SORT_KEY_INTERNAL_QUANTITY" },
    { no: 12, name: "PURCHASE_ORDER_ITEM_SORT_KEY_VENDOR_UOM_ID" },
    { no: 13, name: "PURCHASE_ORDER_ITEM_SORT_KEY_VENDOR_QUANTITY" },
    { no: 14, name: "PURCHASE_ORDER_ITEM_SORT_KEY_VENDOR_UNIT_PRICE" },
    { no: 15, name: "PURCHASE_ORDER_ITEM_SORT_KEY_TAX_GROUP_ID" },
    { no: 16, name: "PURCHASE_ORDER_ITEM_SORT_KEY_DISCOUNT" },
    { no: 17, name: "PURCHASE_ORDER_ITEM_SORT_KEY_DELIVERY_DATE" },
]);
/**
 *
 * Describes the applicable statuses of purchase order items
 *
 * @generated from enum Scailo.PURCHASE_ORDER_ITEM_STATUS
 */
var PURCHASE_ORDER_ITEM_STATUS;
(function (PURCHASE_ORDER_ITEM_STATUS) {
    /**
     * Denotes that status be disregarded. This is used only within search APIs
     *
     * @generated from enum value: PURCHASE_ORDER_ITEM_STATUS_ANY_UNSPECIFIED = 0;
     */
    PURCHASE_ORDER_ITEM_STATUS[PURCHASE_ORDER_ITEM_STATUS["PURCHASE_ORDER_ITEM_STATUS_ANY_UNSPECIFIED"] = 0] = "PURCHASE_ORDER_ITEM_STATUS_ANY_UNSPECIFIED";
    /**
     * Denotes that the purchase order items must have been approved
     *
     * @generated from enum value: PURCHASE_ORDER_ITEM_STATUS_APPROVED = 1;
     */
    PURCHASE_ORDER_ITEM_STATUS[PURCHASE_ORDER_ITEM_STATUS["PURCHASE_ORDER_ITEM_STATUS_APPROVED"] = 1] = "PURCHASE_ORDER_ITEM_STATUS_APPROVED";
    /**
     * Denotes that the purchase order items must be waiting for approval
     *
     * @generated from enum value: PURCHASE_ORDER_ITEM_STATUS_UNAPPROVED = 2;
     */
    PURCHASE_ORDER_ITEM_STATUS[PURCHASE_ORDER_ITEM_STATUS["PURCHASE_ORDER_ITEM_STATUS_UNAPPROVED"] = 2] = "PURCHASE_ORDER_ITEM_STATUS_UNAPPROVED";
})(PURCHASE_ORDER_ITEM_STATUS || (exports.PURCHASE_ORDER_ITEM_STATUS = PURCHASE_ORDER_ITEM_STATUS = {}));
// Retrieve enum metadata with: proto3.getEnumType(PURCHASE_ORDER_ITEM_STATUS)
protobuf_1.proto3.util.setEnumType(PURCHASE_ORDER_ITEM_STATUS, "Scailo.PURCHASE_ORDER_ITEM_STATUS", [
    { no: 0, name: "PURCHASE_ORDER_ITEM_STATUS_ANY_UNSPECIFIED" },
    { no: 1, name: "PURCHASE_ORDER_ITEM_STATUS_APPROVED" },
    { no: 2, name: "PURCHASE_ORDER_ITEM_STATUS_UNAPPROVED" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.PurchasesOrdersServiceCreateRequest
 */
class PurchasesOrdersServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the purchase order
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The location ID of the consignee
     *
     * @generated from field: uint64 consignee_location_id = 12;
     */
    consigneeLocationId = protobuf_1.protoInt64.zero;
    /**
     * The location ID of the buyer
     *
     * @generated from field: uint64 buyer_location_id = 13;
     */
    buyerLocationId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the vendor
     *
     * @generated from field: uint64 vendor_id = 14;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * The associated ID of the currency
     *
     * @generated from field: uint64 currency_id = 15;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The optional associated ID of the project
     *
     * @generated from field: uint64 project_id = 16;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * Any miscellaneous cost
     *
     * @generated from field: uint64 miscellaneous_cost = 17;
     */
    miscellaneousCost = protobuf_1.protoInt64.zero;
    /**
     * The optional discount amount
     *
     * @generated from field: uint64 overall_discount = 18;
     */
    overallDiscount = protobuf_1.protoInt64.zero;
    /**
     * The applicable round off amount (optional, and can be positive or negative)
     *
     * @generated from field: int64 round_off = 19;
     */
    roundOff = protobuf_1.protoInt64.zero;
    /**
     * The amount paid in advance
     *
     * @generated from field: uint64 payment_advance = 20;
     */
    paymentAdvance = protobuf_1.protoInt64.zero;
    /**
     * The payment cycle in days
     *
     * @generated from field: uint64 payment_cycle_in_days = 21;
     */
    paymentCycleInDays = protobuf_1.protoInt64.zero;
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesOrdersServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "consignee_location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "buyer_location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "miscellaneous_cost", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "overall_discount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 19, name: "round_off", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 20, name: "payment_advance", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "payment_cycle_in_days", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesOrdersServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesOrdersServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesOrdersServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesOrdersServiceCreateRequest, a, b);
    }
}
exports.PurchasesOrdersServiceCreateRequest = PurchasesOrdersServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.PurchasesOrdersServiceUpdateRequest
 */
class PurchasesOrdersServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the purchase order
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The location ID of the consignee
     *
     * @generated from field: uint64 consignee_location_id = 12;
     */
    consigneeLocationId = protobuf_1.protoInt64.zero;
    /**
     * The location ID of the buyer
     *
     * @generated from field: uint64 buyer_location_id = 13;
     */
    buyerLocationId = protobuf_1.protoInt64.zero;
    /**
     * The associated ID of the currency
     *
     * @generated from field: uint64 currency_id = 15;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The optional associated ID of the project
     *
     * @generated from field: uint64 project_id = 16;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * Any miscellaneous cost
     *
     * @generated from field: uint64 miscellaneous_cost = 17;
     */
    miscellaneousCost = protobuf_1.protoInt64.zero;
    /**
     * The optional discount amount
     *
     * @generated from field: uint64 overall_discount = 18;
     */
    overallDiscount = protobuf_1.protoInt64.zero;
    /**
     * The applicable round off amount (optional, and can be positive or negative)
     *
     * @generated from field: int64 round_off = 19;
     */
    roundOff = protobuf_1.protoInt64.zero;
    /**
     * The amount paid in advance
     *
     * @generated from field: uint64 payment_advance = 20;
     */
    paymentAdvance = protobuf_1.protoInt64.zero;
    /**
     * The payment cycle in days
     *
     * @generated from field: uint64 payment_cycle_in_days = 21;
     */
    paymentCycleInDays = protobuf_1.protoInt64.zero;
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesOrdersServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "consignee_location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "buyer_location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "miscellaneous_cost", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "overall_discount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 19, name: "round_off", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 20, name: "payment_advance", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "payment_cycle_in_days", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesOrdersServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesOrdersServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesOrdersServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesOrdersServiceUpdateRequest, a, b);
    }
}
exports.PurchasesOrdersServiceUpdateRequest = PurchasesOrdersServiceUpdateRequest;
/**
 *
 * Describes the parameters necessary to perform an autofill request
 *
 * @generated from message Scailo.PurchasesOrdersServiceAutofillRequest
 */
class PurchasesOrdersServiceAutofillRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The UUID of the record that needs to be updated
     *
     * @generated from field: string uuid = 2;
     */
    uuid = "";
    /**
     * Stores if referenced items should be consolidated
     *
     * @generated from field: bool consolidate_referenced_items = 10;
     */
    consolidateReferencedItems = false;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesOrdersServiceAutofillRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "consolidate_referenced_items", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesOrdersServiceAutofillRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesOrdersServiceAutofillRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesOrdersServiceAutofillRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesOrdersServiceAutofillRequest, a, b);
    }
}
exports.PurchasesOrdersServiceAutofillRequest = PurchasesOrdersServiceAutofillRequest;
/**
 *
 * Stores the UUID references of the record
 *
 * @generated from message Scailo.PurchaseOrderAncillaryParameters
 */
class PurchaseOrderAncillaryParameters extends protobuf_1.Message {
    /**
     * The UUID of the consignee location (the UUID of the associated consignee_location_id)
     *
     * @generated from field: string consignee_location_uuid = 212;
     */
    consigneeLocationUuid = "";
    /**
     * The UUID of the buyer location (the UUID of the associated buyer_location_id)
     *
     * @generated from field: string buyer_location_uuid = 213;
     */
    buyerLocationUuid = "";
    /**
     * The UUID of the vendor (the UUID of the associated vendor_id)
     *
     * @generated from field: string vendor_uuid = 214;
     */
    vendorUuid = "";
    /**
     * The UUID of the currency (the UUID of the associated currency)
     *
     * @generated from field: string currency_uuid = 215;
     */
    currencyUuid = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchaseOrderAncillaryParameters";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 212, name: "consignee_location_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 213, name: "buyer_location_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 214, name: "vendor_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 215, name: "currency_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchaseOrderAncillaryParameters().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchaseOrderAncillaryParameters().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchaseOrderAncillaryParameters().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchaseOrderAncillaryParameters, a, b);
    }
}
exports.PurchaseOrderAncillaryParameters = PurchaseOrderAncillaryParameters;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.PurchaseOrder
 */
class PurchaseOrder extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this purchase order
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this purchase order
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this purchase order
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this purchase order was marked as completed
     *
     * @generated from field: uint64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the purchase order
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 11;
     */
    finalRefNumber = "";
    /**
     * The location ID of the consignee
     *
     * @generated from field: uint64 consignee_location_id = 12;
     */
    consigneeLocationId = protobuf_1.protoInt64.zero;
    /**
     * The location ID of the buyer
     *
     * @generated from field: uint64 buyer_location_id = 13;
     */
    buyerLocationId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the vendor
     *
     * @generated from field: uint64 vendor_id = 14;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * The associated ID of the currency
     *
     * @generated from field: uint64 currency_id = 15;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The optional associated ID of the project
     *
     * @generated from field: uint64 project_id = 16;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * Any miscellaneous cost
     *
     * @generated from field: uint64 miscellaneous_cost = 17;
     */
    miscellaneousCost = protobuf_1.protoInt64.zero;
    /**
     * The optional discount amount
     *
     * @generated from field: uint64 overall_discount = 18;
     */
    overallDiscount = protobuf_1.protoInt64.zero;
    /**
     * The applicable round off amount (optional, and can be positive or negative)
     *
     * @generated from field: int64 round_off = 19;
     */
    roundOff = protobuf_1.protoInt64.zero;
    /**
     * The amount paid in advance
     *
     * @generated from field: uint64 payment_advance = 20;
     */
    paymentAdvance = protobuf_1.protoInt64.zero;
    /**
     * The payment cycle in days
     *
     * @generated from field: uint64 payment_cycle_in_days = 21;
     */
    paymentCycleInDays = protobuf_1.protoInt64.zero;
    /**
     * The number of times that the purchase order has been amended
     *
     * @generated from field: uint64 amendment_count = 22;
     */
    amendmentCount = protobuf_1.protoInt64.zero;
    /**
     * Stores the total value of the purchase order (as a double, which requires no adjustments)
     *
     * @generated from field: double total_value = 30;
     */
    totalValue = 0;
    /**
     * The list of associated purchase order items
     *
     * @generated from field: repeated Scailo.PurchaseOrderItem list = 40;
     */
    list = [];
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatum form_data = 50;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchaseOrder";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "consignee_location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "buyer_location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "miscellaneous_cost", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "overall_discount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 19, name: "round_off", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 20, name: "payment_advance", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "payment_cycle_in_days", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 22, name: "amendment_count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "total_value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
        { no: 40, name: "list", kind: "message", T: PurchaseOrderItem, repeated: true },
        { no: 50, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatum, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new PurchaseOrder().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchaseOrder().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchaseOrder().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchaseOrder, a, b);
    }
}
exports.PurchaseOrder = PurchaseOrder;
/**
 *
 * Describes the parameters required to add an item to a purchase order
 *
 * @generated from message Scailo.PurchasesOrdersServiceItemCreateRequest
 */
class PurchasesOrdersServiceItemCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the purchase order ID
     *
     * @generated from field: uint64 purchase_order_id = 10;
     */
    purchaseOrderId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The quantity (in cents) being ordered in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 12;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the vendor's unit of material
     *
     * @generated from field: uint64 vendor_uom_id = 13;
     */
    vendorUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being ordered in vendor's unit of material
     *
     * @generated from field: uint64 vendor_quantity = 14;
     */
    vendorQuantity = protobuf_1.protoInt64.zero;
    /**
     * The unit price of the item (as supplied by the vendor)
     *
     * @generated from field: uint64 vendor_unit_price = 15;
     */
    vendorUnitPrice = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated tax group
     *
     * @generated from field: uint64 tax_group_id = 16;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The applicable discount percentage (in cents)
     *
     * @generated from field: uint64 discount = 17;
     */
    discount = protobuf_1.protoInt64.zero;
    /**
     * The delivery date of the item
     *
     * @generated from field: string delivery_date = 18;
     */
    deliveryDate = "";
    /**
     * Optional specifications
     *
     * @generated from field: string specifications = 19;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesOrdersServiceItemCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "purchase_order_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "vendor_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "vendor_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "vendor_unit_price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "discount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "delivery_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 19, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesOrdersServiceItemCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesOrdersServiceItemCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesOrdersServiceItemCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesOrdersServiceItemCreateRequest, a, b);
    }
}
exports.PurchasesOrdersServiceItemCreateRequest = PurchasesOrdersServiceItemCreateRequest;
/**
 *
 * Describes the parameters required to update an item in a purchase order
 *
 * @generated from message Scailo.PurchasesOrdersServiceItemUpdateRequest
 */
class PurchasesOrdersServiceItemUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * The quantity (in cents) being ordered in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 12;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the vendor's unit of material
     *
     * @generated from field: uint64 vendor_uom_id = 13;
     */
    vendorUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being ordered in vendor's unit of material
     *
     * @generated from field: uint64 vendor_quantity = 14;
     */
    vendorQuantity = protobuf_1.protoInt64.zero;
    /**
     * The unit price of the item (as supplied by the vendor)
     *
     * @generated from field: uint64 vendor_unit_price = 15;
     */
    vendorUnitPrice = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated tax group
     *
     * @generated from field: uint64 tax_group_id = 16;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The applicable discount percentage (in cents)
     *
     * @generated from field: uint64 discount = 17;
     */
    discount = protobuf_1.protoInt64.zero;
    /**
     * The delivery date of the item
     *
     * @generated from field: string delivery_date = 18;
     */
    deliveryDate = "";
    /**
     * Optional specifications
     *
     * @generated from field: string specifications = 19;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesOrdersServiceItemUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "vendor_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "vendor_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "vendor_unit_price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "discount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "delivery_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 19, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesOrdersServiceItemUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesOrdersServiceItemUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesOrdersServiceItemUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesOrdersServiceItemUpdateRequest, a, b);
    }
}
exports.PurchasesOrdersServiceItemUpdateRequest = PurchasesOrdersServiceItemUpdateRequest;
/**
 *
 * Describes the parameters that constitute an item associated to a purchase order
 *
 * @generated from message Scailo.PurchaseOrderItem
 */
class PurchaseOrderItem extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this purchase order
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the purchase order ID
     *
     * @generated from field: uint64 purchase_order_id = 10;
     */
    purchaseOrderId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The quantity (in cents) being ordered in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 12;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the vendor's unit of material
     *
     * @generated from field: uint64 vendor_uom_id = 13;
     */
    vendorUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being ordered in vendor's unit of material
     *
     * @generated from field: uint64 vendor_quantity = 14;
     */
    vendorQuantity = protobuf_1.protoInt64.zero;
    /**
     * The unit price of the item (as supplied by the vendor)
     *
     * @generated from field: uint64 vendor_unit_price = 15;
     */
    vendorUnitPrice = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated tax group
     *
     * @generated from field: uint64 tax_group_id = 16;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The applicable discount percentage (in cents)
     *
     * @generated from field: uint64 discount = 17;
     */
    discount = protobuf_1.protoInt64.zero;
    /**
     * The delivery date of the item
     *
     * @generated from field: string delivery_date = 18;
     */
    deliveryDate = "";
    /**
     * Optional specifications
     *
     * @generated from field: string specifications = 19;
     */
    specifications = "";
    /**
     * Stores the vendor unit price after factoring in the discount
     *
     * @generated from field: uint64 discounted_vendor_unit_price = 30;
     */
    discountedVendorUnitPrice = protobuf_1.protoInt64.zero;
    /**
     * ----------------------------------------
     * The UUID of the associated purchase order
     *
     * @generated from field: string purchase_order_uuid = 110;
     */
    purchaseOrderUuid = "";
    /**
     * The UUID of the associated family
     *
     * ----------------------------------------
     *
     * @generated from field: string family_uuid = 111;
     */
    familyUuid = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchaseOrderItem";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "purchase_order_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "vendor_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "vendor_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "vendor_unit_price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "discount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "delivery_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 19, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "discounted_vendor_unit_price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 110, name: "purchase_order_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 111, name: "family_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchaseOrderItem().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchaseOrderItem().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchaseOrderItem().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchaseOrderItem, a, b);
    }
}
exports.PurchaseOrderItem = PurchaseOrderItem;
/**
 *
 * Describes the message consisting of the list of purchase orders
 *
 * @generated from message Scailo.PurchasesOrdersList
 */
class PurchasesOrdersList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.PurchaseOrder list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesOrdersList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: PurchaseOrder, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesOrdersList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesOrdersList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesOrdersList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesOrdersList, a, b);
    }
}
exports.PurchasesOrdersList = PurchasesOrdersList;
/**
 *
 * Describes the message consisting of the list of purchase order items
 *
 * @generated from message Scailo.PurchaseOrderItemsList
 */
class PurchaseOrderItemsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.PurchaseOrderItem list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchaseOrderItemsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: PurchaseOrderItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new PurchaseOrderItemsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchaseOrderItemsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchaseOrderItemsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchaseOrderItemsList, a, b);
    }
}
exports.PurchaseOrderItemsList = PurchaseOrderItemsList;
/**
 *
 * Describes the parameters that are required to retrieve the history of the record
 *
 * @generated from message Scailo.PurchaseOrderItemHistoryRequest
 */
class PurchaseOrderItemHistoryRequest extends protobuf_1.Message {
    /**
     * Stores the purchase order ID
     *
     * @generated from field: uint64 purchase_order_id = 10;
     */
    purchaseOrderId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchaseOrderItemHistoryRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "purchase_order_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchaseOrderItemHistoryRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchaseOrderItemHistoryRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchaseOrderItemHistoryRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchaseOrderItemHistoryRequest, a, b);
    }
}
exports.PurchaseOrderItemHistoryRequest = PurchaseOrderItemHistoryRequest;
/**
 *
 * Describes the parameters that are required to retrieve the info of a prospective purchase order item
 *
 * @generated from message Scailo.PurchaseOrderItemProspectiveInfoRequest
 */
class PurchaseOrderItemProspectiveInfoRequest extends protobuf_1.Message {
    /**
     * Stores the purchase order ID
     *
     * @generated from field: uint64 purchase_order_id = 10;
     */
    purchaseOrderId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the vendor's unit of material (if 0, the value is ignored while retrieving records)
     *
     * @generated from field: uint64 vendor_uom_id = 13;
     */
    vendorUomId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchaseOrderItemProspectiveInfoRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "purchase_order_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "vendor_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchaseOrderItemProspectiveInfoRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchaseOrderItemProspectiveInfoRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchaseOrderItemProspectiveInfoRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchaseOrderItemProspectiveInfoRequest, a, b);
    }
}
exports.PurchaseOrderItemProspectiveInfoRequest = PurchaseOrderItemProspectiveInfoRequest;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.PurchasesOrdersServicePaginationReq
 */
class PurchasesOrdersServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.PURCHASE_ORDER_SORT_KEY sort_key = 5;
     */
    sortKey = PURCHASE_ORDER_SORT_KEY.PURCHASE_ORDER_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this purchase order
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesOrdersServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(PURCHASE_ORDER_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesOrdersServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesOrdersServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesOrdersServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesOrdersServicePaginationReq, a, b);
    }
}
exports.PurchasesOrdersServicePaginationReq = PurchasesOrdersServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.PurchasesOrdersServicePaginationResponse
 */
class PurchasesOrdersServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.PurchaseOrder payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesOrdersServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: PurchaseOrder, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesOrdersServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesOrdersServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesOrdersServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesOrdersServicePaginationResponse, a, b);
    }
}
exports.PurchasesOrdersServicePaginationResponse = PurchasesOrdersServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.PurchasesOrdersServiceFilterReq
 */
class PurchasesOrdersServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.PURCHASE_ORDER_SORT_KEY sort_key = 5;
     */
    sortKey = PURCHASE_ORDER_SORT_KEY.PURCHASE_ORDER_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this purchase order
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the purchase order
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The associated consignee location ID
     *
     * @generated from field: uint64 consignee_location_id = 22;
     */
    consigneeLocationId = protobuf_1.protoInt64.zero;
    /**
     * The associated buyer location ID
     *
     * @generated from field: uint64 buyer_location_id = 23;
     */
    buyerLocationId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated vendor
     *
     * @generated from field: uint64 vendor_id = 24;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated currency
     *
     * @generated from field: uint64 currency_id = 25;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated project
     *
     * @generated from field: uint64 project_id = 26;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 40;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The exact delivery date of the item in the purchase order
     *
     * @generated from field: string delivery_date_exact = 41;
     */
    deliveryDateExact = "";
    /**
     * The start delivery date of the item in the purchase order
     *
     * @generated from field: string delivery_date_start = 42;
     */
    deliveryDateStart = "";
    /**
     * The end delivery date of the item in the purchase order
     *
     * @generated from field: string delivery_date_end = 43;
     */
    deliveryDateEnd = "";
    /**
     * Stores the minimum value of the purchase order (ignored if 0)
     *
     * @generated from field: uint64 total_value_min = 50;
     */
    totalValueMin = protobuf_1.protoInt64.zero;
    /**
     * Stores the maximum value of the purchase order (ignored if 0)
     *
     * @generated from field: uint64 total_value_max = 51;
     */
    totalValueMax = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesOrdersServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(PURCHASE_ORDER_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "consignee_location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "buyer_location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 26, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 41, name: "delivery_date_exact", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 42, name: "delivery_date_start", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 43, name: "delivery_date_end", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 50, name: "total_value_min", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 51, name: "total_value_max", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesOrdersServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesOrdersServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesOrdersServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesOrdersServiceFilterReq, a, b);
    }
}
exports.PurchasesOrdersServiceFilterReq = PurchasesOrdersServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.PurchasesOrdersServiceCountReq
 */
class PurchasesOrdersServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this purchase order
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the purchase order
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The associated consignee location ID
     *
     * @generated from field: uint64 consignee_location_id = 22;
     */
    consigneeLocationId = protobuf_1.protoInt64.zero;
    /**
     * The associated buyer location ID
     *
     * @generated from field: uint64 buyer_location_id = 23;
     */
    buyerLocationId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated vendor
     *
     * @generated from field: uint64 vendor_id = 24;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated currency
     *
     * @generated from field: uint64 currency_id = 25;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated project
     *
     * @generated from field: uint64 project_id = 26;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 40;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The exact delivery date of the item in the purchase order
     *
     * @generated from field: string delivery_date_exact = 41;
     */
    deliveryDateExact = "";
    /**
     * The start delivery date of the item in the purchase order
     *
     * @generated from field: string delivery_date_start = 42;
     */
    deliveryDateStart = "";
    /**
     * The end delivery date of the item in the purchase order
     *
     * @generated from field: string delivery_date_end = 43;
     */
    deliveryDateEnd = "";
    /**
     * Stores the minimum value of the purchase order (ignored if 0)
     *
     * @generated from field: uint64 total_value_min = 50;
     */
    totalValueMin = protobuf_1.protoInt64.zero;
    /**
     * Stores the maximum value of the purchase order (ignored if 0)
     *
     * @generated from field: uint64 total_value_max = 51;
     */
    totalValueMax = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesOrdersServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "consignee_location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "buyer_location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 26, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 41, name: "delivery_date_exact", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 42, name: "delivery_date_start", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 43, name: "delivery_date_end", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 50, name: "total_value_min", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 51, name: "total_value_max", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesOrdersServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesOrdersServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesOrdersServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesOrdersServiceCountReq, a, b);
    }
}
exports.PurchasesOrdersServiceCountReq = PurchasesOrdersServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.PurchasesOrdersServiceSearchAllReq
 */
class PurchasesOrdersServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.PURCHASE_ORDER_SORT_KEY sort_key = 5;
     */
    sortKey = PURCHASE_ORDER_SORT_KEY.PURCHASE_ORDER_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    /**
     * The associated consignee location ID
     *
     * @generated from field: uint64 consignee_location_id = 22;
     */
    consigneeLocationId = protobuf_1.protoInt64.zero;
    /**
     * The associated buyer location ID
     *
     * @generated from field: uint64 buyer_location_id = 23;
     */
    buyerLocationId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated vendor
     *
     * @generated from field: uint64 vendor_id = 24;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated currency
     *
     * @generated from field: uint64 currency_id = 25;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated project
     *
     * @generated from field: uint64 project_id = 26;
     */
    projectId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesOrdersServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(PURCHASE_ORDER_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "consignee_location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "buyer_location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 26, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesOrdersServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesOrdersServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesOrdersServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesOrdersServiceSearchAllReq, a, b);
    }
}
exports.PurchasesOrdersServiceSearchAllReq = PurchasesOrdersServiceSearchAllReq;
/**
 *
 * Describes the parameters necessary to create a purchase order reference
 *
 * @generated from message Scailo.PurchasesOrdersServiceReferenceCreateRequest
 */
class PurchasesOrdersServiceReferenceCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the purchase order ID
     *
     * @generated from field: uint64 purchase_order_id = 10;
     */
    purchaseOrderId = protobuf_1.protoInt64.zero;
    /**
     * The reference context, could be billing or buying
     *
     * @generated from field: Scailo.PURCHASE_ORDER_REFERENCE_CONTEXT context = 11;
     */
    context = PURCHASE_ORDER_REFERENCE_CONTEXT.PURCHASE_ORDER_REFERENCE_CONTEXT_ANY_UNSPECIFIED;
    /**
     * The associated ref from
     *
     * @generated from field: Scailo.PURCHASE_ORDER_REFERENCE_REF_FROM ref_from = 12;
     */
    refFrom = PURCHASE_ORDER_REFERENCE_REF_FROM.PURCHASE_ORDER_REFERENCE_REF_FROM_ANY_UNSPECIFIED;
    /**
     * The ID of the associated reference
     *
     * @generated from field: uint64 ref_id = 13;
     */
    refId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesOrdersServiceReferenceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "purchase_order_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "context", kind: "enum", T: protobuf_1.proto3.getEnumType(PURCHASE_ORDER_REFERENCE_CONTEXT) },
        { no: 12, name: "ref_from", kind: "enum", T: protobuf_1.proto3.getEnumType(PURCHASE_ORDER_REFERENCE_REF_FROM) },
        { no: 13, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesOrdersServiceReferenceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesOrdersServiceReferenceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesOrdersServiceReferenceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesOrdersServiceReferenceCreateRequest, a, b);
    }
}
exports.PurchasesOrdersServiceReferenceCreateRequest = PurchasesOrdersServiceReferenceCreateRequest;
/**
 *
 * Describes the parameters that constitute a purchase order reference
 *
 * @generated from message Scailo.PurchaseOrderReference
 */
class PurchaseOrderReference extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this purchase order
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the purchase order ID
     *
     * @generated from field: uint64 purchase_order_id = 10;
     */
    purchaseOrderId = protobuf_1.protoInt64.zero;
    /**
     * The reference context, could be billing or buying
     *
     * @generated from field: Scailo.PURCHASE_ORDER_REFERENCE_CONTEXT context = 11;
     */
    context = PURCHASE_ORDER_REFERENCE_CONTEXT.PURCHASE_ORDER_REFERENCE_CONTEXT_ANY_UNSPECIFIED;
    /**
     * The associated ref from
     *
     * @generated from field: Scailo.PURCHASE_ORDER_REFERENCE_REF_FROM ref_from = 12;
     */
    refFrom = PURCHASE_ORDER_REFERENCE_REF_FROM.PURCHASE_ORDER_REFERENCE_REF_FROM_ANY_UNSPECIFIED;
    /**
     * The ID of the associated reference
     *
     * @generated from field: uint64 ref_id = 13;
     */
    refId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchaseOrderReference";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "purchase_order_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "context", kind: "enum", T: protobuf_1.proto3.getEnumType(PURCHASE_ORDER_REFERENCE_CONTEXT) },
        { no: 12, name: "ref_from", kind: "enum", T: protobuf_1.proto3.getEnumType(PURCHASE_ORDER_REFERENCE_REF_FROM) },
        { no: 13, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchaseOrderReference().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchaseOrderReference().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchaseOrderReference().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchaseOrderReference, a, b);
    }
}
exports.PurchaseOrderReference = PurchaseOrderReference;
/**
 *
 * Describes the message consisting of the list of purchase order references
 *
 * @generated from message Scailo.PurchaseOrderReferencesList
 */
class PurchaseOrderReferencesList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.PurchaseOrderReference list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchaseOrderReferencesList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: PurchaseOrderReference, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new PurchaseOrderReferencesList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchaseOrderReferencesList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchaseOrderReferencesList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchaseOrderReferencesList, a, b);
    }
}
exports.PurchaseOrderReferencesList = PurchaseOrderReferencesList;
/**
 *
 * Describes the request payload to retrieve approved or unapproved items.
 *
 * @generated from message Scailo.PurchaseOrderItemsSearchRequest
 */
class PurchaseOrderItemsSearchRequest extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.PURCHASE_ORDER_ITEM_SORT_KEY sort_key = 5;
     */
    sortKey = PURCHASE_ORDER_ITEM_SORT_KEY.PURCHASE_ORDER_ITEM_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * The status of the items
     *
     * @generated from field: Scailo.PURCHASE_ORDER_ITEM_STATUS status = 7;
     */
    status = PURCHASE_ORDER_ITEM_STATUS.PURCHASE_ORDER_ITEM_STATUS_ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 10;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 11;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 12;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 13;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the purchase order
     *
     * @generated from field: uint64 purchase_order_id = 20;
     */
    purchaseOrderId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 21;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the vendor's unit of material
     *
     * @generated from field: uint64 vendor_uom_id = 22;
     */
    vendorUomId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the tax group
     *
     * @generated from field: uint64 tax_group_id = 23;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The exact delivery date of the item in the purchase order
     *
     * @generated from field: string delivery_date_exact = 28;
     */
    deliveryDateExact = "";
    /**
     * The start delivery date of the item in the purchase order
     *
     * @generated from field: string delivery_date_start = 29;
     */
    deliveryDateStart = "";
    /**
     * The end delivery date of the item in the purchase order
     *
     * @generated from field: string delivery_date_end = 30;
     */
    deliveryDateEnd = "";
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 40;
     */
    searchKey = "";
    /**
     * ------------------------------------------
     * Extra filters
     * The ID of the associated vendor
     *
     * ------------------------------------------
     *
     * @generated from field: uint64 vendor_id = 200;
     */
    vendorId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchaseOrderItemsSearchRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(PURCHASE_ORDER_ITEM_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 7, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(PURCHASE_ORDER_ITEM_STATUS) },
        { no: 10, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "purchase_order_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 22, name: "vendor_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 28, name: "delivery_date_exact", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 29, name: "delivery_date_start", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "delivery_date_end", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 40, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 200, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchaseOrderItemsSearchRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchaseOrderItemsSearchRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchaseOrderItemsSearchRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchaseOrderItemsSearchRequest, a, b);
    }
}
exports.PurchaseOrderItemsSearchRequest = PurchaseOrderItemsSearchRequest;
/**
 *
 * Describes the response to a pagination items request
 *
 * @generated from message Scailo.PurchasesOrdersServicePaginatedItemsResponse
 */
class PurchasesOrdersServicePaginatedItemsResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.PurchaseOrderItem payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesOrdersServicePaginatedItemsResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: PurchaseOrderItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesOrdersServicePaginatedItemsResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesOrdersServicePaginatedItemsResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesOrdersServicePaginatedItemsResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesOrdersServicePaginatedItemsResponse, a, b);
    }
}
exports.PurchasesOrdersServicePaginatedItemsResponse = PurchasesOrdersServicePaginatedItemsResponse;
/**
 *
 * Describes the parameters necessary to create a purchase order contact
 *
 * @generated from message Scailo.PurchasesOrdersServiceContactCreateRequest
 */
class PurchasesOrdersServiceContactCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the purchase order ID
     *
     * @generated from field: uint64 purchase_order_id = 10;
     */
    purchaseOrderId = protobuf_1.protoInt64.zero;
    /**
     * Stores the associate ID
     *
     * @generated from field: uint64 associate_id = 11;
     */
    associateId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesOrdersServiceContactCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "purchase_order_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "associate_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesOrdersServiceContactCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesOrdersServiceContactCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesOrdersServiceContactCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesOrdersServiceContactCreateRequest, a, b);
    }
}
exports.PurchasesOrdersServiceContactCreateRequest = PurchasesOrdersServiceContactCreateRequest;
/**
 *
 * Describes the parameters that constitute a purchase order contact
 *
 * @generated from message Scailo.PurchaseOrderContact
 */
class PurchaseOrderContact extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this purchase order
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the purchase order ID
     *
     * @generated from field: uint64 purchase_order_id = 10;
     */
    purchaseOrderId = protobuf_1.protoInt64.zero;
    /**
     * Stores the associate ID
     *
     * @generated from field: uint64 associate_id = 11;
     */
    associateId = protobuf_1.protoInt64.zero;
    /**
     * Stores the UUID of the associate
     *
     * @generated from field: string associate_uuid = 211;
     */
    associateUuid = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchaseOrderContact";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "purchase_order_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "associate_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 211, name: "associate_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchaseOrderContact().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchaseOrderContact().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchaseOrderContact().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchaseOrderContact, a, b);
    }
}
exports.PurchaseOrderContact = PurchaseOrderContact;
/**
 *
 * Describes the message consisting of the list of purchase order contacts
 *
 * @generated from message Scailo.PurchaseOrderContactsList
 */
class PurchaseOrderContactsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.PurchaseOrderContact list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchaseOrderContactsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: PurchaseOrderContact, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new PurchaseOrderContactsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchaseOrderContactsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchaseOrderContactsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchaseOrderContactsList, a, b);
    }
}
exports.PurchaseOrderContactsList = PurchaseOrderContactsList;
/**
 *
 * Describes the parameters that are part of a purchase order's inventory statistics payload
 *
 * @generated from message Scailo.PurchaseOrderInventoryStatistics
 */
class PurchaseOrderInventoryStatistics extends protobuf_1.Message {
    /**
     * Stores the cumulative quantity of the ordered inventory
     *
     * @generated from field: uint64 ordered = 1;
     */
    ordered = protobuf_1.protoInt64.zero;
    /**
     * Stores the cumulative quantity of the received inventory
     *
     * @generated from field: uint64 received = 2;
     */
    received = protobuf_1.protoInt64.zero;
    /**
     * Stores the cumulative quantity of the returned inventory
     *
     * @generated from field: uint64 returned = 3;
     */
    returned = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchaseOrderInventoryStatistics";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "ordered", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "received", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "returned", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchaseOrderInventoryStatistics().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchaseOrderInventoryStatistics().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchaseOrderInventoryStatistics().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchaseOrderInventoryStatistics, a, b);
    }
}
exports.PurchaseOrderInventoryStatistics = PurchaseOrderInventoryStatistics;
/**
 *
 * Describes the parameters that are part of a purchase order's billing statistics payload
 *
 * @generated from message Scailo.PurchaseOrderBillingStatistics
 */
class PurchaseOrderBillingStatistics extends protobuf_1.Message {
    /**
     * Stores the cumulative quantity of the ordered inventory
     *
     * @generated from field: uint64 ordered = 1;
     */
    ordered = protobuf_1.protoInt64.zero;
    /**
     * Stores the cumulative quantity of the billed inventory
     *
     * @generated from field: uint64 billed = 2;
     */
    billed = protobuf_1.protoInt64.zero;
    /**
     * Stores the cumulative quantity of the debited inventory
     *
     * @generated from field: uint64 debited = 3;
     */
    debited = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchaseOrderBillingStatistics";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "ordered", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "billed", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "debited", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchaseOrderBillingStatistics().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchaseOrderBillingStatistics().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchaseOrderBillingStatistics().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchaseOrderBillingStatistics, a, b);
    }
}
exports.PurchaseOrderBillingStatistics = PurchaseOrderBillingStatistics;
/**
 *
 * Describes the parameters that are part of a purchase order's inventory match
 *
 * @generated from message Scailo.PurchaseOrderInventoryMatch
 */
class PurchaseOrderInventoryMatch extends protobuf_1.Message {
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 1;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Stores the vendor unit of material ID
     *
     * @generated from field: uint64 vendor_uom_id = 2;
     */
    vendorUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the ordered quantity in primary unit of material
     *
     * @generated from field: uint64 ordered_primary = 10;
     */
    orderedPrimary = protobuf_1.protoInt64.zero;
    /**
     * Stores the ordered quantity in secondary unit of material
     *
     * @generated from field: uint64 ordered_secondary = 20;
     */
    orderedSecondary = protobuf_1.protoInt64.zero;
    /**
     * Stores the received quantity in primary unit of material
     *
     * @generated from field: uint64 received_primary = 11;
     */
    receivedPrimary = protobuf_1.protoInt64.zero;
    /**
     * Stores the received quantity in secondary unit of material
     *
     * @generated from field: uint64 received_secondary = 21;
     */
    receivedSecondary = protobuf_1.protoInt64.zero;
    /**
     * Stores the invoiced quantity in primary unit of material
     *
     * @generated from field: uint64 invoiced_primary = 12;
     */
    invoicedPrimary = protobuf_1.protoInt64.zero;
    /**
     * Stores the invoiced quantity in secondary unit of material
     *
     * @generated from field: uint64 invoiced_secondary = 22;
     */
    invoicedSecondary = protobuf_1.protoInt64.zero;
    /**
     * Stores the returned quantity in primary unit of material
     *
     * @generated from field: uint64 returned_primary = 13;
     */
    returnedPrimary = protobuf_1.protoInt64.zero;
    /**
     * Stores the returned quantity in secondary unit of material
     *
     * @generated from field: uint64 returned_secondary = 23;
     */
    returnedSecondary = protobuf_1.protoInt64.zero;
    /**
     * Stores the debited quantity in primary unit of material
     *
     * @generated from field: uint64 debited_primary = 14;
     */
    debitedPrimary = protobuf_1.protoInt64.zero;
    /**
     * Stores the debited quantity in secondary unit of material
     *
     * @generated from field: uint64 debited_secondary = 24;
     */
    debitedSecondary = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchaseOrderInventoryMatch";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "vendor_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "ordered_primary", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "ordered_secondary", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "received_primary", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "received_secondary", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "invoiced_primary", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 22, name: "invoiced_secondary", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "returned_primary", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "returned_secondary", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "debited_primary", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "debited_secondary", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchaseOrderInventoryMatch().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchaseOrderInventoryMatch().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchaseOrderInventoryMatch().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchaseOrderInventoryMatch, a, b);
    }
}
exports.PurchaseOrderInventoryMatch = PurchaseOrderInventoryMatch;
/**
 *
 * Describes the list of inventory match families
 *
 * @generated from message Scailo.PurchaseOrderInventoryMatchList
 */
class PurchaseOrderInventoryMatchList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.PurchaseOrderInventoryMatch list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchaseOrderInventoryMatchList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: PurchaseOrderInventoryMatch, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new PurchaseOrderInventoryMatchList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchaseOrderInventoryMatchList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchaseOrderInventoryMatchList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchaseOrderInventoryMatchList, a, b);
    }
}
exports.PurchaseOrderInventoryMatchList = PurchaseOrderInventoryMatchList;
