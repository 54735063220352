/** Form validation library */

import { editorJSIdentifierClass, showFailureAlert } from "./utilities";

/** Validates a form after accepting the formID, the outer validation class name, and an optional class name for checkboxes 
 * and radio buttons, and returns if the form has been validated according to the regexp mentioned in the data-regex attribute. */
export function formValidationLogic(formID: string, validationClassName: string, validationCheckboxName: string, highlightReqd?: boolean): boolean {
    if (highlightReqd === null || highlightReqd === undefined || highlightReqd == true) {
        highlightReqd = true;
    } else {
        highlightReqd = false;
    }

    let allElements: VHTMLElement[] = [];
    let allDivs: VHTMLElement[] = [];
    let allSections: VHTMLElement[] = [];
    let inputTruths: boolean[] = [];
    let passwords: VHTMLElement[] = [];
    let passwordsTruths: boolean[] = [];
    var allNodes = document.getElementById(formID)!.getElementsByClassName(validationClassName);
    for (var i = 0; i < allNodes.length; i++) {
        const element = <HTMLElement>allNodes[i];
        if (element.nodeName == "INPUT") {
            let localEl = <HTMLInputElement>element;
            if (localEl.readOnly || localEl.disabled) {
                continue;
            }
            if (localEl.type == "password") {
                passwords.push(convertInputToVHTML(localEl));
            } else {
                allElements.push(convertInputToVHTML(localEl));
            }
        } else if (element.nodeName == "SELECT") {
            if ((<HTMLSelectElement>element).disabled) {
                continue
            }
            allElements.push(convertSelectToVHTML(<HTMLSelectElement>element));
        } else if (element.nodeName == "TEXTAREA") {
            const el = <HTMLTextAreaElement>element;
            if (el.readOnly || el.disabled) {
                continue;
            }
            allElements.push(convertTextAreaToVHTML(el));
        } else if (element.nodeName == "DIV") {
            allDivs.push(convertDivToVHTML(<HTMLDivElement>element));
        } else if (element.nodeName == "SECTION" && element.classList.contains(editorJSIdentifierClass)) {
            allSections.push(convertSectionToVHTML(<HTMLDivElement>element));
        }
    }

    function __pushTrue(el: VHTMLElement) {
        inputTruths.push(true);
        unhighlightElement(el);
    }

    function __pushFalse(el: VHTMLElement) {
        inputTruths.push(false);
        // Change the CSS here
        if (highlightReqd) {
            highlightElement(el);
        }
    }

    /**Pushes the number element to the fail queue */
    function __numberInputFail(el: VHTMLElement, min: number, max: number, step: number) {
        // Change the placeholder that is displayed in the notification content
        el.alert = `Value needs to be above ${min}`;
        if (max > 0) {
            el.alert += ` and below ${max}`;
        }
        let valuesThatCanBeDisplayed = <string[]>[];
        let maxCount = 3;
        let currentCount = 0;

        if (max == 0) {
            // There's no max configured, which means this could be an infinite series
            max = min + step * (maxCount + 2);
        }

        for (let i = min; i < max; i = i + step) {
            if (currentCount == maxCount) {
                break
            }
            valuesThatCanBeDisplayed.push((min + step * currentCount).toFixed(2));
            currentCount++;
        }
        el.alert += ` such as ${valuesThatCanBeDisplayed.join(", ")}...`;
        __pushFalse(el);
    }

    // Ensure that the numbers are within max-min range, with the appropriate steps
    let tempAllElements = <VHTMLElement[]>[];
    for (let i = 0; i < allElements.length; i++) {
        let element = allElements[i];
        if (element.nodeName == "input") {
            let el = <HTMLInputElement>document.getElementById(element.elementID);
            // && el.getAttribute("max") != null
            if (el.type == "number" && (el.getAttribute("min") != null)) {

                let min = parseFloat(el.min);
                if (isNaN(min)) {
                    min = 0;
                }
                let max = parseFloat(el.max);
                if (isNaN(max)) {
                    max = 0;
                }
                let step = parseFloat(el.step);
                if (isNaN(step)) {
                    step = 0.01;
                }
                let value = parseFloat(el.value);

                if (value < min) {
                    // Can't process
                    __numberInputFail(element, min, max, step);
                    continue
                }
                if (max > 0 && value > max) {
                    // Can't process
                    __numberInputFail(element, min, max, step);
                    continue
                }
                if ((
                    // Values are being multipled by 100 because step can be 0.01, and modulus division by 0.01 does not yield a usable number
                    parseFloat(((value - min) * 100).toFixed(2)) %
                    parseFloat((step * 100).toFixed(2))
                ) != 0) {
                    // Can't process
                    // console.log("Step has not matched for element: ", element.elementID, " with value: ", value, ", min: ", min, " and step: ", step);
                    __numberInputFail(element, min, max, step);
                    continue
                }

                __pushTrue(element);
            } else {
                tempAllElements.push(element);
            }
        } else {
            tempAllElements.push(element);
        }
    }

    allElements = tempAllElements;

    allElements.forEach(element => {
        if (match(element.regex, element.value)) {
            __pushTrue(element);
        } else {
            __pushFalse(element);
        }
    });

    // Match for all divs here
    for (var j = 0; j < allDivs.length; j++) {
        let div = allDivs[j];
        let matchString = "";
        // Fetch all the elements with validationCheckboxName. If they are checkboxes, then fetch all the values that are checked, and add to the string
        let allBoxNodes = document.getElementById(div.elementID)!.getElementsByClassName(validationCheckboxName);
        for (var i = 0; i < allBoxNodes.length; i++) {
            let el = <HTMLInputElement>allBoxNodes[i];
            if (el.type == "checkbox") {
                if (el.checked) {
                    matchString += "1";
                }
            } else if (el.type == "radio") {
                if (el.checked) {
                    matchString += "1";
                }
            }
        }
        if (match(div.regex, matchString)) {
            __pushTrue(div);
        } else {
            __pushFalse(div);
        }
    }

    // Match for all sections here
    for (var j = 0; j < allSections.length; j++) {
        let section = allSections[j];
        let matchString = section.value;
        if (match(section.regex, matchString)) {
            __pushTrue(section);
        } else {
            __pushFalse(section);
        }
    }

    passwords.forEach(element => {
        if (match(element.regex, element.value)) {
            passwordsTruths.push(true);
            unhighlightElement(element);
        } else {
            passwordsTruths.push(false);
            // Change the CSS here
            if (highlightReqd) {
                highlightElement(element);
            }
        }
    });

    // Match all other inputs
    for (var result of inputTruths) {
        if (!result) {
            return false;
        }
    }

    for (var result of passwordsTruths) {
        if (!result) {
            return false;
        }
    }
    // Create a set of the value of all the available passwords here
    let passwordsMap: Object = {};
    for (var pwd of passwords) {
        passwordsMap[pwd.value] = "";
    }
    let passwordsMapLen: string[] = [];
    for (var key in passwordsMap) {
        if (passwordsMap.hasOwnProperty(key)) {
            passwordsMapLen.push(key);
        }
    }
    if (passwordsMapLen.length > 1) {
        showFailureAlert("Passwords entered do not match");
        return false;
    }

    // return checkIfFilesHaveBeenUploaded();
    return true;
}

// /**Checks if all the files have been uploaded and returns a corresponding boolean */
// function checkIfFilesHaveBeenUploaded(): boolean {
//     // Fetch all the files
//     let files = document.getElementsByClassName(variables.globalFileIdentificationClassName);

//     for (let i = 0; i < files.length; i++) {
//         if (files[i].getAttribute("data-uploaded") == "false") {
//             notifications.displayValidationFailError("Still uploading files", "Try again in a few seconds...");
//             return false;
//         }
//     }
//     return true;
// }

/** Matches the value entered with the regex of the particular HTML element */
function match(dataRegex: string, value: string): boolean {
    if (dataRegex == null) {
        // This element does not need to be validated
        return true;
    }
    let regex = new RegExp(dataRegex);
    if (regex.test(value)) {
        return true;
    }
    return false;
}

/** Highlights the HTML element whose content has not been validated */
function highlightElement(element: VHTMLElement) {
    let localEl = document.getElementById(element.elementID)!;
    if (localEl.nodeName.toLowerCase() == "select" && localEl.parentElement?.nodeName.toLowerCase() == "div" && localEl.parentElement.classList.contains("choices__inner")) {
        localEl = localEl.parentElement.parentElement!;
    }
    localEl.style.borderBottom = "1px solid red";
    // Display an alert message here
    showFailureAlert(element.alert);
}

/** Restores the borderBottom to its initial state */
function unhighlightElement(element: VHTMLElement) {
    let localEl = document.getElementById(element.elementID)!;
    if (localEl.nodeName.toLowerCase() == "select" && localEl.parentElement?.nodeName.toLowerCase() == "div" && localEl.parentElement.classList.contains("choices__inner")) {
        localEl = localEl.parentElement.parentElement!;
    }
    localEl.style.borderBottom = "initial";
}

/** Converts an HTMLInputElement to an internal element */
function convertInputToVHTML(element: HTMLInputElement): VHTMLElement {
    let el: VHTMLElement = { nodeName: "input", regex: "", value: "", alert: "", elementID: "" };
    if (element.id == null) { el.elementID = ""; } else { el.elementID = element.id; }
    if (element.getAttribute("data-alert") == null) { el.alert = ""; } else { el.alert = element.getAttribute("data-alert")!; }
    if (element.getAttribute("data-regex") == null) { el.regex = ""; } else { el.regex = element.getAttribute("data-regex")!; }
    if (element.getAttribute("data-input-type") == "search") {
        el.value = element.getAttribute("data-value")!;
    } else {
        el.value = element.value;
    }
    return el;
}

/** Converts an HTMLSelectElement to an internal element */
function convertSelectToVHTML(element: HTMLSelectElement): VHTMLElement {
    var el: VHTMLElement = { nodeName: "select", regex: "", value: "", alert: "", elementID: "" };
    if (element.id == null) { el.elementID = ""; } else { el.elementID = element.id; }
    if (element.getAttribute("data-alert") == null) { el.alert = ""; } else { el.alert = element.getAttribute("data-alert")!; }
    if (element.getAttribute("data-regex") == null) { el.regex = ""; } else { el.regex = element.getAttribute("data-regex")!; }
    el.value = element.value;
    return el;
}

/** Converts an HTMLTextAreaElement to an internal element */
function convertTextAreaToVHTML(element: HTMLTextAreaElement): VHTMLElement {
    var el: VHTMLElement = { nodeName: "textarea", regex: "", value: "", alert: "", elementID: "" };
    if (element.id == null) { el.elementID = ""; } else { el.elementID = element.id; }
    if (element.getAttribute("data-alert") == null) { el.alert = ""; } else { el.alert = element.getAttribute("data-alert")!; }
    if (element.getAttribute("data-regex") == null) { el.regex = ""; } else { el.regex = element.getAttribute("data-regex")!; }
    el.value = element.value;
    return el;
}

/** Converts an HTMLDivElement to an internal element */
function convertDivToVHTML(element: HTMLDivElement): VHTMLElement {
    var el: VHTMLElement = { nodeName: "div", regex: "", value: "", alert: "", elementID: "" };
    if (element.id == null) { el.elementID = ""; } else { el.elementID = element.id; }
    if (element.getAttribute("data-alert") == null) { el.alert = ""; } else { el.alert = element.getAttribute("data-alert")!; }
    if (element.getAttribute("data-regex") == null) { el.regex = ""; } else { el.regex = element.getAttribute("data-regex")!; }
    el.value = "";
    return el;
}

/**Converts a HTMLDivElement to an internal element */
function convertSectionToVHTML(element: HTMLDivElement): VHTMLElement {
    var el: VHTMLElement = { nodeName: "section", regex: "", value: "", alert: "", elementID: "" };
    if (element.id == null) { el.elementID = ""; } else { el.elementID = element.id; }
    if (element.getAttribute("data-alert") == null) { el.alert = ""; } else { el.alert = element.getAttribute("data-alert")!; }
    if (element.getAttribute("data-regex") == null) { el.regex = ""; } else { el.regex = element.getAttribute("data-regex")!; }
    el.value = element.getAttribute("data-value")!;
    return el;
}

interface VHTMLElement {
    nodeName: "input" | "select" | "textarea" | "div" | "section"
    regex: string
    value: string
    alert: string
    elementID: string
}
