import { _returnInCents, bgColor, createObjectFromForm, downloadButtonClass, downloadData, randomId, round, setupDownloadForFilters, showFailureAlert, showSuccessAlert } from "../../utilities";
import { context } from "./../../router";
import { FAMILY_TYPE, getClientForComponentsService, getClientForEquipmentsService, getClientForFeedstocksService, getClientForInfrastructuresService, getClientForMerchandisesService, getClientForProductsService, getClientForQCSamplesService, QCSamplesServiceCountReq, QCSamplesServiceFilterReq, StandardFile } from "@kernelminds/scailo-sdk";
import { viewQCSamples } from "../../tables";
import { _renderPageFilters } from "./searchcomponents";
import { getTransport } from "../../clients";

export function handleQcSampleFilters(ctx: context) {
    let content = <HTMLDivElement>document.getElementById("central-content");
    while (content.firstChild) {
        content.removeChild(content.firstChild);
    }
    let { html, formId, resetButtonId, getButtonId } = _renderPageFilters("Records");
    let filtersHtml = document.createElement("div");
    filtersHtml.innerHTML = html

    content.appendChild(filtersHtml);

    let tableContainer = document.createElement("div");
    content.appendChild(tableContainer);

    (<HTMLButtonElement>document.getElementById(resetButtonId)).addEventListener("click", async evt => {
        evt.preventDefault();
        handleQcSampleFilters(ctx);
    });

    let getButton = (<HTMLButtonElement>document.getElementById(getButtonId));
    const transport = getTransport();

    getButton.addEventListener("click", async evt => {
        evt.preventDefault();
        while (tableContainer.firstChild) {
            tableContainer.removeChild(tableContainer.firstChild);
        }

        getButton.disabled = true;
        getButton.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;

        let client = getClientForQCSamplesService(transport);
        let [filterResp, countResp] = await Promise.all([
            client.filter(new QCSamplesServiceFilterReq(createObjectFromForm(formId))),
            client.count(new QCSamplesServiceCountReq(createObjectFromForm(formId)))
        ]);

        getButton.disabled = false;
        getButton.innerText = `Get Records`;

        const filterRecords = filterResp.list;

        if (!filterRecords.length) {
            showFailureAlert("No Records Found");
            return;
        }

        showSuccessAlert(`${countResp.count} records match the filter criteria`);
        
        let tableDiv = document.createElement("div");
        tableDiv.className = `overflow-x-auto p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words ${bgColor} w-full shadow-lg rounded`;
        tableContainer.appendChild(tableDiv);

        const recordsDiv = await viewQCSamples(filterRecords);
        setupDownloadForFilters(recordsDiv, tableDiv, "QC Samples", 2);

        // Setup PDF downloads
        let pdfDownloadButtons = tableDiv.getElementsByClassName(downloadButtonClass);
        for (let i = 0; i < pdfDownloadButtons.length; i++) {
            let btn = <HTMLButtonElement>pdfDownloadButtons[i];
            btn.addEventListener("click", async evt => {
                evt.preventDefault();

                const originalButtonHTML = btn.innerHTML;
                btn.disabled = true;
                btn.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;

                const inventoryRefUuid = btn.getAttribute("data-inventory-ref-uuid")
                const familyType = parseInt(btn.getAttribute("data-family-type") || "0");
                let file = new StandardFile();

                if (familyType == FAMILY_TYPE.FAMILY_TYPE_COMPONENT) {
                    let inventoryClient = getClientForComponentsService(transport);
                    file = await inventoryClient.downloadQCReportByUUID({ uuid: inventoryRefUuid || "" });
                } else if (familyType == FAMILY_TYPE.FAMILY_TYPE_EQUIPMENT) {
                    let inventoryClient = getClientForEquipmentsService(transport);
                    file = await inventoryClient.downloadQCReportByUUID({ uuid: inventoryRefUuid || "" });
                } else if (familyType == FAMILY_TYPE.FAMILY_TYPE_FEEDSTOCK) {
                    let inventoryClient = getClientForFeedstocksService(transport);
                    file = await inventoryClient.downloadQCReportByUUID({ uuid: inventoryRefUuid || "" });
                } else if (familyType == FAMILY_TYPE.FAMILY_TYPE_INFRASTRUCTURE) {
                    let inventoryClient = getClientForInfrastructuresService(transport);
                    file = await inventoryClient.downloadQCReportByUUID({ uuid: inventoryRefUuid || "" });
                } else if (familyType == FAMILY_TYPE.FAMILY_TYPE_MERCHANDISE) {
                    let inventoryClient = getClientForMerchandisesService(transport);
                    file = await inventoryClient.downloadQCReportByUUID({ uuid: inventoryRefUuid || "" });
                } else if (familyType == FAMILY_TYPE.FAMILY_TYPE_PRODUCT) {
                    let inventoryClient = getClientForProductsService(transport);
                    file = await inventoryClient.downloadQCReportByUUID({ uuid: inventoryRefUuid || "" });
                }

                btn.disabled = false;
                btn.innerHTML = originalButtonHTML;

                downloadData(file.content, "pdf", file.name.replace(".pdf", ""));
            });
        }
    });
}