import { context } from "./../../router";
import { SalesOrder, SalesOrdersService, SalesOrderAncillaryParameters, Currency, Family, UnitOfMaterial, TaxGroup, getClientForSalesOrdersService, getClientForCurrenciesService, getClientForClientsService, getClientForAssociatesService, getClientForUnitsOfMaterialsService, getClientForTaxGroupsService } from "@kernelminds/scailo-sdk";
import { renderFilterPrimarySubSection, renderInput, renderPageTitleSection, renderSpan, returnFormFromFields } from "../../ui";
import { bgColor, convertBigIntTimestampToDate, convertCentsToMoney, decodeSLC, downloadData, internationalizeMoney, randomId, returnAssociateBadge, toTitleCase } from "../../utilities";
import { PromiseClient, Transport } from "@connectrpc/connect";
import { handleSalesOrderInventoryMatch } from "./salesorders.filters";
import { familiesListFromIDs } from "../../fetches";
import { getTransport } from "../../clients";

const inventoryMatchClass = "__inventory-match-btn";
const downloadPDFButtonClass = "__download-pdf-btn";
const downloadCSVButtonClass = "__download-csv-btn";

export async function handleIndividualSalesOrder(ctx: context) {
    let content = <HTMLDivElement>document.getElementById("central-content");
    while (content.firstChild) {
        content.removeChild(content.firstChild);
    }
    const transport = getTransport();
    const accessClient = getClientForSalesOrdersService(transport);
    const [salesorder, ancillaryParams] = await Promise.all([
        accessClient.viewByUUID({ uuid: ctx.params.uuid }),
        accessClient.viewAncillaryParametersByUUID({ uuid: ctx.params.uuid }),
    ]);
    const currenciesClient = getClientForCurrenciesService(transport);
    const currency = await currenciesClient.viewEssentialByID({ id: salesorder.currencyId });

    document.title = salesorder.approvalMetadata?.approvedOn! > 0 ? salesorder.finalRefNumber : salesorder.referenceId;

    let container = document.createElement("div");
    container.className = "overflow-x-auto";
    content.appendChild(container);

    const readonly = true;
    const { formGrid } = await getForm(salesorder, ancillaryParams, currency, readonly, accessClient, transport);

    container.appendChild(formGrid);

    await handleSalesOrderInventoryMatch(inventoryMatchClass, container, accessClient, transport);

    // Setup PDF downloads
    let pdfDownloadButtons = container.getElementsByClassName(downloadPDFButtonClass);
    for (let i = 0; i < pdfDownloadButtons.length; i++) {
        let btn = <HTMLButtonElement>pdfDownloadButtons[i];
        btn.addEventListener("click", async evt => {
            evt.preventDefault();

            const originalButtonHTML = btn.innerHTML;
            btn.disabled = true;
            btn.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
            let file = await accessClient.downloadByUUID({ uuid: btn.getAttribute("data-uuid") || "" });

            btn.disabled = false;
            btn.innerHTML = originalButtonHTML;

            downloadData(file.content, "pdf", file.name.replace(".pdf", ""));
        });
    }

    // Setup CSV downloads
    let csvDownloadButtons = container.getElementsByClassName(downloadCSVButtonClass);
    for (let i = 0; i < csvDownloadButtons.length; i++) {
        let btn = <HTMLButtonElement>csvDownloadButtons[i];
        btn.addEventListener("click", async evt => {
            evt.preventDefault();

            const originalButtonHTML = btn.innerHTML;
            btn.disabled = true;
            btn.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
            let file = await accessClient.downloadItemsAsCSV({ uuid: btn.getAttribute("data-uuid") || "" });

            btn.disabled = false;
            btn.innerHTML = originalButtonHTML;

            downloadData(file.content, "csv", file.name.replace(".csv", ""));
        });
    }

}

async function renderReferencesSection(salesorder: SalesOrder, ancillaryParams: SalesOrderAncillaryParameters, readonly: boolean, accessClient: PromiseClient<typeof SalesOrdersService>): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "References", titleMdColSpan: 3 });

    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Initial Reference", inputType: "text", dataMapper: "referenceId", dataType: "string", value: salesorder.referenceId, mdColSpan: 6, helpText: "Initial Reference of the record.", dataRegex: ".+" }));
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Final Reference", inputType: "text", dataMapper: "finalRefNumber", dataType: "string", value: salesorder.finalRefNumber, mdColSpan: 6, helpText: "Final Reference of the record that is automatically generated.", dataRegex: ".+" }));
    // Display status as well
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Status", inputType: "text", dataMapper: "status", dataType: "string", value: toTitleCase(decodeSLC(salesorder.status)), mdColSpan: 3, helpText: "Status of the record.", dataRegex: ".+" }));
    // Display amendment count
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Amendment Count", inputType: "number", dataMapper: "amendmentCount", dataType: "number", value: salesorder.amendmentCount.toString(), mdColSpan: 3, helpText: "The number of times that this record has been amended.", dataRegex: ".+" }));

    return grid;
}

async function renderBuyerAndConsigneeSection(salesorder: SalesOrder, ancillaryParams: SalesOrderAncillaryParameters, readonly: boolean, accessClient: PromiseClient<typeof SalesOrdersService>, transport: Transport): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "Buyer Information", titleMdColSpan: 3 });

    // Get buyer and consignee
    const clientsAccessClient = getClientForClientsService(transport);
    const [buyer, consignee] = await Promise.all([
        clientsAccessClient.viewByUUID({ uuid: ancillaryParams.buyerClientUuid }),
        clientsAccessClient.viewByUUID({ uuid: ancillaryParams.consigneeClientUuid }),
    ]);

    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Buyer", inputType: "text", dataMapper: "buyerClientId", dataType: "string", value: `${buyer.code.length > 0 ? `(${buyer.code})` : ''} ${buyer.name}`, mdColSpan: 6, helpText: "The information of the buyer.", dataRegex: ".+" }));
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Consignee", inputType: "text", dataMapper: "consigneeClientId", dataType: "string", value: `${consignee.code.length > 0 ? `(${consignee.code})` : ''} ${consignee.name}`, mdColSpan: 6, helpText: "The information of the consignee.", dataRegex: ".+" }));

    return grid;
}

async function renderDatesSection(salesorder: SalesOrder, ancillaryParams: SalesOrderAncillaryParameters, readonly: boolean, accessClient: PromiseClient<typeof SalesOrdersService>): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "Important Dates", titleMdColSpan: 3 });

    // Dates (creation and approval)
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Created On", inputType: "text", dataMapper: "createdAt", dataType: "string", value: convertBigIntTimestampToDate(salesorder.metadata?.createdAt!), mdColSpan: 3, helpText: "The creation date of this record.", dataRegex: ".*" }));
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Approved On", inputType: "text", dataMapper: "approvedOn", dataType: "string", value: convertBigIntTimestampToDate(salesorder.approvalMetadata?.approvedOn!), mdColSpan: 3, helpText: "The approval date of this record.", dataRegex: ".*" }));

    return grid;
}

async function renderPaymentTermsSection(salesorder: SalesOrder, currency: Currency, readonly: boolean, accessClient: PromiseClient<typeof SalesOrdersService>): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "Payment Terms", titleMdColSpan: 3 });

    // Advance Payment
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Advance Payment", inputType: "text", dataMapper: "paymentAdvance", dataType: "string", value: `${currency.symbol} ${internationalizeMoney(parseFloat(convertCentsToMoney(salesorder.paymentAdvance)))}`, mdColSpan: 3, helpText: `The advance amount (in ${currency.symbol}) that is supposed to be paid.`, dataRegex: "^(\\d*\\.\\d{1,2}|\\d+)$" }));

    // Payment Cycle
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Payment Cycle (In Days)", inputType: "text", dataMapper: "paymentCycleInDays", dataType: "string", value: `${salesorder.paymentCycleInDays.toString()} days`, mdColSpan: 3, helpText: `The payment cycle of this Sales Order.`, dataRegex: "^[0-9]\d*" }));

    return grid;
}

async function renderCostsSection(salesorder: SalesOrder, currency: Currency, readonly: boolean, accessClient: PromiseClient<typeof SalesOrdersService>): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "Costs", titleMdColSpan: 3 });

    // Miscellaneous Costs
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Miscellaneous Cost", inputType: "text", dataMapper: "miscellaneousCost", dataType: "string", value: `${currency.symbol} ${internationalizeMoney(parseFloat(convertCentsToMoney(salesorder.miscellaneousCost)))}`, mdColSpan: 4, helpText: `Any miscellaneous costs (in ${currency.symbol}) on the Sales Order.`, dataRegex: "^(\\d*\\.\\d{1,2}|\\d+)$" }));

    // Overall Discount
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Overall Discount", inputType: "text", dataMapper: "overallDiscount", dataType: "string", value: `${currency.symbol} ${internationalizeMoney(parseFloat(convertCentsToMoney(salesorder.overallDiscount)))}`, mdColSpan: 4, helpText: `The overall discount (in ${currency.symbol}) on the Sales Order.`, dataRegex: "^(\\d*\\.\\d{1,2}|\\d+)$" }));

    // Round Off
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Round Off", inputType: "text", dataMapper: "roundOff", dataType: "string", value: `${currency.symbol} ${internationalizeMoney(parseFloat(convertCentsToMoney(salesorder.roundOff)))}`, mdColSpan: 4, helpText: `The round off (in ${currency.symbol}) applied on the Sales Order.`, dataRegex: ".+" }));

    // Total
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Net Total", inputType: "text", dataMapper: "totalValue", dataType: "string", value: `${currency.symbol} ${internationalizeMoney(salesorder.totalValue)}`, mdColSpan: 6, helpText: `The net total (inclusive of applicable taxes, in ${currency.symbol}) of the Sales Order.`, dataRegex: ".+" }));

    return grid;
}

async function renderContactsSection(salesorder: SalesOrder, readonly: boolean, accessClient: PromiseClient<typeof SalesOrdersService>, transport: Transport): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "Contacts", titleMdColSpan: 3 });

    const associatesClient = getClientForAssociatesService(transport);

    let contacts = (await accessClient.viewSalesOrderContacts({ uuid: salesorder.metadata?.uuid })).list;
    let salesOrderAssociates = await Promise.all(contacts.map(c => c.associateUuid).map(uuid => {
        return associatesClient.viewByUUID({ uuid });
    }));

    salesOrderAssociates.forEach(contact => {
        if (contact) {
            contentGrid.appendChild(returnAssociateBadge(contact));
        }
    });

    return grid;
}

async function renderDynamicFormsSection(salesorder: SalesOrder, readonly: boolean, accessClient: PromiseClient<typeof SalesOrdersService>): Promise<HTMLDivElement> {
    let container = document.createElement("div");

    if (salesorder.formData.length == 0) {
        return container;
    }

    let containerTitle = document.createElement("div");
    containerTitle.className = "rounded-t mb-0 px-0 border-0";
    containerTitle.appendChild(renderPageTitleSection({ title: `Other Details` }));
    container.appendChild(containerTitle);

    let formFields = await returnFormFromFields(salesorder.formData, "sales-orders", true, readonly);

    container.appendChild(formFields);

    return container;
}

async function renderFamiliesSection(salesorder: SalesOrder, currency: Currency, readonly: boolean, accessClient: PromiseClient<typeof SalesOrdersService>, transport: Transport): Promise<HTMLDivElement> {
    let container = document.createElement("div");

    let containerTitle = document.createElement("div");
    containerTitle.className = "rounded-t mb-0 px-0 border-0";
    containerTitle.appendChild(renderPageTitleSection({ title: `Line Items` }));

    container.appendChild(containerTitle);

    let familiesMap: Map<bigint, Family> = new Map();
    (await familiesListFromIDs(Array.from(new Set(salesorder.list.map(p => p.familyId))))).forEach(family => {
        familiesMap.set(family.metadata?.id!, family);
    });
    
    const uomClient = getClientForUnitsOfMaterialsService(transport);
    let unitsList = salesorder.list.map(p => p.clientUomId);
    familiesMap.forEach(family => {
        unitsList.push(family.uomId);
    });

    const uomsList = (await uomClient.viewFromIDs({ list: Array.from(new Set(unitsList)) })).list;
    let uomsMap: Map<bigint, UnitOfMaterial> = new Map();

    uomsList.forEach(uom => {
        uomsMap.set(uom.metadata!.id, uom);
    });

    const taxGroupClient = getClientForTaxGroupsService(transport);
    let taxGroupIDsList = salesorder.list.map(p => p.taxGroupId);
    familiesMap.forEach(family => {
        taxGroupIDsList.push(family.taxGroupId);
    });

    const taxGroupsList = (await taxGroupClient.viewFromIDs({ list: Array.from(new Set(taxGroupIDsList)) })).list;
    let taxGroupsMap: Map<bigint, TaxGroup> = new Map();

    taxGroupsList.forEach(taxGroup => {
        taxGroupsMap.set(taxGroup.metadata!.id, taxGroup);
    });

    salesorder.list.forEach((item, index) => {
        let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: `${index + 1}.`, titleMdColSpan: 1 });

        let fam = familiesMap.get(item.familyId) || new Family();

        let pretaxTotal = parseInt(String(item.internalQuantity)) * parseInt(String(item.unitPrice)) / 100.0;

        const discount = parseInt(String(item.discount)) * pretaxTotal / 100.0 / 100.0;
        const postDiscountTotal = pretaxTotal - discount;

        let taxgroup = taxGroupsMap.get(item.taxGroupId) || new TaxGroup();
        let taxAmt = postDiscountTotal * taxgroup.cumulativeTaxPercentage / 100.0 / 100.0;
        let total = postDiscountTotal + taxAmt;

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Material Code", value: `${fam.code}`, mdColSpan: 3, helpText: `The code of the material` }));
        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Material Name", value: `${fam.name}`, mdColSpan: 9, helpText: `The name of the material` }));

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Client Quantity & UoM", value: `${internationalizeMoney(parseFloat(convertCentsToMoney(item.clientQuantity)))} (${uomsMap.get(item.clientUomId)?.symbol}) ${uomsMap.get(item.clientUomId)?.name}`, mdColSpan: 3, helpText: `The quantity of the material in Client's unit of material` }));

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Buyer Quantity & UoM", value: `${internationalizeMoney(parseFloat(convertCentsToMoney(item.internalQuantity)))} (${uomsMap.get(fam.uomId)?.symbol}) ${uomsMap.get(fam.uomId)?.name}`, mdColSpan: 3, helpText: `The quantity of the material in Buyer's unit of material` }));

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Unit Price", value: `${currency.symbol} ${internationalizeMoney(parseFloat(convertCentsToMoney(item.unitPrice)))}/${uomsMap.get(item.clientUomId)?.symbol}`, mdColSpan: 3, helpText: `The price per unit of the material in Client's unit` }));
        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Discount %", value: `${convertCentsToMoney(item.discount)}`, mdColSpan: 3, helpText: `The discount on unit price in percentage` }));
        
        
        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Delivery Date", value: `${item.deliveryDate}`, mdColSpan: 3, helpText: `The delivery date of this material` }));
        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Tax", value: `${taxGroupsMap.get(item.taxGroupId)?.name}`, mdColSpan: 3, helpText: `The applicable tax group` }));

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Pre-Tax Total", value: `${currency.symbol} ${internationalizeMoney(postDiscountTotal / 100.0)}`, mdColSpan: 3, helpText: `The pre tax amount (after applicable discount percentage)` }));
        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Tax Amount", value: `${currency.symbol} ${internationalizeMoney(taxAmt / 100.0)}`, mdColSpan: 3, helpText: `The computed tax amount` }));
        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Net Total", value: `${currency.symbol} ${internationalizeMoney(total / 100.0)}`, mdColSpan: 3, helpText: `The total amount (inclusive of taxes)` }));
        contentGrid.appendChild(renderSpan({ id: randomId(), label: "HSN/SAC Code", value: `${fam.hsnSacCode}`, mdColSpan: 3, helpText: `The HSN/SAC code of the material` }));

        if (item.specifications.length > 0) {
            contentGrid.appendChild(renderSpan({ id: randomId(), label: "Specifications", value: `${item.specifications}`, mdColSpan: 12, helpText: `Any additional specifications that are provided to the Client` }));
        }

        container.appendChild(grid);

        let hr = document.createElement("hr");
        hr.classList.add("m-5");
        container.appendChild(hr);
    });

    return container;
}

async function getForm(salesorder: SalesOrder, ancillaryParams: SalesOrderAncillaryParameters, currency: Currency, readonly: boolean, accessClient: PromiseClient<typeof SalesOrdersService>, transport: Transport) {
    let formGrid = document.createElement("div");
    formGrid.className = "grid grid-cols-1 gap-6 mb-6";
    const formId = randomId();

    const [
        referencesSection,
        buyerAndConsigneeSection,
        datesSection,
        paymentTermsSection,
        costsSection,
        contactsSection,
        dynamicFormsSection,
        familiesSection,
    ] = await Promise.all([
        renderReferencesSection(salesorder, ancillaryParams, readonly, accessClient),
        renderBuyerAndConsigneeSection(salesorder, ancillaryParams, readonly, accessClient, transport),
        renderDatesSection(salesorder, ancillaryParams, readonly, accessClient),
        renderPaymentTermsSection(salesorder, currency, readonly, accessClient),
        renderCostsSection(salesorder, currency, readonly, accessClient),
        renderContactsSection(salesorder, readonly, accessClient, transport),
        renderDynamicFormsSection(salesorder, readonly, accessClient),
        renderFamiliesSection(salesorder, currency, readonly, accessClient, transport)
    ]);

    let buttonContainer = document.createElement("div");
    buttonContainer.classList.add("col-span-12", "flex", "justify-start", "md:justify-center", "overflow-x-auto");

    if (salesorder.approvalMetadata?.approvedOn! > 0) {
        let inventoryMatchButton = document.createElement("button");
        inventoryMatchButton.innerText = "View Inventory Match";
        inventoryMatchButton.className = `btn btn-info btn-outline btn-sm ${inventoryMatchClass} mr-4`;
        inventoryMatchButton.setAttribute("data-uuid", salesorder.metadata?.uuid!);
        inventoryMatchButton.setAttribute("data-name", salesorder.approvalMetadata?.approvedOn! > 0 ? salesorder.finalRefNumber : salesorder.referenceId);
        buttonContainer.appendChild(inventoryMatchButton);
    }

    // Download PDF button
    let downloadPDFButton = document.createElement("button");
    downloadPDFButton.id = randomId();
    downloadPDFButton.className = `btn btn-success btn-outline btn-sm ${downloadPDFButtonClass} mr-4`;
    downloadPDFButton.innerText = "Download PDF";
    downloadPDFButton.setAttribute("data-uuid", salesorder.metadata?.uuid!);
    downloadPDFButton.setAttribute("data-name", salesorder.approvalMetadata?.approvedOn! > 0 ? salesorder.finalRefNumber : salesorder.referenceId);
    buttonContainer.appendChild(downloadPDFButton);

    // Download CSV button
    let downloadCSVButton = document.createElement("button");
    downloadCSVButton.id = randomId();
    downloadCSVButton.className = `btn btn-success btn-outline btn-sm ${downloadCSVButtonClass} mr-4`;
    downloadCSVButton.innerText = "Download CSV";
    downloadCSVButton.setAttribute("data-uuid", salesorder.metadata?.uuid!);
    downloadCSVButton.setAttribute("data-name", salesorder.approvalMetadata?.approvedOn! > 0 ? salesorder.finalRefNumber : salesorder.referenceId);
    buttonContainer.appendChild(downloadCSVButton);

    formGrid.innerHTML = `
        <div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words ${bgColor} w-full shadow-lg rounded">
            <div class="rounded-t mb-0 px-0 border-0">
                ${renderPageTitleSection({ title: `Sales Order: ` + document.title }).outerHTML}
                ${buttonContainer.outerHTML}
            </div>
            <form id="${formId}">
                <hr class="m-5">
                ${referencesSection.outerHTML}
                <hr class="m-5">
                ${buyerAndConsigneeSection.outerHTML}
                <hr class="m-5">
                ${datesSection.outerHTML}
                <hr class="m-5">
                ${paymentTermsSection.outerHTML}
                <hr class="m-5">
                ${costsSection.outerHTML}
                <hr class="m-5">
                ${contactsSection.outerHTML}
                <hr class="m-5">
            </form>
        </div>
        ${dynamicFormsSection.childElementCount > 0 ? 
            `<div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words ${bgColor} w-full shadow-lg rounded">
                ${dynamicFormsSection.outerHTML}
            </div>` 
        : ''}
        <div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words ${bgColor} w-full shadow-lg rounded">
            ${familiesSection.outerHTML}
        </div>
    `;

    return { formGrid }
}
