"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file families.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.FamiliesServiceUpdateMinStockToMaintainRequest = exports.FamiliesServiceUpdatePriceRequest = exports.FamilyQCGroupsList = exports.FamilyQCGroup = exports.FamiliesServiceQCGroupCreateRequest = exports.FamilyUnitConversionsList = exports.FamilyUnitConversion = exports.FamiliesServiceUnitConversionCreateRequest = exports.FamiliesServiceUnitConversionPresenceRequest = exports.FamilyStoragesList = exports.FamilyStorage = exports.FamiliesServiceStorageCreateRequest = exports.FamilyLabelsList = exports.FamilyLabel = exports.FamiliesServiceLabelCreateRequest = exports.FilterFamiliesReqForIdentifier = exports.FamiliesServiceSearchAllReq = exports.FamiliesServiceCountReq = exports.FamiliesServiceFilterReq = exports.FamiliesServicePaginationResponse = exports.FamiliesServicePaginationReq = exports.FamiliesList = exports.Family = exports.FamiliesServiceUpdateRequest = exports.FamiliesServiceCreateRequest = exports.FamilyTypesList = exports.FAMILY_SORT_KEY = exports.CONSUMPTION_SEQUENCE = exports.FAMILY_TYPE = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Stores the available family types
 *
 * @generated from enum Scailo.FAMILY_TYPE
 */
var FAMILY_TYPE;
(function (FAMILY_TYPE) {
    /**
     * Denotes any family type (only used for filter and search queries, when family type needs to be disregarded)
     *
     * @generated from enum value: FAMILY_TYPE_ANY_UNSPECIFIED = 0;
     */
    FAMILY_TYPE[FAMILY_TYPE["FAMILY_TYPE_ANY_UNSPECIFIED"] = 0] = "FAMILY_TYPE_ANY_UNSPECIFIED";
    /**
     * Denotes a component (a family that can be sold, bought, and manufactured - this is a general purpose family)
     *
     * @generated from enum value: FAMILY_TYPE_COMPONENT = 1;
     */
    FAMILY_TYPE[FAMILY_TYPE["FAMILY_TYPE_COMPONENT"] = 1] = "FAMILY_TYPE_COMPONENT";
    /**
     * Denotes equipment (a family that can be used within manufacturing process as a capex item, such as machines)
     *
     * @generated from enum value: FAMILY_TYPE_EQUIPMENT = 2;
     */
    FAMILY_TYPE[FAMILY_TYPE["FAMILY_TYPE_EQUIPMENT"] = 2] = "FAMILY_TYPE_EQUIPMENT";
    /**
     * Denotes feedstock (a family that will be used as raw material within manufacturing process, and will be consumed)
     *
     * @generated from enum value: FAMILY_TYPE_FEEDSTOCK = 3;
     */
    FAMILY_TYPE[FAMILY_TYPE["FAMILY_TYPE_FEEDSTOCK"] = 3] = "FAMILY_TYPE_FEEDSTOCK";
    /**
     * Denotes infrastructure (a family that can be used for any non-manufacturing purpose. Examples include cars, rtubelights, computers, etc.)
     *
     * @generated from enum value: FAMILY_TYPE_INFRASTRUCTURE = 4;
     */
    FAMILY_TYPE[FAMILY_TYPE["FAMILY_TYPE_INFRASTRUCTURE"] = 4] = "FAMILY_TYPE_INFRASTRUCTURE";
    /**
     * Denotes merchandise (a family that can only be bought and sold, and will not be a part of any value addition processes)
     *
     * @generated from enum value: FAMILY_TYPE_MERCHANDISE = 5;
     */
    FAMILY_TYPE[FAMILY_TYPE["FAMILY_TYPE_MERCHANDISE"] = 5] = "FAMILY_TYPE_MERCHANDISE";
    /**
     * Denotes product (a family that is the final outcome of a manufacturing process. Can only be sold, but not purchased)
     *
     * @generated from enum value: FAMILY_TYPE_PRODUCT = 6;
     */
    FAMILY_TYPE[FAMILY_TYPE["FAMILY_TYPE_PRODUCT"] = 6] = "FAMILY_TYPE_PRODUCT";
    /**
     * Denotes service (a family that deals exclusively with services, and no goods)
     *
     * @generated from enum value: FAMILY_TYPE_SERVICE = 7;
     */
    FAMILY_TYPE[FAMILY_TYPE["FAMILY_TYPE_SERVICE"] = 7] = "FAMILY_TYPE_SERVICE";
})(FAMILY_TYPE || (exports.FAMILY_TYPE = FAMILY_TYPE = {}));
// Retrieve enum metadata with: proto3.getEnumType(FAMILY_TYPE)
protobuf_1.proto3.util.setEnumType(FAMILY_TYPE, "Scailo.FAMILY_TYPE", [
    { no: 0, name: "FAMILY_TYPE_ANY_UNSPECIFIED" },
    { no: 1, name: "FAMILY_TYPE_COMPONENT" },
    { no: 2, name: "FAMILY_TYPE_EQUIPMENT" },
    { no: 3, name: "FAMILY_TYPE_FEEDSTOCK" },
    { no: 4, name: "FAMILY_TYPE_INFRASTRUCTURE" },
    { no: 5, name: "FAMILY_TYPE_MERCHANDISE" },
    { no: 6, name: "FAMILY_TYPE_PRODUCT" },
    { no: 7, name: "FAMILY_TYPE_SERVICE" },
]);
/**
 *
 * Stores the available consumption sequences
 *
 * @generated from enum Scailo.CONSUMPTION_SEQUENCE
 */
var CONSUMPTION_SEQUENCE;
(function (CONSUMPTION_SEQUENCE) {
    /**
     * Denotes any consumption sequence (only used for filter and search queries, when consumption sequence needs to be disregarded)
     *
     * @generated from enum value: CONSUMPTION_SEQUENCE_ANY_UNSPECIFIED = 0;
     */
    CONSUMPTION_SEQUENCE[CONSUMPTION_SEQUENCE["CONSUMPTION_SEQUENCE_ANY_UNSPECIFIED"] = 0] = "CONSUMPTION_SEQUENCE_ANY_UNSPECIFIED";
    /**
     * Denotes first-in-first-out according to the intake timestamp
     *
     * @generated from enum value: CONSUMPTION_SEQUENCE_FIFO_INTAKE = 1;
     */
    CONSUMPTION_SEQUENCE[CONSUMPTION_SEQUENCE["CONSUMPTION_SEQUENCE_FIFO_INTAKE"] = 1] = "CONSUMPTION_SEQUENCE_FIFO_INTAKE";
    /**
     * Denotes first-in-first-out according to the shelf life
     *
     * @generated from enum value: CONSUMPTION_SEQUENCE_FIFO_SHELF_LIFE = 2;
     */
    CONSUMPTION_SEQUENCE[CONSUMPTION_SEQUENCE["CONSUMPTION_SEQUENCE_FIFO_SHELF_LIFE"] = 2] = "CONSUMPTION_SEQUENCE_FIFO_SHELF_LIFE";
    /**
     * Denotes first-in-first-out according to the warranty
     *
     * @generated from enum value: CONSUMPTION_SEQUENCE_FIFO_WARRANTY = 3;
     */
    CONSUMPTION_SEQUENCE[CONSUMPTION_SEQUENCE["CONSUMPTION_SEQUENCE_FIFO_WARRANTY"] = 3] = "CONSUMPTION_SEQUENCE_FIFO_WARRANTY";
    /**
     * Denotes last-in-first-out according to the intake timestamp
     *
     * @generated from enum value: CONSUMPTION_SEQUENCE_LIFO_INTAKE = 4;
     */
    CONSUMPTION_SEQUENCE[CONSUMPTION_SEQUENCE["CONSUMPTION_SEQUENCE_LIFO_INTAKE"] = 4] = "CONSUMPTION_SEQUENCE_LIFO_INTAKE";
    /**
     * Denotes last-in-first-out according to the shelf life
     *
     * @generated from enum value: CONSUMPTION_SEQUENCE_LIFO_SHELF_LIFE = 5;
     */
    CONSUMPTION_SEQUENCE[CONSUMPTION_SEQUENCE["CONSUMPTION_SEQUENCE_LIFO_SHELF_LIFE"] = 5] = "CONSUMPTION_SEQUENCE_LIFO_SHELF_LIFE";
    /**
     * Denotes last-in-first-out according to the warranty
     *
     * @generated from enum value: CONSUMPTION_SEQUENCE_LIFO_WARRANTY = 6;
     */
    CONSUMPTION_SEQUENCE[CONSUMPTION_SEQUENCE["CONSUMPTION_SEQUENCE_LIFO_WARRANTY"] = 6] = "CONSUMPTION_SEQUENCE_LIFO_WARRANTY";
})(CONSUMPTION_SEQUENCE || (exports.CONSUMPTION_SEQUENCE = CONSUMPTION_SEQUENCE = {}));
// Retrieve enum metadata with: proto3.getEnumType(CONSUMPTION_SEQUENCE)
protobuf_1.proto3.util.setEnumType(CONSUMPTION_SEQUENCE, "Scailo.CONSUMPTION_SEQUENCE", [
    { no: 0, name: "CONSUMPTION_SEQUENCE_ANY_UNSPECIFIED" },
    { no: 1, name: "CONSUMPTION_SEQUENCE_FIFO_INTAKE" },
    { no: 2, name: "CONSUMPTION_SEQUENCE_FIFO_SHELF_LIFE" },
    { no: 3, name: "CONSUMPTION_SEQUENCE_FIFO_WARRANTY" },
    { no: 4, name: "CONSUMPTION_SEQUENCE_LIFO_INTAKE" },
    { no: 5, name: "CONSUMPTION_SEQUENCE_LIFO_SHELF_LIFE" },
    { no: 6, name: "CONSUMPTION_SEQUENCE_LIFO_WARRANTY" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.FAMILY_SORT_KEY
 */
var FAMILY_SORT_KEY;
(function (FAMILY_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: FAMILY_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    FAMILY_SORT_KEY[FAMILY_SORT_KEY["FAMILY_SORT_KEY_ID_UNSPECIFIED"] = 0] = "FAMILY_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: FAMILY_SORT_KEY_CREATED_AT = 1;
     */
    FAMILY_SORT_KEY[FAMILY_SORT_KEY["FAMILY_SORT_KEY_CREATED_AT"] = 1] = "FAMILY_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: FAMILY_SORT_KEY_MODIFIED_AT = 2;
     */
    FAMILY_SORT_KEY[FAMILY_SORT_KEY["FAMILY_SORT_KEY_MODIFIED_AT"] = 2] = "FAMILY_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: FAMILY_SORT_KEY_APPROVED_ON = 3;
     */
    FAMILY_SORT_KEY[FAMILY_SORT_KEY["FAMILY_SORT_KEY_APPROVED_ON"] = 3] = "FAMILY_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: FAMILY_SORT_KEY_APPROVED_BY = 4;
     */
    FAMILY_SORT_KEY[FAMILY_SORT_KEY["FAMILY_SORT_KEY_APPROVED_BY"] = 4] = "FAMILY_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: FAMILY_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    FAMILY_SORT_KEY[FAMILY_SORT_KEY["FAMILY_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "FAMILY_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the name
     *
     * @generated from enum value: FAMILY_SORT_KEY_NAME = 10;
     */
    FAMILY_SORT_KEY[FAMILY_SORT_KEY["FAMILY_SORT_KEY_NAME"] = 10] = "FAMILY_SORT_KEY_NAME";
    /**
     * Fetch ordered results by the drawing number
     *
     * @generated from enum value: FAMILY_SORT_KEY_DRAWING_NUMBER = 11;
     */
    FAMILY_SORT_KEY[FAMILY_SORT_KEY["FAMILY_SORT_KEY_DRAWING_NUMBER"] = 11] = "FAMILY_SORT_KEY_DRAWING_NUMBER";
    /**
     * Fetch ordered results by the code
     *
     * @generated from enum value: FAMILY_SORT_KEY_CODE = 12;
     */
    FAMILY_SORT_KEY[FAMILY_SORT_KEY["FAMILY_SORT_KEY_CODE"] = 12] = "FAMILY_SORT_KEY_CODE";
    /**
     * Fetch ordered results by the unit quantity
     *
     * @generated from enum value: FAMILY_SORT_KEY_UNIT_QUANTITY = 13;
     */
    FAMILY_SORT_KEY[FAMILY_SORT_KEY["FAMILY_SORT_KEY_UNIT_QUANTITY"] = 13] = "FAMILY_SORT_KEY_UNIT_QUANTITY";
    /**
     * Fetch ordered results by the price
     *
     * @generated from enum value: FAMILY_SORT_KEY_PRICE = 14;
     */
    FAMILY_SORT_KEY[FAMILY_SORT_KEY["FAMILY_SORT_KEY_PRICE"] = 14] = "FAMILY_SORT_KEY_PRICE";
    /**
     * Fetch ordered results by the amendment count
     *
     * @generated from enum value: FAMILY_SORT_KEY_AMENDMENT_COUNT = 15;
     */
    FAMILY_SORT_KEY[FAMILY_SORT_KEY["FAMILY_SORT_KEY_AMENDMENT_COUNT"] = 15] = "FAMILY_SORT_KEY_AMENDMENT_COUNT";
})(FAMILY_SORT_KEY || (exports.FAMILY_SORT_KEY = FAMILY_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(FAMILY_SORT_KEY)
protobuf_1.proto3.util.setEnumType(FAMILY_SORT_KEY, "Scailo.FAMILY_SORT_KEY", [
    { no: 0, name: "FAMILY_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "FAMILY_SORT_KEY_CREATED_AT" },
    { no: 2, name: "FAMILY_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "FAMILY_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "FAMILY_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "FAMILY_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 10, name: "FAMILY_SORT_KEY_NAME" },
    { no: 11, name: "FAMILY_SORT_KEY_DRAWING_NUMBER" },
    { no: 12, name: "FAMILY_SORT_KEY_CODE" },
    { no: 13, name: "FAMILY_SORT_KEY_UNIT_QUANTITY" },
    { no: 14, name: "FAMILY_SORT_KEY_PRICE" },
    { no: 15, name: "FAMILY_SORT_KEY_AMENDMENT_COUNT" },
]);
/**
 *
 * Describes the message consisting of the list of family types
 *
 * @generated from message Scailo.FamilyTypesList
 */
class FamilyTypesList extends protobuf_1.Message {
    /**
     * List of family types
     *
     * @generated from field: repeated Scailo.FAMILY_TYPE list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FamilyTypesList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "enum", T: protobuf_1.proto3.getEnumType(FAMILY_TYPE), repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new FamilyTypesList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FamilyTypesList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FamilyTypesList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FamilyTypesList, a, b);
    }
}
exports.FamilyTypesList = FamilyTypesList;
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.FamiliesServiceCreateRequest
 */
class FamiliesServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The name of the family
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The name of the family that is to be used on prints
     *
     * @generated from field: string print_name = 11;
     */
    printName = "";
    /**
     * The unique code that represents the family
     *
     * @generated from field: string code = 12;
     */
    code = "";
    /**
     * The drawing number of the family
     *
     * @generated from field: string drawing_number = 13;
     */
    drawingNumber = "";
    /**
     * The description of the family
     *
     * @generated from field: string description = 14;
     */
    description = "";
    /**
     * The type of the family
     *
     * @generated from field: Scailo.FAMILY_TYPE family_type = 15;
     */
    familyType = FAMILY_TYPE.FAMILY_TYPE_ANY_UNSPECIFIED;
    /**
     * The HSN/SAC code of the family
     *
     * @generated from field: string hsn_sac_code = 16;
     */
    hsnSacCode = "";
    /**
     * Stores the ID of the associated unit of material
     *
     * @generated from field: uint64 uom_id = 17;
     */
    uomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the unit quantity
     *
     * @generated from field: uint64 unit_quantity = 18;
     */
    unitQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the parent family
     *
     * @generated from field: uint64 parent_id = 19;
     */
    parentId = protobuf_1.protoInt64.zero;
    /**
     * Stores if the family is a leaf family
     *
     * @generated from field: bool is_leaf = 20;
     */
    isLeaf = false;
    /**
     * Stores the ID of the ledger
     *
     * @generated from field: uint64 ledger_id = 21;
     */
    ledgerId = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the tax group
     *
     * @generated from field: uint64 tax_group_id = 23;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * Stores the unit price
     *
     * @generated from field: uint64 price = 26;
     */
    price = protobuf_1.protoInt64.zero;
    /**
     * Stores the minimum stock to maintain
     *
     * @generated from field: uint64 min_stock_to_maintain = 27;
     */
    minStockToMaintain = protobuf_1.protoInt64.zero;
    /**
     * Stores the consumption sequence
     *
     * @generated from field: Scailo.CONSUMPTION_SEQUENCE consumption_sequence = 28;
     */
    consumptionSequence = CONSUMPTION_SEQUENCE.CONSUMPTION_SEQUENCE_ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FamiliesServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "print_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "drawing_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 15, name: "family_type", kind: "enum", T: protobuf_1.proto3.getEnumType(FAMILY_TYPE) },
        { no: 16, name: "hsn_sac_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 17, name: "uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "unit_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 19, name: "parent_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "is_leaf", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 21, name: "ledger_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 26, name: "price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 27, name: "min_stock_to_maintain", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 28, name: "consumption_sequence", kind: "enum", T: protobuf_1.proto3.getEnumType(CONSUMPTION_SEQUENCE) },
    ]);
    static fromBinary(bytes, options) {
        return new FamiliesServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FamiliesServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FamiliesServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FamiliesServiceCreateRequest, a, b);
    }
}
exports.FamiliesServiceCreateRequest = FamiliesServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.FamiliesServiceUpdateRequest
 */
class FamiliesServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that denotes if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The name of the family
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The name of the family that is to be used on prints
     *
     * @generated from field: string print_name = 11;
     */
    printName = "";
    /**
     * The unique code that represents the family (can be updated only prior to the first approval)
     *
     * @generated from field: string code = 12;
     */
    code = "";
    /**
     * The drawing number of the family
     *
     * @generated from field: string drawing_number = 13;
     */
    drawingNumber = "";
    /**
     * The description of the family
     *
     * @generated from field: string description = 14;
     */
    description = "";
    /**
     * The type of the family (can be updated only prior to the first approval)
     *
     * @generated from field: Scailo.FAMILY_TYPE family_type = 15;
     */
    familyType = FAMILY_TYPE.FAMILY_TYPE_ANY_UNSPECIFIED;
    /**
     * The HSN/SAC code of the family
     *
     * @generated from field: string hsn_sac_code = 16;
     */
    hsnSacCode = "";
    /**
     * Stores the ID of the associated unit of material
     *
     * @generated from field: uint64 uom_id = 17;
     */
    uomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the unit quantity
     *
     * @generated from field: uint64 unit_quantity = 18;
     */
    unitQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the parent family (can be updated only prior to the first approval)
     *
     * @generated from field: uint64 parent_id = 19;
     */
    parentId = protobuf_1.protoInt64.zero;
    /**
     * Stores if the family is a leaf family (can be updated only prior to the first approval)
     *
     * @generated from field: bool is_leaf = 20;
     */
    isLeaf = false;
    /**
     * Stores the ID of the ledger
     *
     * @generated from field: uint64 ledger_id = 21;
     */
    ledgerId = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the tax group
     *
     * @generated from field: uint64 tax_group_id = 23;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * Stores the unit price
     *
     * @generated from field: uint64 price = 26;
     */
    price = protobuf_1.protoInt64.zero;
    /**
     * Stores the minimum stock to maintain
     *
     * @generated from field: uint64 min_stock_to_maintain = 27;
     */
    minStockToMaintain = protobuf_1.protoInt64.zero;
    /**
     * Stores the consumption sequence
     *
     * @generated from field: Scailo.CONSUMPTION_SEQUENCE consumption_sequence = 28;
     */
    consumptionSequence = CONSUMPTION_SEQUENCE.CONSUMPTION_SEQUENCE_ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FamiliesServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "print_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "drawing_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 15, name: "family_type", kind: "enum", T: protobuf_1.proto3.getEnumType(FAMILY_TYPE) },
        { no: 16, name: "hsn_sac_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 17, name: "uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "unit_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 19, name: "parent_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "is_leaf", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 21, name: "ledger_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 26, name: "price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 27, name: "min_stock_to_maintain", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 28, name: "consumption_sequence", kind: "enum", T: protobuf_1.proto3.getEnumType(CONSUMPTION_SEQUENCE) },
    ]);
    static fromBinary(bytes, options) {
        return new FamiliesServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FamiliesServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FamiliesServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FamiliesServiceUpdateRequest, a, b);
    }
}
exports.FamiliesServiceUpdateRequest = FamiliesServiceUpdateRequest;
/**
 *
 * Describes the parameters that are part of a family structure
 *
 * @generated from message Scailo.Family
 */
class Family extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this family
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this family
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this purchase order
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The name of the family
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The name of the family that is to be used on prints
     *
     * @generated from field: string print_name = 11;
     */
    printName = "";
    /**
     * The unique code that represents the family
     *
     * @generated from field: string code = 12;
     */
    code = "";
    /**
     * The drawing number of the family
     *
     * @generated from field: string drawing_number = 13;
     */
    drawingNumber = "";
    /**
     * The description of the family
     *
     * @generated from field: string description = 14;
     */
    description = "";
    /**
     * The type of the family
     *
     * @generated from field: Scailo.FAMILY_TYPE family_type = 15;
     */
    familyType = FAMILY_TYPE.FAMILY_TYPE_ANY_UNSPECIFIED;
    /**
     * The HSN/SAC code of the family
     *
     * @generated from field: string hsn_sac_code = 16;
     */
    hsnSacCode = "";
    /**
     * Stores the ID of the associated unit of material
     *
     * @generated from field: uint64 uom_id = 17;
     */
    uomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the unit quantity
     *
     * @generated from field: uint64 unit_quantity = 18;
     */
    unitQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the parent family
     *
     * @generated from field: uint64 parent_id = 19;
     */
    parentId = protobuf_1.protoInt64.zero;
    /**
     * Stores if the family is a leaf family
     *
     * @generated from field: bool is_leaf = 20;
     */
    isLeaf = false;
    /**
     * Stores the ID of the ledger
     *
     * @generated from field: uint64 ledger_id = 21;
     */
    ledgerId = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the tax group
     *
     * @generated from field: uint64 tax_group_id = 23;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * Stores the unit price
     *
     * @generated from field: uint64 price = 26;
     */
    price = protobuf_1.protoInt64.zero;
    /**
     * Stores the minimum stock to maintain
     *
     * @generated from field: uint64 min_stock_to_maintain = 27;
     */
    minStockToMaintain = protobuf_1.protoInt64.zero;
    /**
     * Stores the consumption sequence
     *
     * @generated from field: Scailo.CONSUMPTION_SEQUENCE consumption_sequence = 29;
     */
    consumptionSequence = CONSUMPTION_SEQUENCE.CONSUMPTION_SEQUENCE_ANY_UNSPECIFIED;
    /**
     * The number of times that the family has been amended
     *
     * @generated from field: uint64 amendment_count = 40;
     */
    amendmentCount = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.Family";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "print_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "drawing_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 15, name: "family_type", kind: "enum", T: protobuf_1.proto3.getEnumType(FAMILY_TYPE) },
        { no: 16, name: "hsn_sac_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 17, name: "uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "unit_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 19, name: "parent_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "is_leaf", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 21, name: "ledger_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 26, name: "price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 27, name: "min_stock_to_maintain", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 29, name: "consumption_sequence", kind: "enum", T: protobuf_1.proto3.getEnumType(CONSUMPTION_SEQUENCE) },
        { no: 40, name: "amendment_count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new Family().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Family().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Family().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(Family, a, b);
    }
}
exports.Family = Family;
/**
 *
 * Describes the message consisting of the list of families
 *
 * @generated from message Scailo.FamiliesList
 */
class FamiliesList extends protobuf_1.Message {
    /**
     * List of families
     *
     * @generated from field: repeated Scailo.Family list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FamiliesList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: Family, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new FamiliesList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FamiliesList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FamiliesList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FamiliesList, a, b);
    }
}
exports.FamiliesList = FamiliesList;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.FamiliesServicePaginationReq
 */
class FamiliesServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.FAMILY_SORT_KEY sort_key = 5;
     */
    sortKey = FAMILY_SORT_KEY.FAMILY_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this family
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FamiliesServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(FAMILY_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new FamiliesServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FamiliesServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FamiliesServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FamiliesServicePaginationReq, a, b);
    }
}
exports.FamiliesServicePaginationReq = FamiliesServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.FamiliesServicePaginationResponse
 */
class FamiliesServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.Family payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FamiliesServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: Family, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new FamiliesServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FamiliesServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FamiliesServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FamiliesServicePaginationResponse, a, b);
    }
}
exports.FamiliesServicePaginationResponse = FamiliesServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.FamiliesServiceFilterReq
 */
class FamiliesServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.FAMILY_SORT_KEY sort_key = 5;
     */
    sortKey = FAMILY_SORT_KEY.FAMILY_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this family
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Filter with the given multiple statuses (if the list is not empty). All the records that match any of the statuses will be returned
     *
     * @generated from field: repeated Scailo.STANDARD_LIFECYCLE_STATUS multi_status = 100;
     */
    multiStatus = [];
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The name of the family
     *
     * @generated from field: string name = 20;
     */
    name = "";
    /**
     * The unique code that represents the family
     *
     * @generated from field: string code = 22;
     */
    code = "";
    /**
     * The drawing number of the family
     *
     * @generated from field: string drawing_number = 23;
     */
    drawingNumber = "";
    /**
     * The type of the family
     *
     * @generated from field: Scailo.FAMILY_TYPE family_type = 25;
     */
    familyType = FAMILY_TYPE.FAMILY_TYPE_ANY_UNSPECIFIED;
    /**
     * Filter from any of the given family types. All the records that match any of the family types will be returned
     *
     * @generated from field: repeated Scailo.FAMILY_TYPE multi_family_type = 250;
     */
    multiFamilyType = [];
    /**
     * The HSN/SAC code of the family
     *
     * @generated from field: string hsn_sac_code = 26;
     */
    hsnSacCode = "";
    /**
     * Stores the ID of the associated unit of material
     *
     * @generated from field: uint64 uom_id = 27;
     */
    uomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the unit quantity
     *
     * @generated from field: uint64 unit_quantity = 28;
     */
    unitQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the parent family
     *
     * @generated from field: uint64 parent_id = 29;
     */
    parentId = protobuf_1.protoInt64.zero;
    /**
     * Filter with the given leaf property
     *
     * @generated from field: Scailo.BOOL_FILTER is_leaf = 30;
     */
    isLeaf = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * Stores the ID of the ledger
     *
     * @generated from field: uint64 ledger_id = 31;
     */
    ledgerId = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the QC group
     *
     * @generated from field: uint64 qc_group_id = 32;
     */
    qcGroupId = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the tax group
     *
     * @generated from field: uint64 tax_group_id = 33;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * Stores the consumption sequence
     *
     * @generated from field: Scailo.CONSUMPTION_SEQUENCE consumption_sequence = 39;
     */
    consumptionSequence = CONSUMPTION_SEQUENCE.CONSUMPTION_SEQUENCE_ANY_UNSPECIFIED;
    /**
     * Filter by families that are present in the given parent storage ID (and all the corresponding child storage IDs)
     *
     * @generated from field: uint64 parent_storage_id = 50;
     */
    parentStorageId = protobuf_1.protoInt64.zero;
    /**
     * Filter by families that have the given label ID
     *
     * @generated from field: uint64 label_id = 60;
     */
    labelId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FamiliesServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(FAMILY_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 100, name: "multi_status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS), repeated: true },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "drawing_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 25, name: "family_type", kind: "enum", T: protobuf_1.proto3.getEnumType(FAMILY_TYPE) },
        { no: 250, name: "multi_family_type", kind: "enum", T: protobuf_1.proto3.getEnumType(FAMILY_TYPE), repeated: true },
        { no: 26, name: "hsn_sac_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 27, name: "uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 28, name: "unit_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 29, name: "parent_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "is_leaf", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 31, name: "ledger_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 32, name: "qc_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 33, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 39, name: "consumption_sequence", kind: "enum", T: protobuf_1.proto3.getEnumType(CONSUMPTION_SEQUENCE) },
        { no: 50, name: "parent_storage_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 60, name: "label_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new FamiliesServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FamiliesServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FamiliesServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FamiliesServiceFilterReq, a, b);
    }
}
exports.FamiliesServiceFilterReq = FamiliesServiceFilterReq;
/**
 *
 * Describes the request to count all families with the given criteria
 *
 * @generated from message Scailo.FamiliesServiceCountReq
 */
class FamiliesServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this family
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Filter with the given multiple statuses (if the list is not empty). All the records that match any of the statuses will be returned
     *
     * @generated from field: repeated Scailo.STANDARD_LIFECYCLE_STATUS multi_status = 100;
     */
    multiStatus = [];
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The name of the family
     *
     * @generated from field: string name = 20;
     */
    name = "";
    /**
     * The unique code that represents the family
     *
     * @generated from field: string code = 22;
     */
    code = "";
    /**
     * The drawing number of the family
     *
     * @generated from field: string drawing_number = 23;
     */
    drawingNumber = "";
    /**
     * The type of the family
     *
     * @generated from field: Scailo.FAMILY_TYPE family_type = 25;
     */
    familyType = FAMILY_TYPE.FAMILY_TYPE_ANY_UNSPECIFIED;
    /**
     * Filter from any of the given family types. All the records that match any of the family types will be returned
     *
     * @generated from field: repeated Scailo.FAMILY_TYPE multi_family_type = 250;
     */
    multiFamilyType = [];
    /**
     * The HSN/SAC code of the family
     *
     * @generated from field: string hsn_sac_code = 26;
     */
    hsnSacCode = "";
    /**
     * Stores the ID of the associated unit of material
     *
     * @generated from field: uint64 uom_id = 27;
     */
    uomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the unit quantity
     *
     * @generated from field: uint64 unit_quantity = 28;
     */
    unitQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the parent family
     *
     * @generated from field: uint64 parent_id = 29;
     */
    parentId = protobuf_1.protoInt64.zero;
    /**
     * Filter with the given leaf property
     *
     * @generated from field: Scailo.BOOL_FILTER is_leaf = 30;
     */
    isLeaf = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * Stores the ID of the ledger
     *
     * @generated from field: uint64 ledger_id = 31;
     */
    ledgerId = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the QC group
     *
     * @generated from field: uint64 qc_group_id = 32;
     */
    qcGroupId = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the tax group
     *
     * @generated from field: uint64 tax_group_id = 33;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * Stores the consumption sequence
     *
     * @generated from field: Scailo.CONSUMPTION_SEQUENCE consumption_sequence = 39;
     */
    consumptionSequence = CONSUMPTION_SEQUENCE.CONSUMPTION_SEQUENCE_ANY_UNSPECIFIED;
    /**
     * Filter by families that are present in the given parent storage ID (and all the corresponding child storage IDs)
     *
     * @generated from field: uint64 parent_storage_id = 50;
     */
    parentStorageId = protobuf_1.protoInt64.zero;
    /**
     * Filter by families that have the given label ID
     *
     * @generated from field: uint64 label_id = 60;
     */
    labelId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FamiliesServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 100, name: "multi_status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS), repeated: true },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "drawing_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 25, name: "family_type", kind: "enum", T: protobuf_1.proto3.getEnumType(FAMILY_TYPE) },
        { no: 250, name: "multi_family_type", kind: "enum", T: protobuf_1.proto3.getEnumType(FAMILY_TYPE), repeated: true },
        { no: 26, name: "hsn_sac_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 27, name: "uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 28, name: "unit_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 29, name: "parent_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "is_leaf", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 31, name: "ledger_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 32, name: "qc_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 33, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 39, name: "consumption_sequence", kind: "enum", T: protobuf_1.proto3.getEnumType(CONSUMPTION_SEQUENCE) },
        { no: 50, name: "parent_storage_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 60, name: "label_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new FamiliesServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FamiliesServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FamiliesServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FamiliesServiceCountReq, a, b);
    }
}
exports.FamiliesServiceCountReq = FamiliesServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.FamiliesServiceSearchAllReq
 */
class FamiliesServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.FAMILY_SORT_KEY sort_key = 5;
     */
    sortKey = FAMILY_SORT_KEY.FAMILY_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Filter with the given multiple statuses (if the list is not empty). All the records that match any of the statuses will be returned
     *
     * @generated from field: repeated Scailo.STANDARD_LIFECYCLE_STATUS multi_status = 100;
     */
    multiStatus = [];
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    /**
     * The type of the family
     *
     * @generated from field: Scailo.FAMILY_TYPE family_type = 25;
     */
    familyType = FAMILY_TYPE.FAMILY_TYPE_ANY_UNSPECIFIED;
    /**
     * Filter from any of the given family types. All the records that match any of the family types will be returned
     *
     * @generated from field: repeated Scailo.FAMILY_TYPE multi_family_type = 250;
     */
    multiFamilyType = [];
    /**
     * The ID of the associated non-leaf parent family
     *
     * @generated from field: uint64 parent_id = 29;
     */
    parentId = protobuf_1.protoInt64.zero;
    /**
     * Filter with the given leaf property
     *
     * @generated from field: Scailo.BOOL_FILTER is_leaf = 30;
     */
    isLeaf = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FamiliesServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(FAMILY_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 100, name: "multi_status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS), repeated: true },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 25, name: "family_type", kind: "enum", T: protobuf_1.proto3.getEnumType(FAMILY_TYPE) },
        { no: 250, name: "multi_family_type", kind: "enum", T: protobuf_1.proto3.getEnumType(FAMILY_TYPE), repeated: true },
        { no: 29, name: "parent_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "is_leaf", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
    ]);
    static fromBinary(bytes, options) {
        return new FamiliesServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FamiliesServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FamiliesServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FamiliesServiceSearchAllReq, a, b);
    }
}
exports.FamiliesServiceSearchAllReq = FamiliesServiceSearchAllReq;
/**
 *
 * Describes the parameters that are required to filter the prospective families for a record represented by the given UUID
 *
 * @generated from message Scailo.FilterFamiliesReqForIdentifier
 */
class FilterFamiliesReqForIdentifier extends protobuf_1.Message {
    /**
     * UUID of the resource
     *
     * @generated from field: string uuid = 1;
     */
    uuid = "";
    /**
     * The families filter to apply
     *
     * @generated from field: Scailo.FamiliesServiceFilterReq filter = 10;
     */
    filter;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FilterFamiliesReqForIdentifier";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "filter", kind: "message", T: FamiliesServiceFilterReq },
    ]);
    static fromBinary(bytes, options) {
        return new FilterFamiliesReqForIdentifier().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FilterFamiliesReqForIdentifier().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FilterFamiliesReqForIdentifier().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FilterFamiliesReqForIdentifier, a, b);
    }
}
exports.FilterFamiliesReqForIdentifier = FilterFamiliesReqForIdentifier;
/**
 *
 * Describes the parameters necessary to create a family label
 *
 * @generated from message Scailo.FamiliesServiceLabelCreateRequest
 */
class FamiliesServiceLabelCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 10;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Stores the label ID
     *
     * @generated from field: uint64 label_id = 11;
     */
    labelId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FamiliesServiceLabelCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "label_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new FamiliesServiceLabelCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FamiliesServiceLabelCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FamiliesServiceLabelCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FamiliesServiceLabelCreateRequest, a, b);
    }
}
exports.FamiliesServiceLabelCreateRequest = FamiliesServiceLabelCreateRequest;
/**
 *
 * Describes the parameters that constitute a family label
 *
 * @generated from message Scailo.FamilyLabel
 */
class FamilyLabel extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this family
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 10;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Stores the label ID
     *
     * @generated from field: uint64 label_id = 11;
     */
    labelId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FamilyLabel";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "label_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new FamilyLabel().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FamilyLabel().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FamilyLabel().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FamilyLabel, a, b);
    }
}
exports.FamilyLabel = FamilyLabel;
/**
 *
 * Describes the message consisting of the list of family labels
 *
 * @generated from message Scailo.FamilyLabelsList
 */
class FamilyLabelsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.FamilyLabel list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FamilyLabelsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: FamilyLabel, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new FamilyLabelsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FamilyLabelsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FamilyLabelsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FamilyLabelsList, a, b);
    }
}
exports.FamilyLabelsList = FamilyLabelsList;
/**
 *
 * Describes the parameters necessary to create a family storage
 *
 * @generated from message Scailo.FamiliesServiceStorageCreateRequest
 */
class FamiliesServiceStorageCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 10;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Stores the storage ID
     *
     * @generated from field: uint64 storage_id = 11;
     */
    storageId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FamiliesServiceStorageCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "storage_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new FamiliesServiceStorageCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FamiliesServiceStorageCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FamiliesServiceStorageCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FamiliesServiceStorageCreateRequest, a, b);
    }
}
exports.FamiliesServiceStorageCreateRequest = FamiliesServiceStorageCreateRequest;
/**
 *
 * Describes the parameters that constitute a family storage
 *
 * @generated from message Scailo.FamilyStorage
 */
class FamilyStorage extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this family
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 10;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Stores the storage ID
     *
     * @generated from field: uint64 storage_id = 11;
     */
    storageId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FamilyStorage";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "storage_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new FamilyStorage().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FamilyStorage().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FamilyStorage().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FamilyStorage, a, b);
    }
}
exports.FamilyStorage = FamilyStorage;
/**
 *
 * Describes the message consisting of the list of family storages
 *
 * @generated from message Scailo.FamilyStoragesList
 */
class FamilyStoragesList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.FamilyStorage list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FamilyStoragesList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: FamilyStorage, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new FamilyStoragesList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FamilyStoragesList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FamilyStoragesList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FamilyStoragesList, a, b);
    }
}
exports.FamilyStoragesList = FamilyStoragesList;
/**
 *
 * Describes the parameters necessary to check if a family unit conversion is available
 *
 * @generated from message Scailo.FamiliesServiceUnitConversionPresenceRequest
 */
class FamiliesServiceUnitConversionPresenceRequest extends protobuf_1.Message {
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 10;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Stores the unit of material ID
     *
     * @generated from field: uint64 uom_id = 11;
     */
    uomId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FamiliesServiceUnitConversionPresenceRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new FamiliesServiceUnitConversionPresenceRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FamiliesServiceUnitConversionPresenceRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FamiliesServiceUnitConversionPresenceRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FamiliesServiceUnitConversionPresenceRequest, a, b);
    }
}
exports.FamiliesServiceUnitConversionPresenceRequest = FamiliesServiceUnitConversionPresenceRequest;
/**
 *
 * Describes the parameters necessary to create a family unit conversion
 *
 * @generated from message Scailo.FamiliesServiceUnitConversionCreateRequest
 */
class FamiliesServiceUnitConversionCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 10;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Stores the unit of material ID
     *
     * @generated from field: uint64 uom_id = 11;
     */
    uomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the factor
     *
     * @generated from field: uint64 factor = 12;
     */
    factor = protobuf_1.protoInt64.zero;
    /**
     * Stores the divisor
     *
     * @generated from field: uint64 divisor = 13;
     */
    divisor = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FamiliesServiceUnitConversionCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "factor", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "divisor", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new FamiliesServiceUnitConversionCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FamiliesServiceUnitConversionCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FamiliesServiceUnitConversionCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FamiliesServiceUnitConversionCreateRequest, a, b);
    }
}
exports.FamiliesServiceUnitConversionCreateRequest = FamiliesServiceUnitConversionCreateRequest;
/**
 *
 * Describes the parameters that constitute a family unit conversion
 *
 * @generated from message Scailo.FamilyUnitConversion
 */
class FamilyUnitConversion extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this family
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 10;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Stores the unit of material ID
     *
     * @generated from field: uint64 uom_id = 11;
     */
    uomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the factor
     *
     * @generated from field: uint64 factor = 12;
     */
    factor = protobuf_1.protoInt64.zero;
    /**
     * Stores the divisor
     *
     * @generated from field: uint64 divisor = 13;
     */
    divisor = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FamilyUnitConversion";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "factor", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "divisor", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new FamilyUnitConversion().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FamilyUnitConversion().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FamilyUnitConversion().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FamilyUnitConversion, a, b);
    }
}
exports.FamilyUnitConversion = FamilyUnitConversion;
/**
 *
 * Describes the message consisting of the list of family unit conversions
 *
 * @generated from message Scailo.FamilyUnitConversionsList
 */
class FamilyUnitConversionsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.FamilyUnitConversion list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FamilyUnitConversionsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: FamilyUnitConversion, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new FamilyUnitConversionsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FamilyUnitConversionsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FamilyUnitConversionsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FamilyUnitConversionsList, a, b);
    }
}
exports.FamilyUnitConversionsList = FamilyUnitConversionsList;
/**
 *
 * Describes the parameters necessary to create a family qc group
 *
 * @generated from message Scailo.FamiliesServiceQCGroupCreateRequest
 */
class FamiliesServiceQCGroupCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 10;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Stores the qc group ID
     *
     * @generated from field: uint64 qc_group_id = 11;
     */
    qcGroupId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FamiliesServiceQCGroupCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "qc_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new FamiliesServiceQCGroupCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FamiliesServiceQCGroupCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FamiliesServiceQCGroupCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FamiliesServiceQCGroupCreateRequest, a, b);
    }
}
exports.FamiliesServiceQCGroupCreateRequest = FamiliesServiceQCGroupCreateRequest;
/**
 *
 * Describes the parameters that constitute a family qc group
 *
 * @generated from message Scailo.FamilyQCGroup
 */
class FamilyQCGroup extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this family
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 10;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Stores the qc group ID
     *
     * @generated from field: uint64 qc_group_id = 11;
     */
    qcGroupId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FamilyQCGroup";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "qc_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new FamilyQCGroup().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FamilyQCGroup().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FamilyQCGroup().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FamilyQCGroup, a, b);
    }
}
exports.FamilyQCGroup = FamilyQCGroup;
/**
 *
 * Describes the message consisting of the list of family qc groups
 *
 * @generated from message Scailo.FamilyQCGroupsList
 */
class FamilyQCGroupsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.FamilyQCGroup list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FamilyQCGroupsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: FamilyQCGroup, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new FamilyQCGroupsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FamilyQCGroupsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FamilyQCGroupsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FamilyQCGroupsList, a, b);
    }
}
exports.FamilyQCGroupsList = FamilyQCGroupsList;
/**
 *
 * Describes the parameters necessary to update a family's price
 *
 * @generated from message Scailo.FamiliesServiceUpdatePriceRequest
 */
class FamiliesServiceUpdatePriceRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The UUID of the family
     *
     * @generated from field: string uuid = 10;
     */
    uuid = "";
    /**
     * Stores the unit price
     *
     * @generated from field: uint64 price = 26;
     */
    price = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FamiliesServiceUpdatePriceRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 26, name: "price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new FamiliesServiceUpdatePriceRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FamiliesServiceUpdatePriceRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FamiliesServiceUpdatePriceRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FamiliesServiceUpdatePriceRequest, a, b);
    }
}
exports.FamiliesServiceUpdatePriceRequest = FamiliesServiceUpdatePriceRequest;
/**
 *
 * Describes the parameters necessary to update a family's min stock to maintain
 *
 * @generated from message Scailo.FamiliesServiceUpdateMinStockToMaintainRequest
 */
class FamiliesServiceUpdateMinStockToMaintainRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The UUID of the family
     *
     * @generated from field: string uuid = 10;
     */
    uuid = "";
    /**
     * Stores the minimum stock to maintain
     *
     * @generated from field: uint64 min_stock_to_maintain = 27;
     */
    minStockToMaintain = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.FamiliesServiceUpdateMinStockToMaintainRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 27, name: "min_stock_to_maintain", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new FamiliesServiceUpdateMinStockToMaintainRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new FamiliesServiceUpdateMinStockToMaintainRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new FamiliesServiceUpdateMinStockToMaintainRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(FamiliesServiceUpdateMinStockToMaintainRequest, a, b);
    }
}
exports.FamiliesServiceUpdateMinStockToMaintainRequest = FamiliesServiceUpdateMinStockToMaintainRequest;
