"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file clients.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientsService = void 0;
const clients_scailo_pb_js_1 = require("./clients.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
/**
 *
 * Describes the common methods applicable on each client
 *
 * @generated from service Scailo.ClientsService
 */
exports.ClientsService = {
    typeName: "Scailo.ClientsService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.ClientsService.Create
         */
        create: {
            name: "Create",
            I: clients_scailo_pb_js_1.ClientsServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.ClientsService.Draft
         */
        draft: {
            name: "Draft",
            I: clients_scailo_pb_js_1.ClientsServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.ClientsService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: clients_scailo_pb_js_1.ClientsServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.ClientsService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.ClientsService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.ClientsService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.ClientsService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.ClientsService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: clients_scailo_pb_js_1.ClientsServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.ClientsService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.ClientsService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.ClientsService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * Send Email
         * rpc SendEmail (Identifier) returns (IdentifierResponse);
         *
         * @generated from rpc Scailo.ClientsService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add a user
         *
         * @generated from rpc Scailo.ClientsService.AddClientUser
         */
        addClientUser: {
            name: "AddClientUser",
            I: clients_scailo_pb_js_1.ClientsServiceUserCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve a user
         *
         * @generated from rpc Scailo.ClientsService.ApproveClientUser
         */
        approveClientUser: {
            name: "ApproveClientUser",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete a user
         *
         * @generated from rpc Scailo.ClientsService.DeleteClientUser
         */
        deleteClientUser: {
            name: "DeleteClientUser",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View a user for the given ID
         *
         * @generated from rpc Scailo.ClientsService.ViewClientUserByID
         */
        viewClientUserByID: {
            name: "ViewClientUserByID",
            I: base_scailo_pb_js_1.Identifier,
            O: clients_scailo_pb_js_1.ClientUser,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all users for given client ID
         *
         * @generated from rpc Scailo.ClientsService.ViewClientUsers
         */
        viewClientUsers: {
            name: "ViewClientUsers",
            I: base_scailo_pb_js_1.Identifier,
            O: clients_scailo_pb_js_1.ClientUsersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through client users with pagination
         *
         * @generated from rpc Scailo.ClientsService.SearchClientUsersWithPagination
         */
        searchClientUsersWithPagination: {
            name: "SearchClientUsersWithPagination",
            I: clients_scailo_pb_js_1.ClientUsersSearchRequest,
            O: clients_scailo_pb_js_1.ClientsServicePaginatedUsersResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.ClientsService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: clients_scailo_pb_js_1.Client,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by UUID
         *
         * @generated from rpc Scailo.ClientsService.ViewByUUID
         */
        viewByUUID: {
            name: "ViewByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: clients_scailo_pb_js_1.Client,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View only essential components by ID (without logs)
         *
         * @generated from rpc Scailo.ClientsService.ViewEssentialByID
         */
        viewEssentialByID: {
            name: "ViewEssentialByID",
            I: base_scailo_pb_js_1.Identifier,
            O: clients_scailo_pb_js_1.Client,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View only essential components (without logs) that matches the given UUID
         *
         * @generated from rpc Scailo.ClientsService.ViewEssentialByUUID
         */
        viewEssentialByUUID: {
            name: "ViewEssentialByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: clients_scailo_pb_js_1.Client,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all records with the given IDs
         *
         * @generated from rpc Scailo.ClientsService.ViewFromIDs
         */
        viewFromIDs: {
            name: "ViewFromIDs",
            I: base_scailo_pb_js_1.IdentifiersList,
            O: clients_scailo_pb_js_1.ClientsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.ClientsService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: clients_scailo_pb_js_1.ClientsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.ClientsService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: clients_scailo_pb_js_1.ClientsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.ClientsService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: clients_scailo_pb_js_1.ClientsServicePaginationReq,
            O: clients_scailo_pb_js_1.ClientsServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.ClientsService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: clients_scailo_pb_js_1.ClientsServiceSearchAllReq,
            O: clients_scailo_pb_js_1.ClientsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.ClientsService.Filter
         */
        filter: {
            name: "Filter",
            I: clients_scailo_pb_js_1.ClientsServiceFilterReq,
            O: clients_scailo_pb_js_1.ClientsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.ClientsService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given count criteria
         *
         * @generated from rpc Scailo.ClientsService.Count
         */
        count: {
            name: "Count",
            I: clients_scailo_pb_js_1.ClientsServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * CSV operations
         * Download the CSV file that consists of the list of records according to the given filter request. The same file could also be used as a template for uploading records
         *
         * @generated from rpc Scailo.ClientsService.DownloadAsCSV
         */
        downloadAsCSV: {
            name: "DownloadAsCSV",
            I: clients_scailo_pb_js_1.ClientsServiceFilterReq,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Import records using a CSV file (duplicate codes will be skipped)
         *
         * @generated from rpc Scailo.ClientsService.ImportFromCSV
         */
        importFromCSV: {
            name: "ImportFromCSV",
            I: base_scailo_pb_js_1.StandardFile,
            O: base_scailo_pb_js_1.IdentifierUUIDsList,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
