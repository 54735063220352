"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file equipments.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.EquipmentsService = void 0;
const equipments_scailo_pb_js_1 = require("./equipments.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
/**
 *
 * Describes the common methods applicable on each equipment
 *
 * @generated from service Scailo.EquipmentsService
 */
exports.EquipmentsService = {
    typeName: "Scailo.EquipmentsService",
    methods: {
        /**
         * Create (and Send for QC)
         *
         * @generated from rpc Scailo.EquipmentsService.Create
         */
        create: {
            name: "Create",
            I: equipments_scailo_pb_js_1.EquipmentsServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send to Store
         *
         * @generated from rpc Scailo.EquipmentsService.SendToStore
         */
        sendToStore: {
            name: "SendToStore",
            I: equipments_scailo_pb_js_1.EquipmentsServiceSendToStoreRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update
         *
         * @generated from rpc Scailo.EquipmentsService.Update
         */
        update: {
            name: "Update",
            I: equipments_scailo_pb_js_1.EquipmentsServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for Rework
         *
         * @generated from rpc Scailo.EquipmentsService.SendForRework
         */
        sendForRework: {
            name: "SendForRework",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for QC
         *
         * @generated from rpc Scailo.EquipmentsService.SendForQC
         */
        sendForQC: {
            name: "SendForQC",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Split into unit quantities
         *
         * @generated from rpc Scailo.EquipmentsService.SplitLot
         */
        splitLot: {
            name: "SplitLot",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create a partition from the parent lot (and returns the identifier of the new partition)
         *
         * @generated from rpc Scailo.EquipmentsService.Partition
         */
        partition: {
            name: "Partition",
            I: base_scailo_pb_js_1.InventoryPartitionRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Consume
         *
         * @generated from rpc Scailo.EquipmentsService.Consume
         */
        consume: {
            name: "Consume",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reject
         *
         * @generated from rpc Scailo.EquipmentsService.Reject
         */
        reject: {
            name: "Reject",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Scrap
         *
         * @generated from rpc Scailo.EquipmentsService.Scrap
         */
        scrap: {
            name: "Scrap",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Return
         *
         * @generated from rpc Scailo.EquipmentsService.ReturnMaterial
         */
        returnMaterial: {
            name: "ReturnMaterial",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.EquipmentsService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * @generated from rpc Scailo.EquipmentsService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.EquipmentsService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: equipments_scailo_pb_js_1.Equipment,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by UUID
         *
         * @generated from rpc Scailo.EquipmentsService.ViewByUUID
         */
        viewByUUID: {
            name: "ViewByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: equipments_scailo_pb_js_1.Equipment,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View only essential components by ID (without logs)
         *
         * @generated from rpc Scailo.EquipmentsService.ViewEssentialByID
         */
        viewEssentialByID: {
            name: "ViewEssentialByID",
            I: base_scailo_pb_js_1.Identifier,
            O: equipments_scailo_pb_js_1.Equipment,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View only essential components (without logs) that matches the given UUID
         *
         * @generated from rpc Scailo.EquipmentsService.ViewEssentialByUUID
         */
        viewEssentialByUUID: {
            name: "ViewEssentialByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: equipments_scailo_pb_js_1.Equipment,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all records with the given IDs
         *
         * @generated from rpc Scailo.EquipmentsService.ViewFromIDs
         */
        viewFromIDs: {
            name: "ViewFromIDs",
            I: base_scailo_pb_js_1.IdentifiersList,
            O: equipments_scailo_pb_js_1.EquipmentsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.EquipmentsService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: equipments_scailo_pb_js_1.EquipmentsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.EquipmentsService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: equipments_scailo_pb_js_1.EquipmentsServicePaginationReq,
            O: equipments_scailo_pb_js_1.EquipmentsServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download QC Report with the given Identifier
         *
         * @generated from rpc Scailo.EquipmentsService.DownloadQCReportByID
         */
        downloadQCReportByID: {
            name: "DownloadQCReportByID",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download QC Report with the given IdentifierUUID (can be used to allow public downloads)
         *
         * @generated from rpc Scailo.EquipmentsService.DownloadQCReportByUUID
         */
        downloadQCReportByUUID: {
            name: "DownloadQCReportByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download Label for the given Identifier
         *
         * @generated from rpc Scailo.EquipmentsService.DownloadLabelByID
         */
        downloadLabelByID: {
            name: "DownloadLabelByID",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download Label for the given IdentifierUUID (can be used to allow public downloads)
         *
         * @generated from rpc Scailo.EquipmentsService.DownloadLabelByUUID
         */
        downloadLabelByUUID: {
            name: "DownloadLabelByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all inventory interactions for equipment with the given IdentifierUUID
         *
         * @generated from rpc Scailo.EquipmentsService.ViewInventoryInteractions
         */
        viewInventoryInteractions: {
            name: "ViewInventoryInteractions",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.InventoryInteractionsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.EquipmentsService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: equipments_scailo_pb_js_1.EquipmentsServiceSearchAllReq,
            O: equipments_scailo_pb_js_1.EquipmentsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.EquipmentsService.Filter
         */
        filter: {
            name: "Filter",
            I: equipments_scailo_pb_js_1.EquipmentsServiceFilterReq,
            O: equipments_scailo_pb_js_1.EquipmentsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.EquipmentsService.Count
         */
        count: {
            name: "Count",
            I: equipments_scailo_pb_js_1.EquipmentsServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
