"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file meetings.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.MeetingsService = void 0;
const meetings_scailo_pb_js_1 = require("./meetings.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
/**
 *
 * Describes the common methods applicable on each meeting
 *
 * @generated from service Scailo.MeetingsService
 */
exports.MeetingsService = {
    typeName: "Scailo.MeetingsService",
    methods: {
        /**
         * Create
         *
         * @generated from rpc Scailo.MeetingsService.Create
         */
        create: {
            name: "Create",
            I: meetings_scailo_pb_js_1.MeetingsServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update
         *
         * @generated from rpc Scailo.MeetingsService.Update
         */
        update: {
            name: "Update",
            I: meetings_scailo_pb_js_1.MeetingsServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Cancel
         *
         * @generated from rpc Scailo.MeetingsService.Cancel
         */
        cancel: {
            name: "Cancel",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.MeetingsService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.MeetingsService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * @generated from rpc Scailo.MeetingsService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send Email
         *
         * @generated from rpc Scailo.MeetingsService.SendEmail
         */
        sendEmail: {
            name: "SendEmail",
            I: base_scailo_pb_js_1.IdentifierWithEmailAttributes,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Request RSVPs from all the participants
         *
         * @generated from rpc Scailo.MeetingsService.RequestRSVP
         */
        requestRSVP: {
            name: "RequestRSVP",
            I: base_scailo_pb_js_1.IdentifierWithEmailAttributes,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send all the actionables in an email
         *
         * @generated from rpc Scailo.MeetingsService.SendActionables
         */
        sendActionables: {
            name: "SendActionables",
            I: base_scailo_pb_js_1.IdentifierWithEmailAttributes,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an actionable to a meeting
         *
         * @generated from rpc Scailo.MeetingsService.AddActionable
         */
        addActionable: {
            name: "AddActionable",
            I: meetings_scailo_pb_js_1.MeetingsServiceActionableCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify an actionable in a meeting
         *
         * @generated from rpc Scailo.MeetingsService.ModifyActionable
         */
        modifyActionable: {
            name: "ModifyActionable",
            I: meetings_scailo_pb_js_1.MeetingsServiceActionableUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an actionable in a meeting
         *
         * @generated from rpc Scailo.MeetingsService.DeleteActionable
         */
        deleteActionable: {
            name: "DeleteActionable",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder actionables in a meeting
         *
         * @generated from rpc Scailo.MeetingsService.ReorderActionables
         */
        reorderActionables: {
            name: "ReorderActionables",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Meeting Actionable by ID
         *
         * @generated from rpc Scailo.MeetingsService.ViewActionableByID
         */
        viewActionableByID: {
            name: "ViewActionableByID",
            I: base_scailo_pb_js_1.Identifier,
            O: meetings_scailo_pb_js_1.MeetingActionable,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all actionables for given meeting ID
         *
         * @generated from rpc Scailo.MeetingsService.ViewActionables
         */
        viewActionables: {
            name: "ViewActionables",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: meetings_scailo_pb_js_1.MeetingActionablesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the meeting actionable
         *
         * @generated from rpc Scailo.MeetingsService.ViewActionableHistory
         */
        viewActionableHistory: {
            name: "ViewActionableHistory",
            I: meetings_scailo_pb_js_1.MeetingActionableHistoryRequest,
            O: meetings_scailo_pb_js_1.MeetingActionablesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View meeting actionables for given meeting ID with pagination
         *
         * @generated from rpc Scailo.MeetingsService.ViewPaginatedActionables
         */
        viewPaginatedActionables: {
            name: "ViewPaginatedActionables",
            I: meetings_scailo_pb_js_1.MeetingActionablesSearchRequest,
            O: meetings_scailo_pb_js_1.MeetingsServicePaginatedActionablesResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through meeting actionables with pagination
         *
         * @generated from rpc Scailo.MeetingsService.SearchActionablesWithPagination
         */
        searchActionablesWithPagination: {
            name: "SearchActionablesWithPagination",
            I: meetings_scailo_pb_js_1.MeetingActionablesSearchRequest,
            O: meetings_scailo_pb_js_1.MeetingsServicePaginatedActionablesResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an employee
         *
         * @generated from rpc Scailo.MeetingsService.AddEmployee
         */
        addEmployee: {
            name: "AddEmployee",
            I: meetings_scailo_pb_js_1.MeetingsServiceEmployeeCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an employee
         *
         * @generated from rpc Scailo.MeetingsService.DeleteEmployee
         */
        deleteEmployee: {
            name: "DeleteEmployee",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View an employee for the given ID
         *
         * @generated from rpc Scailo.MeetingsService.ViewEmployeeByID
         */
        viewEmployeeByID: {
            name: "ViewEmployeeByID",
            I: base_scailo_pb_js_1.Identifier,
            O: meetings_scailo_pb_js_1.MeetingEmployee,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all employees for given meeting ID
         *
         * @generated from rpc Scailo.MeetingsService.ViewEmployees
         */
        viewEmployees: {
            name: "ViewEmployees",
            I: base_scailo_pb_js_1.Identifier,
            O: meetings_scailo_pb_js_1.MeetingEmployeesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Set the RSVP of the employee with the given meeting employee UUID
         *
         * @generated from rpc Scailo.MeetingsService.SetEmployeeRSVP
         */
        setEmployeeRSVP: {
            name: "SetEmployeeRSVP",
            I: meetings_scailo_pb_js_1.MeetingsServiceSetRSVPRequest,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Import employees from team and returns the ID of the meeting
         *
         * @generated from rpc Scailo.MeetingsService.ImportFromTeam
         */
        importFromTeam: {
            name: "ImportFromTeam",
            I: meetings_scailo_pb_js_1.MeetingsServiceImportEmployeesRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Import employees from department and returns the ID of the meeting
         *
         * @generated from rpc Scailo.MeetingsService.ImportFromDepartment
         */
        importFromDepartment: {
            name: "ImportFromDepartment",
            I: meetings_scailo_pb_js_1.MeetingsServiceImportEmployeesRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an associate
         *
         * @generated from rpc Scailo.MeetingsService.AddAssociate
         */
        addAssociate: {
            name: "AddAssociate",
            I: meetings_scailo_pb_js_1.MeetingsServiceAssociateCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an associate
         *
         * @generated from rpc Scailo.MeetingsService.DeleteAssociate
         */
        deleteAssociate: {
            name: "DeleteAssociate",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View an associate for the given ID
         *
         * @generated from rpc Scailo.MeetingsService.ViewAssociateByID
         */
        viewAssociateByID: {
            name: "ViewAssociateByID",
            I: base_scailo_pb_js_1.Identifier,
            O: meetings_scailo_pb_js_1.MeetingAssociate,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all associates for given meeting ID
         *
         * @generated from rpc Scailo.MeetingsService.ViewAssociates
         */
        viewAssociates: {
            name: "ViewAssociates",
            I: base_scailo_pb_js_1.Identifier,
            O: meetings_scailo_pb_js_1.MeetingAssociatesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Set the RSVP of the associate with the given meeting associate UUID
         *
         * @generated from rpc Scailo.MeetingsService.SetAssociateRSVP
         */
        setAssociateRSVP: {
            name: "SetAssociateRSVP",
            I: meetings_scailo_pb_js_1.MeetingsServiceSetRSVPRequest,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.MeetingsService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: meetings_scailo_pb_js_1.Meeting,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by UUID
         *
         * @generated from rpc Scailo.MeetingsService.ViewByUUID
         */
        viewByUUID: {
            name: "ViewByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: meetings_scailo_pb_js_1.Meeting,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View only essential components by ID (without logs)
         *
         * @generated from rpc Scailo.MeetingsService.ViewEssentialByID
         */
        viewEssentialByID: {
            name: "ViewEssentialByID",
            I: base_scailo_pb_js_1.Identifier,
            O: meetings_scailo_pb_js_1.Meeting,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View only essential components (without logs) that matches the given UUID
         *
         * @generated from rpc Scailo.MeetingsService.ViewEssentialByUUID
         */
        viewEssentialByUUID: {
            name: "ViewEssentialByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: meetings_scailo_pb_js_1.Meeting,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all records with the given IDs
         *
         * @generated from rpc Scailo.MeetingsService.ViewFromIDs
         */
        viewFromIDs: {
            name: "ViewFromIDs",
            I: base_scailo_pb_js_1.IdentifiersList,
            O: meetings_scailo_pb_js_1.MeetingsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.MeetingsService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: meetings_scailo_pb_js_1.MeetingsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.MeetingsService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: meetings_scailo_pb_js_1.MeetingsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.MeetingsService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: meetings_scailo_pb_js_1.MeetingsServicePaginationReq,
            O: meetings_scailo_pb_js_1.MeetingsServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.MeetingsService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: meetings_scailo_pb_js_1.MeetingsServiceSearchAllReq,
            O: meetings_scailo_pb_js_1.MeetingsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.MeetingsService.Filter
         */
        filter: {
            name: "Filter",
            I: meetings_scailo_pb_js_1.MeetingsServiceFilterReq,
            O: meetings_scailo_pb_js_1.MeetingsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.MeetingsService.Count
         */
        count: {
            name: "Count",
            I: meetings_scailo_pb_js_1.MeetingsServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
