"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file sales_orders.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.SalesOrderInventoryMatchList = exports.SalesOrderInventoryMatch = exports.SalesOrderBillingStatistics = exports.SalesOrderInventoryStatistics = exports.SalesOrderContactsList = exports.SalesOrderContact = exports.SalesOrdersServiceContactCreateRequest = exports.SalesOrdersServicePaginatedItemsResponse = exports.SalesOrderItemsSearchRequest = exports.SalesOrderReferencesList = exports.SalesOrderReference = exports.SalesOrdersServiceReferenceCreateRequest = exports.SalesOrdersServiceSearchAllReq = exports.SalesOrdersServiceCountReq = exports.SalesOrdersServiceFilterReq = exports.SalesOrdersServicePaginationResponse = exports.SalesOrdersServicePaginationReq = exports.SalesOrderItemProspectiveInfoRequest = exports.SalesOrderItemHistoryRequest = exports.SalesOrderItemsList = exports.SalesOrdersList = exports.SalesOrderItem = exports.SalesOrdersServiceItemUpdateRequest = exports.SalesOrdersServiceItemCreateRequest = exports.SalesOrder = exports.SalesOrderAncillaryParameters = exports.SalesOrdersServiceAutofillRequest = exports.SalesOrdersServiceUpdateRequest = exports.SalesOrdersServiceCreateRequest = exports.SALES_ORDER_ITEM_STATUS = exports.SALES_ORDER_ITEM_SORT_KEY = exports.SALES_ORDER_REFERENCE_REF_FROM = exports.SALES_ORDER_REFERENCE_CONTEXT = exports.SALES_ORDER_SORT_KEY = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const forms_fields_data_scailo_pb_js_1 = require("./forms_fields_data.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.SALES_ORDER_SORT_KEY
 */
var SALES_ORDER_SORT_KEY;
(function (SALES_ORDER_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: SALES_ORDER_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    SALES_ORDER_SORT_KEY[SALES_ORDER_SORT_KEY["SALES_ORDER_SORT_KEY_ID_UNSPECIFIED"] = 0] = "SALES_ORDER_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: SALES_ORDER_SORT_KEY_CREATED_AT = 1;
     */
    SALES_ORDER_SORT_KEY[SALES_ORDER_SORT_KEY["SALES_ORDER_SORT_KEY_CREATED_AT"] = 1] = "SALES_ORDER_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: SALES_ORDER_SORT_KEY_MODIFIED_AT = 2;
     */
    SALES_ORDER_SORT_KEY[SALES_ORDER_SORT_KEY["SALES_ORDER_SORT_KEY_MODIFIED_AT"] = 2] = "SALES_ORDER_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: SALES_ORDER_SORT_KEY_APPROVED_ON = 3;
     */
    SALES_ORDER_SORT_KEY[SALES_ORDER_SORT_KEY["SALES_ORDER_SORT_KEY_APPROVED_ON"] = 3] = "SALES_ORDER_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: SALES_ORDER_SORT_KEY_APPROVED_BY = 4;
     */
    SALES_ORDER_SORT_KEY[SALES_ORDER_SORT_KEY["SALES_ORDER_SORT_KEY_APPROVED_BY"] = 4] = "SALES_ORDER_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: SALES_ORDER_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    SALES_ORDER_SORT_KEY[SALES_ORDER_SORT_KEY["SALES_ORDER_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "SALES_ORDER_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the approver's completed on timestamp
     *
     * @generated from enum value: SALES_ORDER_SORT_KEY_COMPLETED_ON = 6;
     */
    SALES_ORDER_SORT_KEY[SALES_ORDER_SORT_KEY["SALES_ORDER_SORT_KEY_COMPLETED_ON"] = 6] = "SALES_ORDER_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch ordered results by the reference ID
     *
     * @generated from enum value: SALES_ORDER_SORT_KEY_REFERENCE_ID = 10;
     */
    SALES_ORDER_SORT_KEY[SALES_ORDER_SORT_KEY["SALES_ORDER_SORT_KEY_REFERENCE_ID"] = 10] = "SALES_ORDER_SORT_KEY_REFERENCE_ID";
    /**
     * Fetch ordered results by the final ref number
     *
     * @generated from enum value: SALES_ORDER_SORT_KEY_FINAL_REF_NUMBER = 11;
     */
    SALES_ORDER_SORT_KEY[SALES_ORDER_SORT_KEY["SALES_ORDER_SORT_KEY_FINAL_REF_NUMBER"] = 11] = "SALES_ORDER_SORT_KEY_FINAL_REF_NUMBER";
    /**
     * Fetch ordered results by the consignee client ID
     *
     * @generated from enum value: SALES_ORDER_SORT_KEY_CONSIGNEE_CLIENT_ID = 12;
     */
    SALES_ORDER_SORT_KEY[SALES_ORDER_SORT_KEY["SALES_ORDER_SORT_KEY_CONSIGNEE_CLIENT_ID"] = 12] = "SALES_ORDER_SORT_KEY_CONSIGNEE_CLIENT_ID";
    /**
     * Fetch ordered results by the buyer client ID
     *
     * @generated from enum value: SALES_ORDER_SORT_KEY_BUYER_CLIENT_ID = 13;
     */
    SALES_ORDER_SORT_KEY[SALES_ORDER_SORT_KEY["SALES_ORDER_SORT_KEY_BUYER_CLIENT_ID"] = 13] = "SALES_ORDER_SORT_KEY_BUYER_CLIENT_ID";
    /**
     * Fetch ordered results by the location ID
     *
     * @generated from enum value: SALES_ORDER_SORT_KEY_LOCATION_ID = 14;
     */
    SALES_ORDER_SORT_KEY[SALES_ORDER_SORT_KEY["SALES_ORDER_SORT_KEY_LOCATION_ID"] = 14] = "SALES_ORDER_SORT_KEY_LOCATION_ID";
    /**
     * Fetch ordered results by the currency ID
     *
     * @generated from enum value: SALES_ORDER_SORT_KEY_CURRENCY_ID = 15;
     */
    SALES_ORDER_SORT_KEY[SALES_ORDER_SORT_KEY["SALES_ORDER_SORT_KEY_CURRENCY_ID"] = 15] = "SALES_ORDER_SORT_KEY_CURRENCY_ID";
    /**
     * Fetch ordered results by the project ID
     *
     * @generated from enum value: SALES_ORDER_SORT_KEY_PROJECT_ID = 16;
     */
    SALES_ORDER_SORT_KEY[SALES_ORDER_SORT_KEY["SALES_ORDER_SORT_KEY_PROJECT_ID"] = 16] = "SALES_ORDER_SORT_KEY_PROJECT_ID";
    /**
     * Fetch ordered results by the advance payment
     *
     * @generated from enum value: SALES_ORDER_SORT_KEY_PAYMENT_ADVANCE = 17;
     */
    SALES_ORDER_SORT_KEY[SALES_ORDER_SORT_KEY["SALES_ORDER_SORT_KEY_PAYMENT_ADVANCE"] = 17] = "SALES_ORDER_SORT_KEY_PAYMENT_ADVANCE";
    /**
     * Fetch ordered results by the amendment count
     *
     * @generated from enum value: SALES_ORDER_SORT_KEY_AMENDMENT_COUNT = 18;
     */
    SALES_ORDER_SORT_KEY[SALES_ORDER_SORT_KEY["SALES_ORDER_SORT_KEY_AMENDMENT_COUNT"] = 18] = "SALES_ORDER_SORT_KEY_AMENDMENT_COUNT";
    /**
     * Fetch ordered results by the total value
     *
     * @generated from enum value: SALES_ORDER_SORT_KEY_TOTAL_VALUE = 30;
     */
    SALES_ORDER_SORT_KEY[SALES_ORDER_SORT_KEY["SALES_ORDER_SORT_KEY_TOTAL_VALUE"] = 30] = "SALES_ORDER_SORT_KEY_TOTAL_VALUE";
})(SALES_ORDER_SORT_KEY || (exports.SALES_ORDER_SORT_KEY = SALES_ORDER_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(SALES_ORDER_SORT_KEY)
protobuf_1.proto3.util.setEnumType(SALES_ORDER_SORT_KEY, "Scailo.SALES_ORDER_SORT_KEY", [
    { no: 0, name: "SALES_ORDER_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "SALES_ORDER_SORT_KEY_CREATED_AT" },
    { no: 2, name: "SALES_ORDER_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "SALES_ORDER_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "SALES_ORDER_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "SALES_ORDER_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 6, name: "SALES_ORDER_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "SALES_ORDER_SORT_KEY_REFERENCE_ID" },
    { no: 11, name: "SALES_ORDER_SORT_KEY_FINAL_REF_NUMBER" },
    { no: 12, name: "SALES_ORDER_SORT_KEY_CONSIGNEE_CLIENT_ID" },
    { no: 13, name: "SALES_ORDER_SORT_KEY_BUYER_CLIENT_ID" },
    { no: 14, name: "SALES_ORDER_SORT_KEY_LOCATION_ID" },
    { no: 15, name: "SALES_ORDER_SORT_KEY_CURRENCY_ID" },
    { no: 16, name: "SALES_ORDER_SORT_KEY_PROJECT_ID" },
    { no: 17, name: "SALES_ORDER_SORT_KEY_PAYMENT_ADVANCE" },
    { no: 18, name: "SALES_ORDER_SORT_KEY_AMENDMENT_COUNT" },
    { no: 30, name: "SALES_ORDER_SORT_KEY_TOTAL_VALUE" },
]);
/**
 *
 * Describes the sales order reference context
 *
 * @generated from enum Scailo.SALES_ORDER_REFERENCE_CONTEXT
 */
var SALES_ORDER_REFERENCE_CONTEXT;
(function (SALES_ORDER_REFERENCE_CONTEXT) {
    /**
     * Denotes that context be disregarded. This is used only within search APIs
     *
     * @generated from enum value: SALES_ORDER_REFERENCE_CONTEXT_ANY_UNSPECIFIED = 0;
     */
    SALES_ORDER_REFERENCE_CONTEXT[SALES_ORDER_REFERENCE_CONTEXT["SALES_ORDER_REFERENCE_CONTEXT_ANY_UNSPECIFIED"] = 0] = "SALES_ORDER_REFERENCE_CONTEXT_ANY_UNSPECIFIED";
    /**
     * Denotes billing context
     *
     * @generated from enum value: SALES_ORDER_REFERENCE_CONTEXT_BILLING = 1;
     */
    SALES_ORDER_REFERENCE_CONTEXT[SALES_ORDER_REFERENCE_CONTEXT["SALES_ORDER_REFERENCE_CONTEXT_BILLING"] = 1] = "SALES_ORDER_REFERENCE_CONTEXT_BILLING";
})(SALES_ORDER_REFERENCE_CONTEXT || (exports.SALES_ORDER_REFERENCE_CONTEXT = SALES_ORDER_REFERENCE_CONTEXT = {}));
// Retrieve enum metadata with: proto3.getEnumType(SALES_ORDER_REFERENCE_CONTEXT)
protobuf_1.proto3.util.setEnumType(SALES_ORDER_REFERENCE_CONTEXT, "Scailo.SALES_ORDER_REFERENCE_CONTEXT", [
    { no: 0, name: "SALES_ORDER_REFERENCE_CONTEXT_ANY_UNSPECIFIED" },
    { no: 1, name: "SALES_ORDER_REFERENCE_CONTEXT_BILLING" },
]);
/**
 *
 * Describes the possible options for ref_from field for a sales order reference
 *
 * @generated from enum Scailo.SALES_ORDER_REFERENCE_REF_FROM
 */
var SALES_ORDER_REFERENCE_REF_FROM;
(function (SALES_ORDER_REFERENCE_REF_FROM) {
    /**
     * Denotes that the ref from be disregarded. This is used only within search APIs
     *
     * @generated from enum value: SALES_ORDER_REFERENCE_REF_FROM_ANY_UNSPECIFIED = 0;
     */
    SALES_ORDER_REFERENCE_REF_FROM[SALES_ORDER_REFERENCE_REF_FROM["SALES_ORDER_REFERENCE_REF_FROM_ANY_UNSPECIFIED"] = 0] = "SALES_ORDER_REFERENCE_REF_FROM_ANY_UNSPECIFIED";
    /**
     * Denotes inward job
     *
     * @generated from enum value: SALES_ORDER_REFERENCE_REF_FROM_INWARD_JOB = 1;
     */
    SALES_ORDER_REFERENCE_REF_FROM[SALES_ORDER_REFERENCE_REF_FROM["SALES_ORDER_REFERENCE_REF_FROM_INWARD_JOB"] = 1] = "SALES_ORDER_REFERENCE_REF_FROM_INWARD_JOB";
    /**
     * Denotes sales enquiry
     *
     * @generated from enum value: SALES_ORDER_REFERENCE_REF_FROM_SALES_ENQUIRY = 2;
     */
    SALES_ORDER_REFERENCE_REF_FROM[SALES_ORDER_REFERENCE_REF_FROM["SALES_ORDER_REFERENCE_REF_FROM_SALES_ENQUIRY"] = 2] = "SALES_ORDER_REFERENCE_REF_FROM_SALES_ENQUIRY";
    /**
     * Denotes sales quotation
     *
     * @generated from enum value: SALES_ORDER_REFERENCE_REF_FROM_SALES_QUOTATION = 3;
     */
    SALES_ORDER_REFERENCE_REF_FROM[SALES_ORDER_REFERENCE_REF_FROM["SALES_ORDER_REFERENCE_REF_FROM_SALES_QUOTATION"] = 3] = "SALES_ORDER_REFERENCE_REF_FROM_SALES_QUOTATION";
})(SALES_ORDER_REFERENCE_REF_FROM || (exports.SALES_ORDER_REFERENCE_REF_FROM = SALES_ORDER_REFERENCE_REF_FROM = {}));
// Retrieve enum metadata with: proto3.getEnumType(SALES_ORDER_REFERENCE_REF_FROM)
protobuf_1.proto3.util.setEnumType(SALES_ORDER_REFERENCE_REF_FROM, "Scailo.SALES_ORDER_REFERENCE_REF_FROM", [
    { no: 0, name: "SALES_ORDER_REFERENCE_REF_FROM_ANY_UNSPECIFIED" },
    { no: 1, name: "SALES_ORDER_REFERENCE_REF_FROM_INWARD_JOB" },
    { no: 2, name: "SALES_ORDER_REFERENCE_REF_FROM_SALES_ENQUIRY" },
    { no: 3, name: "SALES_ORDER_REFERENCE_REF_FROM_SALES_QUOTATION" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.SALES_ORDER_ITEM_SORT_KEY
 */
var SALES_ORDER_ITEM_SORT_KEY;
(function (SALES_ORDER_ITEM_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: SALES_ORDER_ITEM_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    SALES_ORDER_ITEM_SORT_KEY[SALES_ORDER_ITEM_SORT_KEY["SALES_ORDER_ITEM_SORT_KEY_ID_UNSPECIFIED"] = 0] = "SALES_ORDER_ITEM_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: SALES_ORDER_ITEM_SORT_KEY_CREATED_AT = 1;
     */
    SALES_ORDER_ITEM_SORT_KEY[SALES_ORDER_ITEM_SORT_KEY["SALES_ORDER_ITEM_SORT_KEY_CREATED_AT"] = 1] = "SALES_ORDER_ITEM_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: SALES_ORDER_ITEM_SORT_KEY_MODIFIED_AT = 2;
     */
    SALES_ORDER_ITEM_SORT_KEY[SALES_ORDER_ITEM_SORT_KEY["SALES_ORDER_ITEM_SORT_KEY_MODIFIED_AT"] = 2] = "SALES_ORDER_ITEM_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: SALES_ORDER_ITEM_SORT_KEY_APPROVED_ON = 3;
     */
    SALES_ORDER_ITEM_SORT_KEY[SALES_ORDER_ITEM_SORT_KEY["SALES_ORDER_ITEM_SORT_KEY_APPROVED_ON"] = 3] = "SALES_ORDER_ITEM_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: SALES_ORDER_ITEM_SORT_KEY_APPROVED_BY = 4;
     */
    SALES_ORDER_ITEM_SORT_KEY[SALES_ORDER_ITEM_SORT_KEY["SALES_ORDER_ITEM_SORT_KEY_APPROVED_BY"] = 4] = "SALES_ORDER_ITEM_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: SALES_ORDER_ITEM_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    SALES_ORDER_ITEM_SORT_KEY[SALES_ORDER_ITEM_SORT_KEY["SALES_ORDER_ITEM_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "SALES_ORDER_ITEM_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the family ID
     *
     * @generated from enum value: SALES_ORDER_ITEM_SORT_KEY_FAMILY_ID = 10;
     */
    SALES_ORDER_ITEM_SORT_KEY[SALES_ORDER_ITEM_SORT_KEY["SALES_ORDER_ITEM_SORT_KEY_FAMILY_ID"] = 10] = "SALES_ORDER_ITEM_SORT_KEY_FAMILY_ID";
    /**
     * Fetch ordered results by the internal quantity
     *
     * @generated from enum value: SALES_ORDER_ITEM_SORT_KEY_INTERNAL_QUANTITY = 11;
     */
    SALES_ORDER_ITEM_SORT_KEY[SALES_ORDER_ITEM_SORT_KEY["SALES_ORDER_ITEM_SORT_KEY_INTERNAL_QUANTITY"] = 11] = "SALES_ORDER_ITEM_SORT_KEY_INTERNAL_QUANTITY";
    /**
     * Fetch ordered results by the client unit of material ID
     *
     * @generated from enum value: SALES_ORDER_ITEM_SORT_KEY_CLIENT_UOM_ID = 12;
     */
    SALES_ORDER_ITEM_SORT_KEY[SALES_ORDER_ITEM_SORT_KEY["SALES_ORDER_ITEM_SORT_KEY_CLIENT_UOM_ID"] = 12] = "SALES_ORDER_ITEM_SORT_KEY_CLIENT_UOM_ID";
    /**
     * Fetch ordered results by the client quantity
     *
     * @generated from enum value: SALES_ORDER_ITEM_SORT_KEY_CLIENT_QUANTITY = 13;
     */
    SALES_ORDER_ITEM_SORT_KEY[SALES_ORDER_ITEM_SORT_KEY["SALES_ORDER_ITEM_SORT_KEY_CLIENT_QUANTITY"] = 13] = "SALES_ORDER_ITEM_SORT_KEY_CLIENT_QUANTITY";
    /**
     * Fetch ordered results by the client family code
     *
     * @generated from enum value: SALES_ORDER_ITEM_SORT_KEY_CLIENT_FAMILY_CODE = 14;
     */
    SALES_ORDER_ITEM_SORT_KEY[SALES_ORDER_ITEM_SORT_KEY["SALES_ORDER_ITEM_SORT_KEY_CLIENT_FAMILY_CODE"] = 14] = "SALES_ORDER_ITEM_SORT_KEY_CLIENT_FAMILY_CODE";
    /**
     * Fetch ordered results by the unit price
     *
     * @generated from enum value: SALES_ORDER_ITEM_SORT_KEY_UNIT_PRICE = 15;
     */
    SALES_ORDER_ITEM_SORT_KEY[SALES_ORDER_ITEM_SORT_KEY["SALES_ORDER_ITEM_SORT_KEY_UNIT_PRICE"] = 15] = "SALES_ORDER_ITEM_SORT_KEY_UNIT_PRICE";
    /**
     * Fetch ordered results by the tax group ID
     *
     * @generated from enum value: SALES_ORDER_ITEM_SORT_KEY_TAX_GROUP_ID = 16;
     */
    SALES_ORDER_ITEM_SORT_KEY[SALES_ORDER_ITEM_SORT_KEY["SALES_ORDER_ITEM_SORT_KEY_TAX_GROUP_ID"] = 16] = "SALES_ORDER_ITEM_SORT_KEY_TAX_GROUP_ID";
    /**
     * Fetch ordered results by the discount
     *
     * @generated from enum value: SALES_ORDER_ITEM_SORT_KEY_DISCOUNT = 17;
     */
    SALES_ORDER_ITEM_SORT_KEY[SALES_ORDER_ITEM_SORT_KEY["SALES_ORDER_ITEM_SORT_KEY_DISCOUNT"] = 17] = "SALES_ORDER_ITEM_SORT_KEY_DISCOUNT";
    /**
     * Fetch ordered results by the delivery date
     *
     * @generated from enum value: SALES_ORDER_ITEM_SORT_KEY_DELIVERY_DATE = 18;
     */
    SALES_ORDER_ITEM_SORT_KEY[SALES_ORDER_ITEM_SORT_KEY["SALES_ORDER_ITEM_SORT_KEY_DELIVERY_DATE"] = 18] = "SALES_ORDER_ITEM_SORT_KEY_DELIVERY_DATE";
})(SALES_ORDER_ITEM_SORT_KEY || (exports.SALES_ORDER_ITEM_SORT_KEY = SALES_ORDER_ITEM_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(SALES_ORDER_ITEM_SORT_KEY)
protobuf_1.proto3.util.setEnumType(SALES_ORDER_ITEM_SORT_KEY, "Scailo.SALES_ORDER_ITEM_SORT_KEY", [
    { no: 0, name: "SALES_ORDER_ITEM_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "SALES_ORDER_ITEM_SORT_KEY_CREATED_AT" },
    { no: 2, name: "SALES_ORDER_ITEM_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "SALES_ORDER_ITEM_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "SALES_ORDER_ITEM_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "SALES_ORDER_ITEM_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 10, name: "SALES_ORDER_ITEM_SORT_KEY_FAMILY_ID" },
    { no: 11, name: "SALES_ORDER_ITEM_SORT_KEY_INTERNAL_QUANTITY" },
    { no: 12, name: "SALES_ORDER_ITEM_SORT_KEY_CLIENT_UOM_ID" },
    { no: 13, name: "SALES_ORDER_ITEM_SORT_KEY_CLIENT_QUANTITY" },
    { no: 14, name: "SALES_ORDER_ITEM_SORT_KEY_CLIENT_FAMILY_CODE" },
    { no: 15, name: "SALES_ORDER_ITEM_SORT_KEY_UNIT_PRICE" },
    { no: 16, name: "SALES_ORDER_ITEM_SORT_KEY_TAX_GROUP_ID" },
    { no: 17, name: "SALES_ORDER_ITEM_SORT_KEY_DISCOUNT" },
    { no: 18, name: "SALES_ORDER_ITEM_SORT_KEY_DELIVERY_DATE" },
]);
/**
 *
 * Describes the applicable statuses of sales order items
 *
 * @generated from enum Scailo.SALES_ORDER_ITEM_STATUS
 */
var SALES_ORDER_ITEM_STATUS;
(function (SALES_ORDER_ITEM_STATUS) {
    /**
     * Denotes that status be disregarded. This is used only within search APIs
     *
     * @generated from enum value: SALES_ORDER_ITEM_STATUS_ANY_UNSPECIFIED = 0;
     */
    SALES_ORDER_ITEM_STATUS[SALES_ORDER_ITEM_STATUS["SALES_ORDER_ITEM_STATUS_ANY_UNSPECIFIED"] = 0] = "SALES_ORDER_ITEM_STATUS_ANY_UNSPECIFIED";
    /**
     * Denotes that the sales order items must have been approved
     *
     * @generated from enum value: SALES_ORDER_ITEM_STATUS_APPROVED = 1;
     */
    SALES_ORDER_ITEM_STATUS[SALES_ORDER_ITEM_STATUS["SALES_ORDER_ITEM_STATUS_APPROVED"] = 1] = "SALES_ORDER_ITEM_STATUS_APPROVED";
    /**
     * Denotes that the sales order items must be waiting for approval
     *
     * @generated from enum value: SALES_ORDER_ITEM_STATUS_UNAPPROVED = 2;
     */
    SALES_ORDER_ITEM_STATUS[SALES_ORDER_ITEM_STATUS["SALES_ORDER_ITEM_STATUS_UNAPPROVED"] = 2] = "SALES_ORDER_ITEM_STATUS_UNAPPROVED";
})(SALES_ORDER_ITEM_STATUS || (exports.SALES_ORDER_ITEM_STATUS = SALES_ORDER_ITEM_STATUS = {}));
// Retrieve enum metadata with: proto3.getEnumType(SALES_ORDER_ITEM_STATUS)
protobuf_1.proto3.util.setEnumType(SALES_ORDER_ITEM_STATUS, "Scailo.SALES_ORDER_ITEM_STATUS", [
    { no: 0, name: "SALES_ORDER_ITEM_STATUS_ANY_UNSPECIFIED" },
    { no: 1, name: "SALES_ORDER_ITEM_STATUS_APPROVED" },
    { no: 2, name: "SALES_ORDER_ITEM_STATUS_UNAPPROVED" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.SalesOrdersServiceCreateRequest
 */
class SalesOrdersServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the sales order
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The ID of the consignee (client)
     *
     * @generated from field: uint64 consignee_client_id = 12;
     */
    consigneeClientId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the buyer (client)
     *
     * @generated from field: uint64 buyer_client_id = 13;
     */
    buyerClientId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the location
     *
     * @generated from field: uint64 location_id = 14;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The associated ID of the currency
     *
     * @generated from field: uint64 currency_id = 15;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The optional associated ID of the project
     *
     * @generated from field: uint64 project_id = 16;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * Any miscellaneous cost
     *
     * @generated from field: uint64 miscellaneous_cost = 17;
     */
    miscellaneousCost = protobuf_1.protoInt64.zero;
    /**
     * The optional discount amount
     *
     * @generated from field: uint64 overall_discount = 18;
     */
    overallDiscount = protobuf_1.protoInt64.zero;
    /**
     * The applicable round off amount (optional, and can be positive or negative)
     *
     * @generated from field: int64 round_off = 19;
     */
    roundOff = protobuf_1.protoInt64.zero;
    /**
     * The amount paid in advance
     *
     * @generated from field: uint64 payment_advance = 20;
     */
    paymentAdvance = protobuf_1.protoInt64.zero;
    /**
     * The payment cycle in days
     *
     * @generated from field: uint64 payment_cycle_in_days = 21;
     */
    paymentCycleInDays = protobuf_1.protoInt64.zero;
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesOrdersServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "consignee_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "buyer_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "miscellaneous_cost", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "overall_discount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 19, name: "round_off", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 20, name: "payment_advance", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "payment_cycle_in_days", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalesOrdersServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesOrdersServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesOrdersServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesOrdersServiceCreateRequest, a, b);
    }
}
exports.SalesOrdersServiceCreateRequest = SalesOrdersServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.SalesOrdersServiceUpdateRequest
 */
class SalesOrdersServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the sales order
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The ID of the consignee (client)
     *
     * @generated from field: uint64 consignee_client_id = 12;
     */
    consigneeClientId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the buyer (client)
     *
     * @generated from field: uint64 buyer_client_id = 13;
     */
    buyerClientId = protobuf_1.protoInt64.zero;
    /**
     * The associated ID of the currency
     *
     * @generated from field: uint64 currency_id = 15;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The optional associated ID of the project
     *
     * @generated from field: uint64 project_id = 16;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * Any miscellaneous cost
     *
     * @generated from field: uint64 miscellaneous_cost = 17;
     */
    miscellaneousCost = protobuf_1.protoInt64.zero;
    /**
     * The optional discount amount
     *
     * @generated from field: uint64 overall_discount = 18;
     */
    overallDiscount = protobuf_1.protoInt64.zero;
    /**
     * The applicable round off amount (optional, and can be positive or negative)
     *
     * @generated from field: int64 round_off = 19;
     */
    roundOff = protobuf_1.protoInt64.zero;
    /**
     * The amount paid in advance
     *
     * @generated from field: uint64 payment_advance = 20;
     */
    paymentAdvance = protobuf_1.protoInt64.zero;
    /**
     * The payment cycle in days
     *
     * @generated from field: uint64 payment_cycle_in_days = 21;
     */
    paymentCycleInDays = protobuf_1.protoInt64.zero;
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesOrdersServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "consignee_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "buyer_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "miscellaneous_cost", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "overall_discount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 19, name: "round_off", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 20, name: "payment_advance", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "payment_cycle_in_days", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalesOrdersServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesOrdersServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesOrdersServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesOrdersServiceUpdateRequest, a, b);
    }
}
exports.SalesOrdersServiceUpdateRequest = SalesOrdersServiceUpdateRequest;
/**
 *
 * Describes the parameters necessary to perform an autofill request
 *
 * @generated from message Scailo.SalesOrdersServiceAutofillRequest
 */
class SalesOrdersServiceAutofillRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The UUID of the record that needs to be updated
     *
     * @generated from field: string uuid = 2;
     */
    uuid = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesOrdersServiceAutofillRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesOrdersServiceAutofillRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesOrdersServiceAutofillRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesOrdersServiceAutofillRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesOrdersServiceAutofillRequest, a, b);
    }
}
exports.SalesOrdersServiceAutofillRequest = SalesOrdersServiceAutofillRequest;
/**
 *
 * Stores the UUID references of the record
 *
 * @generated from message Scailo.SalesOrderAncillaryParameters
 */
class SalesOrderAncillaryParameters extends protobuf_1.Message {
    /**
     * The UUID of the consignee client (the UUID of the associated consignee_client_id)
     *
     * @generated from field: string consignee_client_uuid = 212;
     */
    consigneeClientUuid = "";
    /**
     * The UUID of the buyer client (the UUID of the associated buyer_client_id)
     *
     * @generated from field: string buyer_client_uuid = 213;
     */
    buyerClientUuid = "";
    /**
     * The UUID of the location (the UUID of the associated location_uuid)
     *
     * @generated from field: string location_uuid = 214;
     */
    locationUuid = "";
    /**
     * The UUID of the currency (the UUID of the associated currency)
     *
     * @generated from field: string currency_uuid = 215;
     */
    currencyUuid = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesOrderAncillaryParameters";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 212, name: "consignee_client_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 213, name: "buyer_client_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 214, name: "location_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 215, name: "currency_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesOrderAncillaryParameters().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesOrderAncillaryParameters().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesOrderAncillaryParameters().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesOrderAncillaryParameters, a, b);
    }
}
exports.SalesOrderAncillaryParameters = SalesOrderAncillaryParameters;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.SalesOrder
 */
class SalesOrder extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this sales order
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this sales order
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this sales order
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this sales order was marked as completed
     *
     * @generated from field: uint64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the sales order
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 11;
     */
    finalRefNumber = "";
    /**
     * The ID of the consignee (client)
     *
     * @generated from field: uint64 consignee_client_id = 12;
     */
    consigneeClientId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the buyer (client)
     *
     * @generated from field: uint64 buyer_client_id = 13;
     */
    buyerClientId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the location
     *
     * @generated from field: uint64 location_id = 14;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The associated ID of the currency
     *
     * @generated from field: uint64 currency_id = 15;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The optional associated ID of the project
     *
     * @generated from field: uint64 project_id = 16;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * Any miscellaneous cost
     *
     * @generated from field: uint64 miscellaneous_cost = 17;
     */
    miscellaneousCost = protobuf_1.protoInt64.zero;
    /**
     * The optional discount amount
     *
     * @generated from field: uint64 overall_discount = 18;
     */
    overallDiscount = protobuf_1.protoInt64.zero;
    /**
     * The applicable round off amount (optional, and can be positive or negative)
     *
     * @generated from field: int64 round_off = 19;
     */
    roundOff = protobuf_1.protoInt64.zero;
    /**
     * The amount paid in advance
     *
     * @generated from field: uint64 payment_advance = 20;
     */
    paymentAdvance = protobuf_1.protoInt64.zero;
    /**
     * The payment cycle in days
     *
     * @generated from field: uint64 payment_cycle_in_days = 21;
     */
    paymentCycleInDays = protobuf_1.protoInt64.zero;
    /**
     * The number of times that the sales order has been amended
     *
     * @generated from field: uint64 amendment_count = 22;
     */
    amendmentCount = protobuf_1.protoInt64.zero;
    /**
     * Stores the total value of the sales order (as a double, which requires no adjustments)
     *
     * @generated from field: double total_value = 30;
     */
    totalValue = 0;
    /**
     * The list of associated sales order items
     *
     * @generated from field: repeated Scailo.SalesOrderItem list = 40;
     */
    list = [];
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatum form_data = 50;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesOrder";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "consignee_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "buyer_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "miscellaneous_cost", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "overall_discount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 19, name: "round_off", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 20, name: "payment_advance", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "payment_cycle_in_days", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 22, name: "amendment_count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "total_value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
        { no: 40, name: "list", kind: "message", T: SalesOrderItem, repeated: true },
        { no: 50, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatum, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalesOrder().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesOrder().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesOrder().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesOrder, a, b);
    }
}
exports.SalesOrder = SalesOrder;
/**
 *
 * Describes the parameters required to add an item to a sales order
 *
 * @generated from message Scailo.SalesOrdersServiceItemCreateRequest
 */
class SalesOrdersServiceItemCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the sales order ID
     *
     * @generated from field: uint64 sales_order_id = 10;
     */
    salesOrderId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 12;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The quantity (in cents) being ordered in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 13;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the client's unit of material
     *
     * @generated from field: uint64 client_uom_id = 14;
     */
    clientUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being ordered in client's unit of material
     *
     * @generated from field: uint64 client_quantity = 15;
     */
    clientQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the family code as given by the client
     *
     * @generated from field: string client_family_code = 16;
     */
    clientFamilyCode = "";
    /**
     * The unit price of the item
     *
     * @generated from field: uint64 unit_price = 17;
     */
    unitPrice = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated tax group
     *
     * @generated from field: uint64 tax_group_id = 18;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The applicable discount percentage (in cents)
     *
     * @generated from field: uint64 discount = 19;
     */
    discount = protobuf_1.protoInt64.zero;
    /**
     * The delivery date of the item
     *
     * @generated from field: string delivery_date = 20;
     */
    deliveryDate = "";
    /**
     * Optional specifications
     *
     * @generated from field: string specifications = 21;
     */
    specifications = "";
    /**
     * Stores if the item can be invoiced
     *
     * @generated from field: bool is_invoiceable = 22;
     */
    isInvoiceable = false;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesOrdersServiceItemCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "sales_order_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "client_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "client_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "client_family_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 17, name: "unit_price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 19, name: "discount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "delivery_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "is_invoiceable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesOrdersServiceItemCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesOrdersServiceItemCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesOrdersServiceItemCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesOrdersServiceItemCreateRequest, a, b);
    }
}
exports.SalesOrdersServiceItemCreateRequest = SalesOrdersServiceItemCreateRequest;
/**
 *
 * Describes the parameters required to update an item in a sales order
 *
 * @generated from message Scailo.SalesOrdersServiceItemUpdateRequest
 */
class SalesOrdersServiceItemUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * The quantity (in cents) being ordered in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 13;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the client's unit of material
     *
     * @generated from field: uint64 client_uom_id = 14;
     */
    clientUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being ordered in client's unit of material
     *
     * @generated from field: uint64 client_quantity = 15;
     */
    clientQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the family code as given by the client
     *
     * @generated from field: string client_family_code = 16;
     */
    clientFamilyCode = "";
    /**
     * The unit price of the item
     *
     * @generated from field: uint64 unit_price = 17;
     */
    unitPrice = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated tax group
     *
     * @generated from field: uint64 tax_group_id = 18;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The applicable discount percentage (in cents)
     *
     * @generated from field: uint64 discount = 19;
     */
    discount = protobuf_1.protoInt64.zero;
    /**
     * The delivery date of the item
     *
     * @generated from field: string delivery_date = 20;
     */
    deliveryDate = "";
    /**
     * Optional specifications
     *
     * @generated from field: string specifications = 21;
     */
    specifications = "";
    /**
     * Stores if the item can be invoiced
     *
     * @generated from field: bool is_invoiceable = 22;
     */
    isInvoiceable = false;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesOrdersServiceItemUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "client_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "client_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "client_family_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 17, name: "unit_price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 19, name: "discount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "delivery_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "is_invoiceable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesOrdersServiceItemUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesOrdersServiceItemUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesOrdersServiceItemUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesOrdersServiceItemUpdateRequest, a, b);
    }
}
exports.SalesOrdersServiceItemUpdateRequest = SalesOrdersServiceItemUpdateRequest;
/**
 *
 * Describes the parameters that constitute an item associated to a sales order
 *
 * @generated from message Scailo.SalesOrderItem
 */
class SalesOrderItem extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this sales order
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the sales order ID
     *
     * @generated from field: uint64 sales_order_id = 10;
     */
    salesOrderId = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the sales orderder item with which this item is bundled with
     *
     * @generated from field: uint64 bundled_with_id = 11;
     */
    bundledWithId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 12;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The quantity (in cents) being ordered in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 13;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the client's unit of material
     *
     * @generated from field: uint64 client_uom_id = 14;
     */
    clientUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being ordered in client's unit of material
     *
     * @generated from field: uint64 client_quantity = 15;
     */
    clientQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the family code as given by the client
     *
     * @generated from field: string client_family_code = 16;
     */
    clientFamilyCode = "";
    /**
     * The unit price of the item
     *
     * @generated from field: uint64 unit_price = 17;
     */
    unitPrice = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated tax group
     *
     * @generated from field: uint64 tax_group_id = 18;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The applicable discount percentage (in cents)
     *
     * @generated from field: uint64 discount = 19;
     */
    discount = protobuf_1.protoInt64.zero;
    /**
     * The delivery date of the item
     *
     * @generated from field: string delivery_date = 20;
     */
    deliveryDate = "";
    /**
     * Optional specifications
     *
     * @generated from field: string specifications = 21;
     */
    specifications = "";
    /**
     * Stores if the item can be invoiced
     *
     * @generated from field: bool is_invoiceable = 22;
     */
    isInvoiceable = false;
    /**
     * Stores the unit price after factoring in the discount
     *
     * @generated from field: uint64 discounted_unit_price = 30;
     */
    discountedUnitPrice = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesOrderItem";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "sales_order_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "bundled_with_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "client_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "client_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "client_family_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 17, name: "unit_price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 19, name: "discount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "delivery_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "is_invoiceable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 30, name: "discounted_unit_price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesOrderItem().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesOrderItem().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesOrderItem().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesOrderItem, a, b);
    }
}
exports.SalesOrderItem = SalesOrderItem;
/**
 *
 * Describes the message consisting of the list of sales orders
 *
 * @generated from message Scailo.SalesOrdersList
 */
class SalesOrdersList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.SalesOrder list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesOrdersList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: SalesOrder, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalesOrdersList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesOrdersList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesOrdersList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesOrdersList, a, b);
    }
}
exports.SalesOrdersList = SalesOrdersList;
/**
 *
 * Describes the message consisting of the list of sales order items
 *
 * @generated from message Scailo.SalesOrderItemsList
 */
class SalesOrderItemsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.SalesOrderItem list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesOrderItemsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: SalesOrderItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalesOrderItemsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesOrderItemsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesOrderItemsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesOrderItemsList, a, b);
    }
}
exports.SalesOrderItemsList = SalesOrderItemsList;
/**
 *
 * Describes the parameters that are required to retrieve the history of the record
 *
 * @generated from message Scailo.SalesOrderItemHistoryRequest
 */
class SalesOrderItemHistoryRequest extends protobuf_1.Message {
    /**
     * Stores the sales order ID
     *
     * @generated from field: uint64 sales_order_id = 10;
     */
    salesOrderId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesOrderItemHistoryRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "sales_order_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesOrderItemHistoryRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesOrderItemHistoryRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesOrderItemHistoryRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesOrderItemHistoryRequest, a, b);
    }
}
exports.SalesOrderItemHistoryRequest = SalesOrderItemHistoryRequest;
/**
 *
 * Describes the parameters that are required to retrieve the info of a prospective sales order item
 *
 * @generated from message Scailo.SalesOrderItemProspectiveInfoRequest
 */
class SalesOrderItemProspectiveInfoRequest extends protobuf_1.Message {
    /**
     * Stores the sales order ID
     *
     * @generated from field: uint64 sales_order_id = 10;
     */
    salesOrderId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the client's unit of material (if 0, the value is ignored while retrieving records)
     *
     * @generated from field: uint64 client_uom_id = 13;
     */
    clientUomId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesOrderItemProspectiveInfoRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "sales_order_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "client_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesOrderItemProspectiveInfoRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesOrderItemProspectiveInfoRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesOrderItemProspectiveInfoRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesOrderItemProspectiveInfoRequest, a, b);
    }
}
exports.SalesOrderItemProspectiveInfoRequest = SalesOrderItemProspectiveInfoRequest;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.SalesOrdersServicePaginationReq
 */
class SalesOrdersServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SALES_ORDER_SORT_KEY sort_key = 5;
     */
    sortKey = SALES_ORDER_SORT_KEY.SALES_ORDER_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this sales order
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesOrdersServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(SALES_ORDER_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new SalesOrdersServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesOrdersServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesOrdersServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesOrdersServicePaginationReq, a, b);
    }
}
exports.SalesOrdersServicePaginationReq = SalesOrdersServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.SalesOrdersServicePaginationResponse
 */
class SalesOrdersServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.SalesOrder payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesOrdersServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: SalesOrder, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalesOrdersServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesOrdersServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesOrdersServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesOrdersServicePaginationResponse, a, b);
    }
}
exports.SalesOrdersServicePaginationResponse = SalesOrdersServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.SalesOrdersServiceFilterReq
 */
class SalesOrdersServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SALES_ORDER_SORT_KEY sort_key = 5;
     */
    sortKey = SALES_ORDER_SORT_KEY.SALES_ORDER_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this sales order
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the sales order
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The associated consignee client ID
     *
     * @generated from field: uint64 consignee_client_id = 22;
     */
    consigneeClientId = protobuf_1.protoInt64.zero;
    /**
     * The associated buyer client ID
     *
     * @generated from field: uint64 buyer_client_id = 23;
     */
    buyerClientId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated location
     *
     * @generated from field: uint64 location_id = 24;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated currency
     *
     * @generated from field: uint64 currency_id = 25;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated project
     *
     * @generated from field: uint64 project_id = 26;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 40;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The exact delivery date of the item in the sales order
     *
     * @generated from field: string delivery_date_exact = 41;
     */
    deliveryDateExact = "";
    /**
     * The start delivery date of the item in the sales order
     *
     * @generated from field: string delivery_date_start = 42;
     */
    deliveryDateStart = "";
    /**
     * The end delivery date of the item in the sales order
     *
     * @generated from field: string delivery_date_end = 43;
     */
    deliveryDateEnd = "";
    /**
     * Stores the minimum value of the sales order (ignored if 0)
     *
     * @generated from field: uint64 total_value_min = 50;
     */
    totalValueMin = protobuf_1.protoInt64.zero;
    /**
     * Stores the maximum value of the sales order (ignored if 0)
     *
     * @generated from field: uint64 total_value_max = 51;
     */
    totalValueMax = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesOrdersServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(SALES_ORDER_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "consignee_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "buyer_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 26, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 41, name: "delivery_date_exact", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 42, name: "delivery_date_start", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 43, name: "delivery_date_end", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 50, name: "total_value_min", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 51, name: "total_value_max", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesOrdersServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesOrdersServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesOrdersServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesOrdersServiceFilterReq, a, b);
    }
}
exports.SalesOrdersServiceFilterReq = SalesOrdersServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.SalesOrdersServiceCountReq
 */
class SalesOrdersServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this sales order
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the sales order
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The associated consignee client ID
     *
     * @generated from field: uint64 consignee_client_id = 22;
     */
    consigneeClientId = protobuf_1.protoInt64.zero;
    /**
     * The associated buyer client ID
     *
     * @generated from field: uint64 buyer_client_id = 23;
     */
    buyerClientId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated location
     *
     * @generated from field: uint64 location_id = 24;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated currency
     *
     * @generated from field: uint64 currency_id = 25;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated project
     *
     * @generated from field: uint64 project_id = 26;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 40;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The exact delivery date of the item in the sales order
     *
     * @generated from field: string delivery_date_exact = 41;
     */
    deliveryDateExact = "";
    /**
     * The start delivery date of the item in the sales order
     *
     * @generated from field: string delivery_date_start = 42;
     */
    deliveryDateStart = "";
    /**
     * The end delivery date of the item in the sales order
     *
     * @generated from field: string delivery_date_end = 43;
     */
    deliveryDateEnd = "";
    /**
     * Stores the minimum value of the sales order (ignored if 0)
     *
     * @generated from field: uint64 total_value_min = 50;
     */
    totalValueMin = protobuf_1.protoInt64.zero;
    /**
     * Stores the maximum value of the sales order (ignored if 0)
     *
     * @generated from field: uint64 total_value_max = 51;
     */
    totalValueMax = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesOrdersServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "consignee_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "buyer_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 26, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 41, name: "delivery_date_exact", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 42, name: "delivery_date_start", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 43, name: "delivery_date_end", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 50, name: "total_value_min", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 51, name: "total_value_max", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesOrdersServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesOrdersServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesOrdersServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesOrdersServiceCountReq, a, b);
    }
}
exports.SalesOrdersServiceCountReq = SalesOrdersServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.SalesOrdersServiceSearchAllReq
 */
class SalesOrdersServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SALES_ORDER_SORT_KEY sort_key = 5;
     */
    sortKey = SALES_ORDER_SORT_KEY.SALES_ORDER_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    /**
     * The associated consignee client ID
     *
     * @generated from field: uint64 consignee_client_id = 22;
     */
    consigneeClientId = protobuf_1.protoInt64.zero;
    /**
     * The associated buyer client ID
     *
     * @generated from field: uint64 buyer_client_id = 23;
     */
    buyerClientId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated location
     *
     * @generated from field: uint64 location_id = 24;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated currency
     *
     * @generated from field: uint64 currency_id = 25;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated project
     *
     * @generated from field: uint64 project_id = 26;
     */
    projectId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesOrdersServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(SALES_ORDER_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "consignee_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "buyer_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 26, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesOrdersServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesOrdersServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesOrdersServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesOrdersServiceSearchAllReq, a, b);
    }
}
exports.SalesOrdersServiceSearchAllReq = SalesOrdersServiceSearchAllReq;
/**
 *
 * Describes the parameters necessary to create a sales order reference
 *
 * @generated from message Scailo.SalesOrdersServiceReferenceCreateRequest
 */
class SalesOrdersServiceReferenceCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the sales order ID
     *
     * @generated from field: uint64 sales_order_id = 10;
     */
    salesOrderId = protobuf_1.protoInt64.zero;
    /**
     * The reference context, could be billing or buying
     *
     * @generated from field: Scailo.SALES_ORDER_REFERENCE_CONTEXT context = 11;
     */
    context = SALES_ORDER_REFERENCE_CONTEXT.SALES_ORDER_REFERENCE_CONTEXT_ANY_UNSPECIFIED;
    /**
     * The associated ref from
     *
     * @generated from field: Scailo.SALES_ORDER_REFERENCE_REF_FROM ref_from = 12;
     */
    refFrom = SALES_ORDER_REFERENCE_REF_FROM.SALES_ORDER_REFERENCE_REF_FROM_ANY_UNSPECIFIED;
    /**
     * The ID of the associated reference
     *
     * @generated from field: uint64 ref_id = 13;
     */
    refId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesOrdersServiceReferenceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "sales_order_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "context", kind: "enum", T: protobuf_1.proto3.getEnumType(SALES_ORDER_REFERENCE_CONTEXT) },
        { no: 12, name: "ref_from", kind: "enum", T: protobuf_1.proto3.getEnumType(SALES_ORDER_REFERENCE_REF_FROM) },
        { no: 13, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesOrdersServiceReferenceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesOrdersServiceReferenceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesOrdersServiceReferenceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesOrdersServiceReferenceCreateRequest, a, b);
    }
}
exports.SalesOrdersServiceReferenceCreateRequest = SalesOrdersServiceReferenceCreateRequest;
/**
 *
 * Describes the parameters that constitute a sales order reference
 *
 * @generated from message Scailo.SalesOrderReference
 */
class SalesOrderReference extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this sales order
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the sales order ID
     *
     * @generated from field: uint64 sales_order_id = 10;
     */
    salesOrderId = protobuf_1.protoInt64.zero;
    /**
     * The reference context, could be billing or buying
     *
     * @generated from field: Scailo.SALES_ORDER_REFERENCE_CONTEXT context = 11;
     */
    context = SALES_ORDER_REFERENCE_CONTEXT.SALES_ORDER_REFERENCE_CONTEXT_ANY_UNSPECIFIED;
    /**
     * The associated ref from
     *
     * @generated from field: Scailo.SALES_ORDER_REFERENCE_REF_FROM ref_from = 12;
     */
    refFrom = SALES_ORDER_REFERENCE_REF_FROM.SALES_ORDER_REFERENCE_REF_FROM_ANY_UNSPECIFIED;
    /**
     * The ID of the associated reference
     *
     * @generated from field: uint64 ref_id = 13;
     */
    refId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesOrderReference";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "sales_order_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "context", kind: "enum", T: protobuf_1.proto3.getEnumType(SALES_ORDER_REFERENCE_CONTEXT) },
        { no: 12, name: "ref_from", kind: "enum", T: protobuf_1.proto3.getEnumType(SALES_ORDER_REFERENCE_REF_FROM) },
        { no: 13, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesOrderReference().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesOrderReference().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesOrderReference().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesOrderReference, a, b);
    }
}
exports.SalesOrderReference = SalesOrderReference;
/**
 *
 * Describes the message consisting of the list of sales order references
 *
 * @generated from message Scailo.SalesOrderReferencesList
 */
class SalesOrderReferencesList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.SalesOrderReference list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesOrderReferencesList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: SalesOrderReference, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalesOrderReferencesList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesOrderReferencesList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesOrderReferencesList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesOrderReferencesList, a, b);
    }
}
exports.SalesOrderReferencesList = SalesOrderReferencesList;
/**
 *
 * Describes the request payload to retrieve approved or unapproved items.
 *
 * @generated from message Scailo.SalesOrderItemsSearchRequest
 */
class SalesOrderItemsSearchRequest extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SALES_ORDER_ITEM_SORT_KEY sort_key = 5;
     */
    sortKey = SALES_ORDER_ITEM_SORT_KEY.SALES_ORDER_ITEM_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * The status of the items
     *
     * @generated from field: Scailo.SALES_ORDER_ITEM_STATUS status = 7;
     */
    status = SALES_ORDER_ITEM_STATUS.SALES_ORDER_ITEM_STATUS_ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 10;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 11;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 12;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 13;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the sales order
     *
     * @generated from field: uint64 sales_order_id = 20;
     */
    salesOrderId = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the sales order item with which this item is bundled with
     *
     * @generated from field: uint64 bundled_with_id = 21;
     */
    bundledWithId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 22;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the client's unit of material
     *
     * @generated from field: uint64 client_uom_id = 23;
     */
    clientUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family code as given by the client
     *
     * @generated from field: string client_family_code = 26;
     */
    clientFamilyCode = "";
    /**
     * The ID of the tax group
     *
     * @generated from field: uint64 tax_group_id = 27;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The exact delivery date of the item in the sales order
     *
     * @generated from field: string delivery_date_exact = 28;
     */
    deliveryDateExact = "";
    /**
     * The start delivery date of the item in the sales order
     *
     * @generated from field: string delivery_date_start = 29;
     */
    deliveryDateStart = "";
    /**
     * The end delivery date of the item in the sales order
     *
     * @generated from field: string delivery_date_end = 30;
     */
    deliveryDateEnd = "";
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 40;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesOrderItemsSearchRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(SALES_ORDER_ITEM_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 7, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(SALES_ORDER_ITEM_STATUS) },
        { no: 10, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "sales_order_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "bundled_with_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 22, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "client_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 26, name: "client_family_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 27, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 28, name: "delivery_date_exact", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 29, name: "delivery_date_start", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "delivery_date_end", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 40, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesOrderItemsSearchRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesOrderItemsSearchRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesOrderItemsSearchRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesOrderItemsSearchRequest, a, b);
    }
}
exports.SalesOrderItemsSearchRequest = SalesOrderItemsSearchRequest;
/**
 *
 * Describes the response to a pagination items request
 *
 * @generated from message Scailo.SalesOrdersServicePaginatedItemsResponse
 */
class SalesOrdersServicePaginatedItemsResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.SalesOrderItem payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesOrdersServicePaginatedItemsResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: SalesOrderItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalesOrdersServicePaginatedItemsResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesOrdersServicePaginatedItemsResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesOrdersServicePaginatedItemsResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesOrdersServicePaginatedItemsResponse, a, b);
    }
}
exports.SalesOrdersServicePaginatedItemsResponse = SalesOrdersServicePaginatedItemsResponse;
/**
 *
 * Describes the parameters necessary to create a sales order contact
 *
 * @generated from message Scailo.SalesOrdersServiceContactCreateRequest
 */
class SalesOrdersServiceContactCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the sales order ID
     *
     * @generated from field: uint64 sales_order_id = 10;
     */
    salesOrderId = protobuf_1.protoInt64.zero;
    /**
     * Stores the associate ID
     *
     * @generated from field: uint64 associate_id = 11;
     */
    associateId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesOrdersServiceContactCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "sales_order_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "associate_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesOrdersServiceContactCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesOrdersServiceContactCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesOrdersServiceContactCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesOrdersServiceContactCreateRequest, a, b);
    }
}
exports.SalesOrdersServiceContactCreateRequest = SalesOrdersServiceContactCreateRequest;
/**
 *
 * Describes the parameters that constitute a sales order contact
 *
 * @generated from message Scailo.SalesOrderContact
 */
class SalesOrderContact extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this sales order
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the sales order ID
     *
     * @generated from field: uint64 sales_order_id = 10;
     */
    salesOrderId = protobuf_1.protoInt64.zero;
    /**
     * Stores the associate ID
     *
     * @generated from field: uint64 associate_id = 11;
     */
    associateId = protobuf_1.protoInt64.zero;
    /**
     * Stores the UUID of the associate
     *
     * @generated from field: string associate_uuid = 211;
     */
    associateUuid = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesOrderContact";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "sales_order_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "associate_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 211, name: "associate_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesOrderContact().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesOrderContact().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesOrderContact().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesOrderContact, a, b);
    }
}
exports.SalesOrderContact = SalesOrderContact;
/**
 *
 * Describes the message consisting of the list of sales order contacts
 *
 * @generated from message Scailo.SalesOrderContactsList
 */
class SalesOrderContactsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.SalesOrderContact list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesOrderContactsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: SalesOrderContact, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalesOrderContactsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesOrderContactsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesOrderContactsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesOrderContactsList, a, b);
    }
}
exports.SalesOrderContactsList = SalesOrderContactsList;
/**
 *
 * Describes the parameters that are part of a sales order's inventory statistics payload
 *
 * @generated from message Scailo.SalesOrderInventoryStatistics
 */
class SalesOrderInventoryStatistics extends protobuf_1.Message {
    /**
     * Stores the cumulative quantity of the ordered inventory
     *
     * @generated from field: uint64 ordered = 1;
     */
    ordered = protobuf_1.protoInt64.zero;
    /**
     * Stores the cumulative quantity of the dispatched inventory
     *
     * @generated from field: uint64 dispatched = 2;
     */
    dispatched = protobuf_1.protoInt64.zero;
    /**
     * Stores the cumulative quantity of the returned inventory
     *
     * @generated from field: uint64 returned = 3;
     */
    returned = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesOrderInventoryStatistics";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "ordered", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "dispatched", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "returned", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesOrderInventoryStatistics().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesOrderInventoryStatistics().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesOrderInventoryStatistics().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesOrderInventoryStatistics, a, b);
    }
}
exports.SalesOrderInventoryStatistics = SalesOrderInventoryStatistics;
/**
 *
 * Describes the parameters that are part of a sales order's billing statistics payload
 *
 * @generated from message Scailo.SalesOrderBillingStatistics
 */
class SalesOrderBillingStatistics extends protobuf_1.Message {
    /**
     * Stores the cumulative quantity of the ordered inventory
     *
     * @generated from field: uint64 ordered = 1;
     */
    ordered = protobuf_1.protoInt64.zero;
    /**
     * Stores the cumulative quantity of the invoiced inventory
     *
     * @generated from field: uint64 invoiced = 2;
     */
    invoiced = protobuf_1.protoInt64.zero;
    /**
     * Stores the cumulative quantity of the credited inventory
     *
     * @generated from field: uint64 credited = 3;
     */
    credited = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesOrderBillingStatistics";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "ordered", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "invoiced", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "credited", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesOrderBillingStatistics().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesOrderBillingStatistics().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesOrderBillingStatistics().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesOrderBillingStatistics, a, b);
    }
}
exports.SalesOrderBillingStatistics = SalesOrderBillingStatistics;
/**
 *
 * Describes the parameters that are part of a sales order's inventory match
 *
 * @generated from message Scailo.SalesOrderInventoryMatch
 */
class SalesOrderInventoryMatch extends protobuf_1.Message {
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 1;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Stores the client unit of material ID
     *
     * @generated from field: uint64 client_uom_id = 2;
     */
    clientUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the ordered quantity in primary unit of material
     *
     * @generated from field: uint64 ordered_primary = 10;
     */
    orderedPrimary = protobuf_1.protoInt64.zero;
    /**
     * Stores the ordered quantity in secondary unit of material
     *
     * @generated from field: uint64 ordered_secondary = 20;
     */
    orderedSecondary = protobuf_1.protoInt64.zero;
    /**
     * Stores the dispatched quantity in primary unit of material
     *
     * @generated from field: uint64 dispatched_primary = 11;
     */
    dispatchedPrimary = protobuf_1.protoInt64.zero;
    /**
     * Stores the dispatched quantity in secondary unit of material
     *
     * @generated from field: uint64 dispatched_secondary = 21;
     */
    dispatchedSecondary = protobuf_1.protoInt64.zero;
    /**
     * Stores the invoiced quantity in primary unit of material
     *
     * @generated from field: uint64 invoiced_primary = 12;
     */
    invoicedPrimary = protobuf_1.protoInt64.zero;
    /**
     * Stores the invoiced quantity in secondary unit of material
     *
     * @generated from field: uint64 invoiced_secondary = 22;
     */
    invoicedSecondary = protobuf_1.protoInt64.zero;
    /**
     * Stores the returned quantity in primary unit of material
     *
     * @generated from field: uint64 returned_primary = 13;
     */
    returnedPrimary = protobuf_1.protoInt64.zero;
    /**
     * Stores the returned quantity in secondary unit of material
     *
     * @generated from field: uint64 returned_secondary = 23;
     */
    returnedSecondary = protobuf_1.protoInt64.zero;
    /**
     * Stores the credited quantity in primary unit of material
     *
     * @generated from field: uint64 credited_primary = 14;
     */
    creditedPrimary = protobuf_1.protoInt64.zero;
    /**
     * Stores the credited quantity in secondary unit of material
     *
     * @generated from field: uint64 credited_secondary = 24;
     */
    creditedSecondary = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesOrderInventoryMatch";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "client_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "ordered_primary", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "ordered_secondary", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "dispatched_primary", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "dispatched_secondary", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "invoiced_primary", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 22, name: "invoiced_secondary", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "returned_primary", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "returned_secondary", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "credited_primary", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "credited_secondary", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesOrderInventoryMatch().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesOrderInventoryMatch().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesOrderInventoryMatch().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesOrderInventoryMatch, a, b);
    }
}
exports.SalesOrderInventoryMatch = SalesOrderInventoryMatch;
/**
 *
 * Describes the list of inventory match families
 *
 * @generated from message Scailo.SalesOrderInventoryMatchList
 */
class SalesOrderInventoryMatchList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.SalesOrderInventoryMatch list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesOrderInventoryMatchList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: SalesOrderInventoryMatch, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalesOrderInventoryMatchList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesOrderInventoryMatchList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesOrderInventoryMatchList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesOrderInventoryMatchList, a, b);
    }
}
exports.SalesOrderInventoryMatchList = SalesOrderInventoryMatchList;
