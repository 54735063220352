"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file payroll_groups.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.PayrollGroupsService = void 0;
const payroll_groups_scailo_pb_js_1 = require("./payroll_groups.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
/**
 *
 * Describes the common methods applicable on each payroll group
 *
 * @generated from service Scailo.PayrollGroupsService
 */
exports.PayrollGroupsService = {
    typeName: "Scailo.PayrollGroupsService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.PayrollGroupsService.Create
         */
        create: {
            name: "Create",
            I: payroll_groups_scailo_pb_js_1.PayrollGroupsServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.PayrollGroupsService.Draft
         */
        draft: {
            name: "Draft",
            I: payroll_groups_scailo_pb_js_1.PayrollGroupsServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.PayrollGroupsService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: payroll_groups_scailo_pb_js_1.PayrollGroupsServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.PayrollGroupsService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.PayrollGroupsService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.PayrollGroupsService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.PayrollGroupsService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.PayrollGroupsService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: payroll_groups_scailo_pb_js_1.PayrollGroupsServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.PayrollGroupsService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.PayrollGroupsService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.PayrollGroupsService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.PayrollGroupsService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.PayrollGroupsService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reopen
         *
         * @generated from rpc Scailo.PayrollGroupsService.Reopen
         */
        reopen: {
            name: "Reopen",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * Send Email
         * rpc SendEmail (IdentifierWithEmailAttributes) returns (IdentifierResponse);
         *
         * @generated from rpc Scailo.PayrollGroupsService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Clone payroll group from an existing payroll group (denoted by the identifier)
         *
         * @generated from rpc Scailo.PayrollGroupsService.Clone
         */
        clone: {
            name: "Clone",
            I: base_scailo_pb_js_1.CloneRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add a param to a payroll group
         *
         * @generated from rpc Scailo.PayrollGroupsService.AddPayrollGroupItem
         */
        addPayrollGroupItem: {
            name: "AddPayrollGroupItem",
            I: payroll_groups_scailo_pb_js_1.PayrollGroupsServiceItemCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify a param in a payroll group
         *
         * @generated from rpc Scailo.PayrollGroupsService.ModifyPayrollGroupItem
         */
        modifyPayrollGroupItem: {
            name: "ModifyPayrollGroupItem",
            I: payroll_groups_scailo_pb_js_1.PayrollGroupsServiceItemUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve a param in a payroll group
         *
         * @generated from rpc Scailo.PayrollGroupsService.ApprovePayrollGroupItem
         */
        approvePayrollGroupItem: {
            name: "ApprovePayrollGroupItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete a param in a payroll group
         *
         * @generated from rpc Scailo.PayrollGroupsService.DeletePayrollGroupItem
         */
        deletePayrollGroupItem: {
            name: "DeletePayrollGroupItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder params in a payroll group
         *
         * @generated from rpc Scailo.PayrollGroupsService.ReorderPayrollGroupItems
         */
        reorderPayrollGroupItems: {
            name: "ReorderPayrollGroupItems",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Payroll Group Item by ID
         *
         * @generated from rpc Scailo.PayrollGroupsService.ViewPayrollGroupItemByID
         */
        viewPayrollGroupItemByID: {
            name: "ViewPayrollGroupItemByID",
            I: base_scailo_pb_js_1.Identifier,
            O: payroll_groups_scailo_pb_js_1.PayrollGroupItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved payroll group params for given payroll group ID
         *
         * @generated from rpc Scailo.PayrollGroupsService.ViewApprovedPayrollGroupItems
         */
        viewApprovedPayrollGroupItems: {
            name: "ViewApprovedPayrollGroupItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: payroll_groups_scailo_pb_js_1.PayrollGroupsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved payroll group params for given payroll group ID
         *
         * @generated from rpc Scailo.PayrollGroupsService.ViewUnapprovedPayrollGroupItems
         */
        viewUnapprovedPayrollGroupItems: {
            name: "ViewUnapprovedPayrollGroupItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: payroll_groups_scailo_pb_js_1.PayrollGroupsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the payroll group item
         *
         * @generated from rpc Scailo.PayrollGroupsService.ViewPayrollGroupItemHistory
         */
        viewPayrollGroupItemHistory: {
            name: "ViewPayrollGroupItemHistory",
            I: payroll_groups_scailo_pb_js_1.PayrollGroupItemHistoryRequest,
            O: payroll_groups_scailo_pb_js_1.PayrollGroupsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * CSV operations
         * Download the CSV file that could be used to upload items from the filled CSV file. The same file can also be used as a template when there are no existing records
         *
         * @generated from rpc Scailo.PayrollGroupsService.DownloadItemsAsCSV
         */
        downloadItemsAsCSV: {
            name: "DownloadItemsAsCSV",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Upload items using a CSV file. This is an idempotent operation. All the existing items are deleted before adding the items from the file.
         *
         * @generated from rpc Scailo.PayrollGroupsService.UploadPayrollGroupItems
         */
        uploadPayrollGroupItems: {
            name: "UploadPayrollGroupItems",
            I: base_scailo_pb_js_1.IdentifierUUIDWithFile,
            O: base_scailo_pb_js_1.IdentifiersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.PayrollGroupsService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: payroll_groups_scailo_pb_js_1.PayrollGroup,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by UUID
         *
         * @generated from rpc Scailo.PayrollGroupsService.ViewByUUID
         */
        viewByUUID: {
            name: "ViewByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: payroll_groups_scailo_pb_js_1.PayrollGroup,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View only essential components by ID (without logs)
         *
         * @generated from rpc Scailo.PayrollGroupsService.ViewEssentialByID
         */
        viewEssentialByID: {
            name: "ViewEssentialByID",
            I: base_scailo_pb_js_1.Identifier,
            O: payroll_groups_scailo_pb_js_1.PayrollGroup,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View only essential components (without logs) that matches the given UUID
         *
         * @generated from rpc Scailo.PayrollGroupsService.ViewEssentialByUUID
         */
        viewEssentialByUUID: {
            name: "ViewEssentialByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: payroll_groups_scailo_pb_js_1.PayrollGroup,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all records with the given IDs
         *
         * @generated from rpc Scailo.PayrollGroupsService.ViewFromIDs
         */
        viewFromIDs: {
            name: "ViewFromIDs",
            I: base_scailo_pb_js_1.IdentifiersList,
            O: payroll_groups_scailo_pb_js_1.PayrollGroupsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.PayrollGroupsService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: payroll_groups_scailo_pb_js_1.PayrollGroupsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.PayrollGroupsService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: payroll_groups_scailo_pb_js_1.PayrollGroupsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.PayrollGroupsService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: payroll_groups_scailo_pb_js_1.PayrollGroupsServicePaginationReq,
            O: payroll_groups_scailo_pb_js_1.PayrollGroupsServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.PayrollGroupsService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: payroll_groups_scailo_pb_js_1.PayrollGroupsServiceSearchAllReq,
            O: payroll_groups_scailo_pb_js_1.PayrollGroupsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.PayrollGroupsService.Filter
         */
        filter: {
            name: "Filter",
            I: payroll_groups_scailo_pb_js_1.PayrollGroupsServiceFilterReq,
            O: payroll_groups_scailo_pb_js_1.PayrollGroupsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.PayrollGroupsService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.PayrollGroupsService.Count
         */
        count: {
            name: "Count",
            I: payroll_groups_scailo_pb_js_1.PayrollGroupsServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * CSV operations
         * Download the CSV file that consists of the list of records according to the given filter request. The same file could also be used as a template for uploading records
         *
         * @generated from rpc Scailo.PayrollGroupsService.DownloadAsCSV
         */
        downloadAsCSV: {
            name: "DownloadAsCSV",
            I: payroll_groups_scailo_pb_js_1.PayrollGroupsServiceFilterReq,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Import records using a CSV file (duplicate codes will be skipped)
         *
         * @generated from rpc Scailo.PayrollGroupsService.ImportFromCSV
         */
        importFromCSV: {
            name: "ImportFromCSV",
            I: base_scailo_pb_js_1.StandardFile,
            O: base_scailo_pb_js_1.IdentifierUUIDsList,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
