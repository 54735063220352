import { _returnInCents, bgColor, convertBigIntTimestampToDate, convertCentsToMoney, createObjectFromForm, internationalizeMoney, round, showFailureAlert } from "../../utilities";
import { context } from "./../../router";
import { Family, getClientForCurrenciesService, getClientForProformaInvoicesService, getClientForSalesOrdersService, ProformaInvoice, ProformaInvoicesServiceFilterReq, STANDARD_LIFECYCLE_STATUS } from "@kernelminds/scailo-sdk";
import { _renderPageFilters } from "./searchcomponents";
import { protoInt64 } from "@bufbuild/protobuf";
import ApexCharts from 'apexcharts';
import { familiesListFromIDs } from "../../fetches";
import { getTransport } from "../../clients";
import { setupCurrencySearchable, setupSalesOrderSearchable } from "../../searchables";

export function handleProformaInvoiceInsights(ctx: context) {
    let content = <HTMLDivElement>document.getElementById("central-content");
    while (content.firstChild) {
        content.removeChild(content.firstChild);
    }
    let { html, formId, resetButtonId, getButtonId, refIdElementId, currencyReferenceId } = _renderPageFilters("Insights");
    let insightsHtml = document.createElement("div");
    insightsHtml.innerHTML = html

    content.appendChild(insightsHtml);

    // Setup searchable
    const transport = getTransport();
    const currenciesClient = getClientForCurrenciesService(transport);
    const salesOrdersClient = getClientForSalesOrdersService(transport);
    setupSalesOrderSearchable(refIdElementId, salesOrdersClient, { status: STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED });
    setupCurrencySearchable(currencyReferenceId, currenciesClient, {});

    let chartsContainer = document.createElement("div");
    // chartsContainer.className = "grid grid-cols-12";
    content.appendChild(chartsContainer);

    (<HTMLButtonElement>document.getElementById(resetButtonId)).addEventListener("click", async evt => {
        evt.preventDefault();
        handleProformaInvoiceInsights(ctx);
    });

    let getButton = (<HTMLButtonElement>document.getElementById(getButtonId));

    getButton.addEventListener("click", async evt => {
        evt.preventDefault();
        while (chartsContainer.firstChild) {
            chartsContainer.removeChild(chartsContainer.firstChild);
        }

        getButton.disabled = true;
        getButton.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;

        let client = getClientForProformaInvoicesService(transport);
        let [filterResp] = await Promise.all([
            client.filter(new ProformaInvoicesServiceFilterReq(createObjectFromForm(formId))),
        ]);

        getButton.disabled = false;
        getButton.innerText = `Get Insights`;

        const insightRecords = filterResp.list;

        if (!insightRecords.length) {
            showFailureAlert("No Records Found");
            return;
        }
        
        let chartsDiv = document.createElement("div");
        chartsDiv.className = `overflow-x-auto p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words ${bgColor} w-full shadow-lg rounded grid grid-cols-12`;
        chartsContainer.appendChild(chartsDiv);

        const commonTheme = {
            palette: 'palette2',
        };
        // Display all the insights here
        displayAllRelevantCharts(insightRecords, chartsDiv, commonTheme);
        chartsDiv.scrollIntoView({ behavior: "smooth" });
    });
}

/**Displays all the charts relevant to proforma invoices */
export function displayAllRelevantCharts(proformainvoices: ProformaInvoice[], chartsDiv: HTMLDivElement, commonTheme: Object) {
    renderAreaChart(proformainvoices, chartsDiv, commonTheme);
    renderTopProformaInvoices(proformainvoices, chartsDiv, commonTheme);
    renderTopMaterials(proformainvoices, chartsDiv, commonTheme);
}

function renderAreaChart(proformainvoices: ProformaInvoice[], chartsDiv: HTMLDivElement, commonTheme: Object) {
    let localChartDiv = document.createElement("div");
    localChartDiv.classList.add("col-span-12");
    chartsDiv.appendChild(localChartDiv);

    let cumulativeSeriesData = <number[]>[];
    let incrementingTotalVal = 0;
    let totalValue = 0;
    proformainvoices.forEach(proformainvoice => {
        incrementingTotalVal += proformainvoice.totalValue;
        cumulativeSeriesData.push(parseFloat(round(incrementingTotalVal)));
        totalValue += proformainvoice.totalValue;
    });

    let options = {
        title: {
            text: `Total Proforma Invoices: ${proformainvoices.length}, Total Value: ${internationalizeMoney(totalValue)}`,
            align: "left",
            margin: 10,
            offsetX: 0,
            offsetY: -10,
            floating: true,
            style: {
                fontSize: "20px",
                fontWeight: "light",
                color: "#263238"
            },
        },
        theme: commonTheme,
        series: [
            // Area Series
            {
                name: "Cumulative Proforma Invoices Value",
                type: "area",
                data: cumulativeSeriesData
            },
            // Line Series
            {
                name: "Individual Proforma Invoice Value",
                type: "line",
                data: proformainvoices.map(proformainvoice => parseFloat(round(proformainvoice.totalValue)))
            }
        ],
        chart: {
            height: 500,
            type: "line",
        },
        stroke: {
            curve: "smooth"
        },
        fill: {
            type: "solid",
            opacity: [0.25, 1],
        },
        labels: proformainvoices.map(proformainvoice => convertBigIntTimestampToDate(proformainvoice.approvalMetadata!.approvedOn)),
        markers: {
            size: 2
        },
        legend: {
            position: "top"
        },
        yaxis: [
            {
                title: {
                    text: "Cumulative Proforma Invoices Value",
                },
            },
            {
                opposite: true,
                title: {
                    text: "Individual Proforma Invoice Value",
                },
            },
        ],

        tooltip: {
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                let proformainvoice = proformainvoices[dataPointIndex];
                return `
                    <ul style='background-color: #424242; color: #F5F5F5; padding: 20px; margin: 0px;'>
                        <li>Proforma Invoice Number: ${proformainvoice.approvalMetadata!.approvedOn > 0 ? proformainvoice.finalRefNumber : proformainvoice.referenceId}</li>
                        <li>Proforma Invoice Value: ${internationalizeMoney(proformainvoice.totalValue)}</li>
                        <li>Cumulative Value: ${internationalizeMoney(cumulativeSeriesData[dataPointIndex])}</li>
                        <li>Approved Date: ${convertBigIntTimestampToDate(proformainvoice.approvalMetadata!.approvedOn)}</li>
                        <li>Index: ${dataPointIndex + 1}</li>
                    </ul>
                `;
            }
        }
    };

    let chart = new ApexCharts(localChartDiv, options);
    chart.render();
}

function renderTopProformaInvoices(proformainvoices: ProformaInvoice[], chartsDiv: HTMLDivElement, commonTheme: Object) {
    let localChartDiv = document.createElement("div");
    localChartDiv.classList.add("col-span-12");
    chartsDiv.appendChild(localChartDiv);

    const topCount = 10;

    // Sort proformainvoices by total value
    proformainvoices.sort((a, b) => b.totalValue - a.totalValue);

    // Slice the arrays
    if (proformainvoices.length > topCount) {
        proformainvoices = proformainvoices.slice(0, topCount);
    }

    let options = {
        title: {
            text: `Top ${proformainvoices.length} Proforma Invoices By Value`,
            align: "center",
            margin: 10,
            offsetX: 0,
            offsetY: -10,
            floating: true,
            style: {
                fontSize: "20px",
                fontWeight: "light",
                color: "#263238"
            },
        },
        theme: commonTheme,
        series: [
            {
                data: proformainvoices.map(s => {
                    return parseFloat(s.totalValue.toFixed(2))
                })
            }
        ],
        chart: {
            height: 500,
            type: "bar",
        },
        legend: {
            position: "top"
        },
        plotOptions: {
            bar: {
                horizontal: true,
            }
        },
        dataLabels: {
            enabled: true
        },
        xaxis: {
            categories: proformainvoices.map(s => s.approvalMetadata!.approvedOn > 0 ? s.finalRefNumber : s.referenceId),
            labels: {
                show: false,
                rotate: -45,
            }
        },
        grid: {
            xaxis: {
                lines: {
                    show: false
                }
            }
        },
        yaxis: {
            reversed: false,
            axisTicks: {
                show: false
            }
        },

        tooltip: {
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                let proformainvoice = proformainvoices[dataPointIndex];
                return `
                    <ul style='background-color: #424242; color: #F5F5F5; padding: 20px; margin: 0px;'>
                        <li>Ranking: ${dataPointIndex + 1}</li>
                        <li>Proforma Invoice: ${proformainvoice.approvalMetadata!.approvedOn > 0 ? proformainvoice.finalRefNumber : proformainvoice.referenceId}</li>
                        <li>Total Value: ${internationalizeMoney(proformainvoice.totalValue)}</li>
                    </ul>
                `;
            }
        }
    };

    let chart = new ApexCharts(localChartDiv, options);
    chart.render();
}

interface proformaInvoiceFamilyForChart {
    family_id: bigint
    internal_quantity: bigint
    client_quantity: bigint
    value: bigint
}

async function renderTopMaterials(proformainvoices: ProformaInvoice[], chartsDiv: HTMLDivElement, commonTheme: Object) {
    let localChartDiv = document.createElement("div");
    localChartDiv.classList.add("col-span-12");

    const innerGrid = document.createElement("div");
    innerGrid.classList.add("grid", "grid-cols-12");
    localChartDiv.appendChild(innerGrid);

    chartsDiv.appendChild(localChartDiv);

    // Map between family ID and cumulative quantity
    let byQuantityMap: Map<bigint, proformaInvoiceFamilyForChart> = new Map();
    // Map between family ID and cumulative value
    let byValueMap: Map<bigint, proformaInvoiceFamilyForChart> = new Map();

    // Fill up the maps with the values
    proformainvoices.forEach(proformainvoice => {
        proformainvoice.list.forEach(item => {
            // Set the quantity here
            if (byQuantityMap.has(item.familyId)) {
                let mapItem = byQuantityMap.get(item.familyId) as proformaInvoiceFamilyForChart;
                mapItem.internal_quantity += item.internalQuantity;
                mapItem.client_quantity += item.clientQuantity;
                mapItem.value += item.clientQuantity * item.unitPrice / protoInt64.parse(100)
                byQuantityMap.set(item.familyId, mapItem);
            } else {
                byQuantityMap.set(item.familyId, {
                    family_id: item.familyId,
                    internal_quantity: item.internalQuantity,
                    client_quantity: item.clientQuantity,
                    value: item.clientQuantity * item.unitPrice / protoInt64.parse(100)
                });
            }
            // Compute the value here
            if (byValueMap.has(item.familyId)) {
                let mapItem = byValueMap.get(item.familyId) as proformaInvoiceFamilyForChart;
                mapItem.internal_quantity += item.internalQuantity;
                mapItem.client_quantity += item.clientQuantity;
                mapItem.value += item.clientQuantity * item.unitPrice / protoInt64.parse(100)
                byValueMap.set(item.familyId, mapItem);
            } else {
                byValueMap.set(item.familyId, {
                    family_id: item.familyId,
                    internal_quantity: item.internalQuantity,
                    client_quantity: item.clientQuantity,
                    value: item.clientQuantity * item.unitPrice / protoInt64.parse(100)
                });
            }
        });
    });

    // Create the array here
    let byQuantityList = Array.from(byQuantityMap.values());
    let byValueList = Array.from(byValueMap.values());

    // Sort
    byQuantityList.sort((a, b) => parseInt(String(b.internal_quantity)) - parseInt(String(a.internal_quantity)));
    byValueList.sort((a, b) => parseInt(String(b.value)) - parseInt(String(a.value)));

    const topCount = 10;

    // Slice the arrays
    if (byQuantityList.length > topCount) {
        byQuantityList = byQuantityList.slice(0, topCount);
    }
    if (byValueList.length > topCount) {
        byValueList = byValueList.slice(0, topCount);
    }

    // Retrieve applicable families here (need to concat family IDs from both lists, and then create a Set)
    let familiesList = await familiesListFromIDs(Array.from(new Set(byQuantityList.map(b => b.family_id).concat(byValueList.map(b => b.family_id)))));
    let familiesMap: Map<bigint, Family> = new Map();
    familiesList.forEach(fam => {
        familiesMap.set(fam.metadata!.id, fam);
    });

    let chartByQuantityDiv = document.createElement("div");
    chartByQuantityDiv.classList.add("col-span-12");
    chartByQuantityDiv.classList.add("md:col-span-6");
    innerGrid.appendChild(chartByQuantityDiv);

    let chartByQuantity = new ApexCharts(chartByQuantityDiv, {
        title: {
            text: `Top ${byQuantityList.length} Materials By Quantity`,
            align: "center",
            margin: 10,
            offsetX: 0,
            offsetY: -10,
            floating: true,
            style: {
                fontSize: "20px",
                fontWeight: "light",
                color: "#263238"
            },
        },
        theme: commonTheme,
        series: [
            {
                data: byQuantityList.map(s => {
                    return parseFloat(convertCentsToMoney(s.internal_quantity)).toFixed(2)
                })
            },
        ],
        chart: {
            height: 500,
            type: "bar",
        },
        legend: {
            position: "top"
        },
        plotOptions: {
            bar: {
                horizontal: true,
            }
        },
        dataLabels: {
            enabled: true
        },
        xaxis: {
            categories: byQuantityList.map(s => {
                return familiesMap.get(s.family_id)?.code;
            }),
            labels: {
                show: false,
                rotate: -45,
            }
        },
        grid: {
            xaxis: {
                lines: {
                    show: false
                }
            }
        },
        yaxis: {
            reversed: false,
            axisTicks: {
                show: false
            }
        },

        tooltip: {
            fixed: {
                enabled: true,
                position: "topLeft",
            },
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                let item = byQuantityList[dataPointIndex];
                let family = familiesMap.get(item.family_id) as Family;
                let avgPrice = parseInt(String(item.value)) / parseInt(String(item.internal_quantity));

                return `
                    <ul style='background-color: #424242; color: #F5F5F5; padding: 20px; margin: 0px;'>
                        <li>Ranking: ${dataPointIndex + 1}</li>
                        <li>Material: (${family.code}) ${family.name}</li>
                        <li>Total Quantity: ${internationalizeMoney(parseInt(String(item.internal_quantity / protoInt64.parse(100))))}</li>
                        <li>Total Value: ${internationalizeMoney(parseInt(String(item.value / protoInt64.parse(100))))}</li>
                        <li>Avg Price: ${internationalizeMoney(avgPrice)}</li>
                    </ul>
                `;
            }
        }
    });
    chartByQuantity.render();

    let chartByValueDiv = document.createElement("div");
    chartByValueDiv.classList.add("col-span-12");
    chartByValueDiv.classList.add("md:col-span-6");
    innerGrid.appendChild(chartByValueDiv);

    let chartByValue = new ApexCharts(chartByValueDiv, {
        title: {
            text: `Top ${byValueList.length} Materials By Value`,
            align: "center",
            margin: 10,
            offsetX: 0,
            offsetY: -10,
            floating: true,
            style: {
                fontSize: "20px",
                fontWeight: "light",
                color: "#263238"
            },
        },
        theme: commonTheme,
        series: [
            {
                data: byValueList.map(s => {
                    return parseFloat(convertCentsToMoney(s.value)).toFixed(2)
                })
            },
        ],
        chart: {
            height: 500,
            type: "bar",
        },
        legend: {
            position: "top"
        },
        plotOptions: {
            bar: {
                horizontal: true,
            }
        },
        dataLabels: {
            enabled: true
        },
        xaxis: {
            categories: byValueList.map(s => {
                return familiesMap.get(s.family_id)?.code;
            }),
            labels: {
                show: false,
                rotate: -45,
            }
        },
        grid: {
            xaxis: {
                lines: {
                    show: false
                }
            }
        },
        yaxis: {
            reversed: false,
            axisTicks: {
                show: false
            }
        },

        tooltip: {
            fixed: {
                enabled: true,
                position: "topLeft",
            },
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                let item = byValueList[dataPointIndex];
                let family = familiesMap.get(item.family_id) as Family;
                let avgPrice = parseInt(String(item.value)) / parseInt(String(item.internal_quantity));
                return `
                    <ul style='background-color: #424242; color: #F5F5F5; padding: 20px; margin: 0px;'>
                        <li>Ranking: ${dataPointIndex + 1}</li>
                        <li>Material: (${family.code}) ${family.name}</li>
                        <li>Total Quantity: ${internationalizeMoney(parseInt(String(item.internal_quantity / protoInt64.parse(100))))}</li>
                        <li>Total Value: ${internationalizeMoney(parseInt(String(item.value / protoInt64.parse(100))))}</li>
                        <li>Avg Price: ${internationalizeMoney(avgPrice)}</li>
                    </ul>
                `;
            }
        }
    });
    chartByValue.render();
}