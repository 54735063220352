"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file quotations_requests.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuotationsRequestsService = void 0;
const quotations_requests_scailo_pb_js_1 = require("./quotations_requests.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
/**
 *
 * Describes the common methods applicable on each quotation request
 *
 * @generated from service Scailo.QuotationsRequestsService
 */
exports.QuotationsRequestsService = {
    typeName: "Scailo.QuotationsRequestsService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.QuotationsRequestsService.Create
         */
        create: {
            name: "Create",
            I: quotations_requests_scailo_pb_js_1.QuotationsRequestsServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.QuotationsRequestsService.Draft
         */
        draft: {
            name: "Draft",
            I: quotations_requests_scailo_pb_js_1.QuotationsRequestsServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.QuotationsRequestsService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: quotations_requests_scailo_pb_js_1.QuotationsRequestsServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.QuotationsRequestsService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.QuotationsRequestsService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.QuotationsRequestsService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.QuotationsRequestsService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.QuotationsRequestsService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: quotations_requests_scailo_pb_js_1.QuotationsRequestsServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.QuotationsRequestsService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.QuotationsRequestsService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.QuotationsRequestsService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.QuotationsRequestsService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.QuotationsRequestsService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.RepeatWithDeliveryDate,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reopen
         *
         * @generated from rpc Scailo.QuotationsRequestsService.Reopen
         */
        reopen: {
            name: "Reopen",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * @generated from rpc Scailo.QuotationsRequestsService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Autofill the quotation request (from the associated purchase enquiry)
         *
         * @generated from rpc Scailo.QuotationsRequestsService.Autofill
         */
        autofill: {
            name: "Autofill",
            I: quotations_requests_scailo_pb_js_1.QuotationsRequestsServiceAutofillRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an item to a quotation request
         *
         * @generated from rpc Scailo.QuotationsRequestsService.AddQuotationRequestItem
         */
        addQuotationRequestItem: {
            name: "AddQuotationRequestItem",
            I: quotations_requests_scailo_pb_js_1.QuotationsRequestsServiceItemCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify an item in a quotation request
         *
         * @generated from rpc Scailo.QuotationsRequestsService.ModifyQuotationRequestItem
         */
        modifyQuotationRequestItem: {
            name: "ModifyQuotationRequestItem",
            I: quotations_requests_scailo_pb_js_1.QuotationsRequestsServiceItemUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve an item in a quotation request
         *
         * @generated from rpc Scailo.QuotationsRequestsService.ApproveQuotationRequestItem
         */
        approveQuotationRequestItem: {
            name: "ApproveQuotationRequestItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an item in a quotation request
         *
         * @generated from rpc Scailo.QuotationsRequestsService.DeleteQuotationRequestItem
         */
        deleteQuotationRequestItem: {
            name: "DeleteQuotationRequestItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder items in a quotation request
         *
         * @generated from rpc Scailo.QuotationsRequestsService.ReorderQuotationRequestItems
         */
        reorderQuotationRequestItems: {
            name: "ReorderQuotationRequestItems",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View quotation request Item by ID
         *
         * @generated from rpc Scailo.QuotationsRequestsService.ViewQuotationRequestItemByID
         */
        viewQuotationRequestItemByID: {
            name: "ViewQuotationRequestItemByID",
            I: base_scailo_pb_js_1.Identifier,
            O: quotations_requests_scailo_pb_js_1.QuotationRequestItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved quotation request items for given quotation request ID
         *
         * @generated from rpc Scailo.QuotationsRequestsService.ViewApprovedQuotationRequestItems
         */
        viewApprovedQuotationRequestItems: {
            name: "ViewApprovedQuotationRequestItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: quotations_requests_scailo_pb_js_1.QuotationsRequestsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved quotation request items for given quotation request ID
         *
         * @generated from rpc Scailo.QuotationsRequestsService.ViewUnapprovedQuotationRequestItems
         */
        viewUnapprovedQuotationRequestItems: {
            name: "ViewUnapprovedQuotationRequestItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: quotations_requests_scailo_pb_js_1.QuotationsRequestsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the quotation request item
         *
         * @generated from rpc Scailo.QuotationsRequestsService.ViewQuotationRequestItemHistory
         */
        viewQuotationRequestItemHistory: {
            name: "ViewQuotationRequestItemHistory",
            I: quotations_requests_scailo_pb_js_1.QuotationRequestItemHistoryRequest,
            O: quotations_requests_scailo_pb_js_1.QuotationsRequestsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved quotation request items for given quotation request ID with pagination
         *
         * @generated from rpc Scailo.QuotationsRequestsService.ViewPaginatedApprovedQuotationRequestItems
         */
        viewPaginatedApprovedQuotationRequestItems: {
            name: "ViewPaginatedApprovedQuotationRequestItems",
            I: quotations_requests_scailo_pb_js_1.QuotationRequestItemsSearchRequest,
            O: quotations_requests_scailo_pb_js_1.QuotationsRequestsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved quotation request items for given quotation request ID with pagination
         *
         * @generated from rpc Scailo.QuotationsRequestsService.ViewPaginatedUnapprovedQuotationRequestItems
         */
        viewPaginatedUnapprovedQuotationRequestItems: {
            name: "ViewPaginatedUnapprovedQuotationRequestItems",
            I: quotations_requests_scailo_pb_js_1.QuotationRequestItemsSearchRequest,
            O: quotations_requests_scailo_pb_js_1.QuotationsRequestsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through quotation request items with pagination
         *
         * @generated from rpc Scailo.QuotationsRequestsService.SearchItemsWithPagination
         */
        searchItemsWithPagination: {
            name: "SearchItemsWithPagination",
            I: quotations_requests_scailo_pb_js_1.QuotationRequestItemsSearchRequest,
            O: quotations_requests_scailo_pb_js_1.QuotationsRequestsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * CSV operations
         * Download the CSV file that could be used to upload items from the filled CSV file. The same file can also be used as a template when there are no existing records
         *
         * @generated from rpc Scailo.QuotationsRequestsService.DownloadItemsAsCSV
         */
        downloadItemsAsCSV: {
            name: "DownloadItemsAsCSV",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Upload items using a CSV file. This is an idempotent operation. All the existing items are deleted before adding the items from the file.
         *
         * @generated from rpc Scailo.QuotationsRequestsService.UploadQuotationRequestItems
         */
        uploadQuotationRequestItems: {
            name: "UploadQuotationRequestItems",
            I: base_scailo_pb_js_1.IdentifierUUIDWithFile,
            O: base_scailo_pb_js_1.IdentifiersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.QuotationsRequestsService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: quotations_requests_scailo_pb_js_1.QuotationRequest,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by UUID
         *
         * @generated from rpc Scailo.QuotationsRequestsService.ViewByUUID
         */
        viewByUUID: {
            name: "ViewByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: quotations_requests_scailo_pb_js_1.QuotationRequest,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View only essential components by ID (without logs)
         *
         * @generated from rpc Scailo.QuotationsRequestsService.ViewEssentialByID
         */
        viewEssentialByID: {
            name: "ViewEssentialByID",
            I: base_scailo_pb_js_1.Identifier,
            O: quotations_requests_scailo_pb_js_1.QuotationRequest,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View only essential components (without logs) that matches the given UUID
         *
         * @generated from rpc Scailo.QuotationsRequestsService.ViewEssentialByUUID
         */
        viewEssentialByUUID: {
            name: "ViewEssentialByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: quotations_requests_scailo_pb_js_1.QuotationRequest,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all records with the given IDs
         *
         * @generated from rpc Scailo.QuotationsRequestsService.ViewFromIDs
         */
        viewFromIDs: {
            name: "ViewFromIDs",
            I: base_scailo_pb_js_1.IdentifiersList,
            O: quotations_requests_scailo_pb_js_1.QuotationsRequestsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the ancillary parameters (UUIDs of the internal references) by UUID
         *
         * @generated from rpc Scailo.QuotationsRequestsService.ViewAncillaryParametersByUUID
         */
        viewAncillaryParametersByUUID: {
            name: "ViewAncillaryParametersByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: quotations_requests_scailo_pb_js_1.QuotationRequestAncillaryParameters,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.QuotationsRequestsService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: quotations_requests_scailo_pb_js_1.QuotationsRequestsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.QuotationsRequestsService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: quotations_requests_scailo_pb_js_1.QuotationsRequestsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.QuotationsRequestsService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: quotations_requests_scailo_pb_js_1.QuotationsRequestsServicePaginationReq,
            O: quotations_requests_scailo_pb_js_1.QuotationsRequestsServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download quotation request with the given IdentifierUUID (can be used to allow public downloads)
         *
         * @generated from rpc Scailo.QuotationsRequestsService.DownloadByUUID
         */
        downloadByUUID: {
            name: "DownloadByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.QuotationsRequestsService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: quotations_requests_scailo_pb_js_1.QuotationsRequestsServiceSearchAllReq,
            O: quotations_requests_scailo_pb_js_1.QuotationsRequestsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.QuotationsRequestsService.Filter
         */
        filter: {
            name: "Filter",
            I: quotations_requests_scailo_pb_js_1.QuotationsRequestsServiceFilterReq,
            O: quotations_requests_scailo_pb_js_1.QuotationsRequestsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.QuotationsRequestsService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.QuotationsRequestsService.Count
         */
        count: {
            name: "Count",
            I: quotations_requests_scailo_pb_js_1.QuotationsRequestsServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * CSV operations
         * Download the CSV file that consists of the list of records according to the given filter request. The same file could also be used as a template for uploading records
         *
         * @generated from rpc Scailo.QuotationsRequestsService.DownloadAsCSV
         */
        downloadAsCSV: {
            name: "DownloadAsCSV",
            I: quotations_requests_scailo_pb_js_1.QuotationsRequestsServiceFilterReq,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
