"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file stock_audits.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.StockAuditsService = void 0;
const stock_audits_scailo_pb_js_1 = require("./stock_audits.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
const families_scailo_pb_js_1 = require("./families.scailo_pb.js");
/**
 *
 * Describes the common methods applicable on each stock audit
 *
 * @generated from service Scailo.StockAuditsService
 */
exports.StockAuditsService = {
    typeName: "Scailo.StockAuditsService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.StockAuditsService.Create
         */
        create: {
            name: "Create",
            I: stock_audits_scailo_pb_js_1.StockAuditsServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.StockAuditsService.Draft
         */
        draft: {
            name: "Draft",
            I: stock_audits_scailo_pb_js_1.StockAuditsServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.StockAuditsService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: stock_audits_scailo_pb_js_1.StockAuditsServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.StockAuditsService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.StockAuditsService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.StockAuditsService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.StockAuditsService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.StockAuditsService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: stock_audits_scailo_pb_js_1.StockAuditsServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.StockAuditsService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.StockAuditsService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.StockAuditsService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.StockAuditsService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.StockAuditsService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reopen
         *
         * @generated from rpc Scailo.StockAuditsService.Reopen
         */
        reopen: {
            name: "Reopen",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * @generated from rpc Scailo.StockAuditsService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send Email
         *
         * @generated from rpc Scailo.StockAuditsService.SendEmail
         */
        sendEmail: {
            name: "SendEmail",
            I: base_scailo_pb_js_1.IdentifierWithEmailAttributes,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Checks if the Stock Audit can be marked as completed
         *
         * @generated from rpc Scailo.StockAuditsService.IsCompletable
         */
        isCompletable: {
            name: "IsCompletable",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.BooleanResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an item to a stock audit
         *
         * @generated from rpc Scailo.StockAuditsService.AddStockAuditItem
         */
        addStockAuditItem: {
            name: "AddStockAuditItem",
            I: stock_audits_scailo_pb_js_1.StockAuditsServiceItemCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify an item in a stock audit
         *
         * @generated from rpc Scailo.StockAuditsService.ModifyStockAuditItem
         */
        modifyStockAuditItem: {
            name: "ModifyStockAuditItem",
            I: stock_audits_scailo_pb_js_1.StockAuditsServiceItemUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve an item in a stock audit
         *
         * @generated from rpc Scailo.StockAuditsService.ApproveStockAuditItem
         */
        approveStockAuditItem: {
            name: "ApproveStockAuditItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an item in a stock audit
         *
         * @generated from rpc Scailo.StockAuditsService.DeleteStockAuditItem
         */
        deleteStockAuditItem: {
            name: "DeleteStockAuditItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder items in a stock audit
         *
         * @generated from rpc Scailo.StockAuditsService.ReorderStockAuditItems
         */
        reorderStockAuditItems: {
            name: "ReorderStockAuditItems",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Stock Audit Item by ID
         *
         * @generated from rpc Scailo.StockAuditsService.ViewStockAuditItemByID
         */
        viewStockAuditItemByID: {
            name: "ViewStockAuditItemByID",
            I: base_scailo_pb_js_1.Identifier,
            O: stock_audits_scailo_pb_js_1.StockAuditItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Stock Audit Item by Inventory Hash
         *
         * @generated from rpc Scailo.StockAuditsService.ViewStockAuditItemByInventoryHash
         */
        viewStockAuditItemByInventoryHash: {
            name: "ViewStockAuditItemByInventoryHash",
            I: base_scailo_pb_js_1.SimpleSearchReq,
            O: stock_audits_scailo_pb_js_1.StockAuditItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved stock audit items for given stock audit ID
         *
         * @generated from rpc Scailo.StockAuditsService.ViewApprovedStockAuditItems
         */
        viewApprovedStockAuditItems: {
            name: "ViewApprovedStockAuditItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: stock_audits_scailo_pb_js_1.StockAuditsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved stock audit items for given stock audit ID
         *
         * @generated from rpc Scailo.StockAuditsService.ViewUnapprovedStockAuditItems
         */
        viewUnapprovedStockAuditItems: {
            name: "ViewUnapprovedStockAuditItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: stock_audits_scailo_pb_js_1.StockAuditsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the stock audit item
         *
         * @generated from rpc Scailo.StockAuditsService.ViewStockAuditItemHistory
         */
        viewStockAuditItemHistory: {
            name: "ViewStockAuditItemHistory",
            I: stock_audits_scailo_pb_js_1.StockAuditItemHistoryRequest,
            O: stock_audits_scailo_pb_js_1.StockAuditsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved stock audit items for given stock audit ID with pagination
         *
         * @generated from rpc Scailo.StockAuditsService.ViewPaginatedApprovedStockAuditItems
         */
        viewPaginatedApprovedStockAuditItems: {
            name: "ViewPaginatedApprovedStockAuditItems",
            I: stock_audits_scailo_pb_js_1.StockAuditItemsSearchRequest,
            O: stock_audits_scailo_pb_js_1.StockAuditsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved stock audit items for given stock audit ID with pagination
         *
         * @generated from rpc Scailo.StockAuditsService.ViewPaginatedUnapprovedStockAuditItems
         */
        viewPaginatedUnapprovedStockAuditItems: {
            name: "ViewPaginatedUnapprovedStockAuditItems",
            I: stock_audits_scailo_pb_js_1.StockAuditItemsSearchRequest,
            O: stock_audits_scailo_pb_js_1.StockAuditsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through stock audit items with pagination
         *
         * @generated from rpc Scailo.StockAuditsService.SearchItemsWithPagination
         */
        searchItemsWithPagination: {
            name: "SearchItemsWithPagination",
            I: stock_audits_scailo_pb_js_1.StockAuditItemsSearchRequest,
            O: stock_audits_scailo_pb_js_1.StockAuditsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * CSV operations
         * Download the CSV file that could be used to upload items from the filled CSV file. The same file can also be used as a template when there are no existing records
         *
         * @generated from rpc Scailo.StockAuditsService.DownloadItemsAsCSV
         */
        downloadItemsAsCSV: {
            name: "DownloadItemsAsCSV",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Upload items using a CSV file. This is an idempotent operation. All the existing items are deleted before adding the items from the file.
         *
         * @generated from rpc Scailo.StockAuditsService.UploadStockAuditItems
         */
        uploadStockAuditItems: {
            name: "UploadStockAuditItems",
            I: base_scailo_pb_js_1.IdentifierUUIDWithFile,
            O: base_scailo_pb_js_1.IdentifiersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.StockAuditsService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: stock_audits_scailo_pb_js_1.StockAudit,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by UUID
         *
         * @generated from rpc Scailo.StockAuditsService.ViewByUUID
         */
        viewByUUID: {
            name: "ViewByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: stock_audits_scailo_pb_js_1.StockAudit,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View only essential components by ID (without logs)
         *
         * @generated from rpc Scailo.StockAuditsService.ViewEssentialByID
         */
        viewEssentialByID: {
            name: "ViewEssentialByID",
            I: base_scailo_pb_js_1.Identifier,
            O: stock_audits_scailo_pb_js_1.StockAudit,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View only essential components (without logs) that matches the given UUID
         *
         * @generated from rpc Scailo.StockAuditsService.ViewEssentialByUUID
         */
        viewEssentialByUUID: {
            name: "ViewEssentialByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: stock_audits_scailo_pb_js_1.StockAudit,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all records with the given IDs
         *
         * @generated from rpc Scailo.StockAuditsService.ViewFromIDs
         */
        viewFromIDs: {
            name: "ViewFromIDs",
            I: base_scailo_pb_js_1.IdentifiersList,
            O: stock_audits_scailo_pb_js_1.StockAuditsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.StockAuditsService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: stock_audits_scailo_pb_js_1.StockAuditsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.StockAuditsService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: stock_audits_scailo_pb_js_1.StockAuditsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.StockAuditsService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: stock_audits_scailo_pb_js_1.StockAuditsServicePaginationReq,
            O: stock_audits_scailo_pb_js_1.StockAuditsServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective families for the given stock audit
         *
         * @generated from rpc Scailo.StockAuditsService.ViewProspectiveFamilies
         */
        viewProspectiveFamilies: {
            name: "ViewProspectiveFamilies",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Filter prospective families for the record represented by the given UUID identifier
         *
         * @generated from rpc Scailo.StockAuditsService.FilterProspectiveFamilies
         */
        filterProspectiveFamilies: {
            name: "FilterProspectiveFamilies",
            I: families_scailo_pb_js_1.FilterFamiliesReqForIdentifier,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective stock audit item info for the given family ID and stock audit ID
         *
         * @generated from rpc Scailo.StockAuditsService.ViewProspectiveStockAuditItem
         */
        viewProspectiveStockAuditItem: {
            name: "ViewProspectiveStockAuditItem",
            I: stock_audits_scailo_pb_js_1.StockAuditItemProspectiveInfoRequest,
            O: stock_audits_scailo_pb_js_1.StockAuditsServiceItemCreateRequest,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download the label for the stock audit with the given IdentifierUUID
         *
         * @generated from rpc Scailo.StockAuditsService.DownloadLabelByUUID
         */
        downloadLabelByUUID: {
            name: "DownloadLabelByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download stock audit with the given IdentifierUUID (can be used to allow public downloads)
         *
         * @generated from rpc Scailo.StockAuditsService.DownloadByUUID
         */
        downloadByUUID: {
            name: "DownloadByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.StockAuditsService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: stock_audits_scailo_pb_js_1.StockAuditsServiceSearchAllReq,
            O: stock_audits_scailo_pb_js_1.StockAuditsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.StockAuditsService.Filter
         */
        filter: {
            name: "Filter",
            I: stock_audits_scailo_pb_js_1.StockAuditsServiceFilterReq,
            O: stock_audits_scailo_pb_js_1.StockAuditsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.StockAuditsService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.StockAuditsService.Count
         */
        count: {
            name: "Count",
            I: stock_audits_scailo_pb_js_1.StockAuditsServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * CSV operations
         * Download the CSV file that consists of the list of records according to the given filter request. The same file could also be used as a template for uploading records
         *
         * @generated from rpc Scailo.StockAuditsService.DownloadAsCSV
         */
        downloadAsCSV: {
            name: "DownloadAsCSV",
            I: stock_audits_scailo_pb_js_1.StockAuditsServiceFilterReq,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
