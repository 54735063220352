"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file production_indents.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductionIndentsService = void 0;
const production_indents_scailo_pb_js_1 = require("./production_indents.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
const families_scailo_pb_js_1 = require("./families.scailo_pb.js");
/**
 *
 * Describes the common methods applicable on each production indent
 *
 * @generated from service Scailo.ProductionIndentsService
 */
exports.ProductionIndentsService = {
    typeName: "Scailo.ProductionIndentsService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.ProductionIndentsService.Create
         */
        create: {
            name: "Create",
            I: production_indents_scailo_pb_js_1.ProductionIndentsServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.ProductionIndentsService.Draft
         */
        draft: {
            name: "Draft",
            I: production_indents_scailo_pb_js_1.ProductionIndentsServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.ProductionIndentsService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: production_indents_scailo_pb_js_1.ProductionIndentsServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.ProductionIndentsService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.ProductionIndentsService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.ProductionIndentsService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.ProductionIndentsService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.ProductionIndentsService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: production_indents_scailo_pb_js_1.ProductionIndentsServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.ProductionIndentsService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.ProductionIndentsService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.ProductionIndentsService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.ProductionIndentsService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.ProductionIndentsService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reopen
         *
         * @generated from rpc Scailo.ProductionIndentsService.Reopen
         */
        reopen: {
            name: "Reopen",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * @generated from rpc Scailo.ProductionIndentsService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send Email
         *
         * @generated from rpc Scailo.ProductionIndentsService.SendEmail
         */
        sendEmail: {
            name: "SendEmail",
            I: base_scailo_pb_js_1.IdentifierWithEmailAttributes,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Autofill the production indent
         *
         * @generated from rpc Scailo.ProductionIndentsService.Autofill
         */
        autofill: {
            name: "Autofill",
            I: production_indents_scailo_pb_js_1.ProductionIndentsServiceAutofillRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Checks if the Production Indent can be marked as completed
         *
         * @generated from rpc Scailo.ProductionIndentsService.IsCompletable
         */
        isCompletable: {
            name: "IsCompletable",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.BooleanResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an item to a production indent
         *
         * @generated from rpc Scailo.ProductionIndentsService.AddProductionIndentItem
         */
        addProductionIndentItem: {
            name: "AddProductionIndentItem",
            I: production_indents_scailo_pb_js_1.ProductionIndentsServiceItemCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify an item in a production indent
         *
         * @generated from rpc Scailo.ProductionIndentsService.ModifyProductionIndentItem
         */
        modifyProductionIndentItem: {
            name: "ModifyProductionIndentItem",
            I: production_indents_scailo_pb_js_1.ProductionIndentsServiceItemUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve an item in a production indent
         *
         * @generated from rpc Scailo.ProductionIndentsService.ApproveProductionIndentItem
         */
        approveProductionIndentItem: {
            name: "ApproveProductionIndentItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an item in a production indent
         *
         * @generated from rpc Scailo.ProductionIndentsService.DeleteProductionIndentItem
         */
        deleteProductionIndentItem: {
            name: "DeleteProductionIndentItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder items in a production indent
         *
         * @generated from rpc Scailo.ProductionIndentsService.ReorderProductionIndentItems
         */
        reorderProductionIndentItems: {
            name: "ReorderProductionIndentItems",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Production Indent Item by ID
         *
         * @generated from rpc Scailo.ProductionIndentsService.ViewProductionIndentItemByID
         */
        viewProductionIndentItemByID: {
            name: "ViewProductionIndentItemByID",
            I: base_scailo_pb_js_1.Identifier,
            O: production_indents_scailo_pb_js_1.ProductionIndentItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved production indent items for given production indent ID
         *
         * @generated from rpc Scailo.ProductionIndentsService.ViewApprovedProductionIndentItems
         */
        viewApprovedProductionIndentItems: {
            name: "ViewApprovedProductionIndentItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: production_indents_scailo_pb_js_1.ProductionIndentsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved production indent items for given production indent ID
         *
         * @generated from rpc Scailo.ProductionIndentsService.ViewUnapprovedProductionIndentItems
         */
        viewUnapprovedProductionIndentItems: {
            name: "ViewUnapprovedProductionIndentItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: production_indents_scailo_pb_js_1.ProductionIndentsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the production indent item
         *
         * @generated from rpc Scailo.ProductionIndentsService.ViewProductionIndentItemHistory
         */
        viewProductionIndentItemHistory: {
            name: "ViewProductionIndentItemHistory",
            I: production_indents_scailo_pb_js_1.ProductionIndentItemHistoryRequest,
            O: production_indents_scailo_pb_js_1.ProductionIndentsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved production indent items for given production indent ID with pagination
         *
         * @generated from rpc Scailo.ProductionIndentsService.ViewPaginatedApprovedProductionIndentItems
         */
        viewPaginatedApprovedProductionIndentItems: {
            name: "ViewPaginatedApprovedProductionIndentItems",
            I: production_indents_scailo_pb_js_1.ProductionIndentItemsSearchRequest,
            O: production_indents_scailo_pb_js_1.ProductionIndentsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved production indent items for given production indent ID with pagination
         *
         * @generated from rpc Scailo.ProductionIndentsService.ViewPaginatedUnapprovedProductionIndentItems
         */
        viewPaginatedUnapprovedProductionIndentItems: {
            name: "ViewPaginatedUnapprovedProductionIndentItems",
            I: production_indents_scailo_pb_js_1.ProductionIndentItemsSearchRequest,
            O: production_indents_scailo_pb_js_1.ProductionIndentsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through production indent items with pagination
         *
         * @generated from rpc Scailo.ProductionIndentsService.SearchItemsWithPagination
         */
        searchItemsWithPagination: {
            name: "SearchItemsWithPagination",
            I: production_indents_scailo_pb_js_1.ProductionIndentItemsSearchRequest,
            O: production_indents_scailo_pb_js_1.ProductionIndentsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * CSV operations
         * Download the CSV file that could be used to upload items from the filled CSV file. The same file can also be used as a template when there are no existing records
         *
         * @generated from rpc Scailo.ProductionIndentsService.DownloadItemsAsCSV
         */
        downloadItemsAsCSV: {
            name: "DownloadItemsAsCSV",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Upload items using a CSV file. This is an idempotent operation. All the existing items are deleted before adding the items from the file.
         *
         * @generated from rpc Scailo.ProductionIndentsService.UploadProductionIndentItems
         */
        uploadProductionIndentItems: {
            name: "UploadProductionIndentItems",
            I: base_scailo_pb_js_1.IdentifierUUIDWithFile,
            O: base_scailo_pb_js_1.IdentifiersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.ProductionIndentsService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: production_indents_scailo_pb_js_1.ProductionIndent,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by UUID
         *
         * @generated from rpc Scailo.ProductionIndentsService.ViewByUUID
         */
        viewByUUID: {
            name: "ViewByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: production_indents_scailo_pb_js_1.ProductionIndent,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View only essential components by ID (without logs)
         *
         * @generated from rpc Scailo.ProductionIndentsService.ViewEssentialByID
         */
        viewEssentialByID: {
            name: "ViewEssentialByID",
            I: base_scailo_pb_js_1.Identifier,
            O: production_indents_scailo_pb_js_1.ProductionIndent,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View only essential components (without logs) that matches the given UUID
         *
         * @generated from rpc Scailo.ProductionIndentsService.ViewEssentialByUUID
         */
        viewEssentialByUUID: {
            name: "ViewEssentialByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: production_indents_scailo_pb_js_1.ProductionIndent,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all records with the given IDs
         *
         * @generated from rpc Scailo.ProductionIndentsService.ViewFromIDs
         */
        viewFromIDs: {
            name: "ViewFromIDs",
            I: base_scailo_pb_js_1.IdentifiersList,
            O: production_indents_scailo_pb_js_1.ProductionIndentsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.ProductionIndentsService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: production_indents_scailo_pb_js_1.ProductionIndentsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.ProductionIndentsService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: production_indents_scailo_pb_js_1.ProductionIndentsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.ProductionIndentsService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: production_indents_scailo_pb_js_1.ProductionIndentsServicePaginationReq,
            O: production_indents_scailo_pb_js_1.ProductionIndentsServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective families for the given production indent
         *
         * @generated from rpc Scailo.ProductionIndentsService.ViewProspectiveFamilies
         */
        viewProspectiveFamilies: {
            name: "ViewProspectiveFamilies",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Filter prospective families for the record represented by the given UUID identifier
         *
         * @generated from rpc Scailo.ProductionIndentsService.FilterProspectiveFamilies
         */
        filterProspectiveFamilies: {
            name: "FilterProspectiveFamilies",
            I: families_scailo_pb_js_1.FilterFamiliesReqForIdentifier,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective production indent item info for the given family ID and production indent ID
         *
         * @generated from rpc Scailo.ProductionIndentsService.ViewProspectiveProductionIndentItem
         */
        viewProspectiveProductionIndentItem: {
            name: "ViewProspectiveProductionIndentItem",
            I: production_indents_scailo_pb_js_1.ProductionIndentItemProspectiveInfoRequest,
            O: production_indents_scailo_pb_js_1.ProductionIndentsServiceItemCreateRequest,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download production indent with the given IdentifierUUID (can be used to allow public downloads)
         *
         * @generated from rpc Scailo.ProductionIndentsService.DownloadByUUID
         */
        downloadByUUID: {
            name: "DownloadByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View issued (stock issuance) statistics of the production indent
         *
         * @generated from rpc Scailo.ProductionIndentsService.ViewIssuedStatistics
         */
        viewIssuedStatistics: {
            name: "ViewIssuedStatistics",
            I: base_scailo_pb_js_1.Identifier,
            O: production_indents_scailo_pb_js_1.ProductionIndentIssuedStatisticsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the added quantity for the family with the given request payload
         *
         * @generated from rpc Scailo.ProductionIndentsService.ViewAddedQuantityForFamily
         */
        viewAddedQuantityForFamily: {
            name: "ViewAddedQuantityForFamily",
            I: production_indents_scailo_pb_js_1.ProductionIndentItemProspectiveInfoRequest,
            O: base_scailo_pb_js_1.QuantityResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.ProductionIndentsService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: production_indents_scailo_pb_js_1.ProductionIndentsServiceSearchAllReq,
            O: production_indents_scailo_pb_js_1.ProductionIndentsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.ProductionIndentsService.Filter
         */
        filter: {
            name: "Filter",
            I: production_indents_scailo_pb_js_1.ProductionIndentsServiceFilterReq,
            O: production_indents_scailo_pb_js_1.ProductionIndentsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.ProductionIndentsService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.ProductionIndentsService.Count
         */
        count: {
            name: "Count",
            I: production_indents_scailo_pb_js_1.ProductionIndentsServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * CSV operations
         * Download the CSV file that consists of the list of records according to the given filter request. The same file could also be used as a template for uploading records
         *
         * @generated from rpc Scailo.ProductionIndentsService.DownloadAsCSV
         */
        downloadAsCSV: {
            name: "DownloadAsCSV",
            I: production_indents_scailo_pb_js_1.ProductionIndentsServiceFilterReq,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
